import { handle } from 'redux-pack';

export const CHOOSE_LOCALE = 'CHOOSE_LOCALE';

export default (state = null, action) => {
  switch (action.type) {
    case CHOOSE_LOCALE:
      return handle(state, action, {
        success: () => action.meta.locale,
      });

    default:
      return state;
  }
};
