import { css } from 'styled-components';

export const ellipsis = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const media = px => (...args) => css`
  @media only screen and (min-width: ${px}) {
    ${css(...args)};
  }
`;

export const maxWidthMedia = px => (...args) => css`
  @media only screen and (max-width: ${px}) {
    ${css(...args)};
  }
`;

export const touchScreenClickable = `
  cursor: pointer;
`;

export default ellipsis;
