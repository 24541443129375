import { filter, path, pipe, equals } from 'ramda';
import { isPresent } from 'lib/data-manipulation';
import { getPrimaryTargetedItem } from './targeting';
import {
  wrangleBannerOrCarousel,
  wrangleNotice,
  wranglePopup,
  wrangleInGrid,
} from './utils';
import debug from './debug';

const PLACEMENTS = {
  primaryBanner: 'primaryBanner',
  secondaryBanner: 'secondaryBanner',
  notice: 'pageNotice',
  popup: 'popUp',
  inGrid: 'inGridItem',
  video: 'video',
  techSheet: 'techSheet',
};
const pagesFromPayload = ({ items, total }) => (total === 0 ? [] : items);

const hasPlacement = type => page => isPresent(page.fields[type]);

const getPlacement = type => path(['fields', type]);

const normalizeParams = entries =>
  Object.keys(entries)
    .sort()
    .map(key =>
      Array.isArray(entries[key])
        ? {
            [key]: entries[key].sort(),
          }
        : { [key]: entries[key] },
    );

const hasParams = params => items =>
  items?.fields?.params
    ? equals(normalizeParams(params), normalizeParams(items?.fields?.params))
    : true;

const findContent = (type, log) => ({
  payload,
  account,
  shoppingMode,
  params,
}) =>
  pipe(
    pagesFromPayload,
    filter(hasParams(params)),
    log.recordConsideredPages,
    filter(hasPlacement(type)),
    log.recordFilteredPages,
    getPrimaryTargetedItem(account, shoppingMode, log),
    log.recordSelectedPage,
    getPlacement(type),
  )(payload);

export const getPrimaryBanner = pipe(
  findContent(PLACEMENTS.primaryBanner, debug(PLACEMENTS.primaryBanner)),
  wrangleBannerOrCarousel,
);

export const getSecondaryBanner = pipe(
  findContent(PLACEMENTS.secondaryBanner, debug(PLACEMENTS.secondaryBanner)),
  wrangleBannerOrCarousel,
);

export const getInGrid = pipe(
  findContent(PLACEMENTS.inGrid, debug(PLACEMENTS.inGrid)),
  wrangleInGrid,
);

export const getPageNotice = pipe(
  findContent(PLACEMENTS.notice, debug(PLACEMENTS.notice)),
  wrangleNotice,
);

export const getPopup = pipe(
  findContent(PLACEMENTS.popup, debug(PLACEMENTS.popup)),
  wranglePopup,
);

export const getVideo = pipe(
  findContent(PLACEMENTS.video, debug(PLACEMENTS.video)),
  data => ({
    filename: data?.fields?.filename,
    position: data?.fields?.position,
  }),
);

export const getTechSheet = data =>
  data?.payload?.includes?.Asset?.map(asset => ({
    source: asset?.fields?.file?.url,
  }));
