import {
  all,
  applySpec,
  assoc,
  compose,
  contains,
  find,
  flatten,
  groupBy,
  identity,
  indexBy,
  isNil,
  keys,
  map,
  mergeDeepRight,
  omit,
  path,
  pipe,
  pluck,
  pick,
  prop,
  propEq,
  propOr,
  reject,
  sortBy,
  unapply,
  uniq,
  values,
} from 'ramda';
import { formatDate, tryParseDate } from 'lib/dates';
import { getGenericArticleId } from 'lib/product-ids';
import { mergeAll } from 'lib/data-manipulation';
import { injectHeaders } from 'middleware/ua-api-injector';

export const mungeStyleDetails = applySpec({
  sizeChartId: path(['sizing', 'chartID']),
  sizeGroupId: path(['sizing', 'sizeGroupID']),
  cut: path(['sizing', 'cut']),
  fitChartId: path(['fit', 'chartID']),
  name: prop('name'),
  fulfillerName: prop('fulfillerName'),
  genericArticles: compose(
    sortBy(prop('id')),
    uniq,
    map(
      applySpec({
        id: prop('materialID'),
        colorNames: compose(pick(['name', 'name2', 'name3']), prop('color')),
        colorHex: path(['color', 'code']),
        colorName: path(['color', 'name']),
        chip: path(['color', 'chipURL']),
        sizes: propOr([], 'sizes'),
        firstShipDate: compose(formatDate, tryParseDate, prop('firstShipDate')),
        isStock: prop('isStock'),
        requiresRoster: prop('requiresRoster'),
      }),
    ),
    prop('materials'),
  ),
});

export const mungeStylesDetails = compose(
  map(mungeStyleDetails),
  indexBy(prop('styleID')),
  prop('items'),
);

export const defaultGenericArticle = style => {
  const { genericArticles, defaultGenericArticleId } = style || {};
  const defaultId =
    defaultGenericArticleId ||
    (genericArticles && genericArticles.length > 0
      ? genericArticles[0].id
      : null);
  return find(propEq('id', defaultId))(genericArticles || []);
};

const mergeGaSet = gaVersions => {
  const nonUniqueCombinedSizes = pipe(
    pluck('sizes'),
    flatten,
    reject(isNil),
  )(gaVersions);

  const combinedSizes = nonUniqueCombinedSizes.filter(size => {
    const indexOfFirstWithId = nonUniqueCombinedSizes.indexOf(
      nonUniqueCombinedSizes.find(item => item.id === size.id),
    );
    return nonUniqueCombinedSizes.indexOf(size) === indexOfFirstWithId;
  });
  return assoc('sizes', combinedSizes, mergeAll(gaVersions));
};

export const mergeGenericArticles = compose(
  sortBy(prop('id')),
  values,
  map(mergeGaSet),
  groupBy(prop('id')),
  reject(isNil),
  flatten,
  unapply(identity),
);

export const mergeStyles = (currentStyles, newStyles) => {
  const newStyleIds = Object.keys(newStyles);
  return {
    ...currentStyles,
    ...newStyleIds.reduce((resultingStyles, newStyleId) => {
      const newStyle = newStyles[newStyleId];
      const existingStyle = currentStyles[newStyleId];
      return {
        ...resultingStyles,
        [newStyleId]: existingStyle
          ? {
              ...existingStyle,
              ...newStyle,
              genericArticles: mergeGenericArticles(
                existingStyle.genericArticles,
                newStyle.genericArticles,
              ),
            }
          : newStyle,
      };
    }, {}),
  };
};

export const mergeSelections = (
  currentSelections,
  copiedSizeSelections,
  genericArticleId,
  availableArticleIds,
) => {
  const clearedGenericArticleSelections = keys(currentSelections).filter(
    articleId => getGenericArticleId(articleId) === genericArticleId,
  );

  const newSelections = Object.entries(copiedSizeSelections).reduce(
    (acc, [sizeId, sizeInfo]) => {
      const articleId = `${genericArticleId}-${sizeId}`;

      if (contains(articleId, availableArticleIds)) {
        return {
          ...acc,
          [articleId]: { quantity: sizeInfo.quantity },
        };
      }

      return acc;
    },
    {},
  );

  const currentSelectionsWithoutTarget = omit(
    clearedGenericArticleSelections,
    currentSelections,
  );

  return mergeDeepRight(currentSelectionsWithoutTarget, newSelections);
};

export const styleDetailsRequest = ({
  accountId,
  styleId,
  shoppingMode,
  accessToken,
  locale,
}) =>
  injectHeaders(
    accessToken,
    locale,
  )({
    url: `/api/accounts/${accountId}/products/${styleId}`,
    method: 'get',
    params: { shoppingMode },
  });

export const mungeIsStock = compose(
  all(propEq('isStock', true)),
  path(['data', 'materials']),
);
