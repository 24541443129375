import { OPEN_CART_DRAWER, CLOSE_CART_DRAWER } from './carts';

const TOGGLE = 'screen/TOGGLE';

export const toggleScreen = enabled => ({
  type: TOGGLE,
  enabled,
});

const INITIAL_STATE = { enabled: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE:
      return { enabled: action.enabled };

    case OPEN_CART_DRAWER:
      return { enabled: true };

    case CLOSE_CART_DRAWER:
      return { enabled: false };

    default:
      return state;
  }
};
