import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  ascend,
  compose,
  descend,
  equals,
  isEmpty,
  path,
  sortBy,
  sortWith,
  toPairs,
} from 'ramda';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import analytics from 'lib/analytics';
import { isMissing, isPresent } from 'lib/data-manipulation';
import { slugRanks, slugTranslations } from 'selectors/slugs';
import { getActiveShoppingMode } from 'selectors/account';
import { categoriesShape, shoppingModeShape } from 'lib/types';
import { urlForFilters } from 'lib/filters';
import {
  colors,
  columnWidth,
  fonts,
  spacing,
  breakpoint,
  height,
} from 'lib/theme';
import { toggleScreen } from 'ducks/screen';
import { TextLink } from '../ui/links';
import Caret from '../caret';
import Loading from '../loading';
import MessagePanel from '../message-panel';

const onCategoryClick = (gender, category, shoppingMode) => () => {
  const { path: categoryPath = [] } = category || {};
  const genderCategory = [gender, ...categoryPath].join('/');
  analytics.logCategoryNavigation(genderCategory, shoppingMode);
};

const shopUrl = (gender, category, customizable = false) =>
  urlForFilters({
    ...(gender && { gender: [gender] }),
    category: category ? [category.path.join('/')] : null,
    ...(customizable && { customizable: 1 }),
  });

const toChildCategories = customizableGenders =>
  toPairs(customizableGenders).map(([k, v]) => ({
    name: k,
    slug: `gender > ${k}`,
    path: [],
    children: v,
  }));

const childCategoryWidth = {
  largeScreen: '20%',
  smallScreen: '50%',
};

const PagerCaret = styled(Caret)`
  transform: rotate(45deg) translateY(-2px);
`;

const CategoryDrawer = styled.div`
  background-color: ${colors.white};
  left: 0;
  right: 0;
  max-height: ${props => (props.open ? '1000px' : '0')};
  opacity: ${props => (props.open ? '1' : '0')};
  overflow: hidden;
  position: absolute;
  top: ${height.shopping};
  transition: opacity 300ms ease;
  width: 100%;
`;

const TopLevelMenu = styled.ul`
  background-color: ${colors.offWhite};
  width: 145px;
  flex: none;
  padding: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  list-style-type: none;
  margin: 0;

  ${media(breakpoint.medium)`
    flex-shrink: 0;
    width: calc(2 * ${columnWidth});
  `};
`;

const TopLevelMenuItem = styled.li`
  text-align: right;
  padding-left: ${spacing.xxl};
`;

const TopLevelCategory = styled.button`
  font: ${fonts.bold};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  width: 100%;
  height: 40px;
  text-align: right;
  border: none;
  display: inline-block;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  background: ${props => (props.active ? colors.white : 'none')};
`;

const LoadingWrapper = styled.div`
  padding: ${spacing.xxxl};
  text-align: center;
`;

const Parents = ({
  unfilteredGenders,
  customizableGenders,
  activeParent,
  onClick,
  slugs,
}) => {
  if (isEmpty(unfilteredGenders) || isEmpty(activeParent)) {
    return null;
  }

  return (
    <TopLevelMenu>
      {unfilteredGenders.map(gender => (
        <TopLevelMenuItem key={gender}>
          <TopLevelCategory
            onClick={onClick(gender)}
            active={gender === activeParent}
          >
            {slugs[gender]}
          </TopLevelCategory>
        </TopLevelMenuItem>
      ))}
      {isPresent(customizableGenders) && (
        <TopLevelMenuItem key="customizable">
          <TopLevelCategory
            onClick={onClick('customizable')}
            active={activeParent === 'customizable'}
          >
            <FormattedMessage id="search.filter.customizable" />
          </TopLevelCategory>
        </TopLevelMenuItem>
      )}
    </TopLevelMenu>
  );
};

Parents.defaultProps = {
  unfilteredGenders: [],
  customizableGenders: [],
  activeParent: null,
};

Parents.propTypes = {
  unfilteredGenders: PropTypes.arrayOf(PropTypes.string),
  customizableGenders: PropTypes.arrayOf(PropTypes.string),
  activeParent: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  slugs: PropTypes.shape({}).isRequired,
};

const ChildrenContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ChildCategoryList = styled.ul`
  padding-top: ${spacing.xl};
  padding-bottom: ${spacing.xxxl};
  display: block;
  list-style-type: none;
  column-count: 2;
  column-gap: 20px;

  ${media(breakpoint.medium)`
    column-count: auto;
    display: flex;
  `};
`;

const ChildCategoryListItem = styled.li`
  line-height: 1.4;
  margin-bottom: ${spacing.xl};
  width: ${childCategoryWidth.smallScreen};
  page-break-inside: avoid;
  break-inside: avoid-column;

  ${media(breakpoint.medium)`
    width: auto;
    min-width: ${columnWidth};
    margin-bottom: 0;
    max-width: ${({ numCategories }) => `calc(100% / ${numCategories})`};
    flex-grow: 1;
  `};
`;

const ChildCategory = styled(TextLink)`
  font: ${fonts.heavy};
  text-transform: uppercase;
  font-family: 'Neue Plak Bold';
`;

const Children = ({
  activeParent,
  categories,
  catSort,
  slugs,
  shoppingMode,
}) => {
  if (isMissing(categories)) {
    return null;
  }
  const customizable = activeParent === 'customizable';

  return (
    <ChildCategoryList>
      {catSort(categories).map(child => {
        const gender = customizable ? child.name : activeParent;
        const category = customizable ? null : child;
        return (
          <ChildCategoryListItem
            key={child.name}
            numCategories={categories.length}
          >
            <ChildCategory
              to={shopUrl(gender, category, customizable)}
              onClick={onCategoryClick(gender, category, shoppingMode)}
            >
              {slugs[child.name]}
            </ChildCategory>
            <Grandchildren
              categories={child.children}
              {...{ gender, customizable, catSort, slugs, shoppingMode }}
            />
          </ChildCategoryListItem>
        );
      })}
    </ChildCategoryList>
  );
};

Children.defaultProps = {
  activeParent: null,
  categories: [],
};

Children.propTypes = {
  activeParent: PropTypes.string,
  categories: categoriesShape,
  catSort: PropTypes.func.isRequired,
  slugs: PropTypes.shape({}).isRequired,
  shoppingMode: shoppingModeShape.isRequired,
};

const GrandchildCategoryList = styled.ul`
  padding: 0;
  list-style-type: none;
  margin-top: ${spacing.m};
  margin-right: ${spacing.m};
`;

const GrandchildCategory = styled(TextLink)`
  font: ${fonts.regular};
  line-height: 1.8;
  text-align: left;
`;

const Grandchildren = ({
  gender,
  customizable,
  categories,
  catSort,
  slugs,
  shoppingMode,
}) => {
  if (isEmpty(categories)) {
    return null;
  }
  return (
    <GrandchildCategoryList>
      {catSort(categories).map(category => (
        <li key={category.name}>
          <GrandchildCategory
            to={shopUrl(gender, category, customizable)}
            onClick={onCategoryClick(gender, category, shoppingMode)}
          >
            {slugs[category.name]}
          </GrandchildCategory>
        </li>
      ))}
    </GrandchildCategoryList>
  );
};

Grandchildren.defaultProps = {
  categories: [],
};

Grandchildren.propTypes = {
  gender: PropTypes.string.isRequired,
  customizable: PropTypes.bool.isRequired,
  categories: categoriesShape,
  catSort: PropTypes.func.isRequired,
  slugs: PropTypes.shape({}).isRequired,
  shoppingMode: shoppingModeShape.isRequired,
};

const CategoryBarLayout = styled.div`
  background-color: ${colors.white};
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const ShopAllParentContainer = styled.div`
  position: absolute;
  right: calc(50% - 30px);
  transform: translateX(100%);
  bottom: ${spacing.xxl};

  ${media(breakpoint.medium)`
    right: ${({ numCategories }) =>
      `calc(100% / ${numCategories > 1 ? numCategories : 2} - 10px)`};
  `};
`;

const ShopAllParentLink = styled(TextLink)`
  text-transform: uppercase;
  white-space: no-wrap;
`;

export class CategoryBar extends Component {
  constructor(props) {
    super(props);
    this.state = { activeParent: this.defaultGenderName(this.props) };
  }

  componentDidMount() {
    const { history } = this.props;
    this.unlistenToHistory = history.listen(() => {
      this.setVisibility(false);
    });
  }

  componentWillReceiveProps(nextProps) {
    const { genders } = this.props;
    if ((!equals(genders), nextProps.genders)) {
      this.setState({ activeParent: this.defaultGenderName(nextProps) });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { forceActive } = this.props;
    const { active } = this.state;
    if (forceActive !== nextProps.forceActive) {
      return !active;
    }
    return true;
  }

  componentDidUpdate() {
    const { actions, forceActive, isCartDrawerOpen } = this.props;
    const { active: currentActive } = this.state;
    const active = !!(forceActive || currentActive);
    if (!isCartDrawerOpen) {
      actions.toggleScreen(active);
    }
  }

  componentWillUnmount() {
    if (this.unlistenToHistory) {
      this.unlistenToHistory();
    }
  }

  setVisibility = visible => {
    this.setState({ active: visible });
  };

  genderNames = (props, genderType) => {
    const results = path(['genders', genderType], props);
    const names = results ? Object.keys(results) : [];
    return sortBy(s => props.slugOrder[s])(names);
  };

  defaultGenderName = props => this.genderNames(props, 'all')[0];

  selectParent = p => () => {
    this.setState({ activeParent: p });
  };

  render() {
    const {
      categories,
      genders,
      slugs,
      slugOrder,
      forceActive,
      shoppingMode,
    } = this.props;
    const { active: currentActive } = this.state;
    const active = forceActive || currentActive;

    if (genders.error) {
      return (
        <CategoryDrawer open={active}>
          <FormattedMessage id="categories.error">
            {msg => <MessagePanel text={msg} />}
          </FormattedMessage>
        </CategoryDrawer>
      );
    }

    if (genders.waiting || !genders.all) {
      return (
        <CategoryDrawer open={active}>
          <FormattedMessage id="loading">
            {msg => (
              <LoadingWrapper>
                <Loading message={msg} />
              </LoadingWrapper>
            )}
          </FormattedMessage>
        </CategoryDrawer>
      );
    }

    const { activeParent } = this.state;
    const activeChildren =
      (activeParent === 'customizable'
        ? toChildCategories(categories.customizable)
        : path(['all', activeParent], categories)) || [];

    const catSort = sortWith([
      descend(c => slugOrder[c.slug]),
      ascend(c => slugs[c.name]),
    ]);

    return (
      <CategoryDrawer open={active}>
        <CategoryBarLayout
          onMouseEnter={() => this.setVisibility(true)}
          onMouseLeave={() => this.setVisibility(false)}
        >
          <Parents
            unfilteredGenders={this.genderNames(this.props, 'all')}
            customizableGenders={this.genderNames(this.props, 'customizable')}
            onClick={this.selectParent}
            {...{ activeParent, slugs }}
          />
          <ChildrenContainer>
            <Children
              categories={activeChildren}
              {...{ slugs, catSort, activeParent, slugOrder, shoppingMode }}
            />
            <ShopAllParentContainer numCategories={activeChildren.length}>
              {activeParent === 'customizable' ? (
                <ShopAllParentLink to={shopUrl(null, null, true)}>
                  <FormattedMessage id="categories.shop-all" />{' '}
                  <FormattedMessage id="search.filter.customizable" />
                </ShopAllParentLink>
              ) : (
                <ShopAllParentLink to={shopUrl(activeParent)}>
                  <FormattedMessage id="categories.shop-all" />{' '}
                  {slugs[activeParent]}
                </ShopAllParentLink>
              )}
              <PagerCaret dir="right" />
            </ShopAllParentContainer>
          </ChildrenContainer>
        </CategoryBarLayout>
      </CategoryDrawer>
    );
  }
}

CategoryBar.propTypes = {
  actions: PropTypes.shape({
    toggleScreen: PropTypes.func.isRequired,
  }).isRequired,
  categories: PropTypes.shape({
    customizable: PropTypes.string,
  }),
  genders: PropTypes.shape({
    waiting: PropTypes.bool,
    error: PropTypes.string,
    all: PropTypes.string,
  }),
  history: PropTypes.shape({ listen: PropTypes.func.isRequired }).isRequired,
  isCartDrawerOpen: PropTypes.bool.isRequired,
  forceActive: PropTypes.bool,
  slugs: PropTypes.shape({}).isRequired,
  slugOrder: PropTypes.shape({}).isRequired,
  shoppingMode: shoppingModeShape.isRequired,
};

CategoryBar.defaultProps = {
  categories: {},
  genders: {},
  forceActive: false,
};

function mapStateToProps(state) {
  const search = state.search || {};
  return {
    isCartDrawerOpen: state.carts.drawerOpen,
    genders: search.genders,
    categories: search.categories,
    slugs: slugTranslations(state),
    slugOrder: slugRanks(state),
    shoppingMode: getActiveShoppingMode(state),
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ toggleScreen }, dispatch),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CategoryBar);
