import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { popupShape } from 'lib/types';
import { colors, fonts, spacing } from 'lib/theme';
import { NoBackgroundButton } from 'components/ui/button';
import { ButtonLink } from 'components/ui/links';
import analytics from '../../../lib/analytics';

const Image = styled.div`
  background-position: center;
  background-size: cover;
  background-image: url(${props => props.image});
  width: 220px;
`;

const Layout = styled.div`
  background-color: ${colors.white};
  height: 400px;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.m} 60px;
  min-width: 300px;
  max-width: 500px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Controls = styled.div`
  flex: 0;
`;

const Control = styled.div`
  text-align: center;
  margin: ${spacing.s} 0;
`;

const Heading = styled.div`
  color: ${colors.black};
  font: ${fonts.extraBold};
  font-size: 40px;
  text-transform: uppercase;
`;

const Subheading = styled.div`
  color: ${colors.black};
  font: ${fonts.extraLargeRegular};
  font-size: 18px;
  margin-bottom: ${spacing.xl};
`;

const Description = styled.div`
  color: ${colors.black};
  font: ${fonts.normal};
`;

const onClick = (shoppingMode, headline, markAsSeen) => () => {
  analytics.logPopupClick({
    shoppingMode,
    contentName: headline,
  });
  markAsSeen();
};

const LeftPopup = ({
  popup: { headline, subHeadline, image, linkText, href, description },
  cancelText,
  markAsSeen,
  shoppingMode,
}) => {
  useEffect(() => {
    analytics.logPopupView({
      shoppingMode,
      contentName: headline,
    });
  }, [headline, shoppingMode]);
  return (
    <Layout>
      <Image image={image} />
      <ContentLayout>
        <Content>
          <Heading>{headline}</Heading>
          <Subheading>{subHeadline}</Subheading>
          <Description>{description}</Description>
        </Content>
        <Controls>
          <Control>
            <ButtonLink
              to={href}
              onClick={onClick(shoppingMode, headline, markAsSeen)}
            >
              {linkText}
            </ButtonLink>
          </Control>
          <Control>
            <NoBackgroundButton onClick={markAsSeen}>
              {cancelText}
            </NoBackgroundButton>
          </Control>
        </Controls>
      </ContentLayout>
    </Layout>
  );
};

LeftPopup.propTypes = {
  popup: popupShape.isRequired,
  cancelText: PropTypes.node.isRequired,
  markAsSeen: PropTypes.func.isRequired,
  shoppingMode: PropTypes.string.isRequired,
};

export default LeftPopup;
