import React from 'react';
import PropTypes from 'prop-types';
import { contains } from 'ramda';
import styled from 'styled-components';
import { colors, fonts, inputHeights } from 'lib/theme';
import FocusAware from './focus-aware';
import RequiredIndicator from './required-indicator';
import StyledInputBox from './input-box/styled-input-box';
import StyledLabel from './input-box/styled-label';
import StyledControl from './input-box/styled-control';
import FieldHelp from './input-box/field-help';
import ValidationError from './validation-error';
// Utility method that can be assigned to an `<input>`'s `onKeyPress` handler to prevent non-numeric
// values from being entered (while still preserving things like up/down arrow).
const handleNonNumericInput = e => {
  if (!contains(e.key, '0123456789')) {
    e.preventDefault();
    e.stopPropagation();
  }
};
const InputContainer = styled(StyledInputBox)`
  min-width: ${props => (props.label || props.labelText ? `90px` : '80px')};
`;
const Label = StyledLabel;
const InputControl = styled(StyledControl)`
  font: ${fonts.heavy};
  box-sizing: border-box;
  padding: 8px;
  padding-top: ${props => (props.label || props.labelText ? '20px' : '8px')};
  text-align: ${props => (props.label || props.labelText ? 'left' : 'center')};
  transition: box-shadow 0.2s ease-in-out;
  appearance: none;
  &:disabled {
    background-color: ${colors.offWhite};
  }
  &::placeholder {
    font-family: 'Neue Plak Text';
    color: transparent;
    font-weight: 500;
    transition: opacity 0.1s ease-in-out;
  }
  &::-webkit-inner-spin-button {
    appearance: none;
  }
  &:focus::placeholder {
    color: ${colors.offBlack};
  }
`;
export const InputWrapper = styled.div``;
function Input({
  label,
  placeholder,
  id,
  focused,
  title,
  titleRaw,
  empty,
  required,
  size,
  help,
  errors,
  onKeyPress,
  inputAction,
  preventNonNumericValues,
  ...props
}) {
  return (
    <InputWrapper>
      <InputContainer help={help} size={size}>
        {label && (
          <Label htmlFor={id} isPlaceholder={!focused && empty}>
            <span>{label}</span>
            {required && <RequiredIndicator />}
          </Label>
        )}
        <InputControl
          as="input"
          {...props}
          id={id}
          label={label}
          required={required}
          placeholder={placeholder}
          title={titleRaw || title}
          onKeyPress={
            preventNonNumericValues ? handleNonNumericInput : onKeyPress
          }
        />
        {inputAction}
      </InputContainer>
      <ValidationError errors={errors} />
      {help && <FieldHelp>{help}</FieldHelp>}
    </InputWrapper>
  );
}
Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  focused: PropTypes.bool.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
  titleRaw: PropTypes.string,
  empty: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  help: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  preventNonNumericValues: PropTypes.bool,
  inputAction: PropTypes.element,
  onKeyPress: PropTypes.func,
};
Input.defaultProps = {
  label: '',
  placeholder: '',
  size: 'normal',
  inputAction: null,
  title: '',
  titleRaw: null,
  required: false,
  help: null,
  errors: [],
  preventNonNumericValues: false,
  onKeyPress: () => {},
};
export const ShortInput = styled(FocusAware(Input))`
  height: ${inputHeights.small};
`;
export default FocusAware(Input);
