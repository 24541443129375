import { combineReducers } from 'redux';
import { compose, filter, find, pluck, propEq, uniq } from 'ramda';
import gql from 'graphql-tag';
import { grafikiShoppingModes } from 'lib/user';
import { getGenericArticlesById } from '../search';
import userCarts from './userCarts';
import byId from './byId';
import cartIdEditing, { EDIT_CART } from './cartIdEditing';
import drawerOpen, { OPEN_CART_DRAWER, CLOSE_CART_DRAWER } from './drawerOpen';

import itemEditing, { EDIT_STYLE } from './item-editing';

import activeCart, {
  SET_ACTIVE_CART,
  FETCH_CARTS,
  FETCH_CART,
} from './activeCart';

export const SHARE_CART = 'cart/SHARE_CART';

export {
  SET_ACTIVE_CART,
  FETCH_CARTS,
  FETCH_CART,
  EDIT_STYLE,
  EDIT_CART,
  OPEN_CART_DRAWER,
  CLOSE_CART_DRAWER,
};

const cartFragment = gql`
  fragment CartF on Cart {
    id
    created
    cartName
    shoppingMode
    shipmentDate
    orders {
      id
    }
    account {
      id
      shoppingModes {
        mode
        firstShipDate
        lastShipDate
      }
    }
    owner {
      id
      name
    }
    creator {
      id
      name
    }
    items {
      unitCount
      edges {
        prices {
          unit {
            amount
            code: currency
          }
          unitDiscount {
            amount
            code: currency
          }
        }
        node {
          id
          quantity
          styleId
          variantId
          recipeCode
          sizeCode
          article {
            name
            colorName
            colorHex
          }
        }
      }
    }
    totals {
      base {
        amount
        code: currency
      }
      discount {
        amount
        code: currency
      }
    }
  }
`;

const fetchCartsQuery = gql`
  ${cartFragment}

  query FetchCarts($accountId: ID!, $shoppingMode: ShoppingMode) {
    node(id: $accountId) {
      ... on Account {
        id
        carts(
          shoppingMode: $shoppingMode
          isActive: true
          includeEmpty: false
          includeCreator: true
        ) {
          edges {
            node {
              ...CartF
            }
          }
        }
      }
    }
  }
`;

const fetchCartQuery = gql`
  ${cartFragment}

  query FetchCart($id: ID!) {
    node(id: $id) {
      ... on Cart {
        ...CartF
        # We're adding account and addresses here as a hack
        # there's a race in Apollo when loading the checkout page
        # and this fixes it. Our goal is to pull this data
        # fetching out of the Redux action entirely.
        account {
          id
          addresses: shippingAddresses {
            id
          }
        }
      }
    }
  }
`;

export const fetchCarts = (accountId, shoppingMode, userId) => ({
  type: FETCH_CARTS,
  // asynchronously import apollo client to avoid circular imports
  promise: import('index').then(({ graphqlClient }) =>
    graphqlClient.query({
      query: fetchCartsQuery,
      variables: {
        accountId,
        shoppingMode: shoppingMode
          ? grafikiShoppingModes[shoppingMode]
          : 'AtOnce',
      },
      fetchPolicy: 'network-only',
    }),
  ),
  meta: {
    accountId,
    shoppingMode,
    userId,
  },
});

export const fetchCart = id => ({
  type: FETCH_CART,
  // asynchronously import apollo client to avoid circular imports
  promise: import('index').then(({ graphqlClient }) =>
    graphqlClient.query({
      query: fetchCartQuery,
      variables: { id },
      fetchPolicy: 'network-only',
    }),
  ),
  meta: { id },
});

export const editStyle = ({
  styleId,
  recipeCode,
  recipeSetCode,
  rosterName,
  genericArticleId,
}) => ({
  type: EDIT_STYLE,
  meta: { styleId, recipeCode, recipeSetCode, rosterName, genericArticleId },
});

export const editCart = id => ({ type: EDIT_CART, meta: { id } });

export const shareCart = id => ({ type: SHARE_CART, meta: { id } });

export const fetchGenericArticlesIfNeeded = (styles, cartItems) => {
  const needToFetch = ({ styleId, genericArticleId }) =>
    !(
      styles[styleId] &&
      find(propEq('id', genericArticleId))(
        styles[styleId].genericArticles || [],
      )
    );
  const genericArticleIdsToFetch = compose(
    uniq,
    pluck('genericArticleId'),
    filter(needToFetch),
  )(cartItems || []);
  if (genericArticleIdsToFetch.length > 0) {
    return getGenericArticlesById(genericArticleIdsToFetch);
  }
  return { type: '' };
};

export const openCartDrawer = () => ({ type: OPEN_CART_DRAWER });

export const closeCartDrawer = () => ({ type: CLOSE_CART_DRAWER });

export const setActiveCart = id => ({ type: SET_ACTIVE_CART, meta: { id } });

export default combineReducers({
  activeCart,
  byId,
  cartIdEditing,
  drawerOpen,
  itemEditing,
  userCarts,
});
