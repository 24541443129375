import {
  isNil,
  prop,
  find,
  propEq,
  mergeDeepRight,
  sortBy,
  compose,
  map,
  indexBy,
  groupBy,
  applySpec,
  always,
} from 'ramda';
import { mungeProduct, mungeGenericArticle } from 'lib/algolia';
import {
  mungeStyleDetails,
  mungeStylesDetails,
  mergeStyles,
} from 'lib/products';
import {
  GET_PRODUCTS,
  INSTANT_SEARCH,
  GET_DETAILS,
  GET_GENERIC_ARTICLES,
  GET_GENERIC_ARTICLES_BY_ID,
  GET_GENERIC_ARTICLE_DETAILS,
  GET_STYLE_DETAILS,
  GET_STYLES_DETAILS,
} from './index';

export const initialProducts = {};

const returnedProducts = compose(indexBy(prop('styleCode')), map(mungeProduct));

const products = (currentProducts = {}, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case GET_PRODUCTS:
      return mergeStyles(
        currentProducts,
        returnedProducts(payload.results[0].hits),
      );

    case INSTANT_SEARCH:
      return mergeStyles(currentProducts, returnedProducts(payload.hits));

    case GET_DETAILS:
      return mergeStyles(currentProducts, {
        [meta.styleCode]: mungeProduct(payload.hits[0]),
      });

    case GET_STYLES_DETAILS:
      return mergeStyles(currentProducts, mungeStylesDetails(payload.data));

    case GET_GENERIC_ARTICLES:
      return mergeStyles(
        currentProducts,
        compose(
          map(
            applySpec({
              genericArticles: compose(
                sortBy(prop('id')),
                map(mungeGenericArticle),
              ),
              allGenericArticlesLoaded: always(true),
            }),
          ),
          groupBy(prop('styleId')),
        )(payload.hits),
      );

    case GET_GENERIC_ARTICLES_BY_ID: {
      const newProducts = payload.hits.reduce((prods, result) => {
        // TODO: This check is only here to handle a combination of 2 things:
        // UA server returns incorrect index name, and Algolia continues to
        // return 200 error code when an index is missing (which is a bug
        // according to their own docs
        // https://www.algolia.com/doc/rest-api/search/?language=javascript#errors-9
        if (isNil(result)) {
          return null;
        }
        const style = prods[result.styleId] || {};

        const concattedArray = [
          ...(style.genericArticles || []),
          mungeGenericArticle(result),
        ];

        const genericArticles = concattedArray.filter(article => {
          const indexOfFirstWithId = concattedArray.indexOf(
            concattedArray.find(item => item.id === article.id),
          );
          return concattedArray.indexOf(article) === indexOfFirstWithId;
        });

        return {
          ...prods,
          [result.styleId]: {
            ...mungeProduct(result),
            genericArticles,
          },
        };
      }, currentProducts);
      return { ...currentProducts, ...newProducts };
    }

    case GET_GENERIC_ARTICLE_DETAILS: {
      const { assets = [], fulfillerIDs, isStock } = payload.data || {};
      const { styleId, genericArticleId } = meta;
      const product = currentProducts[styleId] || {};
      const { genericArticles = [] } = product;
      const genericArticle = find(propEq('id', genericArticleId))(
        genericArticles,
      );
      const updatedGenericArticles = genericArticles.reduce(
        (prevGenArticles, genArticle) => [
          ...prevGenArticles,
          genArticle.id === genericArticleId
            ? {
                ...genericArticle,
                images: assets.map(a => a.url),
                fulfillerIDs,
                isStock,
              }
            : genArticle,
        ],
        [],
      );
      return mergeDeepRight(currentProducts, {
        [styleId]: { genericArticles: updatedGenericArticles },
      });
    }

    case GET_STYLE_DETAILS:
      return mergeStyles(currentProducts, {
        [meta.styleId]: mungeStyleDetails(payload.data),
      });

    default:
      return currentProducts;
  }
};

export default products;
