import { path, pipe } from 'ramda';
import { LIFECYCLE } from 'redux-pack';
import { isPresent } from './data-manipulation';

const reduxLifecycle = path(['meta', 'redux-pack/LIFECYCLE']);

export const isReduxPackAction = pipe(reduxLifecycle, isPresent);

export const anySuccess = action =>
  reduxLifecycle(action) === LIFECYCLE.SUCCESS;

export const packSuccess = (action, type) =>
  action.type === type && anySuccess(action);

export const anyFailure = action =>
  reduxLifecycle(action) === LIFECYCLE.FAILURE;

export const packFailure = (action, type) =>
  action.type === type && anyFailure(action);
