import { path } from 'ramda';

export const trackIfChanged = (attr, val) => {
  if (!window || !window.newrelic) {
    return;
  }
  if (path(['newrelic', 'info', 'jsAttributes', attr], window) !== val) {
    window.newrelic.setCustomAttribute(attr, val);
  }
};

export const trackAuth = (username, accountId) => {
  trackIfChanged('uaUser', username);
  trackIfChanged('uaAccount', accountId);
};

export const recordAction = (type, payload) => {
  if (!window || !window.newrelic) {
    return;
  }
  window.newrelic.addPageAction(type, payload);
};
