import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts, spacing } from 'lib/theme';
import ellipsis from 'styles/mixins';

const OptionContainer = styled.div`
  display: block;
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
  background: ${({ highlighted }) =>
    highlighted ? colors.offWhite : colors.white};
  text-align: left;
  padding: ${spacing.s};

  &:hover,
  &:focus {
    background: ${colors.offWhite};
  }
`;

const Ellipsis = styled.div`
  ${ellipsis};
`;

const Title = styled(Ellipsis)`
  font: ${fonts.regular};
  color: ${colors.black};
`;

const Subtitle = styled(Ellipsis)`
  font: ${fonts.tiny};
  color: ${colors.darkGrey};

  ${/* sc-sel */ Title} + & {
    margin-top: ${spacing.s};
  }
`;

const Option = ({ highlighted, getItemProps, option }) => {
  const { title, subtitle } = option;

  return (
    <OptionContainer
      highlighted={highlighted}
      {...getItemProps({ item: option })}
    >
      <Title role="textbox">{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </OptionContainer>
  );
};

Option.propTypes = {
  highlighted: PropTypes.bool.isRequired,
  getItemProps: PropTypes.func.isRequired,
  option: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }).isRequired,
};

export default Option;
