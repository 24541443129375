import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Query, withApollo } from 'react-apollo';
import { Redirect, Switch, withRouter, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logout, fetchAccount } from 'ducks/authentication';
import loadGenders from 'ducks/search/load-genders';
import { userSummaryShape, shoppingModeShape, accountShape } from 'lib/types';
import useMatchMedia from 'lib/use-match-media';
import { getParams } from 'lib/params';
import { breakpoint } from 'lib/theme';
import { withUserContext } from 'lib/use-user-context';
import { mungeUserInfo } from 'lib/user';
import { isMissing } from 'lib/data-manipulation';
import { setUser } from 'ducks/user';
import { delayAction } from 'ducks/delayed-actions';
import { GET_ACTIVE_CONFIG } from 'ducks/search';
import { hasAccountError, getActiveSearchConfig } from 'selectors/account';
import { fetchSlugs } from 'ducks/slugs';
import ProfileInfoForm from 'components/create-account/profile-info-form';
import MarketingProvider from 'components/marketing/provider';
import {
  MANAGE_ORGANIZATIONS,
  MANAGE_SALES_REPS,
  VIEW_ALLOTMENTS,
  MANAGE_USERS,
} from 'lib/permissions';
import { isUserProfileLoaded, CURRENT_USER_QUERY } from 'selectors/user';
import ErrorMessage from './ui/error-message';
import {
  ConnectedPage,
  ShoppingPage,
  AdminPage,
  PublicPage,
} from './home/page';
import Loading, { LoadingContainer } from './loading';
import Loadable from './loadable';

const AsyncCheckout = Loadable({ loader: () => import('./checkout/checkout') });
const AsyncPublicCheckout = Loadable({
  loader: () => import('./checkout/public/checkout'),
});
const AsyncPublicAllotmentCheckout = Loadable({
  loader: () => import('./checkout/public-allotment/checkout'),
});
const AsyncAllotments = Loadable({
  loader: () => import('./allotments'),
});
const AsyncPublicAllotments = Loadable({
  loader: () => import('./allotments-public'),
});
const AsyncSuggestedOrder = Loadable({
  loader: () => import('./suggested-order'),
});

const AsyncCart = Loadable({ loader: () => import('./cart/cart') });
const AsyncQuote = Loadable({ loader: () => import('./cart/quote') });
const AsyncDashboard = Loadable({
  loader: () => import('./dashboard'),
});
const AsyncPublicDashboard = Loadable({
  loader: () => import('./dashboard/public-dashboard'),
});
const AsyncSearchResults = Loadable({
  loader: () => import('./products/search-results'),
});
const AsyncProductDetail = Loadable({
  loader: () => import('./products/product-detail'),
});
const AsyncTechnologies = Loadable({
  loader: () => import('./technologies'),
});
const AsyncSizeFit = Loadable({
  loader: () => import('./size-fit/browse'),
});
const AsyncMyInformation = Loadable({
  loader: () => import('./user/my-information'),
});
const AsyncOrganizations = Loadable({
  loader: () => import('./organizations'),
});
const AsyncOrganizationDetail = Loadable({
  loader: () => import('./organizations/organization-detail'),
});
const AsyncOrderHistory = Loadable({
  loader: () => import('./orders/order-history'),
});
const AsyncPublicOrderHistory = Loadable({
  loader: () => import('./orders/public-order-history'),
});
const AsyncManageUsers = Loadable({
  loader: () => import('./manage-users/manage-users'),
});
const AsyncNewUser = Loadable({
  loader: () => import('./user-form/user-form'),
});
const AsyncAccountUserForm = Loadable({
  loader: () => import('./user-form/account-user'),
});
const AsyncPublicUserForm = Loadable({
  loader: () => import('./user-form/public-user'),
});
const AsyncRosters = Loadable({
  loader: () => import('./rosters/rosters'),
});
const AsyncRosterDetail = Loadable({
  loader: () => import('./rosters/roster-detail'),
});
const AsyncOrderDetail = Loadable({
  loader: () => import('./orders/order-detail'),
});
const AsyncPublicOrderDetail = Loadable({
  loader: () => import('./orders/public-order-detail'),
});
const AsyncPrintFormattedOrderDetail = Loadable({
  loader: () => import('./orders/print'),
});
const AsyncCartsIndex = Loadable({
  loader: () => import('./cart/carts-index'),
});
const AsyncOfflineOrdering = Loadable({
  loader: () => import('./offline-ordering'),
});
const AsyncStaticContent = Loadable({
  loader: () => import('./static-content'),
});
const AsyncSalesReps = Loadable({
  loader: () => import('./sales-reps'),
});
const AsyncSalesRepDetail = Loadable({
  loader: () => import('./sales-reps/detail'),
});
const AsyncMobileFaqPage = Loadable({
  loader: () => import('./mobile/mobile-faq'),
});

// A simple helper utility that allows us to easily introduce
// "beta" versions of routes. Simply wrap the stable and beta
// components, and this will pick based on queryString
// `betaBranch(Stable, Beta)`
// eslint-disable-next-line
const betaBranch = (StableComponent, BetaComponent) => props => {
  const { search } = useLocation();
  const isBeta = getParams(search)?.beta;
  return isBeta ? <BetaComponent {...props} /> : <StableComponent {...props} />;
};

export class Home extends Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    const { actions, searchConfig, data } = this.props;
    this.fetchUserInfo();
    this.setUserInfo(data);
    if (searchConfig) {
      actions.loadGenders();
      actions.fetchSlugs();
    } else {
      actions.delayAction(fetchSlugs(), GET_ACTIVE_CONFIG);
      actions.delayAction(loadGenders.creator(), GET_ACTIVE_CONFIG);
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, accountId, shoppingMode } = this.props;
    if (
      accountId &&
      shoppingMode &&
      (accountId !== prevProps.accountId ||
        shoppingMode !== prevProps.shoppingMode)
    ) {
      actions.delayAction(fetchSlugs(), GET_ACTIVE_CONFIG);
      actions.delayAction(loadGenders.creator(), GET_ACTIVE_CONFIG);
    }
    if (accountId && !prevProps.accountId) {
      this.fetchUserInfo();
    }
  }

  setUserInfo = data => {
    const { actions, permissions } = this.props;
    const profile = mungeUserInfo(data, permissions);
    actions.setUser(profile);
  };

  fetchUserInfo() {
    const { actions, accountId } = this.props;
    actions
      .fetchAccount(accountId, true)
      .then(() => this.setState({ ready: true }));
  }

  render() {
    const {
      configError,
      accountError,
      errorLoadingProfile,
      user,
      userProfileLoaded,
      data,
      refetch,
      selectedAccount,
      isPublic,
      hasPermission,
      isMobile,
    } = this.props;
    const { ready } = this.state;
    const appUsable = !!user && ready;
    if (errorLoadingProfile) {
      return (
        <PublicPage
          component={() => (
            <ErrorMessage>
              <FormattedMessage id="dashboard.profile-error" />
            </ErrorMessage>
          )}
        />
      );
    }
    if (configError) {
      return (
        <ShoppingPage
          component={() => (
            <ErrorMessage>
              <FormattedMessage id="dashboard.search-config-error" />
            </ErrorMessage>
          )}
        />
      );
    }
    if (accountError) {
      return (
        <ShoppingPage
          component={() => (
            <ErrorMessage>
              <FormattedMessage id="dashboard.account-error" />
            </ErrorMessage>
          )}
        />
      );
    }
    if (!appUsable) {
      return (
        <LoadingContainer>
          <FormattedMessage id="dashboard.loading">
            {msg => <Loading message={msg} />}
          </FormattedMessage>
        </LoadingContainer>
      );
    }

    // Don't block permission-protected routes until the user profile is loaded.
    // If we did "fail closed" on this check, we'd end up completely disabling
    // deep links to protected routes, since there's always a point in between
    // when the route is checked and the profile (containing permission) is
    // loaded... if "failing closed", in that interval we would redirect to /
    const can = perm => !userProfileLoaded || hasPermission(perm);

    const isMissingInfo = isPublic && isMissing(data.currentUser.addresses);

    const hasPublicAllotment =
      isPublic && data?.currentUser?.public?.allotmentAllocations?.current;

    const accountHasAllotment = !!selectedAccount?.allotmentAllocations
      ?.current;
    // When a user creates an account, we may need to capture some additional
    // profile info (e.g., their address). Redirect them to the profile info
    // form if so. Once updated, we must refetch the `currentUser`.
    if (isMissingInfo) {
      return (
        <Switch>
          <ConnectedPage
            path="/"
            showChrome={false}
            showHeader={false}
            showFooter={false}
            showNotices={false}
            component={() => <ProfileInfoForm refetchCurrentUser={refetch} />}
          />
        </Switch>
      );
    }

    const Dash = isPublic ? AsyncPublicDashboard : AsyncDashboard;
    const PublicCheckout = accountHasAllotment
      ? AsyncPublicAllotmentCheckout
      : AsyncPublicCheckout;

    return (
      <MarketingProvider>
        <Switch>
          <ShoppingPage
            exact={true}
            path="/"
            component={Dash}
            footerTopMargin={false}
          />
          <ShoppingPage
            path="/g/:searchResultsPath*"
            component={AsyncSearchResults}
          />
          <ShoppingPage
            path="/s/:setId/:searchResultsPath*"
            component={AsyncSearchResults}
          />
          <ShoppingPage
            path="/p/:styleCode"
            component={AsyncProductDetail}
            showFooter={false}
          />
          <ShoppingPage
            path="/technologies"
            component={AsyncTechnologies}
            showFooter={false}
          />
          <ShoppingPage
            path="/size-fit"
            component={AsyncSizeFit}
            showFooter={false}
          />

          <AdminPage exact={true} path="/user" component={AsyncMyInformation} />

          <ConnectedPage
            exact={true}
            path="/cart/:cartId/checkout/:checkoutStep"
            key="/cart/:cartId/checkout/:checkoutStep"
            component={isPublic ? PublicCheckout : AsyncCheckout}
            showChrome={false}
            showHeader={false}
          />
          <AdminPage
            exact={true}
            path="/carts"
            key="/carts"
            component={AsyncCartsIndex}
          />
          <AdminPage
            path="/cart/:cartId"
            key="/cart/:cartId"
            component={AsyncCart}
          />
          <AdminPage
            path="/quote/:quoteId"
            key="/quote/:quoteId"
            component={AsyncQuote}
          />
          <AdminPage
            exact={true}
            path="/orders"
            key="/orders"
            component={isPublic ? AsyncPublicOrderHistory : AsyncOrderHistory}
          />
          <AdminPage
            exact={true}
            path="/orders/:id"
            key="/orders/:id"
            component={isPublic ? AsyncPublicOrderDetail : AsyncOrderDetail}
          />
          {hasPublicAllotment && (
            <AdminPage
              exact={true}
              path="/allotments"
              key="/allotments"
              component={AsyncPublicAllotments}
            />
          )}

          {!(userProfileLoaded && isPublic) && [
            can(MANAGE_USERS) && (
              <AdminPage
                exact={true}
                path="/users"
                key="/users"
                component={AsyncManageUsers}
              />
            ),
            can(MANAGE_USERS) && (
              <AdminPage
                exact={true}
                path="/users/new"
                key="/users/new"
                component={AsyncNewUser}
              />
            ),
            <AdminPage
              exact={true}
              path="/users/account"
              key="/users/account"
              component={AsyncAccountUserForm}
            />,
            <AdminPage
              exact={true}
              path="/users/account/:id"
              key="/users/account/:id"
              component={AsyncAccountUserForm}
            />,
            <AdminPage
              exact={true}
              path="/users/public"
              key="/users/public"
              component={AsyncPublicUserForm}
            />,
            <AdminPage
              exact={true}
              path="/suggested-order/:id"
              key="/suggested-order/:id"
              component={AsyncSuggestedOrder}
            />,
            <AdminPage
              exact={true}
              path="/rosters"
              key="/rosters"
              component={AsyncRosters}
            />,
            <AdminPage
              exact={true}
              path="/rosters/:id"
              key="/rosters/:id"
              component={AsyncRosterDetail}
            />,
            <ConnectedPage
              exact={true}
              path="/orders/:id/print"
              key="/orders/:id/print"
              showChrome={false}
              showFooter={false}
              component={AsyncPrintFormattedOrderDetail}
            />,
            <AdminPage
              exact={true}
              path="/catalogs"
              key="/catalogs"
              component={AsyncOfflineOrdering}
            />,
            can(MANAGE_ORGANIZATIONS) && [
              <AdminPage
                exact={true}
                path="/organizations"
                key="/organizations"
                component={AsyncOrganizations}
              />,
              <AdminPage
                exact={true}
                path="/organizations/:id"
                key="/organizations/:id"
                component={AsyncOrganizationDetail}
              />,
            ],
            can(MANAGE_SALES_REPS) && [
              <AdminPage
                exact={true}
                path="/salesreps"
                key="/salesreps"
                component={AsyncSalesReps}
              />,
              <AdminPage
                exact={true}
                path="/salesreps/:id"
                key="/salesreps/:id"
                component={AsyncSalesRepDetail}
              />,
              <AdminPage
                exact={true}
                path="/salesreps/new"
                key="/salesreps/new"
                component={AsyncSalesRepDetail}
              />,
            ],
            can(VIEW_ALLOTMENTS) && (
              <AdminPage
                exact={true}
                path="/allotments"
                key="/allotments"
                component={AsyncAllotments}
              />
            ),
          ]}

          <PublicPage
            exact={true}
            path="/privacy"
            component={AsyncStaticContent}
          />
          <PublicPage
            exact={true}
            path="/terms"
            component={AsyncStaticContent}
          />
          {isPublic ? (
            <PublicPage
              exact={true}
              path="/faq"
              component={isMobile ? AsyncMobileFaqPage : AsyncStaticContent}
            />
          ) : (
            <AdminPage
              exact={true}
              path="/faq"
              component={isMobile ? AsyncMobileFaqPage : AsyncStaticContent}
            />
          )}
          <PublicPage
            exact={true}
            path="/training"
            component={AsyncStaticContent}
          />
          <Redirect from="*" to="/" />
        </Switch>
      </MarketingProvider>
    );
  }
}

Home.propTypes = {
  actions: PropTypes.shape({
    logout: PropTypes.func.isRequired,
    fetchAccount: PropTypes.func.isRequired,
    fetchSlugs: PropTypes.func.isRequired,
    loadGenders: PropTypes.func.isRequired,
    delayAction: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
  }).isRequired,
  client: PropTypes.shape({}).isRequired,
  isPublic: PropTypes.bool.isRequired,
  hasPermission: PropTypes.func.isRequired,
  accountError: PropTypes.bool.isRequired,
  configError: PropTypes.bool,
  searchConfig: PropTypes.shape({
    indices: PropTypes.shape({}).isRequired,
  }),
  accountId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  shoppingMode: shoppingModeShape,
  errorLoadingProfile: PropTypes.bool.isRequired,
  selectedAccount: accountShape.isRequired,
  user: userSummaryShape,
  userProfileLoaded: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    currentUser: PropTypes.shape({
      addresses: PropTypes.shape({}),
    }),
  }).isRequired,
  refetch: PropTypes.func.isRequired,
};

Home.defaultProps = {
  searchConfig: null,
  accountId: null,
  shoppingMode: null,
  configError: false,
  user: null,
};

function mapStateToProps(state) {
  const auth = state.authentication || {};
  const search = state.search || {};
  const selectedAccount = auth.selectedAccount || {};
  return {
    accountId: selectedAccount?.id,
    selectedAccount,
    shoppingMode: auth.activeShoppingMode,
    permissions: (auth.access && auth.access.permissions) || [],
    searchConfig: getActiveSearchConfig(state),
    configError: !!search.configError,
    accountError: hasAccountError(state),
    errorLoadingProfile: !!state.user.errorLoadingProfile,
    user: state.user.profile,
    userProfileLoaded: isUserProfileLoaded(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        delayAction,
        fetchAccount,
        fetchSlugs,
        loadGenders: loadGenders.creator,
        logout,
        setUser,
      },
      dispatch,
    ),
  };
}

const HomeConnected = withApollo(
  withUserContext(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)),
  ),
);

const HomeContainer = props => {
  const isMobile = useMatchMedia(`(max-width: ${breakpoint.gd.phoneMax})`);
  return (
    <Query
      query={CURRENT_USER_QUERY}
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return (
            <LoadingContainer>
              <FormattedMessage id="dashboard.loading">
                {msg => <Loading message={msg} />}
              </FormattedMessage>
            </LoadingContainer>
          );
        }
        if (error) {
          return null;
        }
        return (
          <HomeConnected
            data={data}
            refetch={refetch}
            isMobile={isMobile}
            {...props}
          />
        );
      }}
    </Query>
  );
};

export default HomeContainer;
