import { handle } from 'redux-pack';
import { assocPath, keys, mergeDeepRight, reduce } from 'ramda';
import { zip } from 'lib/data-manipulation';
import { requestCategories, wrangleCategories } from 'lib/algolia';

const type = 'search/LOAD_CATEGORIES';

const creator = genders => ({
  type,
  meta: {
    algoliaRequest: requestCategories(genders),
    genders: [
      ...keys(genders.all).map(g => ['all', g]),
      ...keys(genders.customizable).map(g => ['customizable', g]),
    ],
  },
});

export const start = prevState =>
  mergeDeepRight(prevState, {
    categories: { waiting: true, error: null },
  });

export const finish = prevState =>
  mergeDeepRight(prevState, {
    categories: { waiting: false },
  });

export const failure = (prevState, action) =>
  mergeDeepRight(prevState, {
    categories: { error: action.payload.message },
  });

export const success = (prevState, action) =>
  reduce(
    (acc, [catPath, resultSet]) =>
      assocPath(
        ['categories', ...catPath],
        wrangleCategories(resultSet.facets),
        acc,
      ),
    prevState,
    zip(action.meta.genders, action.payload.results),
  );

const reducer = (state, action) =>
  handle(state, action, { start, finish, failure, success });

export default { type, creator, reducer };
