import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { path } from 'ramda';
import { isPresent } from 'lib/data-manipulation';
import { getParams } from 'lib/params';
import { fetchTargetedContent } from 'ducks/marketing';
import { getSelectedAccount } from 'selectors/account';
import { accountShape } from 'lib/types';

class Provider extends React.Component {
  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps = {}) {
    const {
      account: { id, salesOrgCode },
      location: { pathname },
      shoppingMode,
    } = this.props;

    const {
      account: { id: prevId, prevSalesOrgCode },
      location: { pathname: prevPathname },
      shoppingMode: prevShoppingMode,
    } = prevProps;

    if (
      prevPathname !== pathname ||
      prevId !== id ||
      prevSalesOrgCode !== salesOrgCode ||
      prevShoppingMode !== shoppingMode
    ) {
      this.fetch();
    }
  }

  fetch() {
    const {
      account,
      location: { pathname, search },
      shoppingMode,
      fetchTargetedContent: fetchTargetedContentP,
    } = this.props;

    const params = getParams(search);

    if (isPresent(account) && isPresent(shoppingMode)) {
      fetchTargetedContentP(pathname, account, shoppingMode, params);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

Provider.defaultProps = {
  account: {},
  shoppingMode: null,
};

Provider.propTypes = {
  account: accountShape,
  fetchTargetedContent: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.shape({}).isRequired,
  }).isRequired,
  shoppingMode: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  account: getSelectedAccount(state),
  shoppingMode: path(['authentication', 'activeShoppingMode'], state),
});

const mapDispatchToProps = {
  fetchTargetedContent,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Provider);
