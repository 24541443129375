import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, spacing } from 'lib/theme';

const Arrow = styled.div`
  margin-left: ${spacing.m};
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${({ color, direction }) =>
    direction === 'down'
      ? `border-top: 6px solid ${color}`
      : `border-bottom: 6px solid ${color}`};
  display: inline-block;
`;

Arrow.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(['down', 'up']),
};

Arrow.defaultProps = {
  color: colors.black,
  direction: 'down',
};

export default Arrow;
