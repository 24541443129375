import React, { useState } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { DocumentNode } from 'apollo-link';
import { useQuery } from 'react-apollo';
import { mergeDeepWith } from 'ramda';
import ResultsFooter from 'components/forms/results-footer';
import SelectFilter from 'components/forms/select-filter';
import { createMergeStrategy } from './util';

const DynamicFilterError = styled(props => (
  <div {...props}>{props.errorLoadingMessage}</div>
))`
  border: 1px solid #efefef;
  padding: 15px 10px;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  color: #aaa;
`;

const createUpdateQuery = (existingEdges: any[]) => (
  prev: any,
  { fetchMoreResult }: any,
) => {
  return mergeDeepWith(
    createMergeStrategy(existingEdges),
    prev,
    fetchMoreResult,
  );
};

const EMPTY_PAGE_INFO: PageInfo = {
  hasNextPage: false,
  hasPrevPage: false,
  startCursor: null,
  endCursor: null,
};

const EMPTY_CONNECTION: Connection<never> = {
  pageInfo: EMPTY_PAGE_INFO,
  edges: [],
  total: 0,
};

const getHasNextPage = (pageInfo = EMPTY_PAGE_INFO) => pageInfo?.hasNextPage;
const getEndCursor = (pageInfo = EMPTY_PAGE_INFO) => pageInfo?.endCursor;

export interface Option {
  id: string;
  title?: string;
  subtitle?: string;
}

export interface DynamicFilterProps<T, V extends GQLNode> {
  query: any;
  variables?: any;
  allowAll?: boolean;
  getConnection: (data: T) => Connection<V>;
  optionFromNode?: (obj: V) => Option;
  optionToString?: (obj: Option) => string;
  onChange?: (opt: Option, selectedNode: V) => void;
  pageSize?: number;
  nodeFragment?: DocumentNode;
  nodeID?: string;
  errorLoadingMessage?: string;
  size?: string;
  label?: string;
  required?: boolean;
  emptyOptionLabel?: string;
}

const anonymousFragment = (doc: DocumentNode): DocumentNode => ({
  ...doc,
  loc: doc.loc
    ? Object.assign(doc.loc, {
        source: {
          ...doc.loc.source,
          body: doc.loc.source.body.replace(/fragment\s+(\w+)/g, `...`),
        },
      })
    : undefined,
});

function useHydratedOption(nodeFragment?: DocumentNode, nodeID?: string) {
  const [idToHydrate] = useState(nodeID);
  const shouldHydrate = !!idToHydrate;
  const { data, error, loading } = useQuery<any>(
    gql`
      query HydrateFilter($id: ID!) {
        node(id: $id) {
          id
          ${nodeFragment ? anonymousFragment(nodeFragment) : ''}
        }
      }
    `,
    {
      variables: {
        id: idToHydrate || '',
      },
      skip: !shouldHydrate,
    },
  );
  return { data, error, loading, shouldHydrate };
}

export const DynamicFilter: React.FC<DynamicFilterProps<any, any>> = ({
  query,
  variables = null,
  getConnection,
  optionFromNode = node => ({
    id: node?.id,
    title: node?.id,
  }),
  optionToString = (opt: Option, _: boolean) => opt?.id || '–',
  onChange = (opt: Option, selectedNode: any) => ({ opt, selectedNode }),
  pageSize = 10,
  nodeFragment,
  nodeID,
  label,
  required,
  emptyOptionLabel,
  errorLoadingMessage = 'The filter could not be loaded.',
  ...props
}) => {
  const emptyOption = emptyOptionLabel && {
    id: null,
    title: emptyOptionLabel,
    subtitle: null,
  };
  const [fetchingMore, setFetchMore] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(
    emptyOption || null,
  );
  const [{ searchText, first, skipQuery }, setQueryParams] = useState<any>({
    first: pageSize,
    searchText: null,
    skipQuery: true,
  });
  const { data, error, fetchMore, loading } = useQuery<any>(query, {
    variables: {
      ...variables,
      searchText,
      first,
    },
    skip: skipQuery,
  });

  const {
    data: hydratedData,
    error: hydratingError,
    loading: hydrating,
  } = useHydratedOption(nodeFragment, nodeID);
  const hydratedOption = optionFromNode(hydratedData?.node);

  const connection = data ? getConnection(data) : EMPTY_CONNECTION;
  const { edges, pageInfo } = connection;
  const endCursor = getEndCursor(pageInfo);
  const hasNextPage = getHasNextPage(pageInfo);
  const updateQuery = createUpdateQuery(edges);
  const loadMore = () => {
    if (hasNextPage) {
      setFetchMore(true);
      fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery,
      }).then(() => {
        setFetchMore(false);
      });
    }
  };

  const onSearch = (localSearchText: string) => {
    setQueryParams((prev: any) => ({
      ...prev,
      first: pageSize,
      after: null,
      searchText: localSearchText,
      skipQuery: false,
    }));
  };

  if (error || hydratingError) {
    return <DynamicFilterError errorLoadingMessage={errorLoadingMessage} />;
  }

  const onSelectOption = (option: Option) => {
    setSelectedOption(option);
    const selectedNode = edges.find(({ node: { id } }) => id === option.id)
      ?.node;
    onChange(option, selectedNode);
  };

  // We can produce a search with no data, but the intention here
  // is that we CAN'T have data, as the searchText is empty, we
  // aren't loading, and we have no edges in the connection
  const hasNoData = !searchText && !loading && edges.length === 0 && !skipQuery;

  // We disable the control if we are busy hydrating, or we know
  // we don't have data. There are likely other scenarios, and we
  // should handle those.
  const isDisabled = hydrating || hasNoData;

  const mapOptions = (options: Array<Option>) =>
    !!emptyOption ? [...[emptyOption], ...options] : options;

  return (
    <SelectFilter
      {...props}
      isDisabled={isDisabled}
      isLoading={loading}
      getOptions={onSearch}
      onSelectOption={onSelectOption}
      options={mapOptions(
        edges.map(({ node }: Edge<any>) => optionFromNode(node)),
      )}
      selectedOption={selectedOption || hydratedOption}
      optionToString={(opt: Option) => optionToString(opt, hydrating)}
      label={label}
      required={required}
      resultsFooterSection={
        <ResultsFooter
          onClick={() => loadMore()}
          hasMore={hasNextPage}
          loading={fetchingMore}
        />
      }
    />
  );
};
