import {
  __,
  any,
  compose,
  concat,
  find,
  groupBy,
  head,
  isNil,
  join,
  keys,
  last,
  map,
  path,
  pathOr,
  pick,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  props,
  reject,
  sortBy,
  split,
  toPairs,
  uniq,
  values,
  isEmpty,
} from 'ramda';
import {
  multiplyCurrency,
  sumCurrencies,
  mungeCurrency,
  negativeCurrency,
} from 'lib/currency';
import { formatDate, tryParseDate } from 'lib/dates';
import { PRICE_ACCOUNT, PRICE_MSRP } from 'lib/pricing';
import { getGenericArticleId, getStyleCode } from 'lib/product-ids';
import { isMissing, isPresent } from 'lib/data-manipulation';
import { b2bApiShoppingModes } from 'lib/user';

export const CartTypes = {
  ACTIVE: 'active',
  OTHER: 'other',
};

const mungeRecipeSet = cartItem => {
  const { recipeSet } = cartItem || {};
  const { recipeSetCode, name } = recipeSet || {};
  return recipeSet ? { recipeSetCode, name } : undefined;
};

export const mungeGrafikiCartItemIn = ({ node: cartItem, prices }) => {
  const unitDiscount = mungeCurrency(prices.unitDiscount);
  return {
    id: cartItem.cartItemId,
    name: cartItem.article.name,
    quantity: cartItem.quantity,
    articleId: cartItem.variantId,
    genericArticleId: getGenericArticleId(cartItem.variantId),
    styleId: getStyleCode(cartItem.variantId),
    unitPricing: {
      msrp: sumCurrencies([
        mungeCurrency(prices.unit),
        negativeCurrency(unitDiscount),
      ]),
      accountPrice: sumCurrencies([
        mungeCurrency(prices.unit),
        negativeCurrency(unitDiscount),
      ]),
    },
    colorName: cartItem.article.colorName,
    colorHex: cartItem.article.colorHex,
    sizeCode: cartItem.sizeCode,
    recipeCode: cartItem.recipeCode,
    recipeSet: mungeRecipeSet(cartItem),
    embellishmentName: cartItem.embellishmentName,
    embellishmentNumber: cartItem.embellishmentNumber,
    embellishmentInitials: cartItem.embellishmentInitials,
  };
};

export const mungeCartItemOut = cartItem => ({
  cartItemId: cartItem.id,
  sku: cartItem.articleId,
  quantity: cartItem.quantity,
  recipe: cartItem.recipeCode,
  embellishmentName: cartItem.embellishmentName,
  embellishmentNumber: cartItem.embellishmentNumber,
  embellishmentInitials: cartItem.embellishmentInitials,
  rosterName: cartItem.roster ? cartItem.roster.name : cartItem.rosterName,
});

export const aggregateQuantities = cartItems => {
  const groupedItems = groupBy(
    props([
      'sku',
      'recipeCode',
      'embellishmentName',
      'embellishmentNumber',
      'embellishmentInitials',
      'sizeModifier',
    ]),
  )(cartItems);
  return Object.values(groupedItems).map(items =>
    items.reduce(
      (summedItem, item) => ({
        ...item,
        quantity: item.quantity + summedItem.quantity,
      }),
      { quantity: 0 },
    ),
  );
};

export const summarizeSizes = pipe(
  map(variant => ({ ...variant, sku: variant.articleId })),
  aggregateQuantities,
  map(pick(['articleId', 'quantity', 'sizeCode', 'sizeRank'])),
  sortBy(prop('sizeRank')),
);

/*
 * Explicitly set item quantities to 0 if they've been removed
 * (removed === present in initialItems but not in updatedItems)
 * TODO not ideal; preferably the API would handle this instead
 * but currently items not included in the request payload would
 * remain unchanged (i.e. explicit exclusion doesn't delete items)
 */
export const handleDeletedQuantities = (
  initialItems = [],
) => updatedPlayerItems => {
  const itemsForDelete = initialItems.reduce((acc, item) => {
    const shouldDelete =
      !isEmpty(updatedPlayerItems) &&
      !updatedPlayerItems.some(({ cartItemId }) => cartItemId === item.id);
    return shouldDelete
      ? [
          ...acc,
          {
            ...mungeCartItemOut(item),
            quantity: 0,
          },
        ]
      : acc;
  }, []);
  return updatedPlayerItems.concat(itemsForDelete);
};

export const mungeCartOut = ({
  selections = {},
  initialItems = [],
  recipeCodes = {},
  rosterName = null,
  playerInfo = {},
}) => {
  const mungePlayerInfo = Object.entries(playerInfo)
    .map(([sku, players]) =>
      players.map(player => {
        const {
          id,
          name,
          number,
          initials,
          sizeModifier,
          quantity = 1,
          isBlank,
        } = player;
        return {
          quantity,
          sku,
          recipe: recipeCodes[getGenericArticleId(sku)],
          embellishmentName: name,
          embellishmentNumber: number,
          embellishmentInitials: initials,
          isBlank,
          sizeModifier,
          rosterName,
          cartItemId: id,
        };
      }),
    )
    .reduce((acc, val) => acc.concat(val), []);

  const itemsFromPlayerInfo = pipe(
    aggregateQuantities,
    handleDeletedQuantities(initialItems),
  )(mungePlayerInfo).map(reject(isNil));

  const quantityCustomized = sku =>
    (playerInfo[sku] || [])
      .map(propOr(1, 'quantity'))
      .reduce((acc, curr) => acc + curr, 0);

  const stockItems = Object.entries(selections)
    .map(([articleId, item]) =>
      mungeCartItemOut({
        ...item,
        articleId,
        rosterName,
        quantity: item.quantity - quantityCustomized(articleId),
        recipeCode: recipeCodes[getGenericArticleId(articleId)],
      }),
    )
    // don't send spurious additions of 0 new items:
    .filter(item => item.cartItemId || item.quantity > 0)
    .map(reject(isNil));
  return [...itemsFromPlayerInfo, ...stockItems];
};

const mungeGrafikiDiscount = cart => {
  const discount = path(['totals', 'discount'], cart);
  return discount && discount.amount !== '0' ? mungeCurrency(discount) : null;
};

const mungeGrafikiOwner = cart => ({
  id: cart.owner.id,
  firstName: cart.owner.name,
});

const mungeGrafikiCreator = cart => ({
  id: cart.creator.id,
  firstName: cart.creator.name,
});

const mungeGrafikiRemovedItems = ({ invalidItems = [] }) =>
  invalidItems.map(i => ({
    articleId: i.cartItem.variantId,
    genericArticleId: getGenericArticleId(i.cartItem.variantId),
    unfulfilledQuantity: i.itemCount,
    reasons: [i.reasonCode],
  }));

export const mungeGrafikiCarts = carts =>
  carts.reduce((mungedCarts, cart) => {
    if (!cart) {
      return {
        ...mungedCarts,
      };
    }
    const discount = mungeGrafikiDiscount(cart);
    const subtotal = mungeCurrency(cart.totals.base);
    const total = discount
      ? sumCurrencies([subtotal, negativeCurrency(discount)])
      : subtotal;
    const { shoppingMode } = cart;

    const { firstShipDate, lastShipDate } = find(
      propEq('mode', shoppingMode),
      cart.account.shoppingModes,
    );
    const minShipOn = tryParseDate(firstShipDate);
    const maxShipOn = tryParseDate(lastShipDate);
    const requestedShipDate = cart.shipmentDate
      ? tryParseDate(cart.shipmentDate)
      : minShipOn;

    const invalidShippingDate =
      requestedShipDate === null ||
      (minShipOn && requestedShipDate < minShipOn) ||
      (maxShipOn && requestedShipDate > maxShipOn);

    return {
      ...mungedCarts,
      [cart.id]: {
        ...pick(['id'], cart),
        orders: cart.orders,
        items: compose(map(mungeGrafikiCartItemIn))(cart.items.edges),
        name: cart.cartName,
        shippingDate: invalidShippingDate ? minShipOn : requestedShipDate,
        totalQuantity: cart.items.unitCount,
        totals: {
          msrp: total,
          accountPrice: total,
        },
        createdAt: new Date(Date.parse(cart.created)),
        discount,
        shoppingMode: b2bApiShoppingModes[shoppingMode],
        accountId: cart.account.id,
        creator: mungeGrafikiCreator(cart),
        owner: mungeGrafikiOwner(cart),
        removedItems: mungeGrafikiRemovedItems(cart),
        subtotals: { msrp: subtotal, accountPrice: subtotal },
      },
    };
  }, {});

export const mungeGrafikiCart = data => ({
  ...mungeGrafikiCarts([data])[data.id],
  items: data.items.edges.map(mungeGrafikiCartItemIn),
});

export const getArticleIds = pluck('articleId');

export const totalQuantity = items =>
  items.map(item => item.quantity).reduce((acc, curr) => acc + curr, 0);

export const getArticlesEstimatedTotal = ({
  selections,
  articlePrices,
  showCustomerPricingOnly = false,
}) => {
  const articleIds = keys(selections);
  if (articleIds.length === 0) {
    const code = compose(
      path([PRICE_ACCOUNT, 'code']),
      head,
      values,
    )(articlePrices);
    return { amount: '0', code };
  }
  const prices = compose(reject(isNil), props(articleIds))(articlePrices);
  /*
      if no promos are present, promoPrice === accountPrice
      So, we can always consider promoPrice to calculate total
   */
  const getTotalAccountPrice = ({ promoPrice, msrp, articleId }) => {
    const { quantity } = selections[articleId];
    const price = showCustomerPricingOnly ? msrp : promoPrice;
    return multiplyCurrency(price, quantity);
  };
  return compose(sumCurrencies, map(getTotalAccountPrice))(prices);
};

export const getArticlesTotalQuantity = compose(totalQuantity, values);

export const getArticlesProductCount = selections =>
  compose(uniq, map(compose(head, split('-'))), keys)(selections).length;

export const cartItemsToGenericArticles = pipe(
  groupBy(prop('genericArticleId')),
  toPairs,
  sortBy(head),
  map(([id, items]) => ({
    id,
    colorName: items[0].colorName,
    colorHex: items[0].colorHex,
    sizes: items.map(({ articleId, sizeCode }) => ({
      articleId,
      // TODO: we'll eventually remove this when we build a client
      // side map of all possible sizes to display value
      id: sizeCode,
      display: sizeCode,
    })),
  })),
);

export const embellishedGroupingId = cartItem => {
  const recipeSetCode = cartItem.recipeSet && cartItem.recipeSet.recipeSetCode;
  return compose(
    joinedParts => btoa(joinedParts),
    join('-'),
    concat(__, [recipeSetCode]),
    props(['styleId', 'recipeCode', 'rosterName']),
  )(cartItem);
};

// in: a CartItem
// out: true if it's embellished
export const isEmbellishedItem = ({
  embellishmentInitials,
  embellishmentName,
  embellishmentNumber,
  isBlank,
}) =>
  !!(
    embellishmentInitials ||
    embellishmentName ||
    embellishmentNumber ||
    isBlank
  );

export const selectionsFromItems = items =>
  items.reduce((acc, item) => {
    const { articleId, id, quantity: itemQuantity = 0 } = item;
    const { quantity = 0 } = acc[articleId] || {};
    const total = quantity + itemQuantity;
    // This function is aggregating cart items, but embellished items
    // must be unique. Because many embellished items can share the same
    // articleId, it doesn't make sense to include the cart item's `id`
    // field in the aggregated results. It is necessary to include the
    // cart item's `id` for non-embellished items for editing and deleting
    // to work properly. The `id` for embellished items will still be associated
    // via `playerInfo`.
    const includeId = !isEmbellishedItem(item);
    return total > 0
      ? {
          ...acc,
          [articleId]: { ...(includeId && { id }), quantity: total },
        }
      : acc;
  }, {});

// TODO: I thought we echo back currency, but it looks
// like we don't, so we can do this.
export const ensureTotalsCurrencies = cartNode =>
  Object.entries(cartNode.totals).reduce(
    (prev, [k, v]) => ({
      ...prev,
      [k]: {
        ...v,
        code: v && v.code ? v.code : cartNode.account.currency,
      },
    }),
    {},
  );

export const getShipDate = cart => {
  const { firstShipDate, lastShipDate } = find(
    propEq('mode', cart.shoppingMode),
    cart.account.shoppingModes,
  );
  const minShipOn = tryParseDate(firstShipDate);
  const maxShipOn = tryParseDate(lastShipDate);
  const requestedShipDate = cart.shipmentDate
    ? tryParseDate(cart.shipmentDate)
    : minShipOn;

  const invalidShippingDate =
    requestedShipDate === null ||
    (minShipOn && requestedShipDate < minShipOn) ||
    (maxShipOn && requestedShipDate > maxShipOn);

  return formatDate(invalidShippingDate ? minShipOn : requestedShipDate);
};

export const formatAddressForGrafiki = fields => ({
  name: fields.name || 'Placeholder name', // todo remove this when not needed
  line1: fields.address1,
  line2: fields.address2,
  city: fields.city,
  regionCode: fields.region,
  countryCode: fields.country.code,
  postal: fields.postalCode,
});
export const calculatePriceRange = (items, priceToUse) => {
  const {
    unitPricing: {
      [priceToUse]: { code },
    },
  } = items[0];
  const priceValues = items.map(
    ({
      unitPricing: {
        [priceToUse]: { amount },
      },
    }) => amount,
  );
  const minPrice = {
    amount: Math.min(...priceValues),
    code,
  };
  const maxPrice = {
    amount: Math.max(...priceValues),
    code,
  };
  if (priceToUse === PRICE_MSRP) {
    return {
      minPrice,
      maxPrice,
    };
  }
  const promoPriceValues = (items || []).map(
    item => item?.unitPricing?.promoPrice?.amount,
  );
  const promoPrice = promoPriceValues && {
    min: {
      amount: Math.min(...promoPriceValues),
      code,
    },
    max: {
      amount: Math.max(...promoPriceValues),
      code,
    },
  };
  return {
    minPrice,
    maxPrice,
    promoPrice,
  };
};

export const getRosterNames = pipe(
  pathOr([], ['items', 'edges']),
  map(prop('rosterName')),
  reject(isMissing),
);

// in: an array of CartItems returned from GraphQL API
// out: a data structure of logical cart contents, grouped into "modules"
// that can be displayed in a single table when rendering the cart.
export const itemsForDisplay = edges =>
  edges.reduce((acc, { node, prices }) => {
    const article = {
      cartItemId: node.id,
      articleId: node.variantId,
      colorHex: node.article.colorHex,
      colorName: node.article.colorName,
      sizeCode: node.size.sizeCode,
      sizeName: node.size.sizeName,
      sizeRank: node.size.sizeRank,
      styleId: node.styleId,
      name: node.article.name,
      quantity: node.quantity,
      embellishmentName: node.embellishmentName,
      embellishmentNumber: node.embellishmentNumber,
      embellishmentInitials:
        node.embellishmentInitials && node.embellishmentInitials.toUpperCase(),
      isBlank: node.isBlank,
      recipeCode: node.recipeCode,
      recipeSet: node.recipeSet,
      adjustment: node.adjustment,
      genericArticleId: getGenericArticleId(node.variantId),
      unitPricing: {
        msrp: prices.msrp
          ? {
              amount: prices.msrp.amount,
              code: prices.msrp.currency,
            }
          : null,
        accountPrice: prices.unit
          ? {
              amount: prices.unit.amount,
              code: prices.unit.currency,
            }
          : null,
        promoPrice: prices.promoPrice
          ? {
              amount: prices.promoPrice.amount,
              code: prices.promoPrice.currency,
            }
          : null,
      },
      rosterName: node.rosterName,
    };
    const groupingId = embellishedGroupingId(node);
    const currentItems = acc[groupingId] || [];
    return {
      ...acc,
      [groupingId]: [...currentItems, article],
    };
  }, {});

// in: a collection of ItemsForDisplay
// out: a list of cart item module id's, sorted by the style
// code associated with the item module id
export const sortedKeysByStyle = pipe(
  map(path([0, 'styleId'])), // convert to an obj of moduleId->styleId...
  toPairs, // convert to a 2-dimensional list...
  sortBy(last), // sort list by style...
  map(head), // but return only associated module ids!
);

export const anyCustomizedItems = pipe(
  pathOr([], ['items', 'edges']),
  map(path(['node', 'recipeCode'])),
  any(isPresent),
);
