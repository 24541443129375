import { combineReducers } from 'redux';
import gql from 'graphql-tag';
import { handle } from 'redux-pack';
import { compose, isNil, pathOr } from 'ramda';
import { formatDate } from 'lib/dates';
import { mungeCurrency } from 'lib/currency';
import { grafikiShoppingModes } from 'lib/account';
import { PRICE_MSRP, PRICE_ACCOUNT } from 'lib/pricing';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from '../authentication';
import { emptyPrices } from './byStyle';

export const FETCH_PRICES_BY_SKU = 'search/FETCH_PRICES_BY_SKU';
export const FETCH_ARTICLE_PRICES = 'search/FETCH_ARTICLE_PRICES';
export const CLEAR_ARTICLE_PRICES = 'cart/byArticle/CLEAR_ARTICLE_PRICES';

const GET_PRICES_BY_SKU = gql`
  query getPricesBySku(
    $id: ID!
    $styleIds: [String!]!
    $effectiveDate: Date!
    $shoppingMode: ShoppingMode!
  ) {
    node(id: $id) {
      ... on Account {
        id
        products(
          styleIds: $styleIds
          effectiveDate: $effectiveDate
          shoppingMode: $shoppingMode
        ) {
          edges {
            node {
              id
              styleId
            }
            materials {
              edges {
                node {
                  materialId
                }
                skus {
                  sku
                  prices {
                    msrp {
                      amount
                      code: currency
                    }
                    accountPrice {
                      amount
                      code: currency
                    }
                    promoPrice {
                      amount
                      code: currency
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const transformSkuPrices = (edges = []) => {
  const skus = edges.flatMap(edge => {
    const { styleId } = edge.node;
    const materialEdges = edge.materials.edges || [];
    return materialEdges.flatMap(materialEdge => {
      const { materialId } = materialEdge.node;
      return (materialEdge || []).skus.map(skuInfo => ({
        sku: skuInfo.sku,
        prices: skuInfo.prices,
        materialId,
        styleId,
      }));
    });
  });
  return {
    data: {
      skus,
    },
  };
};

export const fetchArticlePrices = (
  accountId,
  styleId,
  shippingDate,
  shoppingMode,
) => ({
  type: FETCH_ARTICLE_PRICES,
  meta: {
    styleId,
  },
  promise: import('index').then(({ graphqlClient }) => {
    if (isNil(styleId)) {
      return emptyPrices;
    }
    const effectiveDate = formatDate(shippingDate);
    return graphqlClient
      .query({
        query: GET_PRICES_BY_SKU,
        variables: {
          id: accountId,
          styleIds: [styleId],
          effectiveDate,
          shoppingMode: grafikiShoppingModes[shoppingMode],
        },
      })
      .then(payload => {
        const edges = payload?.data?.node?.products?.edges;
        if (!edges) {
          return emptyPrices;
        }
        return transformSkuPrices(edges);
      });
  }),
});

export const fetchPricesBySku = (
  accountId,
  skus,
  shippingDate,
  shoppingMode,
) => ({
  type: FETCH_PRICES_BY_SKU,
  meta: {
    uaApiRequest: {
      url: `/api/accounts/${accountId}/products/pricing`,
      method: 'post',
      data: {
        effectiveOn: formatDate(shippingDate),
        shoppingMode,
        skus,
      },
    },
  },
});

export const clearArticlePrices = () => ({ type: CLEAR_ARTICLE_PRICES });

export const wrangleAccountPrice = compose(
  mungeCurrency,
  pathOr({}, ['prices', PRICE_ACCOUNT]),
);
export const wrangleMSRP = compose(
  mungeCurrency,
  pathOr({}, ['prices', PRICE_MSRP]),
);

const mungeArticlePrices = items =>
  (items || []).reduce(
    (entries, item) => ({
      ...entries,
      [item.sku]: {
        articleId: item.sku,
        genericArticleId: item.materialId,
        styleId: item.styleId,
        accountPrice: item.prices.accountPrice,
        msrp: item.prices.msrp,
        promoPrice: item.prices.promoPrice,
      },
    }),
    {},
  );

const byArticleId = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
      return {};

    case FETCH_ARTICLE_PRICES:
    case FETCH_PRICES_BY_SKU:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          ...mungeArticlePrices(payload.data.skus),
        }),
      });

    case CLEAR_ARTICLE_PRICES:
      return {};

    default:
      return state;
  }
};

const fetching = (state = null, action) => {
  if (action.type === FETCH_ARTICLE_PRICES) {
    return handle(state, action, {
      start: () => action.meta.styleId,
      finish: () => null,
    });
  }
  return state;
};

const error = (state = false, action) => {
  const { type, payload } = action;
  if (type === FETCH_ARTICLE_PRICES) {
    return handle(state, action, {
      start: () => null,
      failure: () => payload.message,
    });
  }
  return state;
};

export default combineReducers({ error, fetching, byArticleId });
