import debug from 'debug';
import { path, pathOr } from 'ramda';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { exchangeAccessToken, logout } from 'ducks/authentication';

const getExpiry = path(['authentication', 'access', 'expires']);
const getRefreshToken = path(['authentication', 'refreshToken']);
const getNewToken = pathOr({}, ['payload', 'data']);
const debugTokens = debug('graphql:tokens');

const createTokenRefreshLink = store =>
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      const state = store.getState();
      const expiry = getExpiry(state);
      // If we have an expiration (logged in, the token must be valid)
      // otherwise, we can assume we make the request unauthenticated.
      const validity = expiry ? new Date() < new Date(expiry) : true;
      debugTokens(`Token Validity: ${validity}`);
      return validity;
    },
    fetchAccessToken: () => {
      const state = store.getState();
      const refreshToken = getRefreshToken(state);
      if (refreshToken) {
        debugTokens('Fetching new accessToken...');
        return store.dispatch(exchangeAccessToken(refreshToken));
      }
      debugTokens('NO REFRESH TOKEN!');
      return Promise.resolve({});
    },
    handleResponse: () => response => {
      const newToken = getNewToken(response);
      debug('Handling response...');
      return newToken;
    },
    handleError: err => {
      debug(`Unable to refresh token. Logging out...${err}`);
      const state = store.getState();
      const refreshToken = getRefreshToken(state);
      store.dispatch(logout(refreshToken));
      window.location = '/';
    },
  });

export default createTokenRefreshLink;
