import { useIntl, MessageDescriptor } from 'react-intl';

export const useFormatMessage = () => {
    const { formatMessage } = useIntl();
    return (id: MessageDescriptor['id'], values?: any) => {
        return formatMessage({ id }, values);
    }
}

export const useFormatNumber = () =>  {
    const { formatNumber } = useIntl();
    return (value: number, options?: any) => {
        return formatNumber(value, options);
    }
}