import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { maxWidthMedia } from 'styles/mixins';
import { borders, colors, spacing, breakpoint } from 'lib/theme';

const horizontalPadding = ({ size }) =>
  size === 'large' ? spacing.xl : spacing.s;
const verticalPadding = ({ size }) =>
  size === 'large' ? spacing.xxxl : spacing.xl;

const Layout = styled.div`
  margin: 0;
  padding: 0;
`;

export const Well = styled.div`
  background-color: ${colors.offWhite};
`;

const Segment = styled.li`
  border-bottom: ${borders.hairline};
  padding: ${verticalPadding} ${horizontalPadding};

  &:last-child {
    border-bottom: none;
  }

  ${/* sc-sel */ Well} {
    margin-left: -${horizontalPadding};
    margin-right: -${horizontalPadding};
    padding: calc(${verticalPadding} / 2) ${horizontalPadding};
  }

  ${maxWidthMedia(breakpoint.gd.phoneMax)`
    border-bottom: none;
    padding: ${spacing.gd.xs} 0;

    &:not(:last-child) {
      padding-bottom: 0px;
    }
  `}
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const SegmentedLayout = ({ segments, size }) => (
  <Layout>
    <List>
      {segments.map(segment => (
        <Segment key={segment.key} size={size}>
          {segment}
        </Segment>
      ))}
    </List>
  </Layout>
);

SegmentedLayout.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.node).isRequired,
  size: PropTypes.oneOf(['normal', 'large']),
};

SegmentedLayout.defaultProps = {
  size: 'normal',
};

export default SegmentedLayout;
