import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { head, map, path, pluck, find, propEq } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { isPresent } from './data-manipulation';

const PUBLIC_QUERY = gql`
  query PublicAccountIdQuery {
    currentUser {
      id
      public {
        billingAccount {
          id
        }
      }
    }
  }
`;

export const usePublicAccountId = () => {
  const { data } = useQuery(PUBLIC_QUERY);
  return data?.currentUser?.public?.billingAccount?.id;
};

export const toSubmissionParamsUpdate = ({
  userId,
  newName,
  newEmail,
  newLocale,
  userEnabled,
  accountsList,
  addressesList,
  organization,
  selectedMixinIds,
  selectedRole,
  selectedSalesOrgs: salesOrgCodes,
  selectedSalesGroups: salesGroupCodes,
}) => {
  const organizationIDs =
    organization && organization.id ? [organization.id] : [];
  const roleIDs = [...selectedMixinIds, selectedRole.id];
  return {
    input: {
      user: {
        userId,
        email: newEmail,
        enabled: userEnabled,
        firstName: newName,
        lastName: '',
        locale: newLocale,
        accountIDs: accountsList,
        roleIDs,
        shippingAddressIDs: addressesList,
        organizationIDs,
        ...({ salesOrgCodes } || null),
        ...({ salesGroupCodes } || null),
      },
    },
  };
};

export const toSubmissionParamsNew = ({
  newName,
  newEmail,
  newLocale,
  organization,
  accountsList,
  selectedMixinIds,
  selectedRole,
  addressesList,
  selectedSalesOrgs: salesOrgCodes,
  selectedSalesGroups: salesGroupCodes,
}) => {
  const organizationIds =
    organization && organization.id ? [organization.id] : [];
  const roleIds = [...selectedMixinIds, selectedRole.id];
  return {
    input: {
      user: {
        email: newEmail,
        firstName: newName,
        lastName: '',
        locale: newLocale,
      },
      accountIds: accountsList,
      roleIds,
      organizationIds,
      ...({ salesOrgCodes } || null),
      ...({ salesGroupCodes } || null),
      shippingAddressIDs: addressesList,
    },
  };
};

export const displayName = user =>
  isPresent(user.lastName)
    ? `${user.firstName} ${user.lastName}`
    : user.firstName;

const firstOrg = user => {
  if (user.organization) {
    return user.organization;
  }
  if (!user.organizations) {
    return null;
  }
  const org = head(user.organizations || []);
  if (org) {
    return {
      id: org.id,
      name: org.organizationName,
    };
  }
  return null;
};

export const wrangleUser = user => ({
  id: user.id || user.userID,
  isActive: user.isActive,
  email: user.email,
  name: user.name,
  organization: firstOrg(user),
  role: path(['roleName'], find(propEq('isMixin', false))(user.roles)),
});

export const wrangleFetchedUser = user => ({
  id: user.id,
  email: user.email,
  enabled: user.isActive,
  fetchedAccountIds: map(a => a.node.id, user.accounts.edges),
  fetchedAddressIds: user.addresses.map(({ id }) => id),
  locale: user.locale,
  name: user.name,
  selectedOrganization: firstOrg(user),
  selectedRoleIds: pluck('id', user.roles),
});

export const mungePermissionGroups = permissionGroups =>
  permissionGroups.map(group => ({
    id: group.id,
    name: group.name,
    code: group.code,
  }));

export const grafikiShoppingModes = {
  atonce: 'AtOnce',
  AtOnce: 'AtOnce',
  booking: 'Booking',
  Booking: 'Booking',
};

export const b2bApiShoppingModes = {
  atonce: 'atonce',
  AtOnce: 'atonce',
  booking: 'booking',
  Booking: 'booking',
};

export const isAtOnce = shoppingMode => {
  if (shoppingMode.toLowerCase() === 'atonce') {
    return true;
  }
  return false;
};

export const isBooking = shoppingMode => {
  if (shoppingMode.toLowerCase() === 'booking') {
    return true;
  }
  return false;
};

export const mungeUserInfo = (data, permissions) => {
  const {
    id,
    roles,
    addresses,
    email,
    name,
    locale,
    organizations,
    accounts,
  } = data.currentUser;
  const role = roles.find(r => !r.isMixin);
  const shippingAddressIDs = addresses.map(address => address.id);
  const profile = {
    id,
    role,
    locale,
    name,
    permissionCodes: permissions,
    email,
    shippingAddressIDs,
    organizations,
    accounts: accounts.edges
      ? accounts.edges.map(account => account.node)
      : null,
  };
  return profile;
};

export const passwordErrorSwitch = ({ errorCode, networkError }) => {
  if (isPresent(networkError)) {
    return <FormattedMessage id="create-account.failure" />;
  }
  switch (errorCode) {
    case '6001':
      return <FormattedMessage id="forgot-password.easily-guessed" />;
    case '6002':
      return <FormattedMessage id="forgot-password.top-hundred" />;
    case '6003':
      return <FormattedMessage id="forgot-password.too-short" />;
    case 'mismatch':
      return <FormattedMessage id="password-mismatch-error" />;
    case '409':
      return <FormattedMessage id="create-account.email-taken-error" />;
    case '410':
      return (
        <FormattedMessage id="user.my-information.edit-password.input-error" />
      );
    default:
      return <FormattedMessage id="forgot-password.server-error-message" />;
  }
};

passwordErrorSwitch.propTypes = {
  errorCode: PropTypes.string.isRequired,
  networkError: PropTypes.string,
};

passwordErrorSwitch.defaultProps = {
  networkError: null,
};
