import styled from 'styled-components';
import { colors, fonts } from 'lib/theme';

const CancelButton = styled.button.attrs({ type: 'button' })`
  font: ${fonts.tinyHeavy};
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: ${colors.darkGrey};
  color: ${colors.white};
  border-radius: 14px;
  cursor: pointer;
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  width: 14px;

  &::before {
    content: '×';
    display: block;
    margin-top: -4px;
    margin-left: 0.5px;
  }
`;

export default CancelButton;
