import { mergeDeepWith } from 'ramda';

function _isObject(x: any) {
  return Object.prototype.toString.call(x) === '[object Object]';
};

function shouldMergeItemInArray(prev: any, next: any) {
  // Let's assume that if we can find an `id` on the object
  // and it matches, we can safely merge these items.
  return prev && prev.id && next && next.id && prev.id === next.id;
}

interface MergeStrategy {
  (a: any, b: any): any;
}

export const createMergeStrategy = (edgesToMerge: any[]) => {
  const nonObjectMergeStrategy: MergeStrategy = (
    previousItem: any,
    nextItem: any,
  ) => {
    // This makes a pretty serious assumption that connection edges
    // and previousItem will maintain a stable reference (memory address)
    // The good news is... it does. If you made it here because you're like
    // something isn't working... stop copying objects?
    if (edgesToMerge === previousItem) {
      return [...previousItem, ...nextItem]
    }
    if (Array.isArray(previousItem)) {
      return previousItem.map((itemInPreviousItems, i) => {
        const itemFromNextItems = nextItem[i];
        if (_isObject(itemInPreviousItems)) {
          if (shouldMergeItemInArray(itemInPreviousItems, itemFromNextItems)) {
            return mergeDeepWith(
              nonObjectMergeStrategy,
              itemInPreviousItems,
              itemFromNextItems,
            );
          } else {
            console.warn("Order of intermediate arrays must remain stable. DID NOT MERGE.")
          }
        }
        return itemFromNextItems;
      });
    }
    return nextItem;
  };
  return nonObjectMergeStrategy;
}
