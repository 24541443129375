import { handle } from 'redux-pack';
import { compose, flatten, pluck } from 'ramda';
import { FETCH_CARTS } from './activeCart';
import { SUBMIT_ORDER } from '../checkout';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from '../authentication';

const initialState = { fetching: false, error: null, ids: [] };

export default function userCarts(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
      return initialState;

    case FETCH_CARTS:
      return handle(state, action, {
        start: () => ({ ...initialState, fetching: true }),
        finish: prevState => ({ ...prevState, fetching: false }),
        failure: prevState => ({ ...prevState, error: action.payload.message }),
        success: prevState => {
          const carts = compose(
            flatten,
            pluck('node'),
          )(action.payload.data.node.carts.edges);
          return { ...prevState, ids: pluck('id', carts) };
        },
      });

    case SUBMIT_ORDER:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          ids: prevState.ids.filter(id => id !== action.meta.cartId),
        }),
      });
    default:
      return state;
  }
}
