import { handle } from 'redux-pack';
import { assoc, mergeDeepLeft } from 'ramda';
import { requestSlugs, wrangleSlugs } from 'lib/algolia';

export const FETCH = 'slugs/FETCH';

const initialState = {
  display: {},
};

export const fetchSlugs = () => ({
  type: FETCH,
  meta: {
    algoliaRequest: requestSlugs,
  },
});

export const reducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH:
      return handle(state, action, {
        start: mergeDeepLeft({ error: null, waiting: true }),
        finish: assoc('waiting', false),
        success: prevState => ({
          ...prevState,
          results: wrangleSlugs(action.payload),
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.message,
        }),
      });

    default:
      return state;
  }
};

export default reducer;
