import { handle } from 'redux-pack';
import { combineReducers } from 'redux';
import { assoc, filter, mergeDeepLeft, path } from 'ramda';
import { isMissing, isPresent } from 'lib/data-manipulation';

export const CLEAR_ORDER = 'checkout/CLEAR_ORDER';
export const CLEAR_ORDER_ERROR = 'checkout/CLEAR_ORDER_ERROR';
export const SUBMIT_ORDER = 'checkout/SUBMIT_ORDER';

export const VALIDATE_SHIPPING = 'checkout/VALIDATE_SHIPPING';
export const TOGGLE_CREDIT_CARD = 'checkout/TOGGLE_CREDIT_CARD';
export const VALIDATE_CREDIT_CARD = 'checkout/VALIDATE_CREDIT_CARD';
export const AUTHORIZE_CREDIT_CARD = 'checkout/AUTHORIZE_CREDIT_CARD';
export const VALIDATE_CHECKOUT = 'checkout/VALIDATE_CHECKOUT';
export const VALIDATED_CHECKOUT = 'checkout/VALIDATED_CHECKOUT';
export const INVALIDATED_CHECKOUT = 'checkout/INVALIDATED_CHECKOUT';

export const clearOrder = () => ({ type: CLEAR_ORDER });
export const clearOrderError = () => ({ type: CLEAR_ORDER_ERROR });

const addressFromFields = ({
  address1,
  address2,
  city,
  region,
  postalCode,
  country,
}) => ({
  address1,
  address2,
  city,
  region: { code: region },
  postalCode,
  country: country.code,
});

export const submitOrder = (
  cartID,
  shippingFields,
  billingFields,
  authorization,
) => {
  const {
    shipOn,
    shippingMethodId,
    fulfillmentMethodId,
    name,
    addressId,
    address1,
    address2,
    city,
    region,
    postalCode,
    country,
    cancelDays,
  } = shippingFields;
  const {
    eligibilityMode,
    poNumber,
    ancillaryPoNumber,
    paymentMethod,
    ancillaryPaymentMethod,
    placedForId,
    multiRegionRepCode,
    salesRepId,
    requiresSalesRep,
    requiresExplicitSalesRep,
  } = billingFields;
  const addressField = addressId
    ? { shippingAddressID: addressId }
    : {
        shippingAddress: {
          name,
          address1,
          address2,
          city,
          country: country.code,
          postalCode,
          region: { code: region },
        },
      };
  const billingAddress = addressFromFields(billingFields);
  const creditCard = isPresent(authorization)
    ? { creditCard: { authorization, billingAddress } }
    : {};

  const paymentMethods = filter(isPresent, [
    isPresent(ancillaryPaymentMethod)
      ? {
          id: ancillaryPaymentMethod.id,
          poNumber: ancillaryPoNumber,
        }
      : null,
    { id: paymentMethod.id, poNumber },
  ]);

  let salesRepAccountUserId = null;
  if (requiresExplicitSalesRep) {
    // if explicit sales rep required, then salesRepId is a sales rep user ID
    salesRepAccountUserId = salesRepId;
  } else if (requiresSalesRep) {
    // if sales rep required but explicit sales rep is not, then placedForId
    // is a sales rep account user ID
    salesRepAccountUserId = placedForId;
  }

  return {
    type: SUBMIT_ORDER,
    meta: {
      cartId: cartID,
      uaApiRequest: {
        url: '/api/orders',
        method: 'post',
        data: {
          paymentMethods,
          shippingMethodID: shippingMethodId,
          fulfillmentMethodID: fulfillmentMethodId,
          salesRepAccountUserId,
          placedForUserId: placedForId,
          cancelDays,
          cartID,
          shipOn,
          usageIndicator: eligibilityMode,
          ...addressField,
          ...creditCard,
          multiRegionRepCode,
        },
      },
    },
  };
};

const orderInitialState = {
  submitting: false,
  error: null,
  id: null,
  orderNumber: null,
};

const order = (state = orderInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUBMIT_ORDER:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          submitting: true,
          error: null,
          id: null,
          orderNumber: null,
        }),
        failure: prevState => {
          const reasonMessage = path(['response', 'data', 'message'], payload);
          let reasonCode = path(['response', 'data', 'reasonCode'], payload);
          const httpStatus = path(['response', 'status'], payload);
          const fields = path(['response', 'data', 'fields'], payload);
          // This pattern comes from axios (our http lib)
          // https://github.com/axios/axios/blob/503418718f669fcc674719fd862b355605d7b41f/lib/adapters/xhr.js#L89
          // The code they provide 'ECONNABORTED' is used in other scenarios that we would not
          // want to invoke this logic, hence the REGEX matching on the message. Gross huh?
          const timeoutErrorPattern = /timeout of (\d+)ms exceeded/i;
          if (timeoutErrorPattern.test(payload.message)) {
            reasonCode = 'ECLIENTTIMEOUT';
          }
          return {
            ...prevState,
            error: {
              message: reasonMessage || payload.message,
              code: reasonCode || -1,
              fields,
              httpStatus,
            },
          };
        },
        finish: prevState => ({ ...prevState, submitting: false }),
        success: prevState => ({
          ...prevState,
          ids: payload.data.ids,
          orderNumbers: payload.data.orderNumbers,
        }),
      });

    case CLEAR_ORDER:
      return orderInitialState;

    case CLEAR_ORDER_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const validateCheckout = () => ({
  type: VALIDATE_CHECKOUT,
});

export const validatedCheckout = () => ({
  type: VALIDATED_CHECKOUT,
});

export const invalidateCheckout = () => ({
  type: INVALIDATED_CHECKOUT,
});

const checkout = (
  state = {
    validating: false,
    validated: false,
  },
  action,
) => {
  const { type } = action;
  switch (type) {
    case VALIDATE_CHECKOUT:
      return mergeDeepLeft(
        {
          validating: true,
          validated: false,
        },
        state,
      );
    case VALIDATED_CHECKOUT:
      return mergeDeepLeft(
        {
          validating: false,
          validated: true,
        },
        state,
      );
    case INVALIDATED_CHECKOUT:
      return mergeDeepLeft(
        {
          validating: false,
          validated: false,
        },
        state,
      );
    default:
      return state;
  }
};

export const validateShipping = validator => ({
  type: VALIDATE_SHIPPING,
  promise: new Promise((resolve, reject) =>
    validator(errors => (isMissing(errors) ? resolve(errors) : reject(errors))),
  ),
});

const shipping = (
  state = {
    validating: false,
    validated: false,
  },
  action,
) => {
  const { type } = action;
  switch (type) {
    case VALIDATE_SHIPPING:
      return handle(state, action, {
        start: mergeDeepLeft({
          validating: true,
          validated: false,
        }),
        failure: assoc('validated', false),
        success: assoc('validated', true),
        finish: assoc('validating', false),
      });
    default:
      return state;
  }
};

export const toggleCreditCard = selected => ({
  type: TOGGLE_CREDIT_CARD,
  meta: {
    selected,
  },
});

export const validateCreditCard = sandbox => ({
  type: VALIDATE_CREDIT_CARD,
  promise: sandbox.validate(),
});

export const authorizeCreditCard = (sandbox, data) => ({
  type: AUTHORIZE_CREDIT_CARD,
  promise: sandbox.authorize({
    ...data,
    billingAddress: {
      ...data.billingAddress,
      country: data.billingAddress.country.code,
    },
  }),
});

const initialCreditCard = {
  selected: false,
  validating: false,
  validated: false,
  authorizing: false,
  authorized: false,
  authorization: null,
  errorMessage: null,
  lastFour: null,
};

const creditCard = (state = initialCreditCard, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case TOGGLE_CREDIT_CARD:
      return assoc('selected', meta.selected, initialCreditCard);
    case VALIDATE_CREDIT_CARD:
      return handle(state, action, {
        start: mergeDeepLeft({
          validating: true,
          validated: false,
          authorized: false,
          authorization: null,
          errorMessage: null,
        }),
        failure: assoc('validated', false),
        finish: assoc('validating', false),
        success: assoc('validated', true),
      });
    case AUTHORIZE_CREDIT_CARD:
      return handle(state, action, {
        start: mergeDeepLeft({
          authorizing: true,
          authorized: false,
        }),
        failure: mergeDeepLeft({
          authorization: false,
          errorMessage: path(['message'], payload),
        }),
        finish: assoc('authorizing', false),
        success: mergeDeepLeft({
          authorized: true,
          authorization: path(['authorization', 'payload'], payload),
          lastFour: path(['payment', 'lastFour'], payload),
        }),
      });
    default:
      return state;
  }
};

export default combineReducers({
  order,
  creditCard,
  shipping,
  checkout,
});
