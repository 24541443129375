import styled from 'styled-components';
import { colors } from 'lib/theme';

const valueForLabel = (defaultValue, placeholderValue) => ({ isPlaceholder }) =>
  isPlaceholder ? placeholderValue : defaultValue;

const StyledLabel = styled.label`
  color: ${colors.black};
  position: absolute;
  top: ${valueForLabel('0', '50%')};
  margin-top: ${valueForLabel('5px', '0')};
  padding-left: 8px;
  font-family: 'Neue Plak Text', sans-serif;
  font-weight: ${valueForLabel('400', '300')};
  text-shadow: 0 0 1px #fff;
  transform: translateY(${valueForLabel('0', '-50%')});
  font-size: ${valueForLabel('10px', '13px')};

  /* prettier-ignore */
  transition:
    margin-top 0.1s ease-in-out,
    top 0.1s ease-in-out,
    transform 0.1s ease-in-out,
    font-size 0.1s ease-in-out,
    color 0.1s ease-in-out;
`;

export default StyledLabel;
