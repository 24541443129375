import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontSizes, weights, spacing } from 'lib/theme';

const Wrapper = styled.div`
  background-color: ${colors.offWhite};
  font-size: ${fontSizes.xlarge};
  font-weight: ${weights.light};
  padding: ${spacing.xxxl};
  text-align: center;
  flex: 1;
  align-self: flex-start;
`;

export const MessagePanel = ({ text }) => <Wrapper>{text}</Wrapper>;

MessagePanel.propTypes = { text: PropTypes.string.isRequired };

export default MessagePanel;
