import styled from 'styled-components';
import { media, maxWidthMedia } from 'styles/mixins';
import { spacing, width, breakpoint } from 'lib/theme';

const horizontalMargin = ({ withGutter = false }) =>
  withGutter ? `calc(${spacing.gutterWidth} / 2)` : '0px';

const Container = styled.div`
  padding: ${spacing.xl};
`;

const Contained = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  max-width: ${width.full};
  width: 100%;

  ${maxWidthMedia(breakpoint.gd.phoneMax)`
    padding: 0 ${spacing.gd.xs};
  `}
`;

const Column = styled.div`
  flex: 1;
`;

interface RowProps {
  withGutter?: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;

  ${media(breakpoint.tablet)`
    > ${/* sc-sel */ Column}:not(:first-child) {
      margin-left: ${horizontalMargin};
    }

    > ${/* sc-sel */ Column}:not(:last-child) {
      margin-right: ${horizontalMargin};
    }
  `}
`;

const CenteredRow = styled(Row)`
  justify-content: center;
`;

const FullWidth = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: ${spacing.xxl};
  margin-top: ${spacing.xxl};
  max-width: ${width.full};
  width: 100%;
`;

const FullContainer = styled.div`
  margin: 0 auto;
  max-width: ${width.full};
  width: 100%;
`;

const BreakpointGutter = styled.div`
  ${media(breakpoint.tablet)`
    padding-left: ${spacing.xl};
    padding-right: ${spacing.xl};
  `};
`;

export default {
  Row,
  CenteredRow,
  Column,
  Container,
  Contained,
  FullWidth,
  FullContainer,
  BreakpointGutter,
};
