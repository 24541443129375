import { packSuccess } from 'lib/redux-pack';
import { getActiveSearchConfig } from 'ducks/search';
import { CHOOSE_LOCALE } from 'ducks/locale';
import {
  FETCH_DEFAULT_ACCOUNT,
  FETCH_ACCOUNT,
  ACTIVATE_ACCOUNT,
  LOGOUT,
  CHOOSE_SHOPPING_MODE,
  fetchAccount,
} from 'ducks/authentication';
import { SET as SET_USER } from 'ducks/user';
import { getSelectedAccount } from 'selectors/account';
import { userSignedIn } from 'selectors/user-signed-in';
import { getUserProfile } from 'selectors/user';
import { setErrorContext } from 'lib/errors';
import analytics from 'lib/analytics';

export default store => next => action => {
  const result = next(action);
  const state = store.getState();

  if (
    packSuccess(action, FETCH_ACCOUNT) ||
    action.type === CHOOSE_SHOPPING_MODE
  ) {
    const { selectedAccount, activeShoppingMode } = state.authentication;
    const {
      name,
      number,
      organization,
      salesOrgCode,
      customerGroupCode,
      countryCode,
      salesGroupCode,
    } = selectedAccount;
    analytics.setAccountProperties({
      name,
      number,
      organization,
      salesOrgCode,
      customerGroupCode,
      salesGroupCode,
      countryCode,
    });
    const accountId = selectedAccount.id;
    store.dispatch(getActiveSearchConfig(accountId, activeShoppingMode));
  }

  if (
    packSuccess(action, FETCH_DEFAULT_ACCOUNT) ||
    action.type === ACTIVATE_ACCOUNT
  ) {
    const accountId = getSelectedAccount(state).id;
    if (accountId) {
      store.dispatch(fetchAccount(accountId, true));
    }
  }

  if (action.type === SET_USER) {
    const {
      email,
      id: userID,
      role: { name },
    } = getUserProfile(state);
    analytics.setUserRole(name);
    setErrorContext({
      email,
      userID,
    });
  }

  if (action.type === LOGOUT) {
    analytics.clearUserProperties();
  }

  if (action.type === CHOOSE_LOCALE) {
    analytics.setLocale(state.locale.locale);
  }

  if (userSignedIn(state)) {
    setErrorContext({
      account: getSelectedAccount(state),
    });
  }

  return result;
};
