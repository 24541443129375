import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import analytics from 'lib/analytics';
import logo from 'lib/logo-inverted.svg';
import { spacing, colors, breakpoint } from 'lib/theme';
import InputIntl from './forms/input-intl';
import * as t from './typography';
import { TextLink } from './ui/links';
import { Button } from './ui/button';
import { Row } from './forms/fieldset';
import ForgotPasswordMutation from './user/forgot-password-mutation';

const PageContainer = styled.div`
  background: ${colors.black};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const FormErrors = styled.div`
  align-self: flex-start;
  margin-top: ${spacing.m};
`;

const ImageContainer = styled.div`
  margin-bottom: ${spacing.xxl};
  text-align: center;
`;

const Image = styled.img`
  width: 80px;
  display: inline-block;
`;

const Title = styled(t.H3)`
  margin: 0 0 ${spacing.xl} 0;
  text-align: center;
`;

const InputWrapper = styled(Row)`
  margin-bottom: ${spacing.xl};
  width: 100%;
`;

const MiniForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  background: ${colors.white};
  padding: ${spacing.xl};
  width: 100%;
  max-width: 500px;

  @media (min-width: ${breakpoint.tablet}) {
    padding: ${spacing.xxxl};
  }
`;

const ResetPasswordButton = styled(Button)`
  margin-bottom: ${spacing.xl};
  width: 100%;
`;

const ResetPasswordBackToLoginLink = styled(TextLink)`
  text-transform: uppercase;
`;

const ConfirmationBackToLoginLink = styled(TextLink)`
  align-self: flex-start;
  text-transform: uppercase;
`;

const SentMessage = styled.div`
  margin-bottom: ${spacing.xxl};
`;

export class ForgotPassword extends Component {
  state = { email: '', error: false };

  validateEmail = () => {
    const { email } = this.state;
    const EMAIL_REGEX = /.+@.+\..+/;
    return EMAIL_REGEX.test(email);
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = mutate => e => {
    e.preventDefault();
    analytics.logForgotPassword();
    const { email } = this.state;
    if (this.validateEmail()) {
      mutate(email);
    } else {
      this.setState({ error: true });
    }
  };

  forgotPasswordForm = (mutate, error) => {
    const { email, error: localError } = this.state;
    const hasError = localError || error;
    return (
      <MiniForm onSubmit={this.handleSubmit(mutate)}>
        <Title>
          <FormattedMessage id="forgot-password.reset-password" />
        </Title>
        <InputWrapper>
          <InputIntl
            id="forgotPasswordEmail"
            label="email-address"
            placeholder="forgot-password.email-placeholder"
            type="text"
            name="email"
            onChange={this.handleInputChange}
            value={email}
          />
        </InputWrapper>
        <ResetPasswordButton size="normal" value="Reset Password">
          <FormattedMessage id="forgot-password.reset-password" />
        </ResetPasswordButton>
        <ResetPasswordBackToLoginLink to="/">
          <FormattedMessage id="forgot-password.back-to-login" />
        </ResetPasswordBackToLoginLink>
        {hasError && (
          <FormErrors>
            <t.ErrorText large={true}>
              {error && <FormattedMessage id="forgot-password.invalid-email" />}
            </t.ErrorText>
          </FormErrors>
        )}
      </MiniForm>
    );
  };

  emailSentConfirmation = () => (
    <div>
      <Title>
        <FormattedMessage id="forgot-password.email-sent-title" />
      </Title>
      <SentMessage>
        <FormattedMessage id="forgot-password.email-sent-message" />
      </SentMessage>
      <ConfirmationBackToLoginLink to="/">
        <FormattedMessage id="forgot-password.back-to-login" />
      </ConfirmationBackToLoginLink>
    </div>
  );

  render() {
    return (
      <PageContainer>
        <ImageContainer>
          <Image src={logo} alt="Under Armour" />
        </ImageContainer>
        <ContentContainer>
          <ForgotPasswordMutation>
            {(mutate, { called, loading, error }) => {
              const submittingForgotPassword = called && !loading && !error;
              return submittingForgotPassword
                ? this.emailSentConfirmation()
                : this.forgotPasswordForm(mutate, error);
            }}
          </ForgotPasswordMutation>
        </ContentContainer>
      </PageContainer>
    );
  }
}

export default ForgotPassword;
