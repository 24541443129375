import PropTypes from 'prop-types';
import { PRICE_MSRP, PRICE_ACCOUNT } from 'lib/pricing';

export const organizationShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const permissionShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const roleShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  permissions: PropTypes.arrayOf(permissionShape),
  mappedBy: PropTypes.string,
});

export const accountShape = PropTypes.shape({
  id: PropTypes.string,
  number: PropTypes.string,
  name: PropTypes.string,
  organization: organizationShape,
  salesOrgCode: PropTypes.string,
});

export const accountNodeShape = PropTypes.shape({
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
  }),
});

export const categoryShape = PropTypes.shape({
  children: PropTypes.arrayOf(PropTypes.shape({})),
  count: PropTypes.number,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
});

export const cartCreatorOwnerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
});

export const cartRemovedItemsShape = PropTypes.arrayOf(
  PropTypes.shape({
    unfulfilledQuantity: PropTypes.number.isRequired,
    articleId: PropTypes.string.isRequired,
    genericArticleId: PropTypes.string.isRequired,
    reasons: PropTypes.arrayOf(PropTypes.string),
  }),
);

export const categoriesShape = PropTypes.arrayOf(categoryShape);

export const productListType = PropTypes.arrayOf(
  PropTypes.shape({
    styleCode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

export const colorShape = PropTypes.shape({
  colorHex: PropTypes.string.isRequired,
  colorName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
});

export const filterShape = PropTypes.shape({
  category: PropTypes.arrayOf(PropTypes.string),
  colorGroup: PropTypes.arrayOf(PropTypes.string),
});

export const historyShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const matchShape = PropTypes.shape({
  params: PropTypes.shape({
    searchTerm: PropTypes.string,
    page: PropTypes.string,
  }),
});

export const currencyShape = PropTypes.shape({
  // different api's will return either strings or numbers for amount;
  // where this is used, each case needs to be handled properly.
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
});

export const orderSummaryShape = PropTypes.shape({
  placedOn: PropTypes.string,
  purchaseOrder: PropTypes.string,
  id: PropTypes.string,
  accountNumber: PropTypes.string,
  totalQty: PropTypes.number,
  totalPrice: currencyShape,
  requestedShipOn: PropTypes.string,
});

export const userSummaryShape = PropTypes.shape({
  userID: PropTypes.number,
  email: PropTypes.string,
  name: PropTypes.string,
  locale: PropTypes.string,
  showCustomerPricingOnly: PropTypes.bool,
});

export const addressShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  country: PropTypes.string,
});

export const grafikiAddressShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  postal: PropTypes.string,
  regionCode: PropTypes.string,
  regionName: PropTypes.string,
  countryCode: PropTypes.string,
});

export const lineItemShape = PropTypes.shape({
  genericArticleId: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.number,
  quantityCancelled: PropTypes.number,
  sizeId: PropTypes.string,
  articleId: PropTypes.string,
  unitPrice: currencyShape,
  recipeCode: PropTypes.string,
});

export const breakoutShape = PropTypes.shape({
  requestedShipOn: PropTypes.string,
  fulfiller: PropTypes.string,
  items: PropTypes.arrayOf(lineItemShape),
});

export const shippingStatusShape = PropTypes.shape({
  type: PropTypes.string,
  availableDate: PropTypes.string,
  quantity: PropTypes.number,
});

export const shippingScheduleShape = PropTypes.shape({
  variantId: PropTypes.string,
  rejectionCode: PropTypes.string,
  date: PropTypes.string,
  qty: PropTypes.number,
});

export const shippingSummaryShape = PropTypes.shape({
  sku: PropTypes.string,
  scheduled: PropTypes.arrayOf(shippingScheduleShape),
  shipped: PropTypes.arrayOf(shippingStatusShape),
});

export const orderDetailShape = PropTypes.shape({
  accountNumber: PropTypes.string,
  billingAddress: grafikiAddressShape,
  cancelDays: PropTypes.number,
  createdAt: PropTypes.string,
  shippingMethod: PropTypes.string,
  shippingPrice: currencyShape,
  fulfillmentMethod: PropTypes.string,
  id: PropTypes.string,
  breakouts: PropTypes.arrayOf(breakoutShape),
  paymentMethods: PropTypes.arrayOf(PropTypes.string),
  placedBy: PropTypes.string,
  placedFor: PropTypes.string,
  purchaseOrder: PropTypes.string,
  requestedShipOn: PropTypes.string,
  shippingAddress: grafikiAddressShape,
  shippingStatuses: PropTypes.arrayOf(shippingStatusShape),
  shipmentStatus: shippingSummaryShape,
  subtotalPrice: currencyShape,
  totalPrice: currencyShape,
  totalQty: PropTypes.number,
  type: PropTypes.string,
});

export const shoppingModeShape = PropTypes.oneOf(['atonce', 'booking']);

export const cartShape = PropTypes.shape({
  creator: cartCreatorOwnerShape,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shippingDate: PropTypes.instanceOf(Date),
  totals: PropTypes.shape({
    msrp: currencyShape,
    accountPrice: currencyShape.isRequired,
  }).isRequired,
  items: PropTypes.shape({
    totalQuantity: PropTypes.number,
    edges: PropTypes.arrayOf(lineItemShape),
  }),
  shoppingMode: shoppingModeShape,
  owner: cartCreatorOwnerShape,
  removedItems: cartRemovedItemsShape,
  account: accountShape,
});

export const paymentMethodShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  paymentMethodName: PropTypes.string.isRequired,
});

export const shippingDateRangeShape = PropTypes.shape({
  firstShipOn: PropTypes.instanceOf(Date),
  lastShipOn: PropTypes.instanceOf(Date),
});

export const futureInventoryItemShape = PropTypes.shape({
  quantity: PropTypes.number.isRequired,
  display: PropTypes.string,
  effectiveDate: PropTypes.instanceOf(Date).isRequired,
});

export const bannerShape = PropTypes.shape({
  backgroundImageUrl: PropTypes.string.isRequired,
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  floatText: PropTypes.oneOf(['left', 'right']).isRequired,
  linkPath: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  textColor: PropTypes.string.isRequired,
});

export const carouselShape = PropTypes.shape({
  interval: PropTypes.number.isRequired,
  playMode: PropTypes.string.isRequired,
  banners: PropTypes.arrayOf(bannerShape).isRequired,
});

export const catalogShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shoppingMode: PropTypes.oneOf(['AtOnce', 'Booking']),
});

export const sizeShape = PropTypes.shape({
  display: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
});

export const genericArticleShape = PropTypes.shape({
  colorHex: PropTypes.string.isRequired,
  colorName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sizes: PropTypes.arrayOf(sizeShape).isRequired,
});

export const genericArticlesShape = PropTypes.arrayOf(
  genericArticleShape.isRequired,
);

export const productShape = PropTypes.shape({
  styleCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fulfillerName: PropTypes.string.isRequired,
  description: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  genericArticles: genericArticlesShape,
});

export const allocationShape = PropTypes.shape({
  account: accountShape.isRequired,
  initial: currencyShape.isRequired,
  spent: currencyShape.isRequired,
  balance: currencyShape.isRequired,
});

export const allotmentShape = PropTypes.shape({
  balance: currencyShape.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
});

export const suggestedOrderShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      variantId: PropTypes.string.isRequired,
    }),
  ),
});

export const transactionOrderShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  poNumber: PropTypes.string.isRequired,
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
});

export const allotmentTransactionShape = PropTypes.shape({
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }),
  date: PropTypes.string.isRequired,
  creditOrDebit: PropTypes.oneOf(['CREDIT', 'DEBIT']).isRequired,
  transactionType: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  order: transactionOrderShape.isRequired,
  amount: currencyShape.isRequired,
});

export const designPieceShape = PropTypes.shape({
  recipeCode: PropTypes.string.isRequired,
});

export const priceTypeShape = PropTypes.oneOf([PRICE_MSRP, PRICE_ACCOUNT]);

export const salesRepShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

export const fulfillerNodeShape = PropTypes.shape({
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sapFulfillerName: PropTypes.string.isRequired,
  }),
});

export const addressFieldsShape = PropTypes.shape({
  shipOn: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  region: PropTypes.string,
  postalCode: PropTypes.string.isRequired,
  country: PropTypes.shape({
    code: PropTypes.string,
    regionRequired: PropTypes.bool,
  }).isRequired,
});

export const setShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  productIds: PropTypes.arrayOf(PropTypes.string),
});

export const playerInfoShape = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.string,
      initials: PropTypes.string,
      inseam: PropTypes.string,
      sizeModifier: PropTypes.string,
    }),
  ),
);

export const technologyShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
});

export const popupShape = PropTypes.shape({
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  subHeadline: PropTypes.string.isRequired,
});

export const sizeGuideShape = PropTypes.shape({
  category: PropTypes.string,
  measurementText: PropTypes.string,
  imperialMeasurements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  metricMeasurements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  measurementImage: PropTypes.string,
});

export const selectionsShape = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string,
    quantity: PropTypes.number.isRequired,
  }),
);

export const cartItemShape = PropTypes.shape({
  id: PropTypes.string,
  articleId: PropTypes.string,
  colorHex: PropTypes.string,
  colorName: PropTypes.string,
  sizeCode: PropTypes.string,
  styleId: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.number,
  embellishmentName: PropTypes.string,
  embellishmentNumber: PropTypes.string,
  embellishmentInitials: PropTypes.string,
  isBlank: PropTypes.bool,
  recipeCode: PropTypes.string,
  recipeSet: PropTypes.shape({
    id: PropTypes.string,
  }),
  genericArticleId: PropTypes.string,
  unitPricing: PropTypes.shape({
    msrp: currencyShape,
    accountPrice: currencyShape,
  }),
  rosterName: PropTypes.string,
});
