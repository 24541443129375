import styled from 'styled-components';
import { fonts, spacing } from 'lib/theme';
import * as t from '../typography';

const rowSpacing = ({ size }: { size?: string }) =>
  size === 'large' ? spacing.xxl : spacing.xl;

export const Legend = styled(t.H3)`
  text-transform: unset;
`;

export const Row = styled.div<{ size?: string }>`
  & + & {
    margin-top: ${rowSpacing};
  }
`;

const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;

  & + & {
    margin-top: ${spacing.xxl};
  }

  & & {
    margin-top: ${spacing.xxl};

    ${/* sc-sel */ Legend} {
      font: ${fonts.extra};
    }
  }
`;

export default Fieldset;
