import React from 'react';
import algoliasearch from 'algoliasearch';
import {
  append,
  applySpec,
  assoc,
  equals,
  keys,
  last,
  omit,
  path,
  pickBy,
  prop,
  reject,
  sortBy,
  toPairs,
} from 'ramda';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { useSelector } from 'react-redux';
import { getSelectedAccountId } from 'selectors/account';
import { extractProductIds, hasArticleIds } from 'lib/product-ids';
import { isMissing, isPresent } from './data-manipulation';

const GET_PLANT_INVENTORY_FLAG = gql`
  query getPlantInventoryFlag($accountId: ID!) {
    node(id: $accountId) {
      ... on Account {
        id
        plantInventoryEnabled
        plants {
          plantId
        }
        inventorySegmentCode
      }
    }
  }
`;

export const useAccountPlantData = id => {
  const accountId = id ?? useSelector(getSelectedAccountId);
  const { data, loading = true, error } = useQuery(GET_PLANT_INVENTORY_FLAG, {
    variables: {
      accountId,
    },
  });

  return !loading && !error && data?.node?.plantInventoryEnabled
    ? {
        plantIdOrInventorySegmentCode:
          data?.node?.plants[0]?.plantId || data?.node?.inventorySegmentCode,
      }
    : { plantIdOrInventorySegmentCode: data?.node?.inventorySegmentCode };
};

export const withAccountPlantData = WrappedComponent => {
  const Enhanced = props => (
    <WrappedComponent {...props} {...useAccountPlantData()} />
  );
  return Enhanced;
};

function getClient({ appId, indices }, whichIndex) {
  const { key } = indices[whichIndex];
  return algoliasearch(appId, key);
}

function getIndexName(config, whichIndex) {
  return path(['indices', whichIndex, 'name'], config);
}

function getIndex(config, whichIndex) {
  const algolia = getClient(config, whichIndex);
  return algolia.initIndex(getIndexName(config, whichIndex));
}

const productIdsClause = tags => tags.map(t => `_tags:"${t}"`).join(' OR ');

export const fromAlgoliaCategory = s => s.split(' > ');

export const toAlgoliaCategory = s => s.replace(/\//g, ' > ');

const customizableClause = customizable =>
  customizable ? 'customizable' : null;

export const translateFilters = (
  filters,
  tags = [],
  inventorySegmentCodeOrPlantId,
) => {
  const translate = filterName => {
    const filterValue = filters[filterName];
    if (isMissing(filterValue)) {
      return null;
    }

    switch (filterName) {
      case 'category': {
        const categoryClauses = filterValue.map(value => {
          const levels = value.split('/');
          return `category.lvl${levels.length - 1}:'${toAlgoliaCategory(
            value,
          )}'`;
        });
        return categoryClauses.join(' OR ');
      }

      case 'size': {
        const sizeClauses = toPairs(filterValue).map(
          ([k, v]) => `sizes.${k}.inv.${inventorySegmentCodeOrPlantId} >= ${v}`,
        );
        return sizeClauses.join(' AND ');
      }

      default: {
        return filterValue.map(v => `${filterName}:${v}`).join(' OR ');
      }
    }
  };

  const customizableFilter = equals(filters.customizable, ['1']);
  const filterClauses = Object.keys(filters)
    .filter(item => item !== 'customizable')
    .map(translate);
  const allClauses = append(
    productIdsClause(tags),
    append(customizableClause(customizableFilter), filterClauses),
  );
  return reject(isMissing, allClauses)
    .map(c => `(${c})`)
    .join(' AND ');
};

const CATEGORY_FACETS = ['category.lvl0', 'category.lvl1', 'category.lvl2'];

const facetForFilterName = (name, inventorySegmentCodeOrPlantId) => {
  switch (name) {
    case 'category':
      return CATEGORY_FACETS;
    case 'size':
      return `availableSizes.${inventorySegmentCodeOrPlantId}`;
    default:
      return [name];
  }
};

export const toAlgoliaPromise = (config, requestDescriptor) => {
  const {
    index = 'products',
    operation = 'search',
    query = '*',
    params = null,
  } = requestDescriptor;
  const algoliaIndexRef = getIndex(config, index);
  return algoliaIndexRef[operation](query, params);
};

export const multiQueryPromise = (config, requestDescriptor) => {
  const { index, queries } = requestDescriptor;
  const algolia = getClient(config, index);
  const indexName = getIndexName(config, index);
  const injectedQueries = queries.map(assoc('indexName', indexName));
  return algolia.search(injectedQueries);
};

export const requestProducts = (
  query,
  productIds,
  page,
  filters,
  itemsPerPage = 50,
  inventorySegmentCodeOrPlantId,
) => ({
  index: 'products',
  queries: [
    {
      query,
      params: {
        distinct: true,
        page: page - 1, // algolia pages are zero-indexed
        hitsPerPage: itemsPerPage,
        filters: translateFilters(
          filters,
          productIds,
          inventorySegmentCodeOrPlantId,
        ),
        attributesToHighlight: [],
      },
    },
    ...(isPresent(productIds)
      ? [
          {
            params: {
              // If search term has article id's , distinct param should be false
              distinct: !hasArticleIds(productIds),
              attributesToRetrieve: ['styleId', 'materialId', '_tags'],
              attributesToHighlight: [],
              page: 0,
              hitsPerPage: 1000,
              filters: translateFilters(
                [],
                productIds,
                inventorySegmentCodeOrPlantId,
              ),
            },
          },
        ]
      : []),
  ],
});

export const requestInstantSearch = searchTerm => {
  const [ids, query] = extractProductIds(searchTerm);

  return {
    query,
    params: {
      distinct: true,
      length: 3,
      offset: 0,
      filters: translateFilters([], ids),
    },
  };
};

export const requestByStyle = style => ({
  query: '*',
  params: {
    length: 1,
    offset: 0,
    filters: `_tags:${style}`,
    enableRules: false,
  },
});

export const requestFilterFacetValues = options => {
  const {
    searchTerm,
    filters,
    excludedFilter,
    inventorySegmentCodeOrPlantId,
  } = options;
  const [ids, query] = extractProductIds(searchTerm);
  return {
    query,
    params: {
      length: 1,
      offset: 0,
      filters: translateFilters(
        { ...filters, [excludedFilter]: null },
        ids,
        inventorySegmentCodeOrPlantId,
      ),
      facets: facetForFilterName(excludedFilter, inventorySegmentCodeOrPlantId),
    },
  };
};

export const requestGenders = {
  index: 'products',
  queries: [
    {
      query: '*',
      params: {
        length: 1,
        offset: 0,
        facets: ['gender'],
      },
    },
    {
      query: '*',
      params: {
        length: 1,
        offset: 0,
        filters: 'customizable',
        facets: ['gender'],
      },
    },
  ],
};

export const requestCategories = genders => ({
  index: 'products',
  queries: [
    ...keys(genders.all).map(g => ({
      query: '*',
      params: {
        length: 1,
        offset: 0,
        filters: translateFilters({ gender: [g] }),
        facets: CATEGORY_FACETS.slice(0, 2),
      },
    })),
    ...keys(genders.customizable).map(g => ({
      query: '*',
      params: {
        length: 1,
        offset: 0,
        filters: translateFilters({ gender: [g], customizable: ['1'] }),
        facets: CATEGORY_FACETS.slice(0, 1),
      },
    })),
  ],
});

export const requestGenericArticleAttributes = (styleIds, attributes) => ({
  query: '*',
  params: {
    attributesToRetrieve: attributes,
    length: 200,
    offset: 0,
    distinct: false,
    filters: productIdsClause(styleIds),
    enableRules: false,
  },
});

export const requestGenericArticles = genericArticleIds => ({
  query: '*',
  params: {
    distinct: false,
    filters: productIdsClause(genericArticleIds),
  },
});

// just something comfortably larger than we expect the max rows in slugs table
// to be:
const MAX_SLUGS = 500;

export const requestSlugs = {
  index: 'slugs',
  params: { offset: 0, length: MAX_SLUGS },
};

const mungeSizes = sizes => {
  const sizeRank = sizeId => sizes[sizeId].r;
  const sortedSizeIds = sortBy(sizeRank)(Object.keys(sizes || {}));
  return sortedSizeIds.map(id => ({
    id,
    display: sizes[id].d,
    articleId: sizes[id].sku,
  }));
};

export const mungeGenericArticle = hit => ({
  id: hit.materialId,
  styleId: hit.styleId,
  colorHex: hit.colorHex,
  colorName: hit.color,
  sizes: mungeSizes(hit.sizes),
  thumbnail: hit.assetURL || null,
  chip: hit.chipURL || null,
  isCustomizable: hit.isCustomizable,
  requiresCustomization: hit.requiresCustomization,
  genders: hit.gender,
});

export const mungeSingleProductCategory = raw => {
  // keys are e.g. lvl0, lvl1, lvl2.  We want the highest defined level.
  if (isMissing(raw)) {
    return [];
  }
  const lastKey = last(keys(raw).sort());
  return fromAlgoliaCategory(raw[lastKey][0]);
};

export const mungeProduct = hit =>
  hit
    ? {
        category: mungeSingleProductCategory(hit.category),
        genericArticles: [mungeGenericArticle(hit)],
        defaultGenericArticleId: hit.materialId,
        description: hit.description,
        details: hit.detailBullets,
        fit: hit.fit,
        name: hit.name,
        styleCode: hit.styleId,
        unavailable: false,
      }
    : { unavailable: true };

export const wrangleCategories = facets => {
  const makeCategory = ([algoliaName, count]) => {
    const pieces = fromAlgoliaCategory(algoliaName);
    return {
      name: last(pieces),
      path: pieces,
      slug: algoliaName,
      count,
      children: toPairs(
        pickBy((v, k) => k.startsWith(algoliaName))(
          facets[`category.lvl${pieces.length}`],
        ),
      ).map(makeCategory),
    };
  };

  return toPairs(facets['category.lvl0']).map(makeCategory);
};

const removeCategoryLevelFacets = omit(CATEGORY_FACETS);

const wrangleCategoryFacets = facets => ({
  ...removeCategoryLevelFacets(facets),
  category: wrangleCategories(facets),
});

export const wrangleFacets = (facets, filter) => {
  switch (filter) {
    case 'category':
      return wrangleCategoryFacets(facets);
    default:
      return facets;
  }
};

export const wrangleSlugs = payload =>
  payload.hits.map(
    applySpec({
      id: prop('objectID'),
      path: prop('treeName'),
      rank: prop('rank'),
      translation: prop('name'),
    }),
  );
