export const COPY = 'size-selections/COPY';

export const copySizeSelections = sizeSelections => ({
  type: COPY,
  payload: { sizeSelections },
});

// format: { [sizeId]: quantity, ... }
const initialState = {};

const sizeSelections = (state = initialState, { type, payload }) =>
  type === COPY ? payload.sizeSelections : state;

export default sizeSelections;
