import { useEffect, useState } from 'react';

// NOTE: To support Safari versions before 14, we must use the following, deprecated functions:
// - `addListener` instead of `addEventListener`
// - `removeListener` instead of `removeEventListener`
// https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#browser_compatibility
function useMatchMedia(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    // Updates state with initial query results
    setMatches(mediaQueryList.matches);

    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

    mediaQueryList.addListener(onChange);

    // Remove the event listener on cleanup.
    return () => mediaQueryList.removeListener(onChange);
  }, [query]);

  return matches;
}

export default useMatchMedia;
