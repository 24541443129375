import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import styled from 'styled-components';
import { isPresent } from 'lib/data-manipulation';
import analytics from 'lib/analytics';
import { borders, colors, spacing } from 'lib/theme';
import withMarketing from 'lib/with-marketing';
import TextLink from './ui/links';
import { getActiveShoppingMode } from '../selectors/account';

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: ${spacing.m} ${spacing.xl};
  background-color: ${colors.offWhite};
  border-bottom: ${borders.hairline};
`;

const Link = styled(TextLink)`
  font-size: 16px;
  margin-right: 5px;
`;

const NoticeBar = ({ notice, shoppingMode }) => {
  useEffect(() => {
    if (isPresent(notice)) {
      analytics.logPageNoticeView({
        shoppingMode,
        contentName: notice.text,
      });
    }
  }, [notice, shoppingMode]);
  return isPresent(notice) ? (
    <Bar>
      <div>
        <Link
          to={notice.href}
          onClick={() =>
            analytics.logPageNoticeClick({
              shoppingMode,
              contentName: notice.text,
            })
          }
          target="_blank"
          rel="noopener"
        >
          {notice.linkText}
        </Link>
        {notice.text}
      </div>
    </Bar>
  ) : null;
};
NoticeBar.defaultProps = {
  notice: null,
};

NoticeBar.propTypes = {
  notice: PropTypes.shape({
    path: PropTypes.string,
    text: PropTypes.string,
    active: PropTypes.bool,
    href: PropTypes.string,
    linkText: PropTypes.string,
  }),
  shoppingMode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  shoppingMode: getActiveShoppingMode(state),
});

export default compose(
  withMarketing(['notice']),
  connect(mapStateToProps),
)(NoticeBar);
