import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Router } from 'react-router-dom';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import { updateScreenSize } from 'ducks/screen-size';
import { fetchSiteMeta } from 'ducks/site-meta';
import history from 'lib/history';
import { RequireUserContext, UserContextProvider } from 'lib/use-user-context';
import Home from './home';
import PreLogin from './pre-login';
import ScrollToTop from './scroll-to-top';

const OVERFLOW_HIDDEN_CLASS = 'overflow-hidden';

const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: 0.5s ease;
  will-change: opacity;
`;

const Body = styled.div`
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
`;

export class App extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      updateScreenSize: PropTypes.func.isRequired,
      fetchSiteMeta: PropTypes.func.isRequired,
    }).isRequired,
    overflowHidden: PropTypes.bool.isRequired,
  };

  state = { loaded: false };

  componentDidMount() {
    const { actions, overflowHidden } = this.props;
    actions.fetchSiteMeta();
    this.setOverflowClasses(overflowHidden);
    if (window) {
      actions.updateScreenSize(window.innerWidth);
      window.addEventListener('resize', () => {
        debounce(300, actions.updateScreenSize(window.innerWidth));
      });
    }
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    this.setOverflowClasses(nextProps.overflowHidden);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    if (window) {
      window.removeEventListener('resize', actions.updateScreenSize);
    }
  }

  setOverflowClasses = overflowHidden => {
    const { classList } = document.body;
    const hasOverflowHiddenClass = classList.contains(OVERFLOW_HIDDEN_CLASS);
    if (overflowHidden && !hasOverflowHiddenClass) {
      classList.add(OVERFLOW_HIDDEN_CLASS);
    } else if (!overflowHidden && hasOverflowHiddenClass) {
      classList.remove(OVERFLOW_HIDDEN_CLASS);
    }
  };

  render() {
    const { loaded } = this.state;
    return (
      <Router history={history}>
        <ScrollToTop>
          <Page {...{ loaded }}>
            <UserContextProvider>
              <Body>
                <RequireUserContext fallback={<PreLogin />}>
                  <Home />
                </RequireUserContext>
              </Body>
            </UserContextProvider>
          </Page>
        </ScrollToTop>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    overflowHidden: state.screen.enabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchSiteMeta, updateScreenSize }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
