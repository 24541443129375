import { compose, path, pick, prop } from 'ramda';
import { isMissing, isPresent } from './data-manipulation';

export interface MoneyType {
  code: string;
  amount: string;
}

export const emptyCurrency: MoneyType = { amount: '0', code: '' };

export const emptyCurrencyWithCode = (code: string) => ({
  amount: '0',
  code,
});

const currencyIsEmpty = (curr: MoneyType) =>
  isMissing(curr) || (curr.code === '' && parseFloat(curr.amount) === 0);

export const getAmount = (currency: MoneyType) =>
  parseFloat(prop('amount', currency));

export const mungeCurrency = pick(['amount', 'code']);

export const multiplyCurrency = (currency: MoneyType, qty: number) => ({
  amount: (getAmount(currency) * qty).toString(),
  code: currency.code,
});

export const addCurrencies = (
  currency1: MoneyType,
  currency2: MoneyType,
): MoneyType => {
  if (currencyIsEmpty(currency1)) {
    return currency2;
  }
  if (currencyIsEmpty(currency2)) {
    return currency1;
  }

  if (currency1.code !== currency2.code) {
    throw new Error(
      `Currency codes must match; got "${currency1.code}" and "${currency2.code}".`,
    );
  }
  return {
    amount: (getAmount(currency1) + getAmount(currency2)).toString(),
    code: currency1.code,
  };
};

export const subtractCurrencies = (
  currency1: MoneyType,
  currency2: MoneyType,
) => {
  if (currencyIsEmpty(currency1)) {
    return currency2;
  }
  if (currencyIsEmpty(currency2)) {
    return currency1;
  }

  if (currency1.code !== currency2.code) {
    throw new Error(
      `Currency codes must match; got "${currency1.code}" and "${currency2.code}".`,
    );
  }
  return {
    amount: (getAmount(currency1) - getAmount(currency2)).toString(),
    code: currency1.code,
  };
};

export const sumCurrencies = (currencies: Array<MoneyType>) =>
  currencies.length === 0
    ? emptyCurrency
    : currencies.reduce(addCurrencies, {
        amount: '0',
        code: currencies[0].code,
      });

export const negativeCurrency = (currency: MoneyType) => ({
  ...currency,
  amount:
    getAmount(currency) === 0
      ? getAmount(currency)
      : getAmount(currency) * -1.0,
});

export const currencyRange = (
  currencies: Array<MoneyType>,
): [MoneyType, MoneyType] => {
  const currencySorted = (currencies.length === 0
    ? [emptyCurrency]
    : currencies
  ).sort((a, b) => getAmount(a) - getAmount(b));

  // return min and max values
  return [currencySorted[0], currencySorted.pop() as MoneyType];
};

export const isValidPrice = compose(isPresent, path(['code']));
