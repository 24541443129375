const UPDATE_SCREEN_SIZE = 'layout/UPDATE_SCREEN_SIZE';

export const updateScreenSize = size => ({
  type: UPDATE_SCREEN_SIZE,
  size,
});

const screenSize = (state = 0, { type, size }) => {
  switch (type) {
    case UPDATE_SCREEN_SIZE:
      return size;
    default:
      return state;
  }
};

export default screenSize;
