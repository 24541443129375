import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import Downshift from 'downshift';
import SelectFilterUI from './ui/index';
import optionShape from './option-shape';

const initialState = { query: '' };

class SelectFilter extends React.Component {
  state = initialState;

  delayedGetOptions = debounce(300, query => {
    const { getOptions } = this.props;
    getOptions(query);
  });

  componentDidUpdate = (_, prevState) => {
    const { query } = this.state;
    const { onQueryChange } = this.props;
    if (query !== prevState.query) {
      this.delayedGetOptions(query);
      onQueryChange();
    }
  };

  changeQuery = value => this.setState({ query: value });

  handleStateChange = ({ isOpen }) => {
    const { getOptions } = this.props;
    if (isOpen === true) {
      this.refInput.focus();
      getOptions();
    } else if (isOpen === false) {
      this.setState({ query: '' });
    }
  };

  clearInput = () => {
    this.changeQuery('');

    this.refInput.focus();
  };

  render() {
    const {
      errorMessage,
      filterPlaceholder,
      includeBlank,
      isDisabled,
      isLoading,
      label,
      loadingMessage,
      noResultsMessage,
      options,
      required,
      size,
      onSelectOption,
      selectedOption,
      optionToString,
      resultsFooterSection,
      openDirection,
      width,
    } = this.props;

    const { query } = this.state;

    return (
      <Downshift
        onSelect={onSelectOption}
        defaultHighlightedIndex={0}
        onInputValueChange={this.changeQuery}
        itemToString={() => query}
        selectedItem={selectedOption}
        inputValue={query}
        onStateChange={this.handleStateChange}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getToggleButtonProps,
          isOpen,
          highlightedIndex,
        }) => (
          <div>
            <SelectFilterUI
              errorMessage={errorMessage}
              filterPlaceholder={filterPlaceholder}
              includeBlank={includeBlank}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isOpen={isOpen}
              label={label}
              loadingMessage={loadingMessage}
              noResultsMessage={noResultsMessage}
              onInputClear={this.clearInput}
              options={options}
              query={query}
              required={required}
              size={size}
              value={optionToString(selectedOption)}
              getInputProps={getInputProps}
              getLabelProps={getLabelProps}
              getToggleButtonProps={getToggleButtonProps}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              ref={el => {
                this.refInput = el;
              }}
              resultsFooterSection={resultsFooterSection}
              openDirection={openDirection}
              width={width}
            />
          </div>
        )}
      </Downshift>
    );
  }
}

SelectFilter.propTypes = {
  errorMessage: PropTypes.string,
  filterPlaceholder: PropTypes.string.isRequired,
  getOptions: PropTypes.func.isRequired,
  includeBlank: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  loadingMessage: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func,
  onSelectOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(optionShape).isRequired,
  required: PropTypes.bool,
  size: PropTypes.string,
  selectedOption: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  optionToString: PropTypes.func.isRequired,
  resultsFooterSection: PropTypes.node,
  openDirection: PropTypes.oneOf(['down', 'up']),
  width: PropTypes.number,
};

SelectFilter.defaultProps = {
  errorMessage: null,
  includeBlank: null,
  isDisabled: false,
  onQueryChange: () => {},
  required: false,
  size: 'normal',
  selectedOption: null,
  resultsFooterSection: null,
  openDirection: 'down',
  width: null,
};

export default SelectFilter;
