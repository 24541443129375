import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Button } from 'components/ui/button';
import Loading from 'components/loading';

const ContainerCSS = css`
  text-align: center;
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  ${ContainerCSS}
`;

const LoadingContainer = styled.div`
  ${ContainerCSS}
  padding-bottom: 10px;
`;

const CompactButton = styled(Button)`
  width: 100%;
`;

const ResultsFooter = ({ onClick, hasMore, loading = false }) =>
  loading ? (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  ) : hasMore ? (
    <ButtonContainer>
      <FormattedMessage id="accounts.load-more">
        {msg => (
          <CompactButton onClick={onClick} size="compact">
            {msg}
          </CompactButton>
        )}
      </FormattedMessage>
    </ButtonContainer>
  ) : null;

ResultsFooter.propTypes = {
  loading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ResultsFooter.defaultProps = {
  loading: false,
};

export default ResultsFooter;
