import { createSelector } from 'reselect';
import {
  compose,
  flatten,
  path,
  pluck,
  find,
  ifElse,
  isEmpty,
  prop,
  always,
} from 'ramda';
import { PRICE_MSRP, PRICE_ACCOUNT, PRICE_PROMO } from 'lib/pricing';
import { getUserProfile } from './user';

const getArticleIds = (state, props) => {
  const { styleId } = props;
  const { genericArticles } = state.search.products[styleId];
  const articles = compose(flatten, pluck('sizes'))(genericArticles || []);
  return compose(flatten, pluck('articleId'))(articles || []);
};

export const allArticlePrices = state => state.pricing.byArticle.byArticleId;

export const getArticlePrices = createSelector(
  [getArticleIds, allArticlePrices],
  (articleIds, prices) =>
    articleIds.reduce(
      (pricesByArticle, articleId) =>
        prices[articleId]
          ? {
              ...pricesByArticle,
              [articleId]: prices[articleId],
            }
          : pricesByArticle,
      {},
    ),
);

export const articlePricesLoaded = createSelector(
  [getArticleIds, getArticlePrices],
  (articleIds, prices) => !find(id => !prices[id])(articleIds),
);

export const hasArticlePricingError = state => !!state.pricing.byArticle.error;

export const hasStylePricingError = state => !!state.pricing.byStyle.error;

export const stylePricesLoaded = (state, { styleId }) =>
  !!path(['pricing', 'byStyle', 'byStyleId', styleId], state);

export const hasStylePricing = state =>
  !isEmpty(path(['pricing', 'byStyle', 'byStyleId'], state));

export const getAccountPrice = (state, { articleId }) =>
  path(['pricing', 'byArticle', 'byArticleId', articleId, PRICE_ACCOUNT])(
    state,
  );

export const getShouldShowCustomerPricingOnly = state =>
  path(['user', 'profile', 'showCustomerPricingOnly'], state);
export const getMsrp = (state, { articleId }) =>
  path(['pricing', 'byArticle', 'byArticleId', articleId, PRICE_MSRP])(state);

export const getAccountPrices = createSelector(
  [allArticlePrices],
  pluck(PRICE_ACCOUNT),
);

export const getMsrpPrices = createSelector(
  [allArticlePrices],
  pluck(PRICE_MSRP),
);

export const getPromoPrices = createSelector(
  [allArticlePrices],
  pluck(PRICE_PROMO),
);

export const getPricingForStyle = (state, { styleId }) =>
  path(['pricing', 'byStyle', 'byStyleId', styleId], state);

export const getAccountPriceForStyle = createSelector(
  [getPricingForStyle],
  path([PRICE_ACCOUNT]),
);

export const getMSRPPriceForStyle = createSelector(
  [getPricingForStyle],
  path(['msrp']),
);

export const getDisplayedPriceType = createSelector(
  [getUserProfile],
  ifElse(
    prop('showCustomerPricingOnly'),
    always(PRICE_MSRP),
    always(PRICE_ACCOUNT),
  ),
);
