import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import analytics from 'lib/analytics';
import logo from 'lib/logo-wordmark.svg';
import { withoutRegion } from 'lib/locales';
import { borders, breakpoint, colors, fonts, spacing } from 'lib/theme';
import useMatchMedia from 'lib/use-match-media';
import { getLocale } from 'selectors/locale';
import { media, maxWidthMedia } from 'styles/mixins';
import { TextLink } from './ui/links';

const FooterWrapper = styled.div`
  margin-top: ${({ topMargin }) => (topMargin ? '80px' : 0)};
  background-color: ${colors.offWhite};

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    margin-top: ${({ topMargin }) => (topMargin ? spacing.sm : 0)};
    padding-bottom: ${spacing.gd.xs};
  `};
`;

const FooterSection = styled.div`
  display: flex;

  ${media(breakpoint.tablet)`
    align-items: center;
    padding: ${spacing.xl};
  `};

  &:last-child {
    flex-direction: column;

    ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
      padding: 0 ${spacing.gd.xs} ${spacing.gd.sm} ${spacing.gd.xs};
    `};

    ${media(breakpoint.tablet)`
      flex-direction: row;
    `};
  }

  &:first-child {
    ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
      padding: ${spacing.gd.sm} ${spacing.gd.xs} ${spacing.gd.xs} ${spacing.gd.xs};
    `};

    ${media(breakpoint.tablet)`
      border-bottom: ${borders.hairline};
      padding: 0 ${spacing.xl};
    `};
  }

  ${media(breakpoint.tablet)`
    height: ${props => (props.primary ? '80px' : '40px')};
  `};
`;

const FooterLogo = styled.div`
  display: none;

  ${media(`${breakpoint.gd.phoneMin}`)`
    display: block;
    flex: 1;
  `};

  ${media(`${breakpoint.tablet}`)`
    display: block;
    flex: 1;
  `};
`;

const Logo = styled.img`
  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    height: ${spacing.gd.xxs};
  `};
`;

const FooterMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-count: auto;
  text-align: center;
  width: auto;
`;

const FooterMenuItem = styled.li`
  ${media(breakpoint.tablet)`
    margin-left: ${spacing.xl};
  `};
`;

const FooterLegal = styled.div`
  color: ${colors.offBlack};
  font: ${fonts.gd.regular.legal};
  flex: 1;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    & > span:last-child {
      display: none;
    }
  `};

  ${media(breakpoint.tablet)`
    font: ${fonts.tiny};
    margin-top: 0;
  `};
`;

const FooterLegalMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    justify-content: start;
  `};
`;

const FooterLegalMenuItem = styled.li`
  font: ${fonts.gd.regular.legal};

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    color: ${colors.gd.gray5};
    margin: 0;

    &:not(:last-child)::after {
      content: ' / ';
      white-space: pre;
    }
  `};

  ${media(breakpoint.tablet)`
    font: ${fonts.tiny};
    margin-right: 0;
    margin-left: ${spacing.xl};
  `};
`;

const A = styled.a`
  color: ${colors.black};
  font: ${fonts.normal};
  text-decoration: none;
`;

const LegalA = styled.a`
  color: ${colors.offBlack};
  text-decoration: none;
`;

const FooterLink = styled(TextLink)`
  font: ${fonts.normal};
`;

const links = [
  {
    id: 'technologies',
    to: '/technologies',
    name: 'Technologies',
  },
  {
    id: 'training-materials',
    to: '/training',
    name: 'Training',
  },
  {
    id: 'faq',
    to: '/faq',
    name: 'FAQ',
  },
];
const externalLinks = [
  {
    id: 'investor-relations',
    href: 'http://investor.underarmour.com/investors.cfm',
    name: 'Investor Relations',
  },
  {
    id: 'ua-biz-site',
    href: 'http://www.uabiz.com/',
    name: 'UA Biz Site',
  },
  {
    id: 'product-recalls',
    href: 'http://productsafety.underarmour.com/productsafety/',
    name: 'Product Safety',
  },
  {
    id: 'careers',
    href: 'https://careers.underarmour.com/',
    name: 'Careers',
  },
];
const legalLinks = locale => [
  {
    id: 'privacy-policy',
    href: 'https://account.underarmour.com/privacy',
  },
  {
    id: 'terms-and-conditions',
    href: `https://account.underarmour.com/terms-and-conditions?locale=${withoutRegion(
      locale,
    )}`,
  },
];

const logFooterNavigation = page => () => analytics.logFooterNavigation(page);

const Footer = ({ topMargin, locale }) => {
  const isMobile = useMatchMedia(`(max-width: ${breakpoint.gd.phoneMax})`);

  return (
    <FooterWrapper topMargin={topMargin}>
      <FooterSection primary={true}>
        <FooterLogo>
          <FormattedMessage id="logo">
            {msg => <Logo src={logo} alt={msg} />}
          </FormattedMessage>
        </FooterLogo>
        {!isMobile && (
          <FooterMenu>
            {links.map(({ id, to, name }) => (
              <FooterMenuItem key={id}>
                <FooterLink
                  to={to}
                  target="_blank"
                  rel="noopener"
                  onClick={logFooterNavigation(name)}
                >
                  <FormattedMessage id={`footer.${id}`} />
                </FooterLink>
              </FooterMenuItem>
            ))}
            {externalLinks.map(({ id, href, name }) => (
              <FooterMenuItem key={id}>
                <A href={href} onClick={logFooterNavigation(name)}>
                  <FormattedMessage id={`footer.${id}`} />
                </A>
              </FooterMenuItem>
            ))}
          </FooterMenu>
        )}
      </FooterSection>
      <FooterSection primary={false}>
        <FooterLegal>
          <FormattedMessage
            id="footer.copyright"
            values={{ year: new Date().getFullYear() }}
          />
          <FormattedMessage id="footer.rights-reserved" />
        </FooterLegal>
        <FooterLegalMenu>
          {legalLinks(locale).map(({ id, href }) => (
            <FooterLegalMenuItem key={id}>
              <LegalA href={href} target="_blank" rel="noopener">
                <FormattedMessage id={`footer.${id}`} />
              </LegalA>
            </FooterLegalMenuItem>
          ))}
          <FooterLegalMenuItem key="teconsent">
            <LegalA>
              <span id="teconsent" />
            </LegalA>
          </FooterLegalMenuItem>
        </FooterLegalMenu>
      </FooterSection>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  topMargin: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

Footer.defaultProps = { topMargin: true };

const mapStateToProps = state => ({ locale: getLocale(state) });

export default connect(mapStateToProps)(Footer);
