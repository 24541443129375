import { packSuccess } from 'lib/redux-pack';
import { GET_PRODUCTS, getStylesDetails } from 'ducks/search';
import loadCategories from 'ducks/search/load-categories';
import loadGenders from 'ducks/search/load-genders';
import { getActiveShoppingMode } from 'selectors/account';

export default store => next => action => {
  const result = next(action);
  const state = store.getState();

  if (packSuccess(action, GET_PRODUCTS)) {
    if (action.meta.viewMode === 'list') {
      const accountId = state.authentication.selectedAccount.id;
      const styleIds = state.search.currentGrid;
      const shoppingMode = getActiveShoppingMode(state);
      store.dispatch(getStylesDetails(accountId, shoppingMode, styleIds));
    }
  }

  if (packSuccess(action, loadGenders.type)) {
    store.dispatch(loadCategories.creator(state.search.genders));
  }

  return result;
};
