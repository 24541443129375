import { handle } from 'redux-pack';
import { mergeDeepLeft, pick } from 'ramda';
import { CHOOSE_LOCALE } from 'ducks/locale';
import gql from 'graphql-tag';
import {
  mungeAccountDetails,
  getAvailableShoppingModes,
  getDefaultShoppingMode,
  isShoppingModeAvailable,
} from 'lib/account';
import {
  exchangeAccessToken,
  EXCHANGE_ACCESS_TOKEN,
  mungeAccessToken,
} from 'lib/session';
import { b2bApiShoppingModes } from '../lib/user';

export { exchangeAccessToken, EXCHANGE_ACCESS_TOKEN };
export const LOGIN = 'authentication/LOGIN';
export const FETCH_ACCOUNT = 'authentication/FETCH_ACCOUNT';
export const FETCH_DEFAULT_ACCOUNT = 'authentication/DEFAULT_ACCOUNT';
export const ACTIVATE_ACCOUNT = 'authentication/ACTIVATE_ACCOUNT';
export const SET_ACCOUNT_SEARCH = 'authentication/SET_ACCOUNT_SEARCH';
export const LOGOUT = 'authentication/LOGOUT';
export const CHOOSE_SHOPPING_MODE = 'authentication/CHOOSE_SHOPPING_MODE';

const requestLogin = (username, password) => ({
  uaApiRequest: {
    url: '/api/sessions',
    method: 'POST',
    data: { username, password },
  },
});

const requestLogout = refreshToken => ({
  uaApiRequest: {
    url: '/api/sessions/invalidate',
    method: 'POST',
    data: { refreshToken },
  },
});

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountDataFragment on Account {
    id
    accountNumber
    currency
    eligibilityModes {
      id
      eligibilityModeName
    }
    accountName
    salesOrgCode
    salesGroupCode
    organization {
      id
      organizationName
    }
    shoppingModes {
      mode
      firstShipDate
      lastShipDate
    }
    customerGroup {
      code
    }
    allotmentAllocations {
      current {
        balance {
          amount
          currency
        }
      }
    }
    inventorySegmentCode
    invoicePortalUrl
    primaryBillingAddress {
      id
      shippingCountry {
        countryCode
      }
    }
  }
`;

const DEFAULT_ACCOUNTS_QUERY = gql`
  query getAccounts {
    currentUser {
      id
      accounts(first: 1) {
        edges {
          node {
            id
            ...AccountDataFragment
          }
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

const GET_ACCOUNT = gql`
  query getAccount($accountId: ID!) {
    node(id: $accountId) {
      ... on Account {
        id
        ...AccountDataFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const login = (username, password) => ({
  type: LOGIN,
  meta: {
    username,
    undelayable: true,
    ...requestLogin(username, password),
  },
});

export const logout = refreshToken => ({
  type: LOGOUT,
  meta: {
    undelayable: true,
    ...requestLogout(refreshToken),
  },
});

const transformData = node => {
  const newShoppingModes = node.shoppingModes.map(
    ({ mode, firstShipDate, lastShipDate }) => ({
      id: b2bApiShoppingModes[mode],
      firstShipOn: firstShipDate,
      lastShipOn: lastShipDate,
    }),
  );
  return {
    data: {
      accountNumber: node.accountNumber,
      availableShoppingModes: newShoppingModes,
      currency: node.currency,
      eligibilityModes: node.eligibilityModes,
      id: node.id,
      name: node.accountName,
      customerGroupCode: node.customerGroup.code,
      organizationID: node.organization ? node.organization.id : null,
      organizationName: node.organization
        ? node.organization.organizationName
        : null,
      salesOrg: node.salesOrgCode,
      allotmentAllocations: node.allotmentAllocations,
      countryCode: node.primaryBillingAddress.shippingCountry.countryCode,
      salesGroupCode: node.salesGroupCode,
      inventorySegmentCode: node.inventorySegmentCode,
      invoicePortalUrl: node.invoicePortalUrl,
    },
  };
};

const fetchDefaultAccount = graphqlClient =>
  graphqlClient
    .query({
      query: DEFAULT_ACCOUNTS_QUERY,
    })
    .then(payload => {
      const defaultAccount = payload.data.currentUser.accounts.edges[0].node;
      return transformData(defaultAccount);
    });

export const fetchAccount = (accountId, selectNow = false) => ({
  type: FETCH_ACCOUNT,
  promise: import('index').then(({ graphqlClient }) => {
    if (!accountId) {
      return fetchDefaultAccount(graphqlClient);
    }
    return graphqlClient
      .query({
        query: GET_ACCOUNT,
        variables: {
          accountId,
        },
      })
      .then(payload => {
        // Would return empty data if user didn't have access to account
        if (!payload.data.node) {
          return fetchDefaultAccount(graphqlClient);
        }
        return transformData(payload.data.node);
      });
  }),
  meta: { selectNow },
});

export const activateAccount = account => ({
  type: ACTIVATE_ACCOUNT,
  account,
});

export const chooseShoppingMode = mode => ({
  type: CHOOSE_SHOPPING_MODE,
  meta: { mode },
});

export const setAccountSearch = term => ({
  type: SET_ACCOUNT_SEARCH,
  payload: { term },
});

const initialState = { accounts: {}, accountChooserIds: [] };

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ACCOUNT:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          waitingForAccount: true,
          accountError: null,
        }),
        finish: prevState => ({
          ...prevState,
          waitingForAccount: false,
        }),
        failure: prevState => ({
          ...prevState,
          accountError: payload.message,
        }),
        success: prevState => {
          const { selectNow } = action.meta;
          const { activeShoppingMode } = prevState;
          const account = mungeAccountDetails(payload.data);
          const availableShoppingModes = getAvailableShoppingModes(
            payload.data,
          );
          return {
            ...prevState,
            accounts: { ...prevState.accounts, [account.id]: account },
            selectedAccount: selectNow ? account : prevState.selectedAccount,
            activeShoppingMode:
              activeShoppingMode &&
              isShoppingModeAvailable(payload.data, activeShoppingMode)
                ? activeShoppingMode
                : getDefaultShoppingMode(availableShoppingModes),
          };
        },
      });

    case ACTIVATE_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.account,
      };

    case SET_ACCOUNT_SEARCH:
      return {
        ...state,
        accountSearchTerm: action.payload.term,
      };

    case LOGIN:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          access: null,
          locale: null,
          waiting: true,
          error: null,
        }),
        finish: prevState => ({
          ...prevState,
          waiting: false,
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.message,
        }),
        success: prevState => ({
          ...prevState,
          username: action.meta.username,
          access: mungeAccessToken(payload),
          refreshToken: payload.data.refreshToken,
          locale: payload.data.locale,
        }),
      });

    case EXCHANGE_ACCESS_TOKEN:
      return handle(state, action, {
        start: mergeDeepLeft({ access: { waiting: true } }),
        finish: mergeDeepLeft({ access: { waiting: false } }),
        failure: prevState =>
          mergeDeepLeft({ access: { error: payload.message } }, prevState),
        success: prevState =>
          mergeDeepLeft(
            {
              access: {
                error: null,
                ...mungeAccessToken(payload),
              },
            },
            prevState,
          ),
      });

    case LOGOUT:
      return handle(state, action, {
        // YES, really clear the entire authentication part of redux,
        // except for a whitelist
        start: pick(['activeShoppingMode', 'selectedAccount', 'username']),
      });

    case CHOOSE_LOCALE:
      return handle(state, action, {
        success: prevState => ({ ...prevState, locale: action.meta.locale }),
      });

    case CHOOSE_SHOPPING_MODE:
      return { ...state, activeShoppingMode: action.meta.mode };

    default:
      return state;
  }
};
export default reducer;
