import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { borders, colors, fonts, spacing, height } from 'lib/theme';

export const Header = styled.header`
  background-color: ${colors.white};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  border-bottom: ${borders.hairline};
  display: flex;
  flex-flow: row wrap;
  height: ${height.shopping};
`;

export const HeaderItems = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const LogoLink = styled(Link)`
  padding-right: ${spacing.xxl};
  align-items: center;
  display: flex;
`;

export const Logo = styled.img``;

export const CategoryToggle = styled.div`
  padding-right: ${spacing.xxl};
  align-items: center;
  display: flex;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
  }
`;

export const CategoryToggleContent = styled.div`
  font: ${fonts.extra};
  align-items: center;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
`;
