import { clamp, find, isEmpty, path } from 'ramda';
import clientGql from './utils';

export const defaults = {
  __typename: 'OrderDetail',
  isCancellingLineItems: false,
  cancelQuantities: [],
};

export const resolvers = {
  Mutation: {
    setIsCancellingLineItems: (_, { isCancellingLineItems }, { cache }) => {
      const data = {
        orderDetail: {
          ...defaults,
          isCancellingLineItems,
        },
      };
      cache.writeData({ data });
      return null;
    },
    setCancelQuantity: (_, { newQty, article }, { cache }) => {
      const query = clientGql`
        query GetQuantities {
          orderDetail @client {
            cancelQuantities {
              breakoutId
              sku
              quantity
            }
          }
        }
      `;

      let qty = newQty;
      if (!isEmpty(newQty)) {
        const parsedQty = parseInt(newQty, 10);
        if (!Number.isNaN(parsedQty)) {
          qty = clamp(0, article.qty, parsedQty);
        } else {
          qty = 0;
        }
      }

      const cancelQuantities = path(['orderDetail', 'cancelQuantities'])(
        cache.readQuery({ query }),
      );
      const previous = find(
        q => q.breakoutId === article.breakoutId && q.sku === article.sku,
      )(cancelQuantities);
      if (previous) {
        previous.quantity = qty;
      } else {
        cancelQuantities.push({
          breakoutId: article.breakoutId,
          sku: article.sku,
          quantity: qty,
          __typename: 'LineItemQuantity',
        });
      }

      const data = {
        orderDetail: {
          __typename: 'OrderDetail',
          cancelQuantities,
        },
      };
      cache.writeData({ data });
      return null;
    },
  },
};

export const typeDefs = {
  types: `
    type LineItemQuantity {
      breakoutId: Int!
      sku: String!
      quantity: Int!
    }`,
  queries: `  
    isCancellingLineItems: Boolean!
    lineItemQuantities: [LineItemQuantity!]!`,
  mutations: ``,
};
