import { handle } from 'redux-pack';
import { applySpec, prop, pathOr } from 'ramda';
import { DETAILS_STEP } from './steps';

export const type = 'new-cart-wizard/CREATE_CARTS';

const creator = carts => ({
  type,
  promise: Promise.resolve(carts),
});

const failureCreator = () => ({
  type,
  promise: Promise.reject(new Error('FAILED_CART_CREATION')),
  payload: {
    message: 'Failed To Create Cart From OrderForm',
  },
});

export const start = (prevState, action) => ({
  ...prevState,
  error: null,
  uploading: true,
  filename: action.meta.filename,
});

export const finish = prevState => ({
  ...prevState,
  uploading: false,
  stepIndex: DETAILS_STEP,
  requestCreate: false,
});

export const failure = (prevState, action) => ({
  ...prevState,
  error: applySpec({
    message: prop('message'),
  })(action.payload),
});

const getCartNode = ({ node }) => node;
const getCartItems = items => items.edges.map(getCartNode);
const cartsFromResponse = pathOr(
  [],
  ['data', 'addCartsFromOrderForm', 'carts'],
);

const reduceCarts = (acc, { id, cartName, items }) => ({
  ...acc,
  [id]: {
    id,
    shippingDate: new Date(),
    cartName,
    items: getCartItems(items),
  },
});

const parseErrorsFromResponse = pathOr(
  [],
  ['data', 'addCartsFromOrderForm', 'parsingErrors'],
);

export const success = (prevState, action) => {
  const { payload } = action;
  return {
    ...prevState,
    carts: cartsFromResponse(payload).reduce(reduceCarts, {}),
    parseErrors: parseErrorsFromResponse(payload),
    error: null,
    stepIndex: DETAILS_STEP,
  };
};

const reducer = (state, action) =>
  handle(state, action, { start, finish, failure, success });

export default { type, creator, failureCreator, reducer };
