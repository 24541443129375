import { handle } from 'redux-pack';
import { pathOr } from 'ramda';

export const FETCH_LOGIN_IMAGE = 'login_image/FETCH';

export const initialState = { set: {}, error: null, fetching: false };

export const fetchLoginImage = () => ({
  type: FETCH_LOGIN_IMAGE,
  meta: {
    contentfulRequest: {
      content_type: 'loginImage',
      order: '-sys.createdAt',
      include: 2,
    },
  },
});

const mungePayload = payload =>
  pathOr(
    null,
    ['items', 0, 'fields', 'image', 'fields', 'file', 'url'],
    payload,
  );

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LOGIN_IMAGE:
      return handle(state, action, {
        start: () => ({ ...initialState, fetching: true }),
        success: prevState => ({
          ...prevState,
          image: mungePayload(payload),
        }),
        failure: prevState => ({
          ...prevState,
          error: payload?.response?.data?.message,
        }),
        finish: prevState => ({ ...prevState, fetching: false }),
      });

    default:
      return state;
  }
};

export default reducer;
