import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts } from 'lib/theme';

const borderWidth = ({ isActive }) => (isActive ? 2 : 1);

const borderColor = ({ isActive }) =>
  isActive ? colors.black : colors.darkGrey;

const activeStyles = props => {
  const activeBorderWidth = borderWidth({ ...props, isActive: true });
  const activeBorderColor = borderColor({ ...props, isActive: true });

  return `
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 ${activeBorderWidth}px ${activeBorderColor};
  `;
};

const StyledControl = styled.div`
  height: 100%;
  border: none;
  width: 100%;
  box-shadow: inset 0 0 0 ${borderWidth}px ${borderColor};
  outline: none;
  font: ${fonts.heavy};

  input:focus ~ &,
  input:active ~ &,
  &:focus {
    ${activeStyles};
  }
`;

StyledControl.propTypes = { isActive: PropTypes.bool };

StyledControl.defaultProps = { isActive: false };

export default StyledControl;
