import { path } from 'ramda';

export const getCreditCardSelected = path([
  'checkout',
  'creditCard',
  'selected',
]);

export const getCreditCardValidated = path([
  'checkout',
  'creditCard',
  'validated',
]);

export const getCreditCardAuthorized = path([
  'checkout',
  'creditCard',
  'authorized',
]);

export const getCreditCardAuthorization = path([
  'checkout',
  'creditCard',
  'authorization',
]);

export const getCreditCardErrorMessage = path([
  'checkout',
  'creditCard',
  'errorMessage',
]);

export const getCreditCardLastFour = path([
  'checkout',
  'creditCard',
  'lastFour',
]);

export const getShippingValidated = path(['checkout', 'shipping', 'validated']);

export const getCheckoutValidating = path([
  'checkout',
  'checkout',
  'validating',
]);

export const getCheckoutValidated = path(['checkout', 'checkout', 'validated']);
