import { complement, curry, either, equals, isEmpty, isNil, path } from 'ramda';

export const forceArray = o => {
  if (o === undefined || o === null) {
    return [];
  }
  if (Array.isArray(o)) {
    return o;
  }
  return [o];
};

export const zip = (array1, array2) =>
  array1.reduce((acc, curr) => {
    acc[array1.indexOf(curr)] = [curr, array2[array1.indexOf(curr)]];
    return acc;
  }, []);

export const symmetricDifference = (array1, array2) => {
  const uniqueArray1 = (array1 || []).filter(item => !array2.includes(item));
  const uniqueArray2 = (array2 || []).filter(item => !array1.includes(item));
  return [...new Set([...uniqueArray1, ...uniqueArray2])];
};

export const mapObjIndexed = (func, object) => {
  const entries = Object.entries(object);
  const alteredEntries = entries.map(([key, value]) => [
    key,
    func(value, key, object),
  ]);
  return Object.fromEntries(alteredEntries);
};

export const props = (keys, object) =>
  keys
    .map(key => Object.entries(object).find(entry => entry[0] === key))
    .map(value => (Array.isArray(value) ? value[1] : value));

export const flat = array => array.reduce((acc, val) => acc.concat(val), []);

export const mergeAll = objects => {
  const entries = flat(objects.map(object => Object.entries(object)));
  return Object.fromEntries(entries);
};

export const isMissing = either(isEmpty, isNil);
export const isPresent = complement(isMissing);

// lifted from
// https://github.com/ramda/ramda/wiki/Cookbook#rename-keys-of-an-object
export const renameBy = curry((fn, obj) =>
  Object.entries(obj || {}).reduce(
    (acc, [key, val]) => ({ ...acc, [fn(key)]: val }),
    {},
  ),
);

export const isPresentAndChanged = (propertyOrPath, nextProps, prevProps) => {
  const getProperty = path(forceArray(propertyOrPath));
  return (
    isPresent(getProperty(nextProps)) &&
    !equals(getProperty(nextProps), getProperty(prevProps))
  );
};
