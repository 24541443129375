import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { chooseLocale } from 'ducks/locale';
import { getLocale, isIntlSupported } from 'selectors/locale';
import LocalePicker from './locale-picker';

const LocaleContainer = ({ actions, intlSupported, inChrome, locale }) => (
  <LocalePicker
    inChrome={inChrome}
    locale={locale}
    onChooseLocale={actions.chooseLocale}
    intlSupported={intlSupported}
  />
);

LocaleContainer.propTypes = {
  inChrome: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    chooseLocale: PropTypes.func.isRequired,
  }).isRequired,
  intlSupported: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: getLocale(state),
    intlSupported: isIntlSupported(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ chooseLocale }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleContainer);
