import { handle } from 'redux-pack';
import {
  flatten,
  map,
  merge,
  mergeDeepRight,
  mergeDeepLeft,
  pathOr,
  pick,
  pipe,
  prop,
  props,
  uniq,
} from 'ramda';
import gql from 'graphql-tag';
import {
  requestProducts,
  requestInstantSearch,
  mungeProduct,
  requestByStyle,
  requestGenericArticleAttributes,
  requestGenericArticles,
} from 'lib/algolia';
import { isPresent } from 'lib/data-manipulation';
import { extractProductIds } from 'lib/product-ids';
import { grafikiShoppingModes } from 'lib/user';
import getFacets from './get-facets';
import loadCategories from './load-categories';
import loadGenders from './load-genders';
import setViewMode from './set-view-mode';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from '../authentication';
import products, { initialProducts } from './products';

export const GET_CONFIG = 'search/GET_CONFIG';
export const GET_ACTIVE_CONFIG = 'search/GET_ACTIVE_CONFIG';
export const GET_PRODUCTS = 'search/GET_PRODUCTS';
export const GET_DETAILS = 'search/GET_DETAILS';
export const GET_GENERIC_ARTICLES = 'search/GET_GENERIC_ARTICLES';
export const GET_GENERIC_ARTICLES_BY_ID = 'search/GET_GENERIC_ARTICLES_BY_ID';
export const INSTANT_SEARCH = 'search/INSTANT_SEARCH';
export const GET_GENERIC_ARTICLE_DETAILS = 'search/GET_GENERIC_ARTICLE_DETAILS';
export const GET_STYLE_DETAILS = 'search/GET_STYLE_DETAILS';
export const GET_STYLES_DETAILS = 'search/GET_STYLES_DETAILS';

const SEARCH_TOKENS_QUERY = gql`
  query getSearchTokens($accountId: ID!, $shoppingMode: ShoppingMode!) {
    node(id: $accountId) {
      id
      ... on Account {
        searchTokens(shoppingMode: $shoppingMode) {
          appId
          materials {
            indexName
            key
          }
          facets {
            indexName
            key
          }
        }
      }
    }
  }
`;

export const getSearchConfig = (accountId, shoppingMode, isActive = false) => ({
  type: isActive ? GET_ACTIVE_CONFIG : GET_CONFIG,
  promise: import('index').then(({ graphqlClient }) =>
    graphqlClient.query({
      query: SEARCH_TOKENS_QUERY,
      variables: {
        accountId,
        shoppingMode: grafikiShoppingModes[shoppingMode],
      },
    }),
  ),
  meta: {
    accountId,
    shoppingMode: grafikiShoppingModes[shoppingMode],
  },
});

export const getActiveSearchConfig = (accountId, shoppingMode) =>
  getSearchConfig(accountId, shoppingMode, true);

const ITEMS_PER_PAGE = { list: 12, grid: 50 };

export const getProducts = options => {
  const {
    searchTerm,
    page,
    filters,
    viewMode,
    inventorySegmentCodeOrPlantId,
  } = options;
  const [productIds, query] = extractProductIds(searchTerm);

  return {
    type: GET_PRODUCTS,
    meta: {
      productIds,
      algoliaRequest: requestProducts(
        query,
        productIds,
        page,
        filters,
        ITEMS_PER_PAGE[viewMode],
        inventorySegmentCodeOrPlantId,
      ),
      viewMode,
    },
  };
};

export const getDetails = (styleCode, searchConfig) => ({
  type: GET_DETAILS,
  meta: {
    algoliaRequest: requestByStyle(styleCode),
    searchConfig,
    styleCode,
  },
});

export const getStylesDetails = (accountId, shoppingMode, styleIds) => ({
  type: GET_STYLES_DETAILS,
  meta: {
    accountId,
    styleIds,
    uaApiRequest: {
      url: `/api/accounts/${accountId}/products/query`,
      method: 'POST',
      data: {
        shoppingMode,
        styleIDs: styleIds,
      },
    },
  },
});

export const getGenericArticles = (styleIds, searchConfig) => ({
  type: GET_GENERIC_ARTICLES,
  meta: {
    algoliaRequest: requestGenericArticleAttributes(styleIds, [
      'materialId',
      'chipURL',
      'color',
      'colorHex',
      'sizes',
      'assetURL',
      'styleId',
      'isCustomizable',
      'requiresCustomization',
      'gender',
    ]),
    searchConfig,
    styleIds,
  },
});

export const instantSearch = searchTerm => ({
  type: INSTANT_SEARCH,
  meta: {
    algoliaRequest: requestInstantSearch(searchTerm),
    searchTerm,
  },
});

export const getGenericArticlesById = genericArticleIds => ({
  type: GET_GENERIC_ARTICLES_BY_ID,
  meta: {
    algoliaRequest: requestGenericArticles(genericArticleIds),
  },
});

export const getGenericArticleDetails = (
  styleId,
  genericArticleId,
  accountId,
) => ({
  type: GET_GENERIC_ARTICLE_DETAILS,
  meta: {
    styleId,
    genericArticleId,
    accountId,
    uaApiRequest: {
      url: `/api/accounts/${accountId}/products/materials/${genericArticleId}`,
      method: 'get',
    },
  },
});

export const getStyleDetails = (accountId, shoppingMode, styleId) => ({
  type: GET_STYLE_DETAILS,
  meta: {
    styleId,
    accountId,
    uaApiRequest: {
      url: `/api/accounts/${accountId}/products/${styleId}`,
      method: 'get',
      params: { shoppingMode },
    },
  },
});

const initialState = {
  instant: { waiting: false, error: null, results: [], revealed: false },
  genericArticleDetails: { waiting: false, error: null },
  products: initialProducts,
  viewMode: setViewMode.initState,
  configs: {},
  styleDetails: { waiting: false, error: null },
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case getFacets.type:
      return getFacets.reducer(state, action);

    case loadGenders.type:
      return loadGenders.reducer(state, action);

    case loadCategories.type:
      return loadCategories.reducer(state, action);

    case setViewMode.type:
      return setViewMode.reducer(state, action);

    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
      return merge(initialState, pick(['configs', 'viewMode'])(state));

    case GET_ACTIVE_CONFIG:
    case GET_CONFIG:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          config: null,
          waitingForConfig: true,
          configError: null,
        }),
        finish: prevState => ({
          ...prevState,
          waitingForConfig: false,
        }),
        failure: prevState => ({
          ...prevState,
          configError: payload.message,
        }),
        success: prevState => {
          const {
            meta: { accountId, shoppingMode },
          } = action;
          const {
            data: {
              node: { searchTokens },
            },
          } = payload;
          const { appId, materials, facets } = searchTokens;

          return {
            ...prevState,
            configs: {
              ...prevState.configs,
              [accountId]: {
                ...prevState.configs[accountId],
                [shoppingMode]: {
                  appId,
                  indices: {
                    products: {
                      name: materials.indexName,
                      key: materials.key,
                    },
                    slugs: {
                      name: facets.indexName,
                      key: facets.key,
                    },
                  },
                },
              },
            },
          };
        },
      });

    case GET_PRODUCTS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          waitingForProducts: true,
          productsError: null,
          pageCount: null,
          totalCount: null,
        }),
        finish: prevState => ({
          ...prevState,
          waitingForProducts: false,
        }),
        failure: prevState => ({
          ...prevState,
          productsError: payload.message,
        }),
        success: prevState => ({
          ...prevState,
          products: products(prevState.products, action),
          currentGrid: payload.results[0].hits.map(prop('styleId')),
          pageCount: payload.results[0].nbPages,
          totalCount: payload.results[0].nbHits,
          productIds: isPresent(action.meta.productIds)
            ? {
                sought: action.meta.productIds,
                found: pipe(
                  pathOr([], ['results', 1, 'hits']),
                  map(props(['styleId', 'materialId', '_tags'])),
                  flatten,
                  uniq,
                )(payload).filter(item => item !== 'customizable'),
              }
            : null,
        }),
      });

    case GET_DETAILS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          waitingForDetails: true,
          detailsError: null,
        }),
        finish: prevState => ({
          ...prevState,
          waitingForDetails: false,
        }),
        failure: prevState => ({
          ...prevState,
          detailsError: payload.message,
        }),
        success: prevState => ({
          ...prevState,
          products: products(prevState.products, action),
        }),
      });

    case GET_GENERIC_ARTICLES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          waitingForGenericArticles: true,
          genericArticlesError: null,
        }),
        finish: prevState => ({
          ...prevState,
          waitingForGenericArticles: false,
        }),
        failure: prevState => ({
          ...prevState,
          genericArticlesError: payload.message,
        }),
        success: prevState => ({
          ...prevState,
          products: products(prevState.products, action),
        }),
      });

    case GET_GENERIC_ARTICLES_BY_ID:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          waitingForGenericArticles: true,
          genericArticlesError: undefined,
        }),
        finish: prevState => ({
          ...prevState,
          waitingForGenericArticles: undefined,
        }),
        failure: prevState => ({
          ...prevState,
          genericArticlesError: payload.message,
        }),
        success: prevState => ({
          ...prevState,
          products: products(prevState.products, action),
        }),
      });

    case INSTANT_SEARCH:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          instant: {
            ...prevState.instant,
            error: null,
            term: action.meta.searchTerm,
            waiting: true,
          },
        }),
        finish: prevState => ({
          ...prevState,
          instant: { ...prevState.instant, waiting: false },
        }),
        failure: prevState => ({
          ...prevState,
          instant: { ...prevState.instant, error: payload.message },
        }),
        success: prevState => {
          const newProducts = payload.hits.map(mungeProduct);
          return {
            ...prevState,
            products: products(prevState.products, action),
            instant: {
              ...prevState.instant,
              results: newProducts.map(p => p.styleCode),
            },
          };
        },
      });

    case GET_GENERIC_ARTICLE_DETAILS:
      return handle(state, action, {
        start: prevState =>
          mergeDeepRight(prevState, {
            genericArticleDetails: { error: null, waiting: true },
          }),
        finish: prevState =>
          mergeDeepRight(prevState, {
            genericArticleDetails: { waiting: false },
          }),
        failure: prevState =>
          mergeDeepRight(prevState, {
            genericArticleDetails: { error: payload.message },
          }),
        success: prevState =>
          mergeDeepRight(prevState, {
            products: products(prevState.products, action),
          }),
      });

    case GET_STYLE_DETAILS:
    case GET_STYLES_DETAILS:
      return handle(state, action, {
        start: mergeDeepLeft({ styleDetails: { error: null, waiting: true } }),
        finish: mergeDeepLeft({ styleDetails: { waiting: false } }),
        failure: prevState =>
          mergeDeepLeft({ styleDetails: { error: payload.message } })(
            prevState,
          ),
        success: prevState =>
          mergeDeepLeft({ products: products(state.products, action) })(
            prevState,
          ),
      });

    default:
      return state;
  }
};

export default reducer;
