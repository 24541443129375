import { connect } from 'react-redux';
import { pick, pipe, prop } from 'ramda';

const mapStateToProps = desriredMarketing =>
  pipe(prop('marketing'), pick(desriredMarketing));

const withMarketing = desriredMarketing =>
  connect(mapStateToProps(desriredMarketing));

export default withMarketing;
