import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

const FORGOT_PASSWORD_GQL = gql`
  mutation forgotPassword($input: forgotPasswordInput!) {
    forgotPassword(input: $input) {
      clientMutationId
    }
  }
`;

const ForgotPasswordMutation = ({ children }) => (
  <Mutation mutation={FORGOT_PASSWORD_GQL}>
    {(mutate, { called, loading, error }) => {
      const forgotPassword = email =>
        mutate({
          variables: {
            input: {
              email,
            },
          },
        });
      return children(forgotPassword, { called, loading, error });
    }}
  </Mutation>
);

ForgotPasswordMutation.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ForgotPasswordMutation;
