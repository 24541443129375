import { handle } from 'redux-pack';
import { filter, pluck, pipe, compose, flatten } from 'ramda';
import { GET_GENERIC_ARTICLES } from '../search';
import { SUBMIT_ORDER } from '../checkout';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from '../authentication';

export const FETCH_CART = 'cart/FETCH_CART';
export const SET_ACTIVE_CART = 'cart/SET_ACTIVE_CART';
export const FETCH_CARTS = 'cart/FETCH_CARTS';

const initialState = {
  updating: false,
  updatedCartId: null,
  errorUpdating: null,
  creating: false,
  errorCreating: null,
  loading: false,
  errorLoading: null,
  loaded: true,
  updatingSettings: false,
  errorUpdatingSettings: null,
  updatingOwner: false,
  errorUpdatingOwner: null,
};

export default function activeCart(state = initialState, action) {
  const { type, payload, meta } = action;
  switch (type) {
    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
      return initialState;

    case SET_ACTIVE_CART:
      return { ...state, id: meta.id };

    case FETCH_CARTS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, ...initialState }),
        success: prevState => {
          const currentCartId = prevState.id;
          const carts = compose(
            flatten,
            pluck('node'),
          )(payload.data.node.carts.edges);
          const ownedCartIds = pipe(
            filter(cart => cart.owner.id === meta.userId),
            pluck('id'),
          )(carts);
          return {
            ...prevState,
            id: ownedCartIds.some(id => id === currentCartId)
              ? currentCartId
              : ownedCartIds[0],
          };
        },
      });

    case GET_GENERIC_ARTICLES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          errorUpdating: null,
          updatedCartId: null,
        }),
      });

    case FETCH_CART:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          errorLoading: null,
          loaded: false,
        }),
        failure: prevState => ({ ...prevState, errorLoading: payload.message }),
        success: prevState => ({ ...prevState, loaded: true }),
        finish: prevState => ({ ...prevState, loading: false }),
      });

    case SUBMIT_ORDER:
      return handle(state, action, { success: () => initialState });

    default:
      return state;
  }
}
