import { indexBy, prop } from 'ramda';
import { AllotmentAllocations } from '__generated__/types';

// This is... a questionable type that should absolutely go away.
interface AccountForMunging {
  id: string;
  name: string;
  accountNumber: string;
  organizationID?: string;
  organizationName?: string;
  salesOrg: string;
  customerGroupCode: string;
  salesGroupCode: string;
  allotmentAllocations?: AllotmentAllocations | null;
  countryCode: string;
  invoicePortalUrl?: string;
  availableShoppingModes: Array<AvailableShoppingMode>;
  inventorySegmentCode?: string;
}

export enum b2bShoppingModes {
  atonce = 'atonce',
  booking = 'booking',
}
export enum ShoppingMode {
  AtOnce = 'AtOnce',
  Booking = 'Booking'
}
export const grafikiShoppingModes = {
  atonce: ShoppingMode.AtOnce,
  AtOnce: ShoppingMode.AtOnce,
  booking: ShoppingMode.Booking,
  Booking: ShoppingMode.Booking,
};

interface AvailableShoppingMode {
  id: b2bShoppingModes;
  firstShipOn?: string;
  lastShipOn?: string;
}

export const mungeAccount = ({
  id,
  name,
  accountNumber: number,
  organizationID,
  organizationName,
  salesOrg: salesOrgCode,
  customerGroupCode,
  salesGroupCode,
  countryCode,
  invoicePortalUrl,
  inventorySegmentCode,
  allotmentAllocations,
}: AccountForMunging) => ({
  id,
  name,
  number,
  salesOrgCode,
  customerGroupCode,
  countryCode,
  salesGroupCode,
  invoicePortalUrl,
  allotmentAllocations,
  inventorySegmentCode,
  organization: organizationID
    ? {
        id: organizationID,
        name: organizationName,
      }
    : null,
});

export const mungeAccountDetails = (data: AccountForMunging) => ({
  ...mungeAccount(data),
  availableShoppingModes: indexBy(prop('id'), data.availableShoppingModes),
});

export const getAvailableShoppingModes = ({
  availableShoppingModes,
}: AccountForMunging) => availableShoppingModes.map(m => m.id);

// If you are looking at this all like o_O I understand.
// What we have here is a way to handle the fact that shoppingMode
// across the application can exist as: `AtOnce|atonce|Booking|booking`
// this ensures that we return `AtOnce|atonce` depending on what data was
// given to us. It is on the developer to use the proper form at the respective
// call-site to avoid mis-matched enums. This should all go away once we have fully
// moved to a single API with consistent values for shopping mode enums.
const DEFAULT_SHOPPING_MODE = b2bShoppingModes.atonce;
export const getDefaultShoppingMode = (
  availableShoppingModes: Array<b2bShoppingModes>,
) => {
  const [defaultMode] = availableShoppingModes.filter(
    mode => mode.toLowerCase() === DEFAULT_SHOPPING_MODE,
  );
  if (!defaultMode) {
    // Couldn't find it, fail "gracefully"
    return availableShoppingModes[0];
  }
  return defaultMode;
};

export const isShoppingModeAvailable = (
  { availableShoppingModes }: AccountForMunging,
  shoppingMode: string,
) => availableShoppingModes.some(m => m.id === shoppingMode);
