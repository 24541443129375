import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { mapObjIndexed } from 'lib/data-manipulation';
import { omit, pick } from 'ramda';

const formatMessageObj = (formatMessage, val) => {
  if (typeof val === 'string') {
    return formatMessage({ id: val });
  }
  if (Array.isArray(val)) {
    return val.map(v => formatMessageObj(formatMessage, v));
  }
  if (typeof val === 'object') {
    const { id, defaultMessage, description, ...rest } = val;
    return formatMessage({ id, defaultMessage, description }, rest);
  }
  return val;
};

export const translateProps = (...propNames) => ({ formatMessage }, props) =>
  mapObjIndexed(
    val => formatMessageObj(formatMessage, val),
    pick(propNames, props),
  );

export default mapIntlToProps => type => {
  const wrapper = props => {
    const { intl, children } = props;
    const newProps = {
      ...props,
      ...mapIntlToProps(intl, omit('intl', props)),
    };

    return React.createElement(type, omit('intl', newProps), children);
  };

  wrapper.displayName = `ConnectIntl(${type.name})`;

  wrapper.propTypes = {
    children: PropTypes.node,
  };

  wrapper.defaultProps = {
    children: undefined,
  };

  return injectIntl(wrapper);
};
