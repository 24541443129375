import { handle } from 'redux-pack';
import { mergeDeepRight, pathOr } from 'ramda';
import { requestGenders } from 'lib/algolia';

const type = 'search/LOAD_GENDERS';

const creator = () => ({
  type,
  meta: {
    algoliaRequest: requestGenders,
  },
});

export const start = prevState =>
  mergeDeepRight(prevState, {
    genders: { all: null, customizable: null, waiting: true, error: null },
  });

export const finish = prevState =>
  mergeDeepRight(prevState, {
    genders: { waiting: false },
  });

export const failure = (prevState, action) =>
  mergeDeepRight(prevState, {
    genders: { error: action.payload.message },
  });

export const success = (prevState, action) =>
  mergeDeepRight(prevState, {
    genders: {
      all: pathOr([], ['payload', 'results', 0, 'facets', 'gender'], action),
      customizable: pathOr(
        [],
        ['payload', 'results', 1, 'facets', 'gender'],
        action,
      ),
    },
  });

const reducer = (state, action) =>
  handle(state, action, { start, finish, failure, success });

export default { type, creator, reducer };
