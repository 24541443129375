const type = 'organizationFilter/SELECT_ORGANIZATION';

const creator = (organization, key) => ({ type, organization, key });

const reducer = (state, action) => ({
  ...state,
  selectedOrganizations: {
    ...state.selectedOrganizations,
    [action.key]: action.organization,
  },
  isError: false,
  isLoading: false,
});

export default { type, creator, reducer };
