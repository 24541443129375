import { PROCESSING_STEP } from './steps';

export const UploadedFiles = {};

const type = 'new-cart-wizard/uploads/UPLOAD_FILE';

const creator = file => ({ type, file });

const reducer = (state, { file }) => {
  const filename = file.name;
  UploadedFiles[filename] = file;
  return {
    ...state,
    stepIndex: PROCESSING_STEP,
    uploading: true,
    requestCreate: true,
    filename,
  };
};

export default { type, creator, reducer };
