import { localeToUse } from 'selectors/locale';
import { chooseLocale, CHOOSE_LOCALE } from 'ducks/locale';
import { saveLocaleToServer, SAVE_LOCALE_TO_SERVER } from 'ducks/user';
import { packSuccess } from 'lib/redux-pack';
import userSignedIn from 'selectors/user-signed-in';

// if the chosen locale is changed, we need to fetch
// the translations and setup the internationalization library
// for the new locale
export default store => next => action => {
  const prevLocale = localeToUse(store.getState());
  const result = next(action);
  const nextState = store.getState();
  const userId = nextState.user.profile.id;
  const nextLocale = localeToUse(nextState);
  if (action.type !== CHOOSE_LOCALE && nextLocale !== prevLocale) {
    store.dispatch(chooseLocale(nextLocale), !nextState.intlSupported);
  }
  if (
    userSignedIn(nextState) &&
    packSuccess(action, CHOOSE_LOCALE) &&
    nextLocale !== prevLocale
  ) {
    store.dispatch(saveLocaleToServer(nextLocale, userId));
  }
  if (packSuccess(action, SAVE_LOCALE_TO_SERVER)) {
    window.location.reload();
  }
  return result;
};
