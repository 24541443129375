import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import LoginForm from 'components/login-form';
import { ConnectedPage } from './home/page';
import ForgotPassword from './forgot-password';
import ResetPassword from './reset-password';
import CreateAccountForm from './create-account/create-account-form';

const PreLogin = () => (
  <Switch>
    <ConnectedPage
      path="/change_password/:resetToken"
      showHeader={false}
      showFooter={false}
      showNotices={false}
      component={ResetPassword}
    />
    <ConnectedPage
      path="/forgot-password"
      showHeader={false}
      showFooter={false}
      showNotices={false}
      component={ForgotPassword}
    />
    <Redirect from="/designs" to="/signup" />
    <ConnectedPage
      path="/signup"
      showHeader={false}
      showFooter={false}
      showNotices={false}
      showChrome={false}
      component={CreateAccountForm}
    />
    <ConnectedPage
      path="/"
      showHeader={false}
      showFooter={false}
      showNotices={false}
      showChrome={false}
      component={LoginForm}
    />
  </Switch>
);

export default PreLogin;
