import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'lib/theme';

export const BaseCross = styled.div`
  width: 26px;
  height: 26px;

  &::before,
  &::after {
    position: absolute;
    left: 11px;
    content: '';
    height: 26px;
    width: 3px;
    background-color: ${({ color }) => color || colors.black};
  }

  &::before {
    transform: ${({ rotated }) => (rotated ? 'rotate(45deg)' : 0)};
  }

  &::after {
    transform: ${({ rotated }) =>
      rotated ? 'rotate(-45deg)' : 'rotate(90deg)'};
  }
`;

const Cross = props => <BaseCross {...props} />;

Cross.propTypes = {
  rotated: PropTypes.bool,
};

Cross.defaultProps = {
  rotated: false,
};

export default Cross;
