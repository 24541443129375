import { combineReducers } from 'redux';
import { mergeDeepRight } from 'ramda';
import { combineTree } from 'lib/treeify';
import accountFilter from './account-filter';
import authentication, { LOGOUT } from './authentication';
import browserLocale from './browser-locale';
import carts from './carts';
import checkout from './checkout';
import delayedActions from './delayed-actions';
import dismissedCookieConsent from './dismissed-cookie-consent';
import inventory from './inventory';
import locale from './locale';
import newCartWizard from './new-cart-wizard';
import preferredLocale from './preferred-locale';
import pricing from './pricing';
import screen from './screen';
import screenSize from './screen-size';
import search from './search';
import siteMeta from './site-meta';
import slugs from './slugs';
import technologies from './technologies';
import user from './user';
import marketing from './marketing';
import sets from './sets';
import sizeGuides from './size-guides';
import fitGuides from './fit-guides';
import sizeSelections from './size-selections';
import staticContent from './static-content';
import organizationFilter from './organization-filter/index';
import loginImage from './login-image';

const ducksReducer = combineReducers({
  accountFilter,
  authentication,
  browserLocale,
  carts,
  checkout,
  delayedActions,
  dismissedCookieConsent,
  fitGuides,
  inventory,
  locale,
  marketing,
  newCartWizard,
  preferredLocale,
  pricing,
  screen,
  screenSize,
  search,
  sets,
  sizeGuides,
  siteMeta,
  sizeSelections,
  slugs,
  staticContent,
  technologies,
  user,
  organizationFilter,
  loginImage,
});

const STATES_AFTER_LOGOUT = [
  ['authentication'],
  ['dismissedCookieConsent'],
  ['locale'],
  ['loginImage'],
  ['marketing', 'seen'],
  ['preferredLocale'],
  ['screenSize'],
  ['search', 'viewMode'],
  ['siteMeta'],
];

export const initState = ducksReducer(undefined, { type: '@@INIT' });

const rootReducer = (state, action) => {
  const ducksReduction = ducksReducer(state, action);
  if (action.type === LOGOUT) {
    return mergeDeepRight(
      initState,
      combineTree(STATES_AFTER_LOGOUT, ducksReduction),
    );
  }
  return ducksReduction;
};

export default rootReducer;
