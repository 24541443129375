import { path } from 'ramda';
import { getActiveSearchConfig } from 'selectors/account';
import { toAlgoliaPromise, multiQueryPromise } from 'lib/algolia';
import { isReduxPackAction } from 'lib/redux-pack';
import { delayAction } from 'ducks/delayed-actions';
import { GET_ACTIVE_CONFIG } from 'ducks/search';
import {
  isAccessExpired,
  sessionNeedsRefresh,
  refreshSession,
} from 'lib/session';

export default store => next => action => {
  const algoliaRequest = path(['meta', 'algoliaRequest'], action);
  if (!algoliaRequest || isReduxPackAction(action)) {
    return next(action);
  }

  const state = store.getState();

  if (sessionNeedsRefresh(state)) {
    refreshSession(store);
  }

  const searchConfig =
    path(['meta', 'searchConfig'], action) || getActiveSearchConfig(state);

  if (isAccessExpired(state) || !searchConfig) {
    return store.dispatch(delayAction(action, GET_ACTIVE_CONFIG));
  }

  const promiseBuilder = algoliaRequest.queries
    ? multiQueryPromise
    : toAlgoliaPromise;

  const actionWithAlgoliaConfig = {
    ...action,
    promise: promiseBuilder(searchConfig, algoliaRequest),
  };
  return store.dispatch(actionWithAlgoliaConfig);
};
