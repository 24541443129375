import { compose, map, indexBy, prop, filter } from 'ramda';
import { tryParseDate } from './dates';

const mungeFutureItem = ({ effectiveDate, qty: quantity }) => ({
  effectiveDate: tryParseDate(effectiveDate),
  quantity,
  display: quantity,
});

const mungeInventoryItem = ({ qty: quantity, future }) => ({
  quantity,
  futureQuantities: future.map(mungeFutureItem),
  display: quantity,
});

const mungeInventory = compose(
  map(mungeInventoryItem),
  indexBy(prop('sku')),
  filter(({ sku }) => !!sku),
);

export const showInventoryForMode = mode => mode === 'atonce';

export default mungeInventory;
