const type = 'new-cart-wizard/SUBMIT_DETAILS';

const creator = () => ({ type });

const reducer = state => ({
  ...state,
  requestUpdate: true,
});

export default { type, creator, reducer };
