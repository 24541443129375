import { handle } from 'redux-pack';
import { requestFilterFacetValues, wrangleFacets } from 'lib/algolia';

const type = 'search/GET_FILTER_FACET_VALUES';

const creator = ({
  searchTerm,
  filters,
  excludedFilter,
  inventorySegmentCodeOrPlantId,
}) => ({
  type,
  meta: {
    algoliaRequest: requestFilterFacetValues({
      searchTerm,
      filters,
      excludedFilter,
      inventorySegmentCodeOrPlantId,
    }),
    excludedFilter,
  },
});

export const start = prevState => ({
  ...prevState,
  waitingForFacetValues: true,
  facetValuesError: null,
});

export const finish = prevState => ({
  ...prevState,
  waitingForFacetValues: false,
});

export const failure = (prevState, action) => ({
  ...prevState,
  facetValuesError: action.payload.message,
});

export const success = (prevState, action) => ({
  ...prevState,
  filterFacetValues: wrangleFacets(
    action.payload.facets,
    action.meta.excludedFilter,
  ),
});

const reducer = (state, action) =>
  handle(state, action, { start, finish, failure, success });

export default { type, creator, reducer };
