import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScreenElement } from './ui';

class Screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.enabled) {
      this.setState({ visible: true });
    } else {
      setTimeout(() => {
        this.onTransitionEnd();
      }, 300);
    }
  }

  onTransitionEnd = () => {
    const { enabled } = this.props;
    if (!enabled) {
      this.setState({ visible: false });
    }
  };

  render() {
    const { enabled } = this.props;
    const { visible } = this.state;
    return <ScreenElement enabled={enabled} visible={visible} />;
  }
}

Screen.propTypes = {
  enabled: PropTypes.bool,
};

Screen.defaultProps = {
  enabled: false,
};

const mapStateToProps = state => ({
  enabled: state.screen.enabled,
});

export default connect(mapStateToProps)(Screen);
