import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { resolvers, defaults, typeDefs } from 'resolvers';
import { getLocale } from 'selectors/locale';
import { getAccessToken } from 'selectors/account';
import createApolloLink from 'lib/ua-apollo-link';
import createTokenRefreshLink from 'lib/ua-refresh-token-link';
// Introspection Query Result
import introspectionQueryResultData from 'fragmentTypes.json';

const createRequestLink = interceptFn =>
  new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => interceptFn(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

const createRequestIntercept = store => operation => {
  const state = store.getState();
  operation.setContext({
    headers: {
      Authorization: `Bearer ${getAccessToken(state)}`,
      'Accept-Language': getLocale(state),
    },
  });
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const cache = new InMemoryCache({ fragmentMatcher });

const createApolloClient = store =>
  new ApolloClient({
    link: ApolloLink.from([
      createTokenRefreshLink(store),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path: _path }) =>
            // eslint-disable-next-line
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${_path}`,
            ),
          );
        // eslint-disable-next-line
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      createRequestLink(createRequestIntercept(store)),
      createApolloLink(),
    ]),
    cache,
    resolvers,
    typeDefs,
  });

cache.writeData({
  data: defaults,
});

export default createApolloClient;
