import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Row } from 'components/forms/fieldset';
import InputIntl from 'components/forms/input-intl';
import * as t from 'components/typography';
import { Button } from 'components/ui/button';
import { TextLink } from 'components/ui/links';
import { login } from 'ducks/authentication';
import createAccountSplash from 'lib/create-account-splash.jpg';
import logo from 'lib/logo.svg';
import { breakpoint, colors, fonts, fontSizes, spacing } from 'lib/theme';
import FormContainer from './form-container';
import { passwordErrorSwitch } from '../../lib/user';

const USER_SIGN_UP_MUTATION = gql`
  mutation userSignUp($input: userSignUpInput!) {
    userSignUp(input: $input) {
      user {
        id
      }
      error {
        id
      }
    }
  }
`;

const getMutationError = pathOr(null, ['userSignUp', 'error']);

const FormHeader = styled.div`
  width: 100%;
  margin-bottom: ${spacing.xxxl};
  text-align: center;
`;

const Logo = styled.img`
  width: 84px;
  max-width: 84px;
  display: inline-block;
`;

const MiniForm = styled.form`
  background: ${colors.white};
  padding: ${spacing.xl};
  max-width: 500px;
  width: 100%;

  @media (min-width: ${breakpoint.tablet}) {
    padding: ${spacing.xxxl};
  }
`;

const NameInputsRow = styled(Row)`
  margin-bottom: ${spacing.xl};

  @media (min-width: ${breakpoint.tablet}) {
    display: flex;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  > div {
    flex: 1;

    &:first-child {
      @media (max-width: ${breakpoint.bigPhone}) {
        margin-bottom: ${spacing.xl};
      }
    }
  }

  > div + div {
    @media (min-width: ${breakpoint.tablet}) {
      margin-left: ${spacing.xl};
    }
  }
`;

const USOnly = styled.div`
  font: ${fonts.regular};
  font-size: ${fontSizes.tiny};
  margin-bottom: ${spacing.xxl};
  margin-top: ${spacing.xxl};
  text-align: center;
  white-space: pre-line;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const HaveAccountContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font: ${fonts.regular};
  font-size: ${fontSizes.large};
  margin-top: ${spacing.xl};
`;

const LoginLink = styled(TextLink)`
  font: ${fonts.regular};
  margin-top: ${spacing.s};
  text-decoration: underline;
`;

export class CreateAccountForm extends Component {
  // Since we're storing sensitive info, do not move form state into the redux store.
  state = { email: '', firstName: '', lastName: '', password: '' };

  handleInputChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = createAccount => e => {
    e.preventDefault();
    createAccount().then(response => {
      const { user, error } = pathOr({}, ['data', 'userSignUp'], response);
      const { actions } = this.props;
      const { email, password } = this.state;
      if (user && !error) {
        actions.login(email, password);
      }
    });
  };

  hasError = (networkError, mutationError) => networkError || mutationError;

  render() {
    const { loginError, loginWaiting } = this.props;
    const { email, firstName, lastName, password } = this.state;
    const variables = {
      input: {
        user: { email, firstName, lastName, locale: 'en-US', password },
      },
    };
    return (
      <FormContainer splashImageSrc={createAccountSplash}>
        <FormHeader>
          <Logo src={logo} alt="Under Armour" />
        </FormHeader>
        <Mutation mutation={USER_SIGN_UP_MUTATION} variables={variables}>
          {(createAccount, { loading, data, error: networkError }) => {
            const mutationError = getMutationError(data);
            return (
              <MiniForm onSubmit={this.handleSubmit(createAccount)}>
                <NameInputsRow>
                  <InputIntl
                    id="createAccountFirstName"
                    label="create-account.first-name-label"
                    name="firstName"
                    required={true}
                    type="text"
                    value={firstName}
                    onChange={this.handleInputChange}
                  />
                  <InputIntl
                    id="createAccountLastName"
                    label="create-account.last-name-label"
                    name="lastName"
                    required={true}
                    type="text"
                    value={lastName}
                    onChange={this.handleInputChange}
                  />
                </NameInputsRow>
                <Row>
                  <InputIntl
                    id="createAccountEmail"
                    label="create-account.email-label"
                    name="email"
                    required={true}
                    type="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </Row>
                <Row>
                  <InputIntl
                    id="createAccountPassword"
                    label="create-account.password-label"
                    name="password"
                    required={true}
                    type="password"
                    value={password}
                    onChange={this.handleInputChange}
                  />
                </Row>
                <USOnly>
                  <FormattedMessage id="create-account.us-only" />
                </USOnly>
                <SubmitButton size="normal" disabled={loading || loginWaiting}>
                  <FormattedMessage
                    id={`create-account.${
                      loading || loginWaiting ? 'submitting' : 'submit'
                    }-button`}
                  />
                </SubmitButton>
                {loginError && <FormattedMessage id="create-account.failure" />}
                {this.hasError(networkError, mutationError) && (
                  <div>
                    <t.ErrorText large={true}>
                      {passwordErrorSwitch({
                        errorCode: mutationError.id,
                        networkError,
                      })}
                    </t.ErrorText>
                  </div>
                )}
                <HaveAccountContainer>
                  <FormattedMessage id="create-account.have-account" />
                  <LoginLink to="/">
                    <FormattedMessage id="create-account.login-link" />
                  </LoginLink>
                </HaveAccountContainer>
              </MiniForm>
            );
          }}
        </Mutation>
      </FormContainer>
    );
  }
}

CreateAccountForm.propTypes = {
  actions: PropTypes.shape({
    login: PropTypes.func.isRequired,
  }).isRequired,
  loginError: PropTypes.string,
  loginWaiting: PropTypes.bool,
};

CreateAccountForm.defaultProps = {
  loginError: null,
  loginWaiting: false,
};

const mapStateToProps = state => ({
  loginError: state.authentication.error,
  loginWaiting: state.authentication.waiting,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ login }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm);
