import { handle } from 'redux-pack';
import { head, compose, prop, propOr } from 'ramda';

export const FETCH_SET = 'sets/FETCH';

export const initialState = { set: {}, error: null, fetching: false };

export const fetchSet = id => ({
  type: FETCH_SET,
  meta: {
    contentfulRequest: {
      content_type: 'set',
      'fields.id': id,
    },
    id,
  },
});

const mungeSet = fields => ({
  id: fields.id,
  name: fields.name,
  productIds: propOr([], 'productIds', fields),
  asSearchTerm: propOr([], 'productIds', fields).join(','),
});

const mungePayload = payload => {
  if (payload.total === 0) {
    return null;
  }

  return compose(mungeSet, prop('fields'), head, prop('items'))(payload);
};

export const reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case FETCH_SET:
      return handle(state, action, {
        start: () => ({ ...initialState, fetching: true }),
        success: prevState => ({
          ...prevState,
          sets: {
            ...prevState.sets,
            [meta.id]: mungePayload(payload),
          },
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.response.data.message,
        }),
        finish: prevState => ({ ...prevState, fetching: false }),
      });

    default:
      return state;
  }
};

export default reducer;
