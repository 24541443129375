import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import connectRoute from 'lib/connect-route';
import { accountShape } from 'lib/types';
import logo from 'lib/logo.svg';
import { withUserContext } from 'lib/use-user-context';
import CartControl from '../cart-control';
import AccountMenu from '../account/menu';
import PublicAccountMenu from './public-account-menu';
import CategoryBar from '../products/category-bar';
import ProductSearch from '../product-search';
import {
  CategoryToggle,
  CategoryToggleContent,
  Header,
  HeaderItems,
  Logo,
  LogoLink,
} from './ui';
import Arrow from '../ui/arrow';

class ShoppingHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCategories: false };
  }

  setCategoryVisibility = visible => {
    this.setState({ showCategories: visible });
  };

  render() {
    const { showCategories } = this.state;
    const { account, searchTerm, urlPath, isPublic } = this.props;

    return (
      <Header>
        <HeaderItems>
          <LogoLink to="/">
            <FormattedMessage id="logo">
              {msg => <Logo src={logo} id="appLogo" alt={msg} />}
            </FormattedMessage>
          </LogoLink>
          <CategoryToggle
            onMouseEnter={() => this.setCategoryVisibility(true)}
            onMouseLeave={() => this.setCategoryVisibility(false)}
          >
            <CategoryToggleContent>
              <FormattedMessage id="categories.control" />
              <Arrow />
            </CategoryToggleContent>
          </CategoryToggle>

          <ProductSearch {...{ searchTerm, urlPath }} />

          {isPublic ? <PublicAccountMenu /> : <AccountMenu account={account} />}
          <CartControl />
        </HeaderItems>
        <CategoryBar forceActive={showCategories} />
      </Header>
    );
  }
}

ShoppingHeader.propTypes = {
  account: accountShape,
  searchTerm: PropTypes.string,
  urlPath: PropTypes.string,
  isPublic: PropTypes.bool.isRequired,
};

ShoppingHeader.defaultProps = {
  account: null,
  searchTerm: '',
  urlPath: '',
};

const mapRouteToProps = ({ location, query }) => ({
  searchTerm: query.q,
  urlPath: location.pathname,
});

function mapStateToProps(state) {
  return {
    account: state.authentication.selectedAccount,
  };
}

export default compose(
  withRouter,
  withUserContext,
  connectRoute(mapRouteToProps),
  connect(mapStateToProps),
)(ShoppingHeader);
