import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { path, pathOr, prop } from 'ramda';
import { Query } from 'react-apollo';
import { logout } from 'ducks/authentication';
import analytics from 'lib/analytics';
import { userSummaryShape } from 'lib/types';
import { useUserContext } from 'lib/use-user-context';
import { isMissing } from 'lib/data-manipulation';
import { maxWidthMedia } from 'styles/mixins';
import { breakpoint, colors, fonts, spacing } from 'lib/theme';
import {
  MANAGE_USERS,
  MANAGE_ORGANIZATIONS,
  MANAGE_SALES_REPS,
  VIEW_ALLOTMENTS,
} from 'lib/permissions';
import {
  getSelectedAccountId,
  getSelectedOrganizationId,
} from 'selectors/account';
import getUserProfile, { CURRENT_USER_QUERY } from 'selectors/user';
import { defaultOrdersUrl, defaultRostersUrl } from 'lib/links';
import {
  MenuBody,
  MenuDropdown,
  MenuList,
  MenuListItem,
  MenuGroup,
  MenuGroupItem,
  StyledLink,
} from './ui/menu';
import Arrow from './ui/arrow';

export const CurrentUser = styled.div`
  color: ${colors.lightGrey};
  font: ${fonts.heavy};
  display: flex;
  height: 35px;
  align-items: center;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    font: ${fonts.gd.regular.subtext};
    height: ${spacing.gd.sm};
  `};
`;

const StyledButton = styled.button`
  color: ${colors.black};
  font: ${fonts.heavy};
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${colors.grey};
  }
`;

const Menu = ({
  actions,
  user,
  refreshToken,
  accountId,
  organizationId,
  invoicePortalUrl,
}) => {
  const { isPublic, hasPermission } = useUserContext();
  if (isMissing(prop('id', user))) {
    return null;
  }

  const { name } = user;

  return (
    <MenuDropdown>
      <CurrentUser>
        {name}
        <Arrow color={colors.lightGrey} />
      </CurrentUser>
      <Query query={CURRENT_USER_QUERY}>
        {({ loading, data, error }) => {
          const isCustomizationAvailable =
            !loading &&
            !error &&
            pathOr(false, ['currentUser', 'isCustomizationAvailable'])(data);
          const hasPublicAllotment =
            isPublic &&
            data?.currentUser?.public?.allotmentAllocations?.current;

          return (
            <MenuBody align="right">
              <MenuList>
                <MenuListItem>
                  <MenuGroup>
                    <MenuGroupItem>
                      <StyledLink to="/user">
                        <FormattedMessage id="dashboard.my-information-label" />
                      </StyledLink>
                    </MenuGroupItem>
                  </MenuGroup>
                </MenuListItem>
                <MenuListItem>
                  <MenuGroup>
                    <MenuGroupItem>
                      <StyledLink to="/carts">
                        <FormattedMessage id="dashboard.carts-label" />
                      </StyledLink>
                    </MenuGroupItem>
                    {!isPublic && (
                      <MenuGroupItem>
                        <StyledLink to="/catalogs">
                          <FormattedMessage id="dashboard.catalogs" />
                        </StyledLink>
                      </MenuGroupItem>
                    )}
                    <MenuGroupItem>
                      <StyledLink
                        to={defaultOrdersUrl({ accountId, organizationId })}
                      >
                        <FormattedMessage id="dashboard.order-history" />
                      </StyledLink>
                    </MenuGroupItem>
                    {!isPublic && (
                      <MenuGroupItem>
                        <StyledLink
                          to={defaultRostersUrl({
                            accountId,
                            organizationId,
                          })}
                        >
                          <FormattedMessage id="secondary-menu.rosters" />
                        </StyledLink>
                      </MenuGroupItem>
                    )}
                    {isCustomizationAvailable && (
                      <MenuGroupItem>
                        <StyledLink to="/designs">
                          <FormattedMessage id="dashboard.designs" />
                        </StyledLink>
                      </MenuGroupItem>
                    )}
                    {hasPublicAllotment && (
                      <MenuGroupItem>
                        <StyledLink to="/allotments">
                          <FormattedMessage id="dashboard.allotments" />
                        </StyledLink>
                      </MenuGroupItem>
                    )}
                    {invoicePortalUrl && (
                      <MenuGroupItem>
                        <StyledLink
                          as="a"
                          href={invoicePortalUrl}
                          target="_blank"
                          rel="noopener"
                          onClick={() =>
                            analytics.logInvoiceNavigation('Invoices')
                          }
                        >
                          <FormattedMessage id="dashboard.view-invoices" />
                        </StyledLink>
                      </MenuGroupItem>
                    )}
                  </MenuGroup>
                </MenuListItem>
                {!isPublic && (
                  <MenuListItem>
                    <MenuGroup>
                      {hasPermission(MANAGE_USERS) && (
                        <MenuGroupItem>
                          <StyledLink to="/users">
                            <FormattedMessage id="dashboard.user-management" />
                          </StyledLink>
                        </MenuGroupItem>
                      )}
                      {hasPermission(MANAGE_ORGANIZATIONS) && (
                        <MenuGroupItem>
                          <StyledLink to="/organizations">
                            <FormattedMessage id="dashboard.organization-management" />
                          </StyledLink>
                        </MenuGroupItem>
                      )}
                      {hasPermission(MANAGE_SALES_REPS) && (
                        <MenuGroupItem>
                          <StyledLink to="/salesreps">
                            <FormattedMessage id="dashboard.sales-rep-management" />
                          </StyledLink>
                        </MenuGroupItem>
                      )}
                      {hasPermission(VIEW_ALLOTMENTS) && (
                        <MenuGroupItem>
                          <StyledLink to="/allotments">
                            <FormattedMessage id="dashboard.allotments" />
                          </StyledLink>
                        </MenuGroupItem>
                      )}
                    </MenuGroup>
                  </MenuListItem>
                )}

                <MenuListItem>
                  <MenuGroup>
                    <MenuGroupItem>
                      <FormattedMessage id="logout.label">
                        {msg => (
                          <StyledButton
                            onClick={() => actions.logout(refreshToken)}
                          >
                            {msg}
                          </StyledButton>
                        )}
                      </FormattedMessage>
                    </MenuGroupItem>
                  </MenuGroup>
                </MenuListItem>
              </MenuList>
            </MenuBody>
          );
        }}
      </Query>
    </MenuDropdown>
  );
};

Menu.propTypes = {
  actions: PropTypes.shape({
    logout: PropTypes.func.isRequired,
  }).isRequired,
  refreshToken: PropTypes.string,
  accountId: PropTypes.string,
  organizationId: PropTypes.string,
  invoicePortalUrl: PropTypes.string,
  user: userSummaryShape,
};

Menu.defaultProps = {
  accountId: null,
  organizationId: null,
  refreshToken: null,
  user: null,
  invoicePortalUrl: null,
};

const mapStateToProps = state => ({
  refreshToken: path(['authentication', 'refreshToken'], state),
  accountId: getSelectedAccountId(state),
  organizationId: getSelectedOrganizationId(state),
  user: getUserProfile(state),
  invoicePortalUrl: pathOr(
    null,
    ['authentication', 'selectedAccount', 'invoicePortalUrl'],
    state,
  ),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logout,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
