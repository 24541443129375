import { pluck } from 'ramda';

const nonRegionLocaleMapping = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  nl: 'nl-NL',
};

export const withoutRegion = locale => locale.split('-')[0];

export const fallbackLocale = 'en-US';

export const supportedLocales = [
  { code: 'de-DE', label: 'Deutsch' },
  { code: 'fr-FR', label: 'Français' },
  { code: 'fr-CA', label: 'Français Canadien' },
  { code: 'es-ES', label: 'Español' },
  { code: 'nl-NL', label: 'Nederlands' },
  { code: 'en-US', label: 'English' },
];

export const supportedLocaleCodes = pluck('code', supportedLocales);

export const loadMessages = locale => import(`../translations/${locale}.json`);

export const polyfillLocale = locale =>
  import('intl').then(() =>
    import(`intl/locale-data/jsonp/${withoutRegion(locale)}.js`),
  );

export const browserLocale = navigator => {
  const locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  const localesToTry = [locale, nonRegionLocaleMapping[locale]];
  return localesToTry.filter(code =>
    supportedLocales.some(entry => entry.code === code),
  )[0];
};
