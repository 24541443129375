import { createSelector } from 'reselect';
import gql from 'graphql-tag';
import {
  compose,
  contains,
  find,
  flatten,
  flip,
  map,
  partition,
  path,
  pick,
  pluck,
  prop,
  propEq,
  propOr,
  sortBy,
  uniq,
  values,
} from 'ramda';
import { sizeRank } from 'lib/sizes';
import { isMissing, isPresent } from 'lib/data-manipulation';
import { grafikiShoppingModes } from '../lib/user';

const allProducts = state => state.search.products;
const currentGrid = state => state.search.currentGrid;
const instantResults = state => path(['search', 'instant', 'results'], state);

export const getProductsByStyles = (products, grid) =>
  products && grid && grid.map(styleCode => products[styleCode]);

export const getProductDetail = (state, styleCode) =>
  state.search.products && styleCode && state.search.products[styleCode];

export const currentGridProducts = createSelector(
  [allProducts, currentGrid],
  getProductsByStyles,
);
export const instantResultsProducts = createSelector(
  [allProducts, instantResults],
  getProductsByStyles,
);
export const productDetail = createSelector([getProductDetail], v => v);

export const getStyle = (state, ownProps) =>
  state.search.products[ownProps.styleId];

export const getArticleIdsForStyle = createSelector(
  [getStyle],
  style =>
    (style &&
      style.genericArticles &&
      compose(
        pluck('articleId'),
        flatten,
        pluck('sizes'),
      )(style.genericArticles)) ||
    [],
);

export const getGenericArticleIdsForStyle = createSelector(
  [getStyle],
  compose(pluck('id'), propOr([], 'genericArticles')),
);

export const getGenericArticle = (state, ownProps) => {
  const { genericArticleId } = ownProps;
  const style = getStyle(state, ownProps);
  if (!style) {
    return {};
  }
  const { genericArticles } = style;
  return find(propEq('id', genericArticleId))(genericArticles) || {};
};

export const styleDetailsLoaded = createSelector(
  [getStyle],
  compose(isPresent, propOr(null, 'styleCode')),
);

export const getArticleIds = createSelector(
  [allProducts],
  compose(
    map(prop('articleId')),
    flatten,
    map(compose(map(prop('sizes')), prop('genericArticles'))),
    values,
  ),
);

const articleSizes = article => map(pick(['id', 'display']))(article.sizes);

export const hasSize = (article, size) =>
  compose(contains(size), flatten, articleSizes)(article);

export const uniqueSizes = createSelector(
  [articles => articles],
  compose(
    sortBy(({ display }) => sizeRank(display)),
    uniq,
    flatten,
    map(articleSizes),
  ),
);

const searchedIds = path(['search', 'productIds']);

export const getSearchedIdMatches = ids => {
  if (isMissing(ids)) {
    return null;
  }
  const { sought, found } = ids;
  const [successes, failures] = partition(flip(contains)(found), sought);
  return { successes, failures };
};

export const searchedIdMatches = createSelector(
  [searchedIds],
  getSearchedIdMatches,
);

export const getProductFulfillerStyleIDs = product =>
  compose(
    uniq,
    flatten,
    map(prop('fulfillerIDs')),
  )(product.genericArticles || []);

const CONFIG_FULFILLER_QUERY = gql`
  query getFulfillerAndConfig(
    $accountId: ID!
    $styleIds: [String!]!
    $shoppingMode: ShoppingMode!
  ) {
    account: node(id: $accountId) {
      ... on Account {
        id
        products(styleIds: $styleIds, shoppingMode: $shoppingMode) {
          edges {
            materials {
              edges {
                node {
                  configurableStatus
                }
              }
            }
            node {
              id
              fulfiller {
                id
                fulfillerName
              }
            }
          }
        }
      }
    }
  }
`;

// Currently, fulfiller and configStatus are required (primary in analytics) in places
// where there is either no GraphQL query, or a query with which it would be very difficult to
// also query for products on Account. This function allows these components to use the
// data already available to them to query for an array of fulfillers and configStatuses
// associated with their products

export const getFulfillerAndConfigStatus = async ({
  styleIds,
  shoppingMode,
  accountId,
}) => {
  const { graphqlClient } = await import('index');
  const response = await graphqlClient.query({
    query: CONFIG_FULFILLER_QUERY,
    variables: {
      styleIds,
      shoppingMode: grafikiShoppingModes[shoppingMode],
      accountId,
    },
    fetchPolicy: 'network-only',
  });
  // maps over response and picks out all fulfillerNames in query
  const fulfillersResponse = response.data.account.products.edges.map(
    product => product.node.fulfiller.fulfillerName,
  );
  // maps over response and picks all config statuses out
  const configStatusesResponse = response.data.account.products.edges.map(
    node =>
      node.materials.edges.map(material => material.node.configurableStatus),
  );
  // Deletes any non-unique values
  const fulfillers = [...new Set(fulfillersResponse)];
  // flattens the 2D array into a single array, and then eliminates duplicate values as well
  const configStatuses = [...new Set(flatten(configStatusesResponse))];

  return { fulfillers, configStatuses };
};
