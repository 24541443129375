import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { spacing } from 'lib/theme';

const appRoot = document.getElementById('root');

export const QuestionMark = styled.img`
  cursor: pointer;
  margin-left: ${spacing.m};
`;

const StyledTooltip = styled(ReactTooltip)`
  padding: 0 !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  text-align: center;

  &::after {
    display: none; /* remove caret */
  }
`;

export const overridePosition = ({ left, top }, _x, _y, node) => {
  const d = document.documentElement;
  return {
    left: Math.max(0, Math.min(d.clientWidth - node.clientWidth, left)),
    top: Math.max(0, Math.min(d.clientHeight - node.clientHeight, top)),
  };
};

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    appRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <StyledTooltip effect="solid" {...this.props} />,
      this.el,
    );
  }
}

export default Tooltip;
