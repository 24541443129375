import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { isMissing, isPresent } from 'lib/data-manipulation';
import { markAsSeen as creator } from 'ducks/marketing';
import { colors, fonts } from 'lib/theme';

export const Layout = styled.div`
  position: relative;
`;

export const HideButton = styled.button`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  color: ${colors.black};
  font: ${fonts.heavy};
  text-transform: uppercase;
  right: 10px;
  top: 10px;
  z-index: 1;
  padding: 3px 10px;
`;

export const Hide = ({ onClick }) =>
  isPresent(onClick) && (
    <HideButton onClick={onClick}>
      <FormattedMessage id="marketing.shroud" />
    </HideButton>
  );

Hide.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Collapse = styled.div`
  overflow-y: hidden;
  max-height: ${props => (props.collapse ? '0' : '500px')};
  opacity: ${props => (props.collapse ? '0' : '100%')};
  transition: all 600ms ease-in-out;
`;

const shouldHide = (contentMeta, seen) => {
  const hideRequest = seen[contentMeta.id];

  if (!hideRequest) {
    return false;
  }

  const today = new Date().toISOString();
  const unchanged = contentMeta.updatedAt <= hideRequest.updatedAt;
  const unexpired =
    isMissing(hideRequest.expiresAt) || hideRequest.expiresAt > today;

  return unchanged && unexpired;
};

const CollapsingShroud = ({ hidden, children }) => (
  <Collapse collapse={hidden}>{children}</Collapse>
);

CollapsingShroud.propTypes = {
  hidden: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const NullifyingShroud = ({ hidden, children }) => (hidden ? null : children);

NullifyingShroud.propTypes = {
  hidden: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const Shroud = ({ animate, children, seen, contentMeta, markAsSeen }) => {
  const Wrapper = animate ? CollapsingShroud : NullifyingShroud;

  return (
    <Wrapper hidden={shouldHide(contentMeta, seen)}>
      {React.cloneElement(children, {
        markAsSeen: () => markAsSeen(contentMeta),
      })}
    </Wrapper>
  );
};

Shroud.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node,
  seen: PropTypes.shape({}).isRequired,
  contentMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    ttl: PropTypes.string,
  }).isRequired,
  markAsSeen: PropTypes.func.isRequired,
};

Shroud.defaultProps = {
  animate: false,
  children: null,
};

const mapStateToProps = state => ({
  seen: path(['marketing', 'seen'], state),
});

const mapDispatchToProps = {
  markAsSeen: creator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shroud);
