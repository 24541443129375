import { ApolloLink, Observable } from 'apollo-link';
import {
  selectURI,
  selectHttpOptionsAndBody,
  fallbackHttpConfig,
  serializeFetchParameter,
  createSignalIfSupported,
  parseAndCheckHttpResponse,
} from 'apollo-link-http-common';

export const FILE_FIELD_NAME = '__file__';

// Removes FILE_FIELD_NAME from variables,
// and returns all data we need to perform
// our request.
const extractFile = ({
  variables: { [FILE_FIELD_NAME]: file, ...variables },
  query,
  operationName,
}) => ({
  query,
  variables,
  operationName,
  file,
});

export default ({
  uri: fetchUri = '/graphql',
  fetch: linkFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions,
} = {}) => {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers,
  };

  return new ApolloLink(operation => {
    const uri = selectURI(operation, fetchUri);
    const context = operation.getContext();
    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      linkConfig,
      contextConfig,
    );

    const { query, variables, operationName, file } = extractFile(body);

    if (file) {
      delete options.headers['content-type'];
      const form = new FormData();
      form.append('query', query);
      form.append('variables', serializeFetchParameter(variables));
      form.append('file', file, file.name);
      options.body = form;
    } else {
      options.body = serializeFetchParameter(
        {
          query,
          variables,
          operationName,
        },
        'Payload',
      );
    }

    return new Observable(observer => {
      // Allow aborting fetch, if supported.
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;
      linkFetch(`${uri}?operationName=${operation?.operationName}`, options)
        .then(response => {
          // Forward the response on the context.
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then(result => {
          observer.next(result);
          observer.complete();
          return result;
        })
        .catch(error => {
          if (error.name === 'AbortError')
            // Fetch was aborted.
            return;

          if (error.result && error.result.errors && error.result.data) {
            // There is a GraphQL result to forward.
            observer.next(error.result);
          }
          observer.error(error);
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  });
};
