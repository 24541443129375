import styled, { css } from 'styled-components';
import { maxWidthMedia } from 'styles/mixins';
import {
  colors,
  fonts,
  fontSizes,
  weights,
  spacing,
  breakpoint,
} from 'lib/theme';

export const xxLargeHeadline = css`
  font: ${fonts.heading};
  margin-bottom: 1.5em;
  -webkit-font-smoothing: antialiased;
`;

export const xLargeHeadline = css`
  font-size: ${fontSizes.xxlarge};
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: ${weights.regular};
`;

export const largeHeadline = css`
  font-size: ${fontSizes.xlarge};
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: ${weights.regular};
`;

export const mediumHeadline = css`
  font-size: ${fontSizes.large};
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  font-weight: ${weights.regular};
`;

export const smallHeadline = css`
  font: ${fonts.heavy};
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
`;

export const xSmallHeadline = css`
  font: ${fonts.heavy};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`;

export const H1 = styled.h1`
  ${xxLargeHeadline}
`;

export const H2 = styled.h2`
  ${xLargeHeadline};
`;

export const H3 = styled.h3`
  ${largeHeadline};
`;

export const H4 = styled.h4`
  ${mediumHeadline}
`;

export const H5 = styled.h5`
  ${smallHeadline}
`;

export const H6 = styled.h6`
  ${xSmallHeadline}
`;

export const ErrorArea = styled.div`
  font-size: ${props => (props.large ? 13 : 11)}px;
  font-weight: ${weights.light};
  color: ${colors.red};
`;

export const ErrorText = styled.small`
  font-size: ${props => (props.large ? 13 : 11)}px;
  font-weight: ${weights.light};
  color: ${colors.red};
`;

export const LabeledTextLabel = styled.h4`
  margin-top: 0;
  margin-bottom: ${spacing.s};
  font: ${fonts.regular};

  ${maxWidthMedia(breakpoint.gd.phoneMax)`
    margin-bottom: 0;
    font: ${fonts.gd.regular.body};

    &::after {
      content: ': ';
      white-space: pre;
    }
  `}
`;

export const LabeledTextBody = styled.span`
  font: ${fonts.heavy};
  display: block;

  ${maxWidthMedia(breakpoint.gd.phoneMax)`
    font: ${fonts.gd.regular.body};
  `}
`;

export const LabeledTextItem = styled.li`
  width: 25%;
  margin-bottom: ${spacing.xl};
  padding-right: ${spacing.xl};

  ${maxWidthMedia(breakpoint.gd.phoneMax)`
    margin-bottom: 0;
    padding-right: 0;
  `}
`;

const LabeledTextListStyles = ({ direction }) =>
  direction === 'horizontal' ? '0 20px;' : '0;';

const LabeledTextItemStyles = ({ direction }) =>
  direction === 'horizontal' ? 'width: auto;' : 'width: 25%;';

const LabeledTextStyles = ({ direction }) =>
  direction === 'horizontal'
    ? 'display: inline-block; margin-right: 10px;'
    : 'display: block; margin-right: 0}';

export const LabeledTextList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: ${LabeledTextListStyles};
  padding: 0;
  text-align: left;

  ${/* sc-sel */ LabeledTextItem} {
    ${LabeledTextItemStyles};
  }

  /* stylelint-disable-next-line selector-list-comma-newline-after */
  ${/* sc-sel */ LabeledTextLabel}, ${/* sc-sel */ LabeledTextBody} {
    ${LabeledTextStyles};

    ${maxWidthMedia(breakpoint.gd.phoneMax)`
      margin-right: 0;
    `}
  }

  ${maxWidthMedia(breakpoint.gd.phoneMax)`
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
  `}
`;

export const PrimaryText = styled.div`
  color: ${colors.black};
  font: ${fonts.heavy};
`;

export const BoldSubText = styled.span`
  color: ${colors.black};
  font: ${fonts.heavy};
`;

export const SubText = styled.span`
  color: ${colors.darkGrey};
  font: ${fonts.regular};
`;

export const UpperCase = styled.div`
  text-transform: uppercase;
`;

export const HeaderText = styled.div`
  color: ${colors.black};
  font: ${fonts.regular};
  margin-bottom: 25px;
`;
