import { isNil, path, pipe } from 'ramda';
import { getActiveSearchConfig } from 'ducks/search';
import { getActiveShoppingMode } from 'selectors/account';
import { delayAction } from 'ducks/delayed-actions';

export const EXCHANGE_ACCESS_TOKEN = 'authentication/EXCHANGE_ACCESS_TOKEN';

const requestExchange = refreshToken => ({
  uaApiRequest: {
    url: '/api/sessions/exchange',
    method: 'POST',
    data: { refreshToken },
  },
  meta: { undelayable: true },
});

export const exchangeAccessToken = refreshToken => ({
  type: EXCHANGE_ACCESS_TOKEN,
  meta: {
    undelayable: true,
    ...requestExchange(refreshToken),
  },
});

export const decodeJwt = jwt => JSON.parse(atob(jwt.split('.')[1]));

export const mungeAccessToken = payload => ({
  token: payload.data.accessToken,
  received: payload.data.received || Date.now(),
  expires: payload.data.expiresAt * 1000,
  permissions: decodeJwt(payload.data.accessToken).perm,
});

const getRefreshToken = path(['authentication', 'refreshToken']);
export const isWaitingForAccess = path(['authentication', 'access', 'waiting']);

export const refreshSession = store => {
  const state = store.getState();
  pipe(getRefreshToken, exchangeAccessToken, store.dispatch)(state);

  // search tokens are tied to access tokens.  So whenever we obtain a new
  // access token we also need to turn around and request a new search token.
  const accountId = path(['authentication', 'selectedAccount', 'id'], state);
  const shoppingMode = getActiveShoppingMode(state);
  const searchConfigAction = getActiveSearchConfig(accountId, shoppingMode);
  store.dispatch(delayAction(searchConfigAction, EXCHANGE_ACCESS_TOKEN));
};

export const sessionNeedsRefresh = reduxState => {
  const { authentication } = reduxState;

  if (isNil(authentication) || isNil(authentication.refreshToken)) {
    // don't try to refresh if we're not logged in.
    return false;
  }

  if (isWaitingForAccess(reduxState)) {
    // don't request multiple access tokens at once.
    return false;
  }

  const { received = 0, expires = 0 } = authentication.access;
  const now = Date.now();
  const halfOverMs = (received + expires) / 2;
  return now > halfOverMs;
};

export const isAccessExpired = state => {
  const expires = path(['authentication', 'access', 'expires'], state);
  // provide some padding; requests aren't instant
  return expires <= Date.now() + 5000;
};
