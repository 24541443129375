import { any, compose, join, reject, split, take, test } from 'ramda';
import { isMissing } from 'lib/data-manipulation';

// TODO: this should be temporary, as this is the size code and not
// necessarily the display string
export const getArticleSize = articleId => articleId.split('-')[2];

export const getStyleCode = articleId => articleId.split('-')[0];

export const getGenericArticleId = compose(join('-'), take(2), split('-'));

export const getColorCode = genericArticleId => genericArticleId.split('-')[1];

// We always expect colorNames obj order would be  -> name, name2 and name3
export const parseColorNames = (colorNames = {}) =>
  Object.values(colorNames)
    .filter(n => n)
    .join('/');

export const hasArticleIds = productIds => {
  const isGenericArticleId = test(/\d{6,7}-\d{3}/);
  return any(isGenericArticleId)(productIds);
};

// product ids = product || FulFiller Ids
// returns [product ids, search string minus product ids]
// note:  the CURRENT strategy is: if a search term contains any product ids,
// treat every piece of the search term as a product id (perhaps mistyped if
// of the wrong format)
export const extractProductIds = term => {
  const pieces = reject(isMissing)(term.toString().split(/\s|,|;/));

  const isId = test(/\d{6,7}(-\d{3})?/);
  const isFulFillerId = test(/^U\S{5,6}/i);

  if (any(isId)(pieces) || any(isFulFillerId)(pieces)) {
    // FulFiller Ids should be in caps to be searchable
    const transformedPieces = pieces.map(piece =>
      isFulFillerId(piece) ? piece.toUpperCase() : piece,
    );
    return [transformedPieces, ''];
  }

  return [[], term];
};
