import { combineReducers } from 'redux';
import gql from 'graphql-tag';
import { handle } from 'redux-pack';
import mungeInventory from 'lib/inventory';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from './authentication';

export const FETCH_INVENTORY = 'inventory/FETCH_INVENTORY';
export const CLEAR_INVENTORY = 'inventory/CLEAR_INVENTORY';

const GET_INVENTORY = gql`
  query getInventory($accountId: ID!, $skus: [String!]!) {
    inventory(accountId: $accountId, skus: $skus) {
      sku
      qty
      future {
        qty
        effectiveDate
      }
    }
  }
`;

export const fetchInventory = (accountId, articleIds) => ({
  type: FETCH_INVENTORY,
  promise: import('index').then(({ graphqlClient }) =>
    graphqlClient
      .query({
        query: GET_INVENTORY,
        variables: {
          accountId,
          skus: articleIds,
        },
      })
      .then(payload => payload),
  ),
  meta: { articleIds },
});

export const clearInventory = () => ({
  type: CLEAR_INVENTORY,
});

const byArticleId = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
    case CLEAR_INVENTORY:
      return {};

    case FETCH_INVENTORY:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          ...mungeInventory(payload.data.inventory),
        }),
      });

    default:
      return state;
  }
};

const fetching = (state = null, action) => {
  if (action.type === FETCH_INVENTORY) {
    return handle(state, action, {
      start: () => action.meta.articleIds,
      finish: () => null,
    });
  }
  return state;
};

const error = (state = false, action) => {
  const { type, payload } = action;
  if (type === FETCH_INVENTORY) {
    return handle(state, action, {
      start: () => null,
      failure: () => payload.message,
    });
  }
  return state;
};

export default combineReducers({ error, fetching, byArticleId });
