import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { noticeError } from 'lib/errors';
import Loading from './loading';
import ErrorMessage from './ui/error-message';
import l from './layout';

const PageLoading = () => (
  <l.FullWidth>
    <l.CenteredRow>
      <Loading messageId="page.loading" />
    </l.CenteredRow>
  </l.FullWidth>
);

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch = noticeError;

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <l.FullWidth>
          <l.CenteredRow>
            <ErrorMessage>
              <FormattedMessage id="page.error" />
            </ErrorMessage>
          </l.CenteredRow>
        </l.FullWidth>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

const CHUNK_LOAD_ERROR = 'ChunkLoadError';

const shouldRetry = error => {
  const { message } = error;
  return message === CHUNK_LOAD_ERROR;
};

class RetryBoundary extends React.Component {
  state = { retries: 0, retrying: false };

  static propTypes = {
    delayMS: PropTypes.number.isRequired,
    maxRetries: PropTypes.number.isRequired,
    children: PropTypes.element.isRequired,
  };

  componentDidCatch(error, errorInfo) {
    const { delayMS, maxRetries } = this.props;
    const { retrying, retries } = this.state;
    if (!shouldRetry(error, errorInfo)) {
      if (retries >= maxRetries) {
        // eslint-disable-next-line
        console.info(
          `[RetryBoundary]: Hit maximum retry attempts (${maxRetries})...`,
        );
      }
      throw error;
    }
    if (!retrying) {
      this.setState(prevState => {
        const numTries = prevState.retries + 1;
        return {
          retries: numTries,
          retrying: numTries <= maxRetries,
        };
      });
      const base = 2 ** retries;
      setTimeout(() => {
        this.setState({
          retrying: false,
        });
      }, base * delayMS);
    }
  }

  render() {
    const { retrying } = this.state;
    const { children } = this.props;
    if (!retrying) {
      return children;
    }
    return null;
  }
}

export const LoadableWithRetries = ({ loader }) => {
  const LazyComponent = lazy(loader);
  return props => (
    <ErrorBoundary>
      <RetryBoundary>
        <Suspense fallback={<PageLoading />}>
          <LazyComponent {...props} />
        </Suspense>
      </RetryBoundary>
    </ErrorBoundary>
  );
};

export const Loadable = ({ loader, maxRetries = 5, delayMS = 1000 }) => {
  const LazyComponent = lazy(loader);
  return props => (
    <ErrorBoundary>
      <RetryBoundary maxRetries={maxRetries} delayMS={delayMS}>
        <Suspense fallback={<PageLoading />}>
          <LazyComponent {...props} />
        </Suspense>
      </RetryBoundary>
    </ErrorBoundary>
  );
};

export default Loadable;
