import React, { Component } from 'react';
import { omit } from 'ramda';
import PropTypes from 'prop-types';

function noop() {}

export default function(OriginalComponent) {
  class FocusAware extends Component {
    static propTypes = {
      canBeEmpty: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    };

    static defaultProps = {
      value: '',
      canBeEmpty: true,
      onChange: noop,
      onFocus: noop,
      onBlur: noop,
    };

    constructor(props) {
      super(props);
      this.state = {
        focused: false,
        empty: !props.value && props.canBeEmpty,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { value, canBeEmpty } = nextProps;
      const { value: currentValue } = this.props;
      if (!currentValue && value) {
        this.setState({
          empty: !value && canBeEmpty,
        });
      }
    }

    onChange = e => {
      const { onChange, canBeEmpty } = this.props;
      this.setState({
        empty: !e.target.value && canBeEmpty,
      });
      onChange(e);
    };

    onFocus = e => {
      const { onFocus } = this.props;
      this.setState({ focused: true });
      onFocus(e);
    };

    onBlur = e => {
      const { onBlur, canBeEmpty } = this.props;
      this.setState({
        focused: false,
        empty: !e.target.value && canBeEmpty,
      });
      onBlur(e);
    };

    render() {
      const {
        onFocus,
        onBlur,
        state: { focused, empty },
        props,
      } = this;
      const componentProps = omit(['canBeEmpty'])(props);
      return (
        <OriginalComponent
          {...componentProps}
          focused={focused}
          empty={empty}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );
    }
  }
  return FocusAware;
}
