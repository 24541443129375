export const OPEN_CART_DRAWER = 'cart/OPEN_CART_DRAWER';
export const CLOSE_CART_DRAWER = 'cart/CLOSE_CART_DRAWER';

export default function drawerOpen(state = false, action) {
  switch (action.type) {
    case OPEN_CART_DRAWER:
      return true;

    case CLOSE_CART_DRAWER:
      return false;

    default:
      return state;
  }
}
