export const colors = {
  black: '#1D1D1D',
  offBlack: '#5A5A5A',
  darkGrey: '#767676',
  grey: '#B4B4B4',
  lightGrey: '#D2D2D2',
  offWhite: '#F0F0F0',
  lipstick: '#e51b24',
  white: '#FFFFFF',
  red: '#e51b24',
  pink: '#fae5e8',
  yellow: '#FAF7E5',

  gd: {
    black: '#1d1d1d',
    gray1: '#f7f7f7',
    gray2: '#f0f0f0',
    gray3: '#d0d0d0',
    gray4: '#949494',
    gray5: '#5f5f5f',
    gray6: '#444444',
    green: '#008850',
    none: 'none',
    red: {
      default: '#dc0019',
      hover: '#e93e51',
      press: '#e6152d',
      disabled: '#fab2ba',
    },
    tabFocus: '#0096fa',
    white: '#ffffff',
  }
};

export const weights = {
  light: 300,
  regular: 500,
  bold: 700,
  extraBold: 800,
};

export const fontSizes = {
  tiny: '11px',
  medium: '13px',
  large: '15px',
  xlarge: '18px',
  xxlarge: '22px',
  huge: '24px',
};

export const borders = {
  activeStroke: `4px solid ${colors.black}`,
  dashed: `1px dashed ${colors.grey}`,
  hairline: `1px solid ${colors.lightGrey}`,
  thin: `1px solid ${colors.black}`,
  thinDarkGrey: `1px solid ${colors.darkGrey}`,
  thinOffWhite: `1px solid ${colors.offWhite}`,
};

export const columnWidth = '120px';

const uaFont = (weight: any, size: any, lineHeight = '1.3') =>
  `${weight} ${size}/${lineHeight} 'Neue Plak Text', sans-serif`;

export const fonts = {
  tiny: uaFont(weights.light, fontSizes.tiny),
  tinyHeavy: uaFont(weights.regular, fontSizes.tiny),
  regular: uaFont(weights.light, fontSizes.medium),
  normal: uaFont('normal', fontSizes.medium),
  heavy: uaFont(weights.regular, fontSizes.medium),
  bold: uaFont(weights.bold, fontSizes.medium),
  largeLight: uaFont(weights.light, fontSizes.large),
  largeRegular: uaFont(weights.regular, fontSizes.large),
  extra: uaFont(weights.bold, fontSizes.large),
  extraLargeRegular: uaFont(weights.regular, fontSizes.xlarge),
  extraLargeLight: uaFont(weights.light, fontSizes.xxlarge),
  heading: uaFont(weights.regular, fontSizes.huge),
  boldHeading: uaFont(weights.bold, fontSizes.huge),
  extraBold: uaFont(weights.extraBold, '50px'),

  // Global Design
  gd: {
    condensed: {
      h1: `900 64px/1 'Neue Plak Condensed Black', sans-serif`,
      h2: `900 48px/1 'Neue Plak Condensed Black', sans-serif`,
      h3: `900 40px/1 'Neue Plak Condensed Black', sans-serif`,
      h4: `900 32px/1 'Neue Plak Condensed Black', sans-serif`,
      h5: `900 24px/1 'Neue Plak Condensed Black', sans-serif`,
    },
    bold: {
      h3: `700 40px/1.2 'Neue Plak Bold', sans-serif`,
      h4: `700 32px/1.25 'Neue Plak Bold', sans-serif`,
      h5: `700 24px/1.33 'Neue Plak Bold', sans-serif`,
      h6: `700 16px/1.5 'Neue Plak Bold', sans-serif`,
      body: `700 14px/1.4285 'Neue Plak Bold', sans-serif`,
      subtext: `700 12px/1.667 'Neue Plak Bold', sans-serif`,
      legal: `700 10px/1.6 'Neue Plak Bold', sans-serif`,
    },
    semibold: {
      h6: `400 16px/1.5 'Neue Plak Semi Bold', sans-serif`,
      body: `400 14px/1.4285 'Neue Plak Semi Bold', sans-serif`,
      subtext: `400 12px/1.667 'Neue Plak Semi Bold', sans-serif`,
      legal: `400 10px/1.6 'Neue Plak Semi Bold', sans-serif`,
    },
    regular: {
      h6: `400 16px/1.5 'Neue Plak Text', sans-serif`,
      body: `400 14px/1.4285 'Neue Plak Text', sans-serif`,
      subtext: `400 12px/1.667 'Neue Plak Text', sans-serif`,
      legal: `400 10px/1.6 'Neue Plak Text', sans-serif`,
    },
  }
};

export const spacing = {
  s: '5px',
  m: '10px',
  l: '15px',
  xl: '20px',
  xxl: '30px',
  xxxl: '40px',
  gutterWidth: '20px',

  // Global Design
  gd: {
    none: '0px',
    xxxs: '4px',
    xxs: '8px',
    xs: '16px',
    sm: '24px',
    md: '32px',
    lg: '56px',
    xl: '64px',
    xxl: '80px',
    xxxl: '120px',
  }
};

export const width = {
  full: '1400px',
};

export const breakpoint = {
  // Legacy Design Breakpoints
  phoneMax: '649px',
  bigPhone: '650px',
  bigPhoneMax: '768px',
  tablet: '769px',
  tabletMax: '1024px',
  medium: '1025px',
  desktop: '1025px',

  // Global Design
  gd: {
    phoneMin: '320px',
    phoneMax: '479px',
    tabletMin: '480px',
    tabletMax: '767px',
    desktopMin: '768px',
  }
};

export const height = {
  shopping: '70px',
  global: '35px',
};

export const inputHeights = {
  normal: '50px',
  small: '40px',
  compact: '30px',
};

export const theme = {
  colors,
  spacing,
  fonts,
  breakpoint,
  height,
  inputHeights,
  borders,
};

type UATheme = typeof theme;
declare module 'styled-components' {
  // eslint-disable-next-line
  export interface DefaultTheme extends UATheme {}
}
