import combineDucklings from 'lib/ducklings';
import selectOrganization from './select-organization';

const initialState = {
  organizations: [],
  isError: false,
  isLoading: false,
  selectedOrganizations: {},
};

export default combineDucklings(initialState, [selectOrganization]);
