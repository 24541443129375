import { createStore, applyMiddleware } from 'redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'ducks';
import algoliaInjectorMiddleware from 'middleware/algolia-injector';
import contentfulInjectorMiddleware from 'middleware/contentful-injector';
import localeChangedMiddleware from 'middleware/locale-changed';
import localStorageSupported from 'lib/local-storage-supported';
import uaApiInjectorMiddleware from 'middleware/ua-api-injector';
import searchMiddleware from 'middleware/search';
import sessionRefreshedMiddleware from 'middleware/session-refreshed';
import authenticationMiddleware from 'middleware/authentication';
import localStorageMiddleware from 'middleware/localStorage';
import checkoutMiddleware from 'middleware/checkout';

const middlewares = [
  checkoutMiddleware,
  reduxPackMiddleware,
  localeChangedMiddleware,
  algoliaInjectorMiddleware,
  uaApiInjectorMiddleware,
  contentfulInjectorMiddleware,
  searchMiddleware,
  authenticationMiddleware,
  sessionRefreshedMiddleware,
];

if (localStorageSupported()) {
  middlewares.push(localStorageMiddleware);
}

const enableReduxDevToolsExtension =
  process.env.NODE_ENV !== 'production' ||
  process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS_EXTENSION === '1';

const middleware = enableReduxDevToolsExtension
  ? composeWithDevTools(applyMiddleware(...middlewares))
  : applyMiddleware(...middlewares);

export default initialState => {
  const store = createStore(rootReducer, initialState, middleware);
  /* eslint-disable */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../ducks', () => {
      const nextRootReducer = require('../ducks');
      store.replaceReducer(nextRootReducer);
    });
  }
  /* eslint-enable */
  return store;
};
