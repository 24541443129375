import { KEY } from 'redux-pack';
import {
  assocPath,
  both,
  dissocPath,
  identity,
  ifElse,
  isNil,
  path,
  prop,
} from 'ramda';

const getActionId = path(['meta', KEY.TRANSACTION]);
const keyForAction = prop('type');
const pathForAction = action => ['lastActions', keyForAction(action)];

const addLastAction = action =>
  assocPath(pathForAction(action), getActionId(action));
const getLastAction = action => path(pathForAction(action));
const removeLastAction = action => dissocPath(pathForAction(action));

const isActionPending = (state, action) => !isNil(getLastAction(action)(state));
const actionMatches = (state, action) =>
  getLastAction(action)(state) === getActionId(action);

export const checkLastAction = reducer =>
  ifElse(both(isActionPending, actionMatches), reducer, identity);

export const clearLastAction = reducer => (state, action, ...args) =>
  removeLastAction(action)(reducer(state, action, ...args));

export const persistLastAction = reducer => (state, action, ...args) =>
  addLastAction(action)(reducer(state, action, ...args));
