import { createSelector } from 'reselect';
import {
  allPass,
  compose,
  filter,
  find,
  flatten,
  indexBy,
  map,
  path,
  pipe,
  pluck,
  prop,
  propEq,
  uniq,
} from 'ramda';
import { isPresent } from 'lib/data-manipulation';
import { currencyRange } from 'lib/currency';
import { getArticleSize } from 'lib/product-ids';
import { getDisplayedPriceType } from './pricing';

export const getActiveCartId = state => state.carts.activeCart.id;

export const getActiveCartInfo = state => state.carts.activeCart;

export const getActiveCartItems = state => {
  const activeCartId = getActiveCartId(state);
  const activeCart = state.carts.byId[activeCartId];
  return (activeCart && activeCart.items) || null;
};

export const getUserCartIds = state => state.carts.userCarts.ids;

export const getUserCartsError = state => state.carts.userCarts.error;

export const getCarts = state => state.carts.byId;

export const getCartItems = ({ carts: { byId } }, { cartId }) =>
  (byId[cartId] && byId[cartId].items) || null;

export const getCartItemsByGenericArticle = (state, ownProps) =>
  filter(propEq('genericArticleId', ownProps.genericArticleId))(
    getCartItems(state, ownProps),
  );

export const getCartItemsByGenericArticleAndRecipe = (state, ownProps) =>
  filter(
    allPass([
      propEq('genericArticleId', ownProps.genericArticleId),
      propEq('recipeCode', ownProps.recipeCode),
    ]),
  )(getCartItems(state, ownProps));

export const getCartItemByArticle = (state, ownProps) =>
  find(propEq('articleId', ownProps.articleId))(getCartItems(state, ownProps));

const getCartItemsByStyle = (state, ownProps) =>
  filter(propEq('styleId', ownProps.styleId))(getCartItems(state, ownProps));

export const isActiveCartLoaded = createSelector(
  [getActiveCartId, getActiveCartItems],
  (activeCartId, items) => !!items || !activeCartId,
);

export const cartsAreLoaded = createSelector(
  [isActiveCartLoaded, getUserCartIds],
  (activeCartLoaded, userCartIds) =>
    !!userCartIds && (userCartIds.length === 0 || activeCartLoaded),
);

export const hasCartError = createSelector(
  [getUserCartsError, getActiveCartInfo],
  (userCartsError, activeCart) => !!userCartsError || !!activeCart.errorLoading,
);

const getSearchGenericArticles = ({ search: { products } }, { styleId }) =>
  path([styleId, 'genericArticles'], products) || {};

const getStyleArticleIds = createSelector(
  [getSearchGenericArticles],
  compose(pluck('articleId'), flatten, pluck('sizes')),
);

export const articleQuantities = createSelector(
  [getCartItems, getStyleArticleIds, (_, { recipeCode }) => recipeCode],
  (cartItems, styleArticleIds, recipeCode) => {
    if (!styleArticleIds) {
      return {};
    }
    return styleArticleIds.reduce((selections, articleId) => {
      const articleInCart = find(
        allPass([
          propEq('articleId', articleId),
          propEq('recipeCode', recipeCode),
        ]),
      )(cartItems || []);
      if (articleInCart) {
        return { ...selections, [articleId]: articleInCart.quantity };
      }
      return selections;
    }, {});
  },
);

export const getGenericArticle = (state, { genericArticleId, styleId }) =>
  find(propEq('id', genericArticleId))(
    state.search.products[styleId].genericArticles,
  );

export const getSpecificGenericArticles = createSelector(
  [getSearchGenericArticles, (_, props) => props.genericArticleIds],
  (genericArticles, genericArticleIds) =>
    pipe(
      uniq,
      map(id => find(propEq('id', id), genericArticles)),
      filter(isPresent),
    )(genericArticleIds.sort()),
);

const getStyles = state => state.search.products;

export const genericArticlesLoaded = createSelector(
  [getCartItems, getStyles],
  (cartItems, styles) => {
    if (!cartItems) {
      return false;
    }
    const needToFetch = cartItem => {
      const style = styles[cartItem.styleId];
      return (
        !style ||
        !find(propEq('id', cartItem.genericArticleId))(
          style.genericArticles || [],
        )
      );
    };
    return !find(needToFetch)(cartItems);
  },
);

export const isCartSubmitting = createSelector(
  [state => state.carts.activeCart],
  cart => !!(cart.creating || cart.updating),
);

export const cartFailedSubmission = createSelector(
  [state => state.carts.activeCart],
  cart => !!(cart.errorCreating || cart.errorUpdating),
);

export const getGenericArticlePriceRange = createSelector(
  [getCartItemsByGenericArticle, getDisplayedPriceType],
  (items, priceType) =>
    compose(currencyRange, map(path(['unitPricing', priceType])))(items),
);

export const getStylePriceRange = createSelector(
  [getCartItemsByStyle, getDisplayedPriceType],
  (items, priceType) =>
    compose(currencyRange, map(path(['unitPricing', priceType])))(items),
);

export const getQuantitiesBySize = createSelector(
  [getCartItemsByGenericArticleAndRecipe],
  cartItems =>
    cartItems.map(({ articleId, quantity }) => ({
      articleId,
      quantity,
      size: getArticleSize(articleId),
    })),
);

export const isEditingCart = ({ carts: { cartIdEditing } }, { cartId }) =>
  cartIdEditing === cartId;

export const isSharingCart = ({ carts: { cartIdSharing } }, { cartId }) =>
  cartIdSharing === cartId;

export const getArticlePrices = createSelector(
  [getCartItems, getDisplayedPriceType],
  (items, priceType) =>
    compose(
      map(path(['unitPricing', priceType])),
      indexBy(prop('articleId')),
    )(items),
);

export const getParseErrors = ({ carts: { parseErrors } }, { cartId }) =>
  parseErrors[cartId] || { errors: [] };

export const getUnitPrice = createSelector(
  [getCartItemByArticle, getDisplayedPriceType],
  (item, priceType) => path(['unitPricing', priceType], item),
);

export const isEditingItem = (state, item) => {
  // Default `undefined` values to `null` to ensure strict-equality checks work properly.
  const { styleId, recipeCode = null, recipeSet } = item;
  const { recipeSetCode = null } = recipeSet || {};
  const {
    styleId: editingStyleId,
    recipeCode: editingRecipeCode = null,
    recipeSetCode: editingRecipeSetCode = null,
  } = state.carts.itemEditing;

  return (
    editingStyleId === styleId &&
    editingRecipeCode === recipeCode &&
    editingRecipeSetCode === recipeSetCode
  );
};
