import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, inputHeights } from 'lib/theme';

const backgroundColor = ({ isDisabled }) =>
  isDisabled ? colors.offWhite : colors.white;

const StyledInputBox = styled.div`
  position: relative;
  height: ${props => inputHeights[props.size]};
  background-color: ${backgroundColor};
`;

StyledInputBox.propTypes = {
  size: PropTypes.string,
  isDisabled: PropTypes.bool,
};

StyledInputBox.defaultProps = {
  size: 'normal',
  isDisabled: false,
};

export default StyledInputBox;
