export const MANAGE_USERS = 'users_manage_team';
export const MANAGE_ORGANIZATIONS = 'organizations_manage';
export const MANAGE_SALES_REPS = 'manage_sales_reps';
export const PLACE_ORDERS_IN_AT_ONCE_MODE = 'checkout_atonce';
export const PLACE_ORDERS_IN_BOOKING_MODE = 'checkout_booking';
export const PLACE_PROXY_ORDER = 'orders_place_proxy';
export const MANAGE_ALLOTMENTS = 'allotments_manage_terms';
export const VIEW_ALLOTMENTS = 'allotments_view';
export const TRANSFER_ALLOTMENTS = 'allotments_transfer';
export const MANAGE_LICENSEE_ORDERS = 'can_manage_licensee_orders';
export const VIEW_ALL_ORDERS = 'orders_view_all';

const AT_ONCE = 'atonce';

const BOOKING = 'booking';

export const hasShoppingModePermission = (
  shoppingMode: string,
  hasPermission: (permission: string | null) => boolean,
) => {
  let permissionRequired = null;
  if (shoppingMode.toLowerCase() === AT_ONCE) {
    permissionRequired = PLACE_ORDERS_IN_AT_ONCE_MODE;
  }
  if (shoppingMode.toLowerCase() === BOOKING) {
    permissionRequired = PLACE_ORDERS_IN_BOOKING_MODE;
  }

  return hasPermission(permissionRequired);
};
