import styled from 'styled-components';
import { touchScreenClickable } from 'styles/mixins';

export const Above = styled.div`
  position: relative;
  z-index: 3;
`;

export const ScreenElement = styled.div`
  bottom: ${props => (props.visible ? '0' : 'auto')};
  opacity: ${props => (props.enabled ? 1 : 0)};
  background-color: rgba(0, 0, 0, 0.465);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  transition: opacity 300ms ease;

  ${touchScreenClickable};
`;

export const Below = styled.div`
  position: relative;
  z-index: 1;
`;
