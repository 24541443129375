import { handle } from 'redux-pack';
import {
  applySpec,
  assoc,
  compose,
  defaultTo,
  fromPairs,
  head,
  map,
  mergeDeepLeft,
  path,
  pathOr,
  pipe,
  prop,
} from 'ramda';
import { parseCSV } from 'lib/csv';
import { mungeFitGuide } from './fit-guides';

export const FETCH = 'size-guides/FETCH';
export const FETCH_ALL = 'size-guides/FETCH_ALL';

const initialState = {
  error: null,
  fetching: false,
  byId: {},
};

export const fetchSizeGuide = chartId => ({
  type: FETCH,
  meta: {
    chartId,
    contentfulRequest: {
      content_type: 'sizeChart',
      'fields.chartId': chartId,
      include: 2,
    },
  },
});

export const fetchAllSizeGuides = () => ({
  type: FETCH_ALL,
  meta: {
    contentfulRequest: {
      content_type: 'sizeChart',
      include: 2,
    },
  },
});

const mungeSizeGuide = compose(
  applySpec({
    imperialMeasurements: compose(parseCSV, prop('imperialMeasurements')),
    metricMeasurements: compose(parseCSV, prop('metricMeasurements')),
    chartId: prop('chartId'),
    measurementText: prop('measurementText'),
    category: prop('category'),
    gender: prop('gender'),
    clothingType: prop('clothingType'),
    rank: prop('rank'),
    fitChart: sizeGuide => mungeFitGuide(prop('fitGuide', sizeGuide)),
    measurementImage: path(['measurementImage', 'fields', 'file', 'url']),
    icon: path(['icon', 'fields', 'file', 'url']),
  }),
  prop('fields'),
);

const mungeFirstSizeGuide = compose(
  defaultTo({ missing: true }),
  head,
  map(mungeSizeGuide),
  prop('items'),
);

export const reducer = (state = initialState, action) => {
  const { type, meta, payload } = action;
  switch (type) {
    case FETCH:
      return handle(state, action, {
        start: mergeDeepLeft({ error: null, fetching: true }),
        success: prevState =>
          mergeDeepLeft({
            byId: {
              [meta.chartId]: mungeFirstSizeGuide(payload),
            },
          })(prevState),
        failure: prevState =>
          mergeDeepLeft({
            error: payload.response.data.message,
          })(prevState),
        finish: mergeDeepLeft({ fetching: false }),
      });

    case FETCH_ALL:
      return handle(state, action, {
        start: mergeDeepLeft({ error: null, fetching: true }),
        success: prevState =>
          assoc(
            'byId',
            pipe(
              pathOr([], ['items']),
              map(item => [item.fields.chartId, mungeSizeGuide(item)]),
              fromPairs,
            )(payload),
            prevState,
          ),
        failure: prevState =>
          mergeDeepLeft({
            error: payload.response.data.message,
          })(prevState),
        finish: mergeDeepLeft({ fetching: false }),
      });

    default:
      return state;
  }
};

export default reducer;
