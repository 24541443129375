import { handle } from 'redux-pack';
import { path, evolve, groupBy, map, pipe, pick, prop } from 'ramda';

export const FETCH_TECHNOLOGIES = 'technologies/FETCH';

const initialState = {};

const mungeTechnology = pipe(
  prop('fields'),
  pick(['id', 'name', 'tagline', 'description', 'href', 'category', 'image']),
  evolve({
    image: path(['fields', 'file', 'url']),
  }),
);

const mungeTechnologies = pipe(
  prop('items'),
  map(mungeTechnology),
  groupBy(prop('category')),
);

export const fetchTechnologies = () => ({
  type: FETCH_TECHNOLOGIES,
  meta: {
    contentfulRequest: {
      content_type: 'technology',
      order: 'sys.createdAt',
    },
  },
});

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TECHNOLOGIES:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          ...mungeTechnologies(payload),
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.response.data.message,
        }),
      });

    default:
      return state;
  }
};

export default reducer;
