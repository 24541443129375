import { MaybeString, Account } from 'types';

export const noticeError = (error: Error, payload: any = {}) => {
  (async function _noticeError(){
    const Sentry = await import('@sentry/browser');
    Sentry.withScope(scope => {
      scope.setExtra("extra", payload)
      Sentry.captureException(error);
    })
  })()
};

export type ErrorContext = {
  userID?: MaybeString,
  email?: MaybeString,
  account: Account,
}

export const setErrorContext: (ctx: ErrorContext) => void = ({ userID, email, account }) => {
  (async function _setErrorContext(){
    const Sentry = await import('@sentry/browser');
    if (userID) {
      Sentry.setUser({
        id: userID,
        ...(email ? { email: email.toLowerCase() }: null)
      })
    }
    if (account) {
      Sentry.setTags({
        salesOrg: account.salesOrgCode,
        accountNumber: account.number,
      })
    }
  })()

};

export default noticeError;
