import styled from 'styled-components';
import { borders, colors, fonts, spacing, breakpoint } from 'lib/theme';
import { ellipsis, media } from 'styles/mixins';

export const Layout = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const AccountTrigger = styled.div`
  align-items: center;
  border-right: ${borders.hairline};
  cursor: pointer;
  display: flex;
  height: 100%;
  padding-left: ${spacing.m};
  padding-right: ${spacing.xxl};
  text-decoration: none;
`;

export const AccountLabel = styled.div``;

export const AccountMode = styled.div`
  color: ${colors.black};
  font: ${fonts.regular};
  padding-left: ${spacing.m};
  line-height: 1.4;
  text-align: right;
`;

export const AccountIdentity = styled.div`
  color: ${colors.black};
  font: ${fonts.heavy};
  max-width: 150px;
  ${ellipsis};
  padding-left: ${spacing.m};
  line-height: 1.4;
  text-align: right;

  ${media(breakpoint.medium)`
    max-width: 100%;
  `};
`;
