import gql from 'graphql-tag';

export const SET = 'user/SET';
export const SAVE_LOCALE_TO_SERVER = 'user/SAVE_LOCALE_TO_SERVER';
export const SUBMIT_CHANGES = 'user/SUBMIT_CHANGES';

const UPDATE_LOCALE = gql`
  mutation updateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        locale
      }
      error {
        id
        msg
      }
    }
  }
`;

export const setUser = profile => ({
  type: SET,
  meta: {
    profile,
  },
});

export const saveLocaleToServer = (locale, userId) => ({
  type: SAVE_LOCALE_TO_SERVER,
  promise: import('index').then(({ graphqlClient }) =>
    graphqlClient.mutate({
      mutation: UPDATE_LOCALE,
      variables: {
        input: {
          user: {
            userId,
            locale,
          },
        },
      },
    }),
  ),
});

export const submitChanges = changes => ({
  type: SUBMIT_CHANGES,
  meta: {
    changes,
  },
});

const initialState = {
  profile: { showCustomerPricingOnly: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.meta.profile,
        },
      };
    case SUBMIT_CHANGES:
      return {
        ...state,
        profile: { ...state.profile, ...action.meta.changes },
      };

    default:
      return state;
  }
};
