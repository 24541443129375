import * as React from 'react';
import gql from 'graphql-tag';
import connectIntl from 'lib/connect-intl';
import {
  DynamicFilter,
  DynamicFilterProps,
  Option,
} from 'lib/components/DynamicFilter';

// TODO: Generate
type AccountNode = {
  id: string;
  accountName: string;
  accountNumber: string;
};
// TODO: Generate
type AccountQuery = {
  currentUser: {
    accounts: Connection<AccountNode>;
  };
};

const AccountNodeFragment = gql`
  fragment AccountFilter__NodeFragment on Account {
    id
    accountName
    accountNumber
  }
`;

const ACCOUNTS_QUERY = gql`
  query AccountFilter_accounts(
    $searchText: String
    $organizationId: ID
    $after: String
    $first: Int
  ) {
    currentUser {
      id
      accounts(
        searchText: $searchText
        organizationId: $organizationId
        first: $first
        after: $after
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            ...AccountFilter__NodeFragment
          }
        }
      }
    }
  }
  ${AccountNodeFragment}
`;

const AccountFilterImpl: React.FC<DynamicFilterProps<
  AccountQuery,
  AccountNode
>> = props => <DynamicFilter {...props} />;

const displayOption = (opt: Option) => {
  let display = '-';
  if (opt) {
    display = opt.title || display;
    if (opt.subtitle) {
      display += ` (${opt.subtitle})`;
    }
  }
  return display;
};

const optionFromNode: (node: AccountNode) => Option = node => ({
  id: node?.id,
  title: node?.accountName,
  subtitle: node?.accountNumber,
});

const AccountFilter = ({ selectedAccount, onSelectAccount, ...props }: any) => (
  <AccountFilterImpl
    {...props}
    query={ACCOUNTS_QUERY}
    getConnection={data => data?.currentUser?.accounts}
    onChange={onSelectAccount}
    optionToString={displayOption}
    optionFromNode={optionFromNode}
    nodeID={selectedAccount && selectedAccount.id}
    nodeFragment={AccountNodeFragment}
  />
);

const mapIntlToProps = ({ formatMessage }: any) => ({
  filterPlaceholder: formatMessage({ id: 'accounts.search-accounts' }),
  label: formatMessage({ id: 'home.my-account' }),
  loadingMessage: formatMessage({ id: 'accounts.loading' }),
  noResultsMessage: formatMessage({ id: 'accounts.no-results' }),
  errorLoadingMessage: formatMessage({ id: 'accounts.error' }),
});

export default connectIntl(mapIntlToProps)(AccountFilter);
