import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useMatchMedia from 'lib/use-match-media';
import { useUserContext } from 'lib/use-user-context';
import logo from 'lib/logo-inverted.svg';
import { breakpoint, colors, columnWidth, fonts, spacing } from 'lib/theme';
import {
  MANAGE_USERS,
  VIEW_ALLOTMENTS,
  MANAGE_ORGANIZATIONS,
  MANAGE_SALES_REPS,
} from 'lib/permissions';
import {
  getSelectedAccountId,
  getSelectedOrganizationId,
} from 'selectors/account';
import { defaultOrdersUrl, defaultRostersUrl } from 'lib/links';
import { media, maxWidthMedia } from 'styles/mixins';

const Header = styled.header`
  background-color: ${colors.black};
  border-color: ${colors.offWhite};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  align-items: center;
  border-top: 1px solid #5a5a5a;
  display: flex;
  flex-direction: row;
  height: 70px;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    justify-content: center;
    height: ${spacing.gd.lg};
  `};
`;

const LogoLink = styled(Link)`
  width: ${columnWidth};
  display: block;

  ${media(`${breakpoint.tablet}`)`
    padding-right: ${spacing.xl};
  `};
`;

const Logo = styled.img`
  align-items: center;
  display: flex;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    margin: auto;
    height: 18px;
    width: ${spacing.gd.md};
  `};
`;

const BackLink = styled(Link)`
  color: ${colors.white};
  font: ${fonts.extra};
  align-items: center;
  border: 1px solid ${colors.darkGrey};
  display: flex;
  height: 40px;
  padding: 0 30px;
  text-decoration: none;
  text-transform: uppercase;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    display: none;
  `};
`;

const Arrow = styled.div`
  margin-right: ${spacing.m};
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  border-bottom: 2px solid ${colors.white};
  border-left: 2px solid ${colors.white};
  transition: transform 0.1s ease-in-out;

  ${/* sc-sel */ BackLink}:hover & {
    transform: translateX(-2px) rotate(45deg);
  }
`;

const Nav = styled.nav`
  flex: 1;
`;

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
`;

const MenuItem = styled.li`
  color: ${colors.white};
  font: ${fonts.bold};
  text-transform: uppercase;

  ${media(breakpoint.tablet)`
    font: ${fonts.extra};
  `};

  & + & {
    margin-left: ${spacing.m};
    ${media(breakpoint.tablet)`
      margin-left: ${spacing.xl};
    `};
  }
`;

const MenuItemLink = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
`;

const AdminHeader = ({ accountId, organizationId }) => {
  const { isPublic, hasPermission } = useUserContext();
  const isMobile = useMatchMedia(`(max-width: ${breakpoint.gd.phoneMax})`);

  return (
    <Header>
      <FormattedMessage id="logo">
        {msg => (
          <LogoLink to="/">
            <Logo src={logo} alt={msg} />
          </LogoLink>
        )}
      </FormattedMessage>
      <BackLink to="/">
        <Arrow />
        <FormattedMessage id="secondary-menu.back" />
      </BackLink>
      {!isMobile && (
        <Nav>
          <Menu>
            <MenuItem>
              <MenuItemLink to="/carts">
                <FormattedMessage id="secondary-menu.carts" />
              </MenuItemLink>
            </MenuItem>
            {!isPublic && (
              <MenuItem>
                <MenuItemLink to="/catalogs">
                  <FormattedMessage id="secondary-menu.catalogs" />
                </MenuItemLink>
              </MenuItem>
            )}
            <MenuItem>
              <MenuItemLink
                to={defaultOrdersUrl({ accountId, organizationId })}
              >
                <FormattedMessage id="secondary-menu.orders" />
              </MenuItemLink>
            </MenuItem>
            {!isPublic && (
              <React.Fragment>
                <MenuItem>
                  <MenuItemLink
                    to={defaultRostersUrl({ accountId, organizationId })}
                  >
                    <FormattedMessage id="secondary-menu.rosters" />
                  </MenuItemLink>
                </MenuItem>
                {hasPermission(MANAGE_USERS) && (
                  <MenuItem>
                    <MenuItemLink to="/users">
                      <FormattedMessage id="secondary-menu.user-management" />
                    </MenuItemLink>
                  </MenuItem>
                )}
                {hasPermission(MANAGE_ORGANIZATIONS) && (
                  <MenuItem>
                    <MenuItemLink to="/organizations">
                      <FormattedMessage id="secondary-menu.organizations" />
                    </MenuItemLink>
                  </MenuItem>
                )}
                {hasPermission(MANAGE_SALES_REPS) && (
                  <MenuItem>
                    <MenuItemLink to="/salesreps">
                      <FormattedMessage id="secondary-menu.sales-reps" />
                    </MenuItemLink>
                  </MenuItem>
                )}
                {hasPermission(VIEW_ALLOTMENTS) && (
                  <MenuItem>
                    <MenuItemLink to="/allotments">
                      <FormattedMessage id="secondary-menu.allotments" />
                    </MenuItemLink>
                  </MenuItem>
                )}
              </React.Fragment>
            )}
          </Menu>
        </Nav>
      )}
    </Header>
  );
};

AdminHeader.propTypes = {
  accountId: PropTypes.string,
  organizationId: PropTypes.string,
};

AdminHeader.defaultProps = {
  accountId: null,
  organizationId: null,
};

const mapStateToProps = state => ({
  accountId: getSelectedAccountId(state),
  organizationId: getSelectedOrganizationId(state),
});

export default connect(mapStateToProps)(AdminHeader);
