import {
  allPass,
  anyPass,
  contains,
  descend,
  equals,
  filter,
  head,
  isEmpty,
  path,
  pathEq,
  pathOr,
  pipe,
  sortWith,
} from 'ramda';
import { isMissing } from 'lib/data-manipulation';
import { grafikiShoppingModes } from 'lib/user';

const getAppliesToValues = tag => item => {
  const { appliesTo } = item.fields;
  const tagFields = (appliesTo || []).filter(i => i.startsWith(`${tag}:`));
  return tagFields.map(field => field.split(':')[1]);
};

export const isTargetedForAccount = (account, shoppingMode) => items => {
  const { salesOrgCode, number, customerGroupCode, salesGroupCode } = account;

  const matchesSalesOrg = pathEq(['fields', 'salesOrg'], salesOrgCode);

  const hasNoAppliesTo = pipe(pathOr([], ['fields', 'appliesTo']), isEmpty);

  const satisfiesCustomerGroup = pipe(
    getAppliesToValues('cg'),
    contains(customerGroupCode),
  );

  const satisfiesAccount = pipe(getAppliesToValues('ac'), contains(number));

  const satisfiesSalesGroup = pipe(
    getAppliesToValues('sg'),
    contains(salesGroupCode),
  );

  const satisfiesShoppingMode = pipe(
    path(['fields', 'shoppingMode']),
    anyPass([isMissing, equals(grafikiShoppingModes[shoppingMode])]),
  );

  return allPass([
    matchesSalesOrg,
    satisfiesShoppingMode,
    anyPass([
      hasNoAppliesTo,
      satisfiesCustomerGroup,
      satisfiesAccount,
      satisfiesSalesGroup,
    ]),
  ])(items);
};

export const rankBySpecificity = (account, shoppingMode) => items => {
  const { number, customerGroupCode, salesGroupCode } = account;

  const matchesAccount = pipe(getAppliesToValues('ac'), contains(number));

  const matchesCustomerGroup = pipe(
    getAppliesToValues('cg'),
    contains(customerGroupCode),
  );

  const matchesSalesGroup = pipe(
    getAppliesToValues('sg'),
    contains(salesGroupCode),
  );

  const matchesShoppingMode = pipe(
    path(['fields', 'shoppingMode']),
    equals(grafikiShoppingModes[shoppingMode]),
  );

  const descAll = pipe(allPass, descend);

  return sortWith([
    descAll([matchesAccount, matchesShoppingMode]),
    descend(matchesAccount),
    descAll([matchesCustomerGroup, matchesShoppingMode, matchesSalesGroup]),
    descend(matchesCustomerGroup),
    descend(matchesSalesGroup),
    descend(matchesShoppingMode),
  ])(items);
};

export const getTargetedItems = (account, shoppingMode) =>
  pipe(
    filter(isTargetedForAccount(account, shoppingMode)),
    rankBySpecificity(account, shoppingMode),
  );

export const getPrimaryTargetedItem = (account, shoppingMode, log) =>
  pipe(getTargetedItems(account, shoppingMode), log.recordRankedPages, head);
