import { isEmpty } from 'ramda';
import { forceArray } from 'lib/data-manipulation';
import { displayDate } from 'lib/dates';
import { Maybe, Map, WeakMap } from '../types';


/*
  { account: "234", id: "1234" } -> "?account=234&id=1234"
  { account: undefined, id: "1234" } -> "?id=1234"
  { account: null, id: "1234" } -> "?id=1234"
  { account: null, id: undefined } -> ""
  {} -> ""
 */
export const queryString = (input: WeakMap<string | Date | number>): string => {
  const params = new URLSearchParams();
  Object.entries(input).forEach(([key, value]) => {
    if (value) {
      const type = typeof value;
      if (type === 'string' || type === 'number') {
        params.set(key, value.toString());
      } else {
        params.set(key, displayDate(value))
      }

    }
  });
  return isEmpty(params.toString()) ? '' : `?${params}`;
};

/*
  "account=234&id=1234" -> { account: "234", id: "1234" }
  "account=234&id" -> { account: "234" }
  "account&id" -> {}
  "account=a&account=b" -> {account:["a", "b"]}
  "account=a,b" -> { account: ["a", "b"]}
  "" -> {}
 */
export const getParams = (queryParams: Maybe<string>): Map<string | string[]> => {
  const obj = {} as Map<string | string[]>;
  if (queryParams) {
    const params = new URLSearchParams(queryParams);
    params.forEach((value, key) => {
      if (!isEmpty(value)) {
        if (obj[key]) {
          obj[key] = [...forceArray(obj[key]), value]
        } else if (value.includes(",")) {
          obj[key] = value.split(",");
        } else {
          obj[key] = value
        }
      }
    });
  }
  return obj;
};
