import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { chooseLocale } from 'ducks/locale';
import { fetchLoginImage } from 'ducks/login-image';
import { login } from 'ducks/authentication';
import localStorageSupported from 'lib/local-storage-supported';
import { supportedLocales } from 'lib/locales';
import analytics from 'lib/analytics';
import { isPresent } from 'lib/data-manipulation';
import logo from 'lib/logo.svg';
import { colors, spacing, fonts, breakpoint, fontSizes } from 'lib/theme';
import { useFormatMessage } from 'lib/use-format-message';
import { useTestIDs } from 'lib/test-hooks';
import { isIntlSupported } from 'selectors/locale';
import InputIntl from 'components/forms/input-intl';
import { Row } from 'components/forms/fieldset';
import { getActiveShoppingMode } from 'selectors/account';
import { TextLink } from '../ui/links';
import { Button } from '../ui/button';
import * as t from '../typography';

const Container = styled.div`
  display: flex;

  @media (max-width: ${breakpoint.tabletMax}) {
    min-height: calc(100vh - 40px);
    padding-top: 40px;
  }

  @media (min-width: ${breakpoint.desktop}) {
    flex: 1;
  }
`;

const InteractiveArea = styled.div`
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 84px;
  margin: 0 auto;
`;

const MiniForm = styled.form`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: ${spacing.xxl} 0;
`;

const LoginButton = styled(Button)`
  width: 100%;
`;

const SignUpContainer = styled.div`
  font: ${fonts.regular};
  text-align: center;

  & > * {
    margin-bottom: 15px;
  }
`;

const LearnMore = styled.a`
  font: ${fonts.regular};
  text-decoration: underline;
  color: ${colors.black};
  transition: color 0.2s ease;
  display: block;

  &:hover {
    color: ${colors.grey};
  }
`;

const ForgotPasswordLinkContainer = styled.div`
  bottom: 37px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  float: right;
`;

const ForgotPasswordLink = styled(TextLink)`
  margin-right: ${spacing.m};
  font-weight: 100;
  text-decoration: underline;
  z-index: 1;
`;

const SplashImage = styled.img`
  @media (max-width: ${breakpoint.tabletMax}) {
    display: none;
  }

  @media (min-width: ${breakpoint.desktop}) {
    flex: 1;
    object-fit: cover;
  }
`;

const LocaleSelectorButton = styled.button`
  font: ${fonts.heavy};
  font-size: ${fontSizes.xlarge};
  margin-bottom: 10px;
  text-align: center;
  border: none;
  background: transparent;
  display: block;
  width: 100%;
  cursor: pointer;

  & span {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: -14px;
      width: 0;
      height: 0;
      border-top: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      display: inline-block;
      transition: transform 0.5s;
      ${({ showing }) =>
        showing ? 'transform: rotate(-180deg)' : 'transform: rotate(0deg)'};
    }
  }
`;

const LocaleList = styled.div`
  background-color: ${colors.white};
  overflow: hidden;
  transition: max-height 1s;
  ${({ showing }) => (showing ? 'max-height: 200px;' : 'max-height: 0')};
`;

const LocaleListItem = styled.button`
  color: ${colors.black};
  font: ${fonts.heavy};
  cursor: pointer;
  border: none;
  display: block;
  padding: 0;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &:active {
    color: ${colors.grey};
  }
`;

const SignUp = () => {
  const formatMessage = useFormatMessage();
  const loginText = formatMessage('login.no-account-link');

  return (
    <SignUpContainer>
      <div>
        <FormattedMessage id="login.no-account-na" />
        <LearnMore
          href="http://www.uaallaccess.com/home.aspx"
          target="_blank"
          rel="noopener"
        >
          {loginText}
        </LearnMore>
      </div>

      <div>
        <FormattedMessage id="login.no-account-eu" />
        <LearnMore
          href="http://www.uaallaccess.com/home.aspx"
          target="_blank"
          rel="noopener"
        >
          {loginText}
        </LearnMore>
      </div>
    </SignUpContainer>
  );
};

const Form = ({ waiting, error, onSubmit }) => {
  const [formState, setFormState] = useState({
    username: '',
    password: '',
    loginProblemShown: false,
  });

  const handleInputChange = event => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = event => {
    const { username, password } = formState;
    event.preventDefault();
    onSubmit(username, password);
    setFormState({ ...formState, loginProblemShown: true });
  };

  const localStorageDisabled = !localStorageSupported();
  const { username, password, loginProblemShown } = formState;
  const [usernameTestProp, passwordTestProp, loginBtnTestProp] = useTestIDs(
    'login-username',
    'login-password',
    'login-button',
  );

  return (
    <MiniForm onSubmit={handleSubmit}>
      {localStorageDisabled && (
        <div>
          <FormattedMessage id="login.local-storage-warning" />
        </div>
      )}
      <Row>
        <InputIntl
          id="loginUsername"
          label="login.username-label"
          placeholder="login.username-placeholder"
          type="text"
          name="username"
          autoComplete="username"
          onChange={handleInputChange}
          value={username}
          disabled={localStorageDisabled}
          {...usernameTestProp}
        />
      </Row>
      <Row>
        <InputIntl
          id="loginPassword"
          label="password-label"
          placeholer="password-placeholder"
          autoComplete="current-password"
          type="password"
          name="password"
          value={password}
          onChange={handleInputChange}
          disabled={localStorageDisabled}
          {...passwordTestProp}
        />
        <ForgotPasswordLinkContainer>
          <ForgotPasswordLink to="/forgot-password">
            <FormattedMessage id="forgot-password.forgot-password-link" />
          </ForgotPasswordLink>
        </ForgotPasswordLinkContainer>
      </Row>
      <FormattedMessage id={`login.${waiting ? 'logging-in' : 'login'}-button`}>
        {intlText => (
          <LoginButton
            {...loginBtnTestProp}
            size="normal"
            value="Login"
            disabled={localStorageDisabled || waiting}
          >
            {intlText}
          </LoginButton>
        )}
      </FormattedMessage>
      {loginProblemShown && error && (
        <div>
          <t.ErrorText large={true}>
            <FormattedMessage id="login.failure" />
          </t.ErrorText>
        </div>
      )}
    </MiniForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  waiting: PropTypes.bool,
};

Form.defaultProps = {
  error: null,
  waiting: false,
};

const LocalePicker = ({ intlSupported, onChooseLocale }) => {
  const [localeSelectorVisible, setLocaleSelectorVisible] = useState(false);

  const currentLocaleClick = event => {
    event.preventDefault();
    setLocaleSelectorVisible(!localeSelectorVisible);
  };

  return (
    <React.Fragment>
      <LocaleSelectorButton
        onClick={currentLocaleClick}
        showing={localeSelectorVisible}
        aria-controls="locale-list"
      >
        <FormattedMessage id="login.locale-select" />
      </LocaleSelectorButton>
      <LocaleList id="locale-list" showing={localeSelectorVisible}>
        {supportedLocales.map(({ code, label }) => (
          <LocaleListItem
            key={code}
            onClick={() => onChooseLocale(code, !intlSupported)}
          >
            {label}
          </LocaleListItem>
        ))}
      </LocaleList>
    </React.Fragment>
  );
};

LocalePicker.propTypes = {
  onChooseLocale: PropTypes.func.isRequired,
  intlSupported: PropTypes.bool.isRequired,
};

export const LoginForm = ({
  loginImage,
  waiting,
  error,
  intlSupported,
  actions,
}) => {
  const shoppingMode = useSelector(state => getActiveShoppingMode(state));
  useEffect(() => {
    if (!loginImage) {
      actions.fetchLoginImage();
    }
    if (loginImage) {
      analytics.logLoginImageView({ contentName: loginImage, shoppingMode });
    }
  }, [loginImage, actions, shoppingMode]);

  const submit = async (username, password) => {
    const response = await actions.login(username, password);

    if (response.error) {
      analytics.logLoginFail();
    } else {
      analytics.logLogin();
    }
  };

  return (
    <Container>
      <InteractiveArea>
        <Logo src={logo} alt="Under Armour" />
        <Form waiting={waiting} error={error} onSubmit={submit} />
        <SignUp />
        <LocalePicker
          onChooseLocale={actions.chooseLocale}
          intlSupported={intlSupported}
        />
      </InteractiveArea>
      {isPresent(loginImage) && <SplashImage src={loginImage} />}
    </Container>
  );
};

LoginForm.propTypes = {
  actions: PropTypes.shape({
    login: PropTypes.func.isRequired,
    fetchLoginImage: PropTypes.func.isRequired,
    chooseLocale: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string,
  loginImage: PropTypes.string,
  waiting: PropTypes.bool,
  intlSupported: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
  error: null,
  loginImage: '',
  waiting: false,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { login, fetchLoginImage, chooseLocale },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  error: state.authentication.error,
  loginImage: pathOr('', ['loginImage', 'image'], state),
  waiting: state.authentication.waiting,
  intlSupported: isIntlSupported(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
