import { isNil, indexBy, prop } from 'ramda';

export default (initialState, ducklings) => {
  const byType = indexBy(prop('type'), ducklings);

  return (state = initialState, action) => {
    const duckling = byType[action.type];

    if (isNil(duckling)) {
      return state;
    }

    return duckling.reducer(state, action);
  };
};
