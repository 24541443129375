import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts, inputHeights, spacing, weights } from 'lib/theme';
import Arrow from './arrow';
import Icon from './icon';

const buttonPadding = {
  normal: '0 60px',
  small: '0 40px',
};

export const getButtonStyles = root => (root ? styled(root) : styled.button)`
  color: ${colors.white};
  background-color: ${colors.black};
  border: none;
  cursor: pointer;
  display: inline-block;
  font: bold 13px/1 'Neue Plak Bold', sans-serif;
  height: ${({ size = 'small' }) => inputHeights[size]};
  padding: ${({ size = 'small' }) => buttonPadding[size]};
  outline: none;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;

  &:focus {
    color: ${colors.grey};
  }

  &:hover {
    color: ${colors.grey};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const Button = getButtonStyles();

Button.propTypes = {
  size: PropTypes.string,
};

Button.defaultProps = {
  size: 'small',
};

export const TransparentButton = styled.button`
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

TransparentButton.propTypes = {
  type: PropTypes.string,
};

TransparentButton.defaultProps = {
  type: 'button',
};

export const IconButton = styled.button`
  display: inline-flex;
  border: none;
  background-color: transparent;
  background-size: auto 16px;
  background-position: center left;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  font: ${fonts.heavy};
  margin-right: 40px;
  padding: 10px 0 10px 30px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
`;

export const EditButton = styled(IconButton)`
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxN3B4IiBoZWlnaHQ9IjE3cHgiIHZpZXdCb3g9IjAgMCAxNyAxNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5FZGl0PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IkNhcnRzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9IkNhcnQtRGV0YWlscyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM5LjAwMDAwMCwgLTI0My4wMDAwMDApIiBzdHJva2U9IiM3Njc2NzYiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDAuMDAwMDAwLCAyNDMuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC05LUNvcHkiPiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9IkVkaXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAxLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00LjY0NCwxNC40MjQyNjY3IEwwLjMzMzMzMzMzMywxNS40MzQ5MzMzIEwxLjM0NCwxMS4xMjQyNjY3IEwxMS43NDQ2NjY3LDAuNzIzNiBDMTIuMjY1MzMzMywwLjIwMjkzMzMzMyAxMy4xMSwwLjIwMjkzMzMzMyAxMy42MywwLjcyMzYgTDE1LjA0NDY2NjcsMi4xMzgyNjY2NyBDMTUuNTY1MzMzMywyLjY1ODkzMzMzIDE1LjU2NTMzMzMsMy41MDI5MzMzMyAxNS4wNDQ2NjY3LDQuMDIzNiBMNC42NDQsMTQuNDI0MjY2NyBaIiBpZD0iU3Ryb2tlLTEiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTQuNTc1LDQuNDk2ODY2NjcgTDExLjI3MywxLjE5NDg2NjY3IiBpZD0iU3Ryb2tlLTMiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMuNjIzODY2Nyw1LjQ0ODA2NjY3IEwxMC4zMzA1MzMzLDIuMTM4MDY2NjciIGlkPSJTdHJva2UtNSI+PC9wYXRoPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00LjgxMiwxNC4yNTYzMzMzIEwxLjUxMiwxMC45NTYzMzMzIiBpZD0iU3Ryb2tlLTciPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==');
`;

export const getBorderButtonStyled = root => styled(
  root ? getButtonStyles(root) : Button,
)`
  background-color: ${colors.white};
  color: ${colors.black};
  border: 1px solid black;
  min-width: auto;
  padding: 0 30px;
`;

export const BorderButton = getBorderButtonStyled();

export const NoBackgroundButton = styled(Button)`
  color: ${colors.black};
  background: none;
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;
  right: ${spacing.xl};
  top: calc(${spacing.xl} + ${spacing.s});
  width: 26px;
`;

export const MinimumQuantityButton = styled(NoBackgroundButton)`
  color: ${colors.darkGrey};
  font: ${fonts.regular};
  text-transform: none;
  padding: 0px;

  & :hover {
    text-decoration: underline;
  }
`;

export const IndentedButton = styled(Button)`
  margin-left: ${spacing.m};
`;

export const ButtonArrow = styled(Arrow)`
  margin-left: 0;
`;

export const LinkButton = styled(NoBackgroundButton)`
  font-weight: ${weights.light};
  padding: 0;
  text-transform: none;
  text-decoration: underline;
`;

export const CancelIconButton = styled(IconButton)`
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+UGFnZSAxPC90aXRsZT48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIHN0cm9rZT0iIzc2NzY3NiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMi40MiAxMy40OTFoOC4xOVYyLjg5NkgyLjQyeiIvPjxwYXRoIGQ9Ik00LjQyNyAzLjUzOGg0LjU5OXYtMi4xMmgtNC42ek0uNjA3IDMuNTE0aDExLjk3NSIvPjwvZz48cGF0aCBmaWxsPSIjNzY3Njc2IiBkPSJNMy45NzUgNS4wNjNoMS4wOXY2Ljg5NGgtMS4wOXpNNi4wNCA1LjA2M2guOTkydjYuOUg2LjA0ek04LjAyNiA1LjA2M2guOTUzdjYuODY2aC0uOTUzeiIvPjwvZz48L3N2Zz4=');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border: none;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;
`;

export const CartEditIconButton = styled(CancelIconButton)`
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNCAxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+RWRpdDwvdGl0bGU+PGcgc3Ryb2tlPSIjNzY3Njc2IiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBhdGggZD0iTTQuNTYgMTIuNzJsLTMuMzA0LjgyLjc3NC0zLjUwMiA3Ljk3Mi04LjQ1YS45ODEuOTgxIDAgMCAxIDEuNDQ2IDBsMS4wODQgMS4xNWMuMzk5LjQyMi4zOTkgMS4xMDggMCAxLjUzMUw0LjU2IDEyLjcyek0xMi4xNzIgNC42NTRMOS42NCAxLjk3TTExLjQ0MyA1LjQyN2wtMi41MjUtMi42OU00LjY4OCAxMi41ODNMMi4xNiA5LjkwMiIvPjwvZz48L3N2Zz4=');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
`;

export const EditIconButton = styled(CancelIconButton)`
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxN3B4IiBoZWlnaHQ9IjE3cHgiIHZpZXdCb3g9IjAgMCAxNyAxNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5FZGl0PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IkNhcnRzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9IkNhcnQtRGV0YWlscyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM5LjAwMDAwMCwgLTI0My4wMDAwMDApIiBzdHJva2U9IiM3Njc2NzYiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDAuMDAwMDAwLCAyNDMuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC05LUNvcHkiPiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9IkVkaXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAxLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00LjY0NCwxNC40MjQyNjY3IEwwLjMzMzMzMzMzMywxNS40MzQ5MzMzIEwxLjM0NCwxMS4xMjQyNjY3IEwxMS43NDQ2NjY3LDAuNzIzNiBDMTIuMjY1MzMzMywwLjIwMjkzMzMzMyAxMy4xMSwwLjIwMjkzMzMzMyAxMy42MywwLjcyMzYgTDE1LjA0NDY2NjcsMi4xMzgyNjY2NyBDMTUuNTY1MzMzMywyLjY1ODkzMzMzIDE1LjU2NTMzMzMsMy41MDI5MzMzMyAxNS4wNDQ2NjY3LDQuMDIzNiBMNC42NDQsMTQuNDI0MjY2NyBaIiBpZD0iU3Ryb2tlLTEiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTQuNTc1LDQuNDk2ODY2NjcgTDExLjI3MywxLjE5NDg2NjY3IiBpZD0iU3Ryb2tlLTMiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMuNjIzODY2Nyw1LjQ0ODA2NjY3IEwxMC4zMzA1MzMzLDIuMTM4MDY2NjciIGlkPSJTdHJva2UtNSI+PC9wYXRoPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00LjgxMiwxNC4yNTYzMzMzIEwxLjUxMiwxMC45NTYzMzMzIiBpZD0iU3Ryb2tlLTciPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
`;

const NoBackgroundIconButtonText = styled.span`
  font: ${fonts.heavy};
  text-transform: none;
`;

export const NoBackgroundIconButton = ({
  icon,
  onClick = () => {},
  children = null,
  alt,
  ...restProps
}) => {
  const StyledNoBackgroundIconButton = styled(NoBackgroundButton)`
    padding: 0;
  `;
  return (
    <StyledNoBackgroundIconButton
      onClick={onClick}
      title={alt}
      type="button"
      {...restProps}
    >
      <Icon src={icon} alt={alt} />
      <NoBackgroundIconButtonText>{children}</NoBackgroundIconButtonText>
    </StyledNoBackgroundIconButton>
  );
};

NoBackgroundIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.string,
  alt: PropTypes.string,
};

NoBackgroundIconButton.defaultProps = {
  onClick: () => {},
  children: null,
  alt: null,
};
