import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from 'lib/theme';

const Container = styled.label`
  display: ${({ updatedStyling }) => (updatedStyling ? 'flex' : 'inline-flex')};
  flex-direction: row;
  align-items: center;
  padding-bottom: ${({ updatedStyling }) => (updatedStyling ? spacing.s : 0)};
  padding-top: ${({ updatedStyling }) => (updatedStyling ? spacing.s : 0)};
  ${({ isClickable }) => isClickable && 'cursor: pointer;'};
`;

const Label = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: ${({ updatedStyling }) =>
    updatedStyling ? 'flex-start' : 'center'};
  margin-left: ${spacing.m};
`;

const CheckerLayout = ({ checker, label, isClickable, updatedStyling }) => (
  <Container isClickable={isClickable} updatedStyling={updatedStyling}>
    <div>{checker}</div>
    <Label updatedStyling={updatedStyling}>{label}</Label>
  </Container>
);

CheckerLayout.propTypes = {
  checker: PropTypes.node.isRequired,
  isClickable: PropTypes.bool,
  label: PropTypes.node.isRequired,
  updatedStyling: PropTypes.bool,
};

CheckerLayout.defaultProps = {
  isClickable: true,
  updatedStyling: false,
};

export default CheckerLayout;
