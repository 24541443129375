export default function ieVersion() {
  const agent = window.navigator.userAgent;
  const iMsie = agent.indexOf('MSIE');

  if (iMsie > 0)
    return parseInt(agent.substring(iMsie + 5, agent.indexOf('.', iMsie)), 10);

  if (agent.match(/Trident\/7\./)) return 11;

  return 0; // not IE
}
