import { createSelector } from 'reselect';
import { path, compose, map, pathOr } from 'ramda';
import { tryParseDate } from 'lib/dates';
import { grafikiShoppingModes } from 'lib/user';

export const getActiveShoppingMode = state =>
  state.authentication.activeShoppingMode;

export const getGrafikiActiveShoppingMode = state =>
  grafikiShoppingModes[getActiveShoppingMode(state)];

export const getSelectedAccount = state =>
  pathOr({}, ['authentication', 'selectedAccount'], state);

export const getAccessToken = state =>
  path(['authentication', 'access', 'token'], state);

export const getSelectedAccountId = state => {
  const { id } = getSelectedAccount(state);
  return id;
};

export const getInventorySegmentCode = compose(
  path(['inventorySegmentCode']),
  getSelectedAccount,
);

export const getAccountById = (state, accountId) =>
  path(['authentication', 'accounts', accountId], state);

export const getSelectedOrganization = compose(
  path(['organization']),
  getSelectedAccount,
);
export const getSelectedOrganizationId = compose(
  path(['id']),
  getSelectedOrganization,
);

const getSelectedAccountWithOverride = (state, { account = null } = {}) =>
  account || getSelectedAccount(state);

const getActiveShoppingModeWithOverride = (
  state,
  { shoppingMode = null } = {},
) => shoppingMode || getActiveShoppingMode(state);

export const getAvailableShoppingModes = createSelector(
  [getSelectedAccountWithOverride],
  acct =>
    acct && acct.availableShoppingModes
      ? Object.keys(acct.availableShoppingModes).sort()
      : [],
);

export const getAvailableShoppingModesWithDateRanges = createSelector(
  [getSelectedAccountWithOverride],
  acct =>
    map(
      dateRange => ({
        firstShipOn: dateRange && tryParseDate(dateRange.firstShipOn),
        lastShipOn: dateRange && tryParseDate(dateRange.lastShipOn),
      }),
      path(['availableShoppingModes'], acct) || {},
    ),
);

export const getShippingDateRange = createSelector(
  getAvailableShoppingModesWithDateRanges,
  getActiveShoppingModeWithOverride,
  (shoppingModes, shoppingMode) => shoppingModes[shoppingMode],
);

export const getDefaultShipOn = createSelector(
  getShippingDateRange,
  path(['firstShipOn']),
);

export const hasAccountError = state => !!state.authentication.accountError;

const getSearchConfigs = state => state.search.configs;

export const getActiveSearchConfig = createSelector(
  [getSearchConfigs, getSelectedAccount, getGrafikiActiveShoppingMode],
  (configs, acct, mode) => (acct ? path([acct.id, mode])(configs) : null),
);

export const getSearchConfig = (state, { accountId, shoppingMode }) =>
  path([accountId, shoppingMode])(getSearchConfigs(state));

export const getAccountChooserAccounts = ({
  authentication: { accounts, accountChooserIds },
}) => (accountChooserIds || []).map(id => accounts[id]);
