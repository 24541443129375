import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getLocale, isLocaleLoading, getMessages } from 'selectors/locale';
import App from './app';

export function Root({ locale, localeLoading, messages }) {
  return (
    locale &&
    !localeLoading && (
      <IntlProvider
        textComponent="span"
        locale={locale}
        key={locale}
        messages={messages}
      >
        <App />
      </IntlProvider>
    )
  );
}

Root.propTypes = {
  locale: PropTypes.string.isRequired,
  localeLoading: PropTypes.bool.isRequired,
  messages: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  const locale = getLocale(state);
  return {
    locale,
    messages: getMessages(state, locale),
    localeLoading: isLocaleLoading(state),
  };
};

export default connect(mapStateToProps)(Root);
