import { EventEmitter } from 'events';

class CookiePreferences extends EventEmitter {
  _domain: string;
  _interval: any = null;
  _privacyManager: any = null;

  constructor(domain: string = 'underarmour.com') {
    super();
    this._domain = domain;

    window.addEventListener(
      'message',
      msg => {
        try {
          const json = JSON.parse(msg.data);
          json.PrivacyManagerAPI &&
            this._handlePrivacyManagerAPIResponse(json.PrivacyManagerAPI);
        } catch (e) {
          e.name !== 'SyntaxError' && console.error(e);
        }
      },
      false,
    );
  }

  /**
   * Handles the responses from the TrustArc PrivacyManagerAPI
   *
   * This may emit the following events:
   * functional_approved
   * functional_denied
   *
   * @param response the response to handle from the PrivacyManagerAPI
   */
  _handlePrivacyManagerAPIResponse = (response: any) => {
    // Only process responses from the defined _domain
    if (!response.source || response.self !== this._domain) return;

    // We only want to emit events for functional cookies
    if (response.type === 'functional' && (response.source === 'implied' || response.source === 'asserted')) {
      return this.emit(`${response.type}_${response.consent}`);
    }

    // WARNING: TrustArc does not pass the type in the message passed after
    // setting preferences from the modal. This makes a direct request for that
    // consent until they update their script.
    return this.emit(`functional_${this._privacyManager.callApi(
      'getConsent',
      this._domain,
      null,
      null,
      'functional',
    ).consent}`);
  };

  /**
   * Initialize EventEmitter and wait for TrustArc PrivacyManagerAPI to exist.
   *
   * It will emit a `ready` event which can be used to perform actions prior to
   * asserting the users cookie preferences.
   *
   * Note: For implied workflows, this will cause a `functional_denied` event
   * to emit. This can be used to disable any implied tracking after initialized
   */
  initialize = () => {
    this._interval = setInterval(() => {
      if (!this._privacyManager && (window as any)?.PrivacyManagerAPI) {
        clearInterval(this._interval);
        this._privacyManager = (window as any).PrivacyManagerAPI;

        // Required cookies should be initialized before or on the ready event
        this.emit('ready');

        // This initiates a functional `getConsent` check for implied workflows
        window.top.postMessage(
          JSON.stringify({
            PrivacyManagerAPI: {
              self: this._domain,
              action: 'getConsent',
              timestamp: new Date().getTime(),
              type: 'functional',
            },
          }),
          '*',
        );
      }
    }, 10);
  };
}

export default CookiePreferences;
