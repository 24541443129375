import {
  any,
  complement,
  compose,
  concat,
  difference,
  pick,
  pickBy,
  pipe,
  reject,
  slice,
  values,
} from 'ramda';
import QueryString from 'query-string';
import {
  forceArray,
  isMissing,
  renameBy,
  symmetricDifference,
  mapObjIndexed,
} from './data-manipulation';

export const toggleOption = (allowMultiple, existingFilter, option) =>
  (allowMultiple ? symmetricDifference : difference)([option], existingFilter);

export const hasActiveFilters = (filters, sections) =>
  compose(any(complement(isMissing)), values, pick(sections))(filters);

const SIZE_PREFIX = 'size_';

const isSizeArg = (v, k) => k.startsWith(SIZE_PREFIX);

export const extractSizes = pipe(
  pickBy(isSizeArg),
  renameBy(slice(SIZE_PREFIX.length, Infinity)),
);

export const parseFilters = (genderNames, match, query) => {
  const { searchResultsPath = '' } = match.params;
  const [firstPiece, ...restPieces] = searchResultsPath.split('/');
  const singleGender = genderNames.indexOf(firstPiece) >= 0 ? firstPiece : null;
  const singleCategory =
    (singleGender ? restPieces.join('/') : searchResultsPath) || null;

  return {
    category: forceArray(singleCategory || query.category),
    colorGroup: forceArray(query.colorGroup),
    fit: forceArray(query.fit),
    gender: forceArray(singleGender || query.gender),
    size: extractSizes(query),
    sport: forceArray(query.sport),
    technology: forceArray(query.technology),
    customizable: forceArray(query.customizable),
  };
};

export const updatedSizeArgs = (query, { size }) => {
  if (!size) {
    return null;
  }

  const oldSizesRemoved = mapObjIndexed(() => null, pickBy(isSizeArg, query));
  const newSizes = renameBy(concat(SIZE_PREFIX), size);
  return { ...oldSizesRemoved, ...newSizes };
};

export const applyFiltersToUrl = (root, query, newFilters) => {
  const { category, gender, ...queryFilters } = newFilters;
  const singleGender = gender && gender.length === 1 ? gender[0] : null;
  const singleCategory = category && category.length === 1 ? category[0] : null;
  const path = reject(isMissing, [root, singleGender, singleCategory]).join(
    '/',
  );

  const newQuery = {
    ...query,
    ...queryFilters,
    ...(!singleGender && { gender }),
    ...(!singleCategory && { category }),
    ...updatedSizeArgs(query, newFilters),
  };
  const newQueryFiltered = pickBy(
    (v, k) =>
      !!v &&
      k !== 'page' &&
      k !== 'size' &&
      !(singleGender && k === 'gender') &&
      !(singleCategory && k === 'category'),
    newQuery,
  );
  const maybeQuestionMark = isMissing(newQueryFiltered) ? '' : '?';
  const stringifiedQuery = QueryString.stringify(newQueryFiltered);
  return `/${path}/${maybeQuestionMark}${stringifiedQuery}`;
};

export const urlForFilters = filters => applyFiltersToUrl('g', {}, filters);
