import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { equals } from 'ramda';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import logo from 'lib/logo-inverted.svg';
import { passwordErrorSwitch } from 'lib/user';
import { spacing, colors } from 'lib/theme';
import InputIntl from './forms/input-intl';
import { TextLink, ButtonLink } from './ui/links';
import * as t from './typography';
import { Button } from './ui/button';
import { Row } from './forms/fieldset';

const PageContainer = styled.div`
  background: ${colors.black};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MiniForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled(Row)`
  width: 100%;
`;

const ContentContainer = styled.div`
  background: ${colors.white};
  padding: ${spacing.xxxl};
  width: 500px;
`;

const FormFooter = styled.div`
  margin-top: ${spacing.xl};
  width: 100%;
`;

const ImageContainer = styled.div`
  margin-bottom: ${spacing.xxl};
  text-align: center;
`;

const Image = styled.img`
  width: 80px;
  display: inline-block;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const Confirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ConfirmationBackToLoginLink = styled(TextLink)`
  margin-left: ${spacing.s};
  line-height: 25px;
  text-transform: uppercase;
`;

export class ResetPassword extends Component {
  static propTypes = {
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
      mutate: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    newPassword: '',
    passwordConfirmation: '',
    resetPasswordFormError: false,
    errorCode: null,
    error: false,
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      location,
      client,
      match: {
        params: { resetToken },
      },
    } = this.props;
    const urlValues = QueryString.parse(location.search.replace(/\+/g, '%2B'));

    if (this.validatePasswords()) {
      const { newPassword, passwordConfirmation } = this.state;
      client
        .mutate({
          mutation: gql`
            mutation resetPassword($input: resetPasswordInput!) {
              resetPassword(input: $input) {
                user {
                  id
                }
                error {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              email: urlValues.email,
              password1: newPassword,
              password2: passwordConfirmation,
              resetToken: decodeURIComponent(resetToken),
              tokenExpiration: parseInt(urlValues.expiration, 10),
            },
          },
        })
        .then(
          status => {
            if (status.data.resetPassword.error) {
              this.setState({
                resetPasswordFormError: true,
                resetPasswordSubmitted: false,
                errorCode: status.data.resetPassword.error.id,
              });
            } else {
              this.setState({
                resetPasswordSubmitted: true,
              });
            }
          },
          () => {
            this.setState({
              error: true,
              resetPasswordSubmitted: false,
            });
          },
        );
    } else {
      this.setState({ errorCode: 'mismatch', resetPasswordFormError: true });
    }
  };

  validatePasswords = () => {
    const { newPassword, passwordConfirmation } = this.state;
    return equals(newPassword, passwordConfirmation);
  };

  hasError = () => {
    const { resetPasswordFormError, errorCode } = this.state;
    return resetPasswordFormError || errorCode;
  };

  resetPasswordForm = () => {
    const {
      resetPasswordFormError,
      newPassword,
      passwordConfirmation,
      errorCode,
      error,
    } = this.state;
    return (
      <MiniForm onSubmit={this.handleSubmit}>
        {error || errorCode === '444' ? (
          <React.Fragment>
            <FormattedMessage id="forgot-password.server-error-message" />{' '}
            <ButtonLink to="/forgot-password">
              <FormattedMessage id="forgot-password.request-link" />
            </ButtonLink>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <InputWrapper>
              <InputIntl
                id="newPassword"
                label="new-password-label"
                placeholder="password-placeholder"
                type="password"
                name="newPassword"
                onChange={this.handleInputChange}
                value={newPassword}
              />
            </InputWrapper>
            <InputWrapper>
              <InputIntl
                id="passwordConfirmation"
                label="confirm-password-label"
                placeholder="password-placeholder"
                type="password"
                name="passwordConfirmation"
                onChange={this.handleInputChange}
                value={passwordConfirmation}
              />
            </InputWrapper>
            <FormFooter>
              <SubmitButton size="normal" value="submit">
                <FormattedMessage id="forgot-password.save-password" />
              </SubmitButton>
              {this.hasError() && (
                <div>
                  <t.ErrorText large={true}>
                    {resetPasswordFormError &&
                      passwordErrorSwitch({ errorCode })}
                  </t.ErrorText>
                </div>
              )}
            </FormFooter>
          </React.Fragment>
        )}
      </MiniForm>
    );
  };

  passwordChangedConfirmation = () => (
    <div>
      <Confirmation>
        <FormattedMessage id="forgot-password.password-changed-message" />
        <ConfirmationBackToLoginLink to="/">
          <FormattedMessage id="forgot-password.log-in" />
        </ConfirmationBackToLoginLink>
      </Confirmation>
    </div>
  );

  render() {
    const { resetPasswordSubmitted } = this.state;
    return (
      <PageContainer>
        <ImageContainer>
          <Image src={logo} alt="Under Armour" />
        </ImageContainer>
        <ContentContainer>
          {resetPasswordSubmitted
            ? this.passwordChangedConfirmation()
            : this.resetPasswordForm()}
        </ContentContainer>
      </PageContainer>
    );
  }
}

ResetPassword.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ resetToken: PropTypes.string }),
  }).isRequired,
};

export default withApollo(ResetPassword);
