const type = 'new-cart-wizard/SELECT_ACCOUNT';

const creator = account => ({ type, account });

const reducer = (state, action) => ({
  ...state,
  selectedAccount: action.account,
});

export default { type, creator, reducer };
