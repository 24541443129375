import React, { FC, useState } from 'react';
import styled from 'styled-components';
import logo from 'lib/logo.svg';
import { colors } from 'lib/theme';

type ImageProps = {
  alt?: string;
  width: number;
  height: number;
  src?: string;
};

type PlaceholderProps = {
  light?: boolean;
};

type PartialImageProps = Omit<ImageProps, 'src'>;

function aspectRatio({ height, width }: PartialImageProps) {
  return `${(height / width) * 100}%`;
}

const PlaceholderImage = styled.div<PlaceholderProps & { hasFailed: boolean }>`
  opacity: ${({ hasFailed, light }) => (hasFailed ? (light ? 0.05 : 1) : 0.05)};
  transition: .2s;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${logo}');
  background-size: 33%;
  width: 100%;
  height:100%;
  position: absolute;
`;

const ImageContainer = styled.div<PartialImageProps>`
  position: relative;
  padding-bottom: ${aspectRatio};
  background-color: ${colors.offWhite};
`;

const BaseImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const SmartProductImage: FC<ImageProps & PlaceholderProps> = ({
  width,
  height,
  light,
  src,
  ...rest
}) => {
  const [hasFailed, setFailed] = useState(!src);
  return (
    <ImageContainer width={width} height={height}>
      <PlaceholderImage hasFailed={hasFailed} light={light} />
      {!hasFailed && (
        <BaseImage src={src} onError={() => setFailed(true)} {...rest} />
      )}
    </ImageContainer>
  );
};
