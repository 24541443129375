import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { accountShape, shoppingModeShape } from 'lib/types';
import { getActiveShoppingMode } from 'selectors/account';
import AccountMenuBody from './body';
import { MenuBody, MenuDropdown } from '../../ui/menu';
import Arrow from '../../ui/arrow';
import {
  AccountTrigger,
  AccountIdentity,
  AccountLabel,
  AccountMode,
  Layout,
} from './ui';

export const Menu = ({ account, shoppingMode }) => {
  let label = 'Account Label';
  if (account) {
    label = `${account.name} (${account.number})`;
  }
  return (
    <Layout>
      <MenuDropdown>
        <AccountTrigger>
          <AccountLabel>
            <AccountMode>
              <FormattedMessage
                id={`accounts.shopping-mode.${shoppingMode || 'atonce'}`}
              />
            </AccountMode>
            <AccountIdentity title={label}>{label}</AccountIdentity>
          </AccountLabel>
          <Arrow id="accountIdentity" />
        </AccountTrigger>
        <MenuBody align="right" sticky={true}>
          <AccountMenuBody />
        </MenuBody>
      </MenuDropdown>
    </Layout>
  );
};

Menu.propTypes = { account: accountShape, shoppingMode: shoppingModeShape };

Menu.defaultProps = { account: null, shoppingMode: null };

const mapStateToProps = state => ({
  shoppingMode: getActiveShoppingMode(state),
});

export default connect(mapStateToProps)(Menu);
