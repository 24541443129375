import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, spacing, breakpoint } from 'lib/theme';

const Container = styled.div`
  @media (max-width: ${breakpoint.bigPhone}) {
    padding-top: 40px;
  }

  @media (min-width: ${breakpoint.tablet}) {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
`;

const InteractiveArea = styled.div`
  background: ${colors.white};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${({ centerContent }) => (centerContent ? 'center' : 'normal')};
  justify-content: ${({ centerContent }) =>
    centerContent ? 'center' : 'normal'};

  @media (max-width: ${breakpoint.bigPhone}) {
    min-height: calc(100vh - 40px);
    display: flex;
  }
`;

// By setting `overflow` to 'hidden', the splash image won't contribute to
// the dimensions of the screen, which will prevent unwanted scrollbars.
const SplashImageContainer = styled.div`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  position: relative;
  background-image: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
`;

const FormContainer = ({ centerContent, children, splashImageSrc }) => (
  <Container>
    <InteractiveArea centerContent={centerContent}>{children}</InteractiveArea>
    <SplashImageContainer backgroundImageUrl={splashImageSrc} />
  </Container>
);

FormContainer.propTypes = {
  centerContent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  splashImageSrc: PropTypes.string.isRequired,
};

FormContainer.defaultProps = {
  centerContent: true,
};

export default FormContainer;
