import combineDucklings from 'lib/ducklings';
import createCart from './create-cart';
import reset from './reset';
import selectAccount from './select-account';
import setFormField from './set-form-field';
import setInProgress from './set-in-progress';
import submitDetails from './submit-details';
import uploadFile from './upload-file';
import initialState from './initial-state';

export default combineDucklings(initialState, [
  createCart,
  reset,
  selectAccount,
  setFormField,
  setInProgress,
  submitDetails,
  uploadFile,
]);
