import { path } from 'ramda';
import { anySuccess } from 'lib/redux-pack';
import { markActionsReplayed } from 'ducks/delayed-actions';

export default store => next => action => {
  const result = next(action);
  const state = store.getState();
  if (anySuccess(action)) {
    const delayedActions = path(['delayedActions', action.type], state);
    if (delayedActions) {
      delayedActions.forEach(delayedAction => {
        store.dispatch(delayedAction);
      });
      store.dispatch(markActionsReplayed(action.type));
    }
  }
  return result;
};
