import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import connectIntl from 'lib/connect-intl';
import { colors, fonts, spacing } from 'lib/theme';
import Caret from '../caret';
import RequiredIndicator from './required-indicator';
import StyledInputBox from './input-box/styled-input-box';
import StyledLabel from './input-box/styled-label';
import StyledControl from './input-box/styled-control';

const CARET_WIDTH = spacing.xxxl;

const CaretContainer = styled.div`
  position: absolute;
  right: 0;
  pointer-events: none;
  top: 50%;
  transform: translateY(-60%);
  width: ${CARET_WIDTH};
  text-align: center;
`;

const SelectContainer = styled(StyledInputBox)`
  min-width: ${p => p.width};
  transition: opacity 0.33s ease-in-out;
`;

const Label = StyledLabel;

const SelectControl = styled(StyledControl)`
  background-color: ${colors.white};
  box-sizing: border-box;
  padding: ${spacing.l} ${CARET_WIDTH} 0 ${spacing.m};
  font: ${fonts.heavy};
  border-radius: 0;
  transition: box-shadow 0.2s ease-in-out, background-color 0.1s ease-in-out;
  appearance: none;

  &:disabled {
    background-color: ${colors.offWhite};
    color: ${colors.darkGrey};
  }

  &::placeholder {
    color: ${colors.darkGrey};
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  ::-ms-expand {
    display: none;
  }
`;

export const Select = ({
  labelString,
  placeholder,
  id,
  children,
  required,
  size,
  width,
  ...props
}) => (
  <SelectContainer {...{ size, width }}>
    <Label htmlFor={id} isPlaceholder={false}>
      {labelString}
      {required && <RequiredIndicator />}
    </Label>
    <CaretContainer>
      <Caret dir="down" />
    </CaretContainer>
    <SelectControl as="select" {...props} id={id} required={required}>
      {children}
    </SelectControl>
  </SelectContainer>
);

Select.propTypes = {
  labelString: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  required: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.string,
};

Select.defaultProps = {
  labelString: '',
  placeholder: '',
  required: false,
  size: 'normal',
  width: '160px',
};

const mapIntlToProps = (intl, ownProps) => ({
  labelString: intl.formatMessage({ id: ownProps.label }),
});

export default connectIntl(mapIntlToProps)(Select);
