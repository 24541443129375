import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { isEmpty } from 'ramda';
import searchIcon from 'lib/icon-search.svg';
import { inputHeights, fonts } from 'lib/theme';
import CancelButton from '../cancel-button';
import StyledInputBox from './input-box/styled-input-box';
import StyledControl from './input-box/styled-control';

const decorationWidth = '30px';
const inputHeight = ({ size }) => inputHeights[size];

const InputContainer = styled.div`
  height: ${inputHeight};
  position: relative;
  z-index: 1;
`;

InputContainer.propTypes = {
  size: PropTypes.oneOf(['normal', 'small']),
};

InputContainer.defaultProps = {
  size: 'normal',
};

const Decoration = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${decorationWidth};
`;

const LeftDecoration = styled(Decoration)`
  z-index: 1;
  left: 0;
`;

const RightDecoration = styled(Decoration)`
  z-index: 3;
  right: 0;
`;

const innerInput = css`
  font: ${fonts.regular};
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  padding-left: ${decorationWidth};
  padding-right: ${decorationWidth};
  background: none;
`;

const NakedInnerInput = styled.input`
  ${innerInput};
`;

const SearchIcon = styled.div`
  width: 14px;
  height: 14px;
  margin: 0 auto;
  background: url('${searchIcon}');
  background-size: contain;
`;

const ClearButton = styled(CancelButton)`
  margin: 0 auto;
`;

export const NakedFilterInput = React.forwardRef(
  (
    { value, onChange, onClear, size, inputComponent, disabled, ...props },
    ref,
  ) => (
    <InputContainer size={size}>
      <LeftDecoration>
        <SearchIcon />
      </LeftDecoration>

      {React.createElement(inputComponent, {
        ...props,
        ref,
        value,
        disabled,
        onChange,
      })}

      {!isEmpty(value) && !disabled && (
        <RightDecoration>
          <FormattedMessage id="buttons.cancel">
            {msg => (
              <ClearButton onClick={onClear} tabIndex={-1} aria-label={msg} />
            )}
          </FormattedMessage>
        </RightDecoration>
      )}
    </InputContainer>
  ),
);

NakedFilterInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['normal', 'small']),
  inputComponent: PropTypes.func,
};

NakedFilterInput.defaultProps = {
  disabled: false,
  size: 'normal',
  inputComponent: NakedInnerInput,
};

const StyledInnerInput = styled(StyledControl)`
  ${innerInput};
`;

const FilterInput = React.forwardRef(
  ({ disabled, size, placeholder, ...props }, ref) => (
    <StyledInputBox size={size} isDisabled={disabled}>
      <NakedFilterInput
        as="input"
        placeholder={placeholder}
        inputComponent={StyledInnerInput}
        size={size}
        disabled={disabled}
        ref={ref}
        {...props}
      />
    </StyledInputBox>
  ),
);

FilterInput.propTypes = {
  size: PropTypes.oneOf(['normal', 'small']),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

FilterInput.defaultProps = {
  size: 'normal',
  disabled: false,
  placeholder: null,
};

export default FilterInput;
