import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { isMissing } from 'lib/data-manipulation';
import { popupShape } from 'lib/types';
import withMarketing from 'lib/with-marketing';
import PopupUI from 'components/ui/popup';
import Shroud from 'components/marketing/shroud';
import { getActiveShoppingMode } from '../selectors/account';

const Popup = ({ popup, shoppingMode }) =>
  isMissing(popup) ? null : (
    <Shroud contentMeta={popup.contentMeta}>
      <PopupUI
        popup={popup}
        shoppingMode={shoppingMode}
        cancelText={<FormattedMessage id="marketing.cancel" />}
      />
    </Shroud>
  );

Popup.propTypes = {
  popup: popupShape,
  shoppingMode: PropTypes.string.isRequired,
};

Popup.defaultProps = {
  popup: null,
};

const mapStateToProps = state => ({
  shoppingMode: getActiveShoppingMode(state),
});

export default compose(
  connect(mapStateToProps),
  withMarketing(['popup']),
)(Popup);
