import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, fonts, weights } from 'lib/theme';
import { getButtonStyles, getBorderButtonStyled } from './button';
import { BaseCaret } from '../caret';
import Icon from './icon';

export const TextLink = styled(Link)`
  color: ${colors.black};
  font: ${fonts.bold};
  text-decoration: none;
  transition: color 0.2s ease;

  ${/* sc-sel */ BaseCaret} {
    transition: border-color 0.2s ease;
  }

  &:focus {
    color: ${colors.grey};
  }

  &:hover {
    color: ${colors.grey};

    ${/* sc-sel */ BaseCaret} {
      border-color: ${colors.grey};
    }
  }
`;

export const ButtonLink = styled(getButtonStyles(Link))`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

export const BorderButtonLink = styled(getBorderButtonStyled(Link))`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const IconTextLink = styled(TextLink)`
  font-weight: ${weights.regular};
`;

export const IconLink = ({ icon, to, children, newTab, alt }) => (
  <IconTextLink to={to} target={newTab ? '_blank' : null}>
    <Icon src={icon} alt={alt} />
    {children}
  </IconTextLink>
);

IconLink.propTypes = {
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  alt: PropTypes.string.isRequired,
};

IconLink.defaultProps = { newTab: true };

export default TextLink;
