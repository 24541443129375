import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import styled from 'styled-components';

import { Row } from 'components/forms/fieldset';
import InputIntl from 'components/forms/input-intl';
import Select from 'components/forms/select';
import * as t from 'components/typography';
import { Button } from 'components/ui/button';
import splashImage from 'lib/profile-info-splash.jpg';
import logo from 'lib/logo.svg';
import regions from 'lib/regions.json';
import { breakpoint, colors, fonts, spacing } from 'lib/theme';
import FormContainer from './form-container';

const UPDATE_ADDRESS_MUTATION = gql`
  mutation addOrUpdateUserAddress($input: addOrUpdateUserAddressInput!) {
    addOrUpdateUserAddress(input: $input) {
      address {
        id
      }
      error {
        id
      }
    }
  }
`;

const FormHeader = styled.div`
  padding-top: ${spacing.xl};
  position: absolute;
`;

const Logo = styled.img`
  width: 54px;
  max-width: 54px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const MiniForm = styled.form`
  background: ${colors.white};
  padding: ${spacing.xl};
  max-width: 500px;
  width: 100%;

  @media (min-width: ${breakpoint.tablet}) {
    padding: ${spacing.xxxl};
  }
`;

export const Title = styled.h1`
  font: ${fonts.extraLargeRegular};
  color: ${colors.black};
  margin: 0 0 ${spacing.xl} 0;
`;

const ColumnatedRow = styled(Row)`
  display: flex;

  > div {
    flex: 1;
  }

  > div + div {
    margin-left: ${spacing.xl};
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: ${spacing.xxl};
`;

// A list of US region code/name pairs, including a default empty value,
// for use with the State `<select>` component.
const REGION_OPTIONS = [{ code: '', name: '' }, ...regions.US];
const POSTAL_PATTERN = '\\d{5}(-\\d{4})?';

export class ProfileInfoForm extends Component {
  state = {
    city: '',
    line1: '',
    line2: '',
    orgName: '',
    postal: '',
    regionCode: '',
  };

  handleInputChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = updateAddress => e => {
    e.preventDefault();
    updateAddress().then(response => {
      const { refetchCurrentUser } = this.props;
      const { address, error } = pathOr(
        {},
        ['data', 'addOrUpdateUserAddress'],
        response,
      );
      if (address && !error) {
        refetchCurrentUser();
      }
    });
  };

  render() {
    const { city, line1, line2, orgName, postal, regionCode } = this.state;
    const variables = {
      input: {
        address: {
          city,
          countryCode: 'US',
          line1,
          line2,
          name: orgName,
          postal,
          regionCode,
        },
      },
    };
    return (
      <FormContainer centerContent={false} splashImageSrc={splashImage}>
        <FormHeader>
          <Logo src={logo} alt="Under Armour" />
        </FormHeader>
        <ContentContainer>
          <Mutation mutation={UPDATE_ADDRESS_MUTATION} variables={variables}>
            {(updateAddress, { data, error, loading }) => (
              <MiniForm onSubmit={this.handleSubmit(updateAddress)}>
                <Title>
                  <FormattedMessage id="profile-info.title" />
                </Title>
                <Row>
                  <InputIntl
                    id="profileInfoOrgName"
                    label="profile-info.org-name-label"
                    name="orgName"
                    required={true}
                    type="text"
                    value={orgName}
                    onChange={this.handleInputChange}
                  />
                </Row>
                <Row>
                  <InputIntl
                    id="profileInfoLine1"
                    label="profile-info.line-1-label"
                    name="line1"
                    required={true}
                    type="text"
                    value={line1}
                    onChange={this.handleInputChange}
                  />
                </Row>
                <Row>
                  <InputIntl
                    id="profileInfoLine2"
                    label="profile-info.line-2-label"
                    name="line2"
                    required={false}
                    type="text"
                    value={line2}
                    onChange={this.handleInputChange}
                  />
                </Row>
                <Row>
                  <InputIntl
                    id="profileInfoCity"
                    label="profile-info.city-label"
                    name="city"
                    required={true}
                    type="text"
                    value={city}
                    onChange={this.handleInputChange}
                  />
                </Row>
                <ColumnatedRow>
                  <Select
                    id="profileInfoRegion"
                    label="profile-info.region-label"
                    name="regionCode"
                    required={true}
                    value={regionCode}
                    onChange={this.handleInputChange}
                  >
                    {REGION_OPTIONS.map(({ code, name }) => (
                      <option value={code} key={code}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputIntl
                    id="profileInfoPostal"
                    label="profile-info.postal-label"
                    name="postal"
                    pattern={POSTAL_PATTERN}
                    required={true}
                    type="text"
                    value={postal}
                    onChange={this.handleInputChange}
                  />
                </ColumnatedRow>
                <SubmitButton size="normal" disabled={loading}>
                  <FormattedMessage id="profile-info.submit-button" />
                </SubmitButton>
                {(error ||
                  pathOr(null, ['addOrUpdateUserAddress', 'error'])(data)) && (
                  <div>
                    <t.ErrorText large={true}>
                      <FormattedMessage id="profile-info.failure" />
                    </t.ErrorText>
                  </div>
                )}
              </MiniForm>
            )}
          </Mutation>
        </ContentContainer>
      </FormContainer>
    );
  }
}

ProfileInfoForm.propTypes = {
  refetchCurrentUser: PropTypes.func.isRequired,
};

export default ProfileInfoForm;
