import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { colors, fonts, spacing } from 'lib/theme';
import cart from 'lib/cart.svg';
import { openCartDrawer, closeCartDrawer } from 'ducks/carts';
import { getActiveCartId } from 'selectors/cart';
import ellipsis from 'styles/mixins';
import { NoBackgroundButton } from './ui/button';

const CartButton = styled(NoBackgroundButton)`
  padding-left: ${spacing.xxl};
  padding-right: 0;
  align-items: center;
  display: flex;
  min-width: auto;
  height: auto;
  margin-left: auto;
`;

const CartIcon = styled.img`
  width: 28px;
  margin-left: ${spacing.s};
  height: 28px;
`;

const CartQuantity = styled.div`
  color: ${colors.black};
  font: ${fonts.regular};
  padding-right: ${spacing.m};
  line-height: 1.4;
  text-align: right;
`;

const CartName = styled.div`
  ${ellipsis};
  color: ${colors.black};
  font: ${fonts.heavy};
  padding-right: ${spacing.m};
  line-height: 1.4;
  max-width: 150px;
  text-align: right;
`;

const CartLabel = styled.div``;

const ACTIVE_CART_QUERY = gql`
  query getActiveCart($cartId: ID!) {
    cart: node(id: $cartId) {
      ... on Cart {
        id
        cartName
        shipmentDate
        items {
          unitCount
        }
      }
    }
  }
`;

const CartControl = ({ cartDrawerOpen, actions, activeCartId }) => (
  <CartButton
    onClick={cartDrawerOpen ? actions.closeCartDrawer : actions.openCartDrawer}
  >
    {activeCartId && (
      <Query query={ACTIVE_CART_QUERY} variables={{ cartId: activeCartId }}>
        {({ data, error, loading }) => {
          if (error || loading) {
            return null;
          }
          return (
            <CartLabel>
              <CartQuantity>
                {pathOr(null, ['cart', 'items', 'unitCount'], data)}
              </CartQuantity>
              <CartName>{pathOr(null, ['cart', 'cartName'], data)}</CartName>
            </CartLabel>
          );
        }}
      </Query>
    )}
    <FormattedMessage id="carts.icon">
      {msg => <CartIcon src={cart} id="miniCart" alt={msg} />}
    </FormattedMessage>
  </CartButton>
);

CartControl.propTypes = {
  actions: PropTypes.shape({
    openCartDrawer: PropTypes.func.isRequired,
    closeCartDrawer: PropTypes.func.isRequired,
  }).isRequired,
  activeCartId: PropTypes.string,
  cartDrawerOpen: PropTypes.bool.isRequired,
};

CartControl.defaultProps = { activeCartId: null };

const mapStateToProps = state => ({
  cartDrawerOpen: state.carts.drawerOpen,
  activeCartId: getActiveCartId(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openCartDrawer, closeCartDrawer }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartControl);
