import QueryString from 'query-string';
import { compose, isNil, reject } from 'ramda';

export const stringify = compose(QueryString.stringify, reject(isNil));

export const defaultOrdersUrl = ({ accountId, organizationId }) =>
  `/orders?${stringify({
    accountId,
    organizationId,
  })}`;

export const defaultRostersUrl = ({ accountId, organizationId }) => {
  const params = { accountId, organizationId };
  return `/rosters?${stringify(params)}`;
};
