import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { popupShape } from 'lib/types';
import PopupContent from './content';

const MODALS_Z_INDEX = 3;
const style = {
  overlay: {
    display: 'flex',
    backgroundColor: 'rgba(29, 29, 29, 0.4)',
    zIndex: MODALS_Z_INDEX,
  },
  content: {
    alignSelf: 'center',
    background: 'none',
    border: 'none',
    borderRadius: 'none',
    outline: 'none',
    padding: '0',
    position: 'relative',
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
    margin: '0 auto',
  },
};

const Popup = ({ markAsSeen, popup, cancelText, shoppingMode }) => (
  <ReactModal style={style} isOpen={true} onRequestClose={markAsSeen}>
    <PopupContent
      popup={popup}
      cancelText={cancelText}
      markAsSeen={markAsSeen}
      shoppingMode={shoppingMode}
    />
  </ReactModal>
);

Popup.propTypes = {
  popup: popupShape.isRequired,
  cancelText: PropTypes.string.isRequired,
  markAsSeen: PropTypes.func.isRequired,
  shoppingMode: PropTypes.string.isRequired,
};

export default Popup;
