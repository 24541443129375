import { handle } from 'redux-pack';
import {
  applySpec,
  compose,
  head,
  map,
  mergeDeepLeft,
  path,
  pathOr,
  pluck,
  prop,
} from 'ramda';

export const FETCH = 'fit-guides/FETCH';

const initialState = {
  error: null,
  fetching: false,
  byId: {},
};

export const fetchFitGuide = chartId => ({
  type: FETCH,
  meta: {
    chartId,
    contentfulRequest: {
      content_type: 'fitGuide',
      'fields.chartId': chartId,
    },
  },
});

export const mungeFitGuide = compose(
  map(
    applySpec({
      title: prop('title'),
      description: prop('description'),
      image: path(['image', 'fields', 'file', 'url']),
    }),
  ),
  pluck('fields'),
  pathOr([], ['fields', 'fitGuideItems']),
);

const mungeFirstFitGuide = compose(head, map(mungeFitGuide), prop('items'));

export const reducer = (state = initialState, action) => {
  const { type, meta, payload } = action;
  switch (type) {
    case FETCH:
      return handle(state, action, {
        start: mergeDeepLeft({ error: null, fetching: true }),
        success: prevState =>
          mergeDeepLeft({
            byId: { [meta.chartId]: mungeFirstFitGuide(payload) },
          })(prevState),
        failure: prevState =>
          mergeDeepLeft({
            error: payload.response.data.message,
          })(prevState),
        finish: mergeDeepLeft({ fetching: false }),
      });

    default:
      return state;
  }
};

export default reducer;
