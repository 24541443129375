import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import ErrorBoundary from 'components/error-boundary';
import { useUserContext } from 'lib/use-user-context';
import Popup from 'components/popup';
import { Screen, Above, Below } from '../layout/screen-layout';
import Footer from '../footer';
import AdminHeader from '../admin-header';
import PublicHeader from '../public-header';
import ShoppingHeader from '../shopping-header';
import PageHeader from '../header';
import CookieConsent from '../cookie-consent';
import NoticeBar from '../notice-bar';
import AllotmentsBar from '../allotments-bar';
import Loadable from '../loadable';

const AsyncCartDrawer = Loadable({
  loader: () => import('../cart/cart-drawer/account'),
});
const AsyncPublicCartDrawer = Loadable({
  loader: () => import('../cart/cart-drawer/public'),
});

const Full = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const Chrome = styled.div`
  flex: none;
  z-index: 5;
`;

const Header = styled(Above)`
  z-index: 4;
`;

const Body = styled(Below)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`;

const Page = props => {
  const {
    showChrome,
    displayCookieConsent,
    showHeader,
    header,
    showAllotmentInfo,
    showNotices,
    showCartDrawer,
    showFooter,
    footerTopMargin,
  } = props;
  const { isPublic } = useUserContext();

  return (
    <Full>
      {showChrome && (
        <Chrome>
          <PageHeader />
        </Chrome>
      )}
      <Screen />
      {showHeader && (
        <Header>{header ? React.createElement(header, {}) : null}</Header>
      )}
      {showCartDrawer && (
        <Above>
          {isPublic ? <AsyncPublicCartDrawer /> : <AsyncCartDrawer />}
        </Above>
      )}
      {showAllotmentInfo && <AllotmentsBar />}
      {showNotices && <NoticeBar />}
      <Body>
        <Content>
          <ErrorBoundary messageId="page.error">
            <Route {...props} />
          </ErrorBoundary>
        </Content>
        {showFooter && <Footer topMargin={footerTopMargin} />}
        {displayCookieConsent && <CookieConsent />}
      </Body>
      <Popup />
    </Full>
  );
};

Page.propTypes = {
  header: PropTypes.func,
  footerTopMargin: PropTypes.bool,
  showCartDrawer: PropTypes.bool,
  showFooter: PropTypes.bool,
  displayCookieConsent: PropTypes.bool.isRequired,
  showChrome: PropTypes.bool,
  showHeader: PropTypes.bool,
  showNotices: PropTypes.bool,
  showAllotmentInfo: PropTypes.bool,
};

Page.defaultProps = {
  header: () => <div />,
  footerTopMargin: true,
  showCartDrawer: false,
  showChrome: true,
  showFooter: true,
  showHeader: true,
  showNotices: true,
  showAllotmentInfo: false,
};

const mapStateToProps = state => ({
  displayCookieConsent: !state.dismissedCookieConsent,
});

export const ConnectedPage = connect(mapStateToProps)(Page);
export const ShoppingPage = props => (
  <ConnectedPage
    header={ShoppingHeader}
    showCartDrawer={true}
    showAllotmentInfo={true}
    {...props}
  />
);
export const AdminPage = props => (
  <ConnectedPage header={AdminHeader} {...props} />
);
export const PublicPage = props => (
  <ConnectedPage header={PublicHeader} {...props} />
);
export const FullPage = props => (
  <ConnectedPage
    showHeader={false}
    showChrome={false}
    showNotices={false}
    showFooter={false}
    {...props}
  />
);
