import { handle } from 'redux-pack';
import { mergeDeepLeft, compose, flatten, pluck } from 'ramda';
import { mungeGrafikiCart, mungeGrafikiCarts } from 'lib/cart';
import { FETCH_CART, FETCH_CARTS } from './activeCart';

export default function byId(state = {}, action) {
  const { meta, type, payload } = action;
  switch (type) {
    case FETCH_CART:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          [meta.id]: mungeGrafikiCart(payload.data.node),
        }),
      });

    case FETCH_CARTS:
      return handle(state, action, {
        success: prevState => {
          const carts = compose(
            flatten,
            pluck('node'),
          )(payload.data.node.carts.edges);
          return mergeDeepLeft(prevState, mungeGrafikiCarts(carts));
        },
      });

    default:
      return state;
  }
}
