import axios from 'axios';
import { both, complement, map, mergeDeepRight, path, pipe } from 'ramda';
import { isReduxPackAction } from 'lib/redux-pack';
import { isPresent } from 'lib/data-manipulation';
import { getLocale } from 'selectors/locale';
import { getAccessToken } from 'selectors/account';
import {
  isAccessExpired,
  isWaitingForAccess,
  refreshSession,
  sessionNeedsRefresh,
} from 'lib/session';
import {
  logout,
  activateAccount,
  FETCH_ACCOUNT,
  EXCHANGE_ACCESS_TOKEN,
  LOGOUT,
} from 'ducks/authentication';
import { GET_ACTIVE_CONFIG } from 'ducks/search';
import { delayAction } from 'ducks/delayed-actions';

const getRequestData = path(['meta', 'uaApiRequest']);
const hasRequestData = pipe(getRequestData, isPresent);
const shouldHandleAction = both(hasRequestData, complement(isReduxPackAction));

export const injectHeaders = (authToken, locale) => request =>
  mergeDeepRight(request, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Accept-Language': locale,
      Pragma: 'no-cache',
    },
  });

const defaultPromiseBuilder = (store, action) => request => {
  const state = store.getState();
  const decorateRequest = injectHeaders(
    getAccessToken(state),
    getLocale(state),
  );
  return axios.request(decorateRequest(request)).catch(e => {
    if (action.type === GET_ACTIVE_CONFIG || action.type === FETCH_ACCOUNT) {
      // The active config is to an account that could not be located,
      // lets go ahead and fetch one that we expect to exist.
      if (e.response && e.response.status === 404) {
        store.dispatch(
          activateAccount({
            id: null,
            name: 'Account Label',
            number: '000000000',
          }),
        );
      }
    }
    if (e.response && e.response.status === 401) {
      // TODO re-enable this check when we can automatically retry request
      // eslint-disable-next-line no-constant-condition
      if (action.meta.undelayable) {
        if (action.type !== LOGOUT) {
          // can't refresh, better just log out.
          store.dispatch(logout());
        }
      } else if (!isWaitingForAccess(state)) {
        refreshSession(store);
      }
    }
    throw e;
  });
};

export const buildMiddleware = promiseBuilder => store => next => action => {
  if (!shouldHandleAction(action)) {
    return next(action);
  }

  if (!action.meta.undelayable) {
    const state = store.getState();

    if (sessionNeedsRefresh(state)) {
      refreshSession(store);
    }

    if (isAccessExpired(state)) {
      return store.dispatch(delayAction(action, EXCHANGE_ACCESS_TOKEN));
    }
  }

  const promiseForRequest = promiseBuilder(store, action);
  const requestData = getRequestData(action);

  const promise = requestData.length
    ? Promise.all(map(promiseForRequest, requestData))
    : promiseForRequest(requestData);

  return store.dispatch({
    ...action,
    promise,
  });
};

export default buildMiddleware(defaultPromiseBuilder);
