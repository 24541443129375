import { createContext, useContext } from 'react';

type TestIdentifierContext = {
    enabled: boolean
}

export const TestIdentifiers = createContext<TestIdentifierContext>({ enabled: false })

export const useTestIDs = (...args: string[]) => {
    const { enabled } = useContext(TestIdentifiers)
    if (enabled) {
        return args.map((id) => ({
            'data-test-id': id
        }))
    }
    return args.map(() => null);
}

