import { packSuccess, packFailure } from 'lib/redux-pack';
import { getShippingValidated } from 'selectors/checkout';
import {
  VALIDATE_SHIPPING,
  validatedCheckout,
  invalidateCheckout,
} from 'ducks/checkout';

export default store => next => action => {
  const result = next(action);
  const state = store.getState();

  if (packSuccess(action, VALIDATE_SHIPPING)) {
    const shippingValid = getShippingValidated(state);

    if (shippingValid) {
      store.dispatch(validatedCheckout());
    }
  }

  if (packFailure(action, VALIDATE_SHIPPING)) {
    store.dispatch(invalidateCheckout());
  }

  return result;
};
