import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { supportedLocales } from 'lib/locales';
import { maxWidthMedia } from 'styles/mixins';
import { borders, breakpoint, colors, fonts, spacing } from 'lib/theme';
import LocaleDisplay from './locale-display';
import Arrow from './ui/arrow';

export const LocaleDropdown = styled.div`
  position: relative;
`;

export const LocaleList = styled.ul`
  background-color: ${colors.white};
  border: ${borders.hairline};
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 170px;

  ${/* sc-sel */ LocaleDropdown}:hover & {
    display: block;
  }

  ${p => (p.inChrome ? 'border-top: none; right: 0;' : '')};
`;

export const LocaleListItem = styled.li`
  color: ${colors.black};
  font: ${fonts.heavy};
  padding: 0 20px 15px;
  cursor: pointer;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
  }

  &:hover,
  &:active {
    color: ${colors.grey};
  }
`;

export const CurrentLocale = styled.div`
  color: ${p => (p.inChrome ? colors.lightGrey : colors.black)};
  font: ${fonts.heavy};
  display: flex;
  height: 35px;
  align-items: center;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    font: ${fonts.gd.regular.subtext};
    height: ${spacing.gd.sm};
  `};
`;

export const LocalePicker = ({
  locale,
  intlSupported,
  onChooseLocale,
  inChrome,
}) => (
  <LocaleDropdown>
    <CurrentLocale id="currentLocale" inChrome={inChrome}>
      <LocaleDisplay locale={locale} />
      <Arrow color={inChrome ? colors.lightGrey : colors.black} />
    </CurrentLocale>
    <LocaleList inChrome={inChrome}>
      {supportedLocales.map(({ code, label }) => (
        <LocaleListItem
          key={code}
          onClick={() => onChooseLocale(code, !intlSupported)}
        >
          {label}
        </LocaleListItem>
      ))}
    </LocaleList>
  </LocaleDropdown>
);

LocalePicker.propTypes = {
  inChrome: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  onChooseLocale: PropTypes.func.isRequired,
  intlSupported: PropTypes.bool.isRequired,
};

export default LocalePicker;
