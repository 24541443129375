import React, {
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import QueryString from 'query-string';
import { defaultMemoize } from 'reselect';
import { parseFilters } from 'lib/filters';
import analytics from 'lib/analytics';
import { genderNames } from 'selectors/genders';
import { instantSearch } from 'ducks/search';
import { borders, colors, fonts } from 'lib/theme';
import { getActiveShoppingMode } from 'selectors/account';
import searchIcon from 'lib/icon-search.svg';
import { useFormatMessage } from 'lib/use-format-message';
import { Maybe } from 'types';
import InstantResults, { Item, SearchSuccess } from './instant-results';
import { ProductSearchProps } from './index';

const Wrapper = styled.div`
  height: 40px;
  width: 100%;
`;

const Form = styled.form`
  border: ${borders.thinDarkGrey};
  display: flex;
  height: 100%;
  width: 100%;
`;

const Label = styled.label`
  display: none;
`;

const Input = styled.input`
  border: none;
  background-color: ${colors.white};
  font: ${fonts.regular};
  flex: 1;
  padding: 10px;
  outline: none;
`;

const SearchButton = styled.button`
  background-color: ${colors.white};
  border: none;
  border-left: ${borders.thinDarkGrey};
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 40px;
`;

interface FilterShape {
  category?: Array<string>;
  colorGroup?: Array<string>;
}
const THREE_HUNDRED_MILLI_SECONDS = 300;

const isSearchSuccess = (selection: Item): selection is SearchSuccess => {
  return (selection as SearchSuccess).styleCode !== undefined;
};

const SearchBox: FunctionComponent<ProductSearchProps> = props => {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const formatMessage = useFormatMessage();
  const searchFieldLabel = formatMessage('search.fieldLabel');
  const searchPlaceHolder = formatMessage('search.placeholder');
  const searchButtonLabel = formatMessage('search.buttonLabel');

  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const debounceInstantSearch = useCallback(
    debounce(THREE_HUNDRED_MILLI_SECONDS, (term: string) =>
      dispatch(instantSearch(term)),
    ),
    [dispatch, instantSearch, debounce],
  );

  const shoppingMode = useSelector(getActiveShoppingMode);

  const onSelect = (
    selection: Maybe<Item>,
    stateAndHelpers?: ControllerStateAndHelpers<Item>,
  ) => {
    if (!selection) {
      return;
    }
    if (isSearchSuccess(selection)) {
      stateAndHelpers?.clearSelection();
      setSearchTerm('');
      history.push(`/p/${selection.styleCode}`);
    } else {
      history.push(`/g/?q=${encodeURIComponent(selection)}`);
    }
  };

  const handleSearchChange = (
    term: string,
    stateAndHelpers: ControllerStateAndHelpers<Item>,
  ) => {
    stateAndHelpers.setHighlightedIndex(0);
    if (!stateAndHelpers.isOpen) {
      return;
    }
    setSearchTerm(term);
    debounceInstantSearch(term);
  };

  const filters: FilterShape = useSelector(state =>
    defaultMemoize(parseFilters)(
      genderNames(state),
      match,
      defaultMemoize(QueryString.parse)(history.location.search),
    ),
  );

  const onSubmit = (e: FormEvent, closeMenu: () => void) => {
    e.preventDefault();
    onSelect(searchTerm);
    analytics.logSearch(searchTerm, shoppingMode, filters);
    closeMenu();
  };

  return (
    <Downshift
      onSelect={onSelect}
      inputValue={searchTerm}
      onInputValueChange={handleSearchChange}
      defaultHighlightedIndex={0}
    >
      {({
        closeMenu,
        getInputProps,
        getItemProps,
        getLabelProps,
        getRootProps,
        isOpen,
        highlightedIndex,
        inputValue,
        selectedItem,
      }) => (
        <Wrapper {...getRootProps()}>
          <Form onSubmit={e => onSubmit(e, closeMenu)}>
            <Label htmlFor="searchField" {...getLabelProps()}>
              {searchFieldLabel}
            </Label>
            <Input
              aria-label={searchPlaceHolder}
              id="searchField"
              type="text"
              name="search"
              value={inputValue}
              placeholder={searchPlaceHolder}
              {...getInputProps()}
            />

            <SearchButton>
              <img src={searchIcon} alt={searchButtonLabel} />
            </SearchButton>
          </Form>

          <InstantResults
            {...{
              getItemProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem,
            }}
          />
        </Wrapper>
      )}
    </Downshift>
  );
};
export default SearchBox;
