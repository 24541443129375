import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { borders, colors, fonts, spacing } from 'lib/theme';
import { touchScreenClickable } from 'styles/mixins';

export const MenuDropdown = styled.div`
  position: relative;
  ${touchScreenClickable};
`;

export const MenuBody = styled.div`
  background-color: ${colors.white};
  margin: 0;
  padding: 0;
  position: absolute;
  display: none;
  ${/* sc-prop */ props => props.align}: 0;
  top: 100%;
  transition: max-height 0.1s ease-in-out;

  ${/* sc-sel */ MenuDropdown}:focus-within & {
    ${props => (props.sticky ? 'display: unset;' : '')};
  }

  ${/* sc-sel */ MenuDropdown}:hover & {
    display: inherit;
  }
`;

export const MenuList = styled.ul`
  border: ${borders.hairline};
  border-top: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;

  &:first-child {
    padding-top: ${spacing.s};
  }
`;

export const MenuListItem = styled.li`
  border-bottom: ${borders.hairline};
  color: ${colors.black};
  cursor: pointer;
  margin-left: ${spacing.l};
  margin-right: ${spacing.l};

  &:last-child {
    border-bottom: none;
  }
`;

export const MenuGroup = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const MenuGroupItem = styled.li`
  color: ${colors.black};
  font: ${fonts.heavy};
  padding: 0 ${spacing.s} ${spacing.l};
  cursor: pointer;

  &:first-child {
    padding-top: ${spacing.l};
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.black};
  text-decoration: none;

  &:hover,
  &:active {
    color: ${colors.grey};
  }
`;
