import { path, pathEq, pathOr, pick } from 'ramda';
import { isMissing } from 'lib/data-manipulation';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export const wrangleBanner = data => {
  if (isMissing(data)) {
    return null;
  }

  const { fields } = data;
  const imageUrl = path(['image', 'fields', 'file', 'url'], fields);

  return {
    ...pick([
      'primaryText',
      'secondaryText',
      'secondaryTextLong',
      'linkText',
      'linkPath',
      'textColor',
      'buttonTextColor',
      'buttonBackgroundColor',
      'backgroundColor',
      'alignText',
    ])(fields),
    contentMeta: {
      id: data.sys.id,
      updatedAt: data.sys.updatedAt,
      ttl: fields.ttl,
    },
    floatText: fields.floatTextLeft ? 'left' : 'right',
    backgroundImageUrl: imageUrl,
    secondaryTextLong: fields.secondaryTextLong
      ? documentToHtmlString(fields.secondaryTextLong)
      : null,
  };
};

export const wrangleInGrid = data => {
  if (isMissing(data)) {
    return null;
  }

  return {
    ...data.fields,
    image: path(['image', 'fields', 'file', 'url'], data.fields),
  };
};

export const wrangleNotice = data => {
  if (isMissing(data)) {
    return null;
  }

  return data.fields;
};

export const wranglePopup = data => {
  if (isMissing(data)) {
    return null;
  }

  return {
    ...data.fields,
    image: path(['image', 'fields', 'file', 'url'], data.fields),
    contentMeta: {
      id: data.sys.id,
      updatedAt: data.sys.updatedAt,
      ttl: data.fields.ttl,
    },
  };
};

const getPlayMode = data => {
  const mode = path(['fields', 'playMode'], data);

  switch (mode) {
    case 'Static':
      return 'static';
    case 'Once Through':
      return 'once';
    case 'Continuous':
      return 'continuous';
    default:
      return 'continuous';
  }
};

const getInterval = data => {
  const inSeconds = pathOr(5, ['fields', 'interval'], data);
  return inSeconds * 1000;
};

const wrangleCarousel = data => {
  if (isMissing(data)) {
    return null;
  }

  const playMode = getPlayMode(data);
  const interval = getInterval(data);

  const bannersData = pathOr([], ['fields', 'banners'], data);
  const banners = bannersData.map(wrangleBanner);

  return {
    contentMeta: {
      id: data.sys.id,
      updatedAt: data.sys.updatedAt,
      ttl: data.fields.ttl,
    },
    playMode,
    interval,
    banners,
  };
};

const isContentType = pathEq(['sys', 'contentType', 'sys', 'id']);

const isBanner = isContentType('banner');
const isCarousel = isContentType('carousel');

export const wrangleBannerOrCarousel = data => {
  if (isMissing(data)) {
    return null;
  }

  if (isCarousel(data)) {
    return wrangleCarousel(data);
  }

  if (isBanner(data)) {
    return wrangleBanner(data);
  }

  return null;
};
