import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'lib/theme';

const transformations = {
  up: 'translateY(20%) rotate(315deg)',
  right: 'translateX(-20%) translateY(-2px) rotate(45deg)',
  down: 'translateY(-20%) rotate(135deg)',
  left: 'translateX(20%) translateY(-2px) rotate(225deg)',
};

export const BaseCaret = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: solid 2px ${colors.black};
  border-top: solid 2px ${colors.black};
  transform: ${({ dir }) => transformations[dir]};
  vertical-align: middle;
`;

BaseCaret.propTypes = {
  dir: PropTypes.oneOf(['up', 'right', 'down', 'left']).isRequired,
};

export default BaseCaret;
