import React from 'react';
import styled from 'styled-components';
import { colors } from 'lib/theme';

function RequiredIndicatorComponent(props) {
  return <span {...props}> * </span>;
}

export default styled(RequiredIndicatorComponent)`
  color: ${colors.red};
`;
