import { UPLOAD_STEP } from './steps';

export default {
  errorMessage: null,
  error: null,
  file: null,
  inProgress: false,
  requestCreate: false,
  requestUpdate: false,
  selectedAccount: null,
  selectedShoppingMode: null,
  stepIndex: UPLOAD_STEP,
  uploading: null,
  carts: {},
};
