import React from 'react';
import PropTypes from 'prop-types';
import LeftPopup from './left';
import FullPopup from './full';

const PopupContent = props => {
  let { popup } = props;
  if (!props) {
    popup = {};
  }

  switch (popup.type) {
    case 'Left Image':
      return <LeftPopup {...props} />;
    case 'Full Image':
      return <FullPopup {...props} />;
    default:
      return null;
  }
};

PopupContent.propTypes = {
  popup: PropTypes.shape({
    type: PropTypes.string,
  }),
};

PopupContent.defaultProps = {
  popup: null,
};

export default PopupContent;
