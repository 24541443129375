import { handle } from 'redux-pack';
import { applySpec } from 'ramda';
import {
  getPrimaryBanner,
  getSecondaryBanner,
  getInGrid,
  getPageNotice,
  getPopup,
  getVideo,
  getTechSheet,
} from 'lib/contentful';

const mungeResponse = applySpec({
  primaryBanner: getPrimaryBanner,
  secondaryBanner: getSecondaryBanner,
  inGrid: getInGrid,
  notice: getPageNotice,
  popup: getPopup,
  video: getVideo,
  techSheet: getTechSheet,
});

export const FETCH_TARGETED_CONTENT = 'marketing/FETCH_TARGETED_CONTENT';
export const FETCH_USER_PREFERENCES = 'marketing/FETCH_USER_PREFERENCES';
export const FETCH_COPY = 'marketing/FETCH_COPY';
export const MARK_AS_SEEN = 'marketing/MARK_AS_SEEN';

export const fetchTargetedContent = (
  pathname,
  account,
  shoppingMode,
  params,
) => ({
  type: FETCH_TARGETED_CONTENT,
  meta: {
    contentfulRequest: {
      content_type: 'page',
      'fields.path': pathname,
      'fields.salesOrg': account.salesOrgCode,
      order: '-sys.createdAt',
      include: 2,
    },
    account,
    shoppingMode,
    params,
  },
});

export const fetchUserPreferences = () => ({
  type: FETCH_USER_PREFERENCES,
  meta: {
    contentfulRequest: {
      content_type: 'rankedList',
      'fields.slug': 'user-preferences',
    },
  },
});

export const fetchCopy = () => ({
  type: FETCH_COPY,
  meta: {
    contentfulRequest: {
      content_type: 'copy',
    },
  },
});

export const markAsSeen = ({ id, updatedAt, ttl }) => ({
  type: MARK_AS_SEEN,
  id,
  updatedAt,
  ttl,
});

const determineExpiration = ttl => {
  let expiresAt = null;

  if (ttl) {
    const expiresDate = new Date();
    expiresDate.setDate(expiresDate.getDate() + ttl);
    expiresAt = expiresDate.toISOString();
  }

  return expiresAt;
};

export const reducer = (state = { seen: {} }, action) => {
  const {
    type,
    payload,
    meta: { account, shoppingMode, params } = {},
  } = action;

  switch (type) {
    case FETCH_TARGETED_CONTENT:
      return handle(state, action, {
        success: () => ({
          ...state,
          ...mungeResponse({ payload, account, shoppingMode, params }),
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.message,
        }),
      });

    case FETCH_USER_PREFERENCES:
      return handle(state, action, {
        success: () => ({
          ...state,
          preferences: action?.payload?.items?.flatMap(
            item => item?.fields?.items,
          ),
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.message,
        }),
      });

    case FETCH_COPY:
      return handle(state, action, {
        success: () => ({
          ...state,
          copy: action?.payload?.items?.flatMap(item => item?.fields),
        }),
        failure: prevState => ({
          ...prevState,
          error: payload.message,
        }),
      });

    case MARK_AS_SEEN:
      return {
        ...state,
        seen: {
          ...state.seen,
          [action.id]: {
            updatedAt: action.updatedAt,
            expiresAt: determineExpiration(action.ttl),
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
