import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from 'lib/logo.svg';
import { borders, colors, columnWidth, spacing } from 'lib/theme';

const Header = styled.header`
  align-items: center;
  background-color: ${colors.white};
  border-bottom: ${borders.hairline};
  display: flex;
  flex-direction: row;
  height: 70px;
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
`;

const LogoLink = styled(Link)`
  width: ${columnWidth};
  padding-right: ${spacing.xl};
  display: block;
`;

const Logo = styled.img`
  align-items: center;
  display: flex;
`;

export default () => (
  <Header>
    <LogoLink to="/">
      <Logo src={logo} />
    </LogoLink>
  </Header>
);
