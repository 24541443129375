import React, { ErrorInfo } from 'react';
import { FormattedMessage } from 'react-intl';
import { noticeError } from 'lib/errors';
import ErrorMessage from 'components/ui/error-message';

type ErrorBoundaryPropTypes = {
  messageId: string;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryPropTypes,
  ErrorBoundaryState
> {
  state = { hasError: false };

  componentDidCatch(err: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    noticeError(err, { info });
  }

  render() {
    const { messageId, children } = this.props;
    const { hasError } = this.state;
    return hasError ? (
      <ErrorMessage>
        <FormattedMessage id={messageId} />
      </ErrorMessage>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
