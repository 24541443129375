import { createSelector } from 'reselect';
import convertPxValueToInt from 'styles/helpers';
import { breakpoint } from 'lib/theme';

export const DESKTOP_LAYOUT = 'DESKTOP_LAYOUT';
export const TABLET_LAYOUT = 'TABLET_LAYOUT';

const screenSize = state => state.screenSize;

export const getActiveLayout = size =>
  size <= convertPxValueToInt(breakpoint.tablet)
    ? TABLET_LAYOUT
    : DESKTOP_LAYOUT;

export const activeLayout = createSelector([screenSize], getActiveLayout);
