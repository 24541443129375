import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { pathOr } from 'ramda';
import { Query } from 'react-apollo';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import l from 'components/layout';
import { spacing, weights, fontSizes, colors } from 'lib/theme';
import { usePublicAccountId } from 'lib/user';
import { getSelectedAccountId } from 'selectors/account';
import { getAmount } from 'lib/currency';
import { displayApiDate, formatDate } from 'lib/dates';
import Currency from 'components/currency';
import { allotmentShape } from 'lib/types';
import CurrentShipmentDate from 'components/current-shipment-date';
import { useUserContext } from 'lib/use-user-context';

const ALLOTMENT_ALLOCATIONS_FRAGMENT = gql`
  fragment AllotmentAllocationsFragment on AllotmentAllocations {
    current {
      balance {
        amount
        currency
      }
      endDate
      startDate
    }
    future {
      balance {
        amount
        currency
      }
      endDate
      startDate
    }
  }
`;

const ALLOCATIONS_QUERY = gql`
  query getAllotmentAllocations($accountId: ID!, $shipmentDate: Date!) {
    node(id: $accountId) {
      ... on Account {
        id
        allotmentAllocations(requestedShipDate: $shipmentDate) {
          ...AllotmentAllocationsFragment
        }
      }
    }
  }
  ${ALLOTMENT_ALLOCATIONS_FRAGMENT}
`;

const PUBLIC_ALLOCATIONS_QUERY = gql`
  query getAllotmentAllocationsPublic {
    currentUser {
      id
      public {
        allotmentAllocations {
          ...AllotmentAllocationsFragment
        }
      }
    }
  }
  ${ALLOTMENT_ALLOCATIONS_FRAGMENT}
`;

const Wrapper = styled(l.Row)`
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  flex-wrap: wrap;
  font-size: ${fontSizes.medium};
  min-height: 35px;
  justify-content: space-evenly;
`;

const AllotmentWrapper = styled.div`
  margin: ${spacing.s} ${spacing.xl};
`;

const Title = styled.span`
  font-weight: ${weights.bold};
`;

const mungeAllocation = allocation => {
  const { startDate, endDate, balance = {} } = allocation || {};
  return startDate && endDate
    ? {
        startDate,
        endDate,
        balance: {
          amount: getAmount(balance),
          code: balance.currency,
        },
      }
    : {};
};

const Allotment = ({ title, allocation: { startDate, endDate, balance } }) => (
  <AllotmentWrapper>
    <Title>{title}</Title> ({displayApiDate(startDate)} -{' '}
    {displayApiDate(endDate)}
    ): <Currency {...balance} />
  </AllotmentWrapper>
);

Allotment.propTypes = {
  title: PropTypes.string.isRequired,
  allocation: allotmentShape.isRequired,
};

const AllotmentsBar = ({ accountId }) => {
  const { isPublic } = useUserContext();
  const PUBLIC_ACCOUNT_ID = usePublicAccountId();
  const selectedAccountisPublicAccount = useSelector(
    state => getSelectedAccountId(state) === PUBLIC_ACCOUNT_ID,
  );
  return (
    <CurrentShipmentDate>
      {shipmentDate => (
        <Query
          query={isPublic ? PUBLIC_ALLOCATIONS_QUERY : ALLOCATIONS_QUERY}
          variables={{
            accountId,
            shipmentDate: formatDate(shipmentDate),
          }}
          skip={!shipmentDate}
        >
          {({ loading, data, error }) => {
            if (loading || !data) {
              return null;
            }

            if (error) {
              return (
                <Wrapper>
                  <FormattedMessage id="allotments.allocations.error" />
                </Wrapper>
              );
            }

            const { current, future } = isPublic
              ? pathOr(
                  {},
                  ['currentUser', 'public', 'allotmentAllocations'],
                  data,
                )
              : pathOr({}, ['node', 'allotmentAllocations'], data);

            const currentAllocation = mungeAllocation(current);
            const futureAllocation = mungeAllocation((future || [])[0]);
            const hasCurrentBalance = !!currentAllocation.balance;
            const hasFutureBalance = !!futureAllocation.balance;

            if (
              (!hasCurrentBalance && !hasFutureBalance) ||
              (isPublic && selectedAccountisPublicAccount)
            ) {
              return null;
            }

            return (
              <Wrapper>
                {hasCurrentBalance && (
                  <FormattedMessage id="allotments.current-allotment">
                    {msg => (
                      <Allotment allocation={currentAllocation} title={msg} />
                    )}
                  </FormattedMessage>
                )}
                {hasFutureBalance && (
                  <FormattedMessage id="allotments.future-allotment">
                    {msg => (
                      <Allotment allocation={futureAllocation} title={msg} />
                    )}
                  </FormattedMessage>
                )}
              </Wrapper>
            );
          }}
        </Query>
      )}
    </CurrentShipmentDate>
  );
};

const mapStateToProps = state => ({
  accountId: getSelectedAccountId(state),
});

export default connect(mapStateToProps)(AllotmentsBar);

AllotmentsBar.propTypes = {
  accountId: PropTypes.string.isRequired,
};
