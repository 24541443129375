import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import { breakpoint, colors, fontSizes, spacing } from 'lib/theme';
import errorIcon from 'lib/error-icon.svg';

const ErrorIcon = styled.img`
  margin-right: ${spacing.l};
`;

const ErrorContainer = styled.div`
  background-color: ${colors.pink};
  border: solid 1px ${colors.white};
  color: ${colors.black};
  display: flex;
  flex-direction: row;
  font-size: ${fontSizes.medium};
  margin-top: ${spacing.xxxl};
  padding: ${spacing.l} ${spacing.xxl};

  ${media(breakpoint.tablet)`
    margin-left: ${spacing.xl};
    margin-right: ${spacing.xl};
  `};
`;

const ErrorContent = styled.span`
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  img {
    bottom: -4px;
    margin-right: ${spacing.m};
    position: relative;
  }
`;

export const ErrorMessage = ({ children }) => (
  <ErrorContainer>
    <ErrorIcon src={errorIcon} />
    <ErrorContent>{children}</ErrorContent>
  </ErrorContainer>
);

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
