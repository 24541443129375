import { map, mergeDeepLeft, path, reduce } from 'ramda';

export function treeify(keys, val) {
  const [headKey, ...restKeys] = [...keys].reverse();
  return headKey ? treeify([...restKeys].reverse(), { [headKey]: val }) : val;
}

export function combineTree(paths, state) {
  const pieces = map(keys => treeify(keys, path(keys, state)), paths);
  return reduce(mergeDeepLeft, {}, pieces);
}
