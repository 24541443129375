export const EDIT_STYLE = 'cart/EDIT_STYLE';

const initialState = { styleId: null, recipeCode: null };

export default function itemEditing(state = initialState, action) {
  const { styleId, recipeCode, recipeSetCode, rosterName, genericArticleId } =
    action.meta || {};
  switch (action.type) {
    case EDIT_STYLE:
      return {
        styleId,
        recipeCode,
        recipeSetCode,
        rosterName,
        genericArticleId,
      };

    default:
      return state;
  }
}
