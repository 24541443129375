import { contains, filter, flip, head } from 'ramda';
import { createSelector } from 'reselect';
import { fallbackLocale, supportedLocaleCodes } from 'lib/locales';

export const localeToUse = createSelector(
  [
    state => state.authentication.locale,
    state => state.preferredLocale,
    state => state.browserLocale,
  ],
  (accountLocale, preferredLocale, browserLocale) => {
    const possibilities = [
      accountLocale,
      preferredLocale,
      browserLocale,
      fallbackLocale,
    ];
    return head(filter(flip(contains)(supportedLocaleCodes))(possibilities));
  },
);

export const getLocale = createSelector(
  [state => state.locale.locale],
  locale => locale,
);

export const isLocaleLoading = createSelector(
  [state => state.locale.loading],
  loading => loading,
);

export const getMessages = createSelector(
  [state => state.locale.locale, state => state.locale.messages],
  (locale, messages) => ({
    ...messages[fallbackLocale],
    ...messages[locale],
  }),
);

export const isIntlSupported = createSelector(
  [state => state.locale.intlSupported],
  supported => supported,
);
