import { compose, prop, defaultTo, find, propEq } from 'ramda';
import { injectHeaders } from 'middleware/ua-api-injector';

export const PRICE_MSRP = 'msrp';
export const PRICE_ACCOUNT = 'accountPrice';
export const PRICE_PROMO = 'promoPrice';

export const genericArticlePricingRequest = ({
  genericArticleId,
  firstShipDate,
  accessToken,
  accountId,
  shoppingMode,
  locale,
}) =>
  injectHeaders(
    accessToken,
    locale,
  )({
    url: `/api/accounts/${accountId}/products/pricing`,
    method: 'POST',
    data: {
      effectiveOn: firstShipDate,
      shoppingMode,
      materialIDs: [genericArticleId],
    },
  });

export const mungeGenericArticlePriceRange = genericArticleId => payload => {
  const {
    min: { amount: min },
    max: { amount: max },
  } = compose(
    prop(PRICE_MSRP),
    defaultTo({}),
    find(propEq('materialID', genericArticleId)),
  )(payload.data.materials);
  return { min, max };
};
