import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import SearchBox from './search-box';
import { ProductSearchProps } from './index';

const SearchBar = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  flex: 1;
  display: flex;
  max-width: 460px;
`;

const DesktopSearch: FunctionComponent<ProductSearchProps> = props => (
  <SearchBar>
    <SearchBox {...props} />
  </SearchBar>
);

export default DesktopSearch;
