import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { borders, colors, fonts, spacing } from 'lib/theme';
import ellipsis from 'styles/mixins';
import RequiredIndicator from '../../required-indicator';
import StyledInputBox from '../../input-box/styled-input-box';
import StyledLabel from '../../input-box/styled-label';
import StyledControl from '../../input-box/styled-control';
import { NakedFilterInput } from '../../filter-input';
import Results from './results';
import Caret from '../../../caret';
import optionShape from '../option-shape';

const CARET_WIDTH = spacing.xxxl;

const Container = styled(StyledInputBox)`
  z-index: ${({ isOpen }) => (isOpen ? 2 : 1)};
`;

const Label = styled(StyledLabel)`
  z-index: -1;
  left: 0;
  right: 0;
  padding-right: ${CARET_WIDTH};
  ${ellipsis};
`;

const CaretContainer = styled.div`
  position: absolute;
  z-index: -1;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
  width: ${CARET_WIDTH};
  text-align: center;
`;

const Control = styled(StyledControl).attrs({
  type: 'button',
})`
  background: none;
  text-align: left;
  font: ${fonts.heavy};
  padding: ${spacing.l} ${CARET_WIDTH} 0 ${spacing.m};
  ${ellipsis};
  ${({ width }) => width && `width: ${width}px;`}

  &:not([disabled]) {
    cursor: pointer;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: ${({ openDirection }) => (openDirection === 'down' ? '100%' : 'auto')};
  bottom: ${({ openDirection }) => (openDirection === 'up' ? '100%' : 'auto')};
  right: 0;
  left: 0;
  overflow: auto;
  border-left: ${borders.thinDarkGrey};
  border-right: ${borders.thinDarkGrey};
  border-bottom: ${borders.thinDarkGrey};
  border-top: ${({ openDirection }) =>
    openDirection === 'up' ? borders.thinDarkGrey : 'auto'};
  background: ${colors.white};
  ${({ width }) => width && `width: ${width}px;`};
`;

const ResultsContainer = styled.div`
  border-top: ${borders.thinDarkGrey};
`;

const SelectFilterUI = React.forwardRef(
  (
    {
      errorMessage,
      filterPlaceholder,
      includeBlank,
      isDisabled,
      isLoading,
      isOpen,
      label,
      loadingMessage,
      noResultsMessage,
      onInputClear,
      onSelectOption,
      options,
      query,
      required,
      size,
      value,
      getInputProps,
      getLabelProps,
      getToggleButtonProps,
      getItemProps,
      highlightedIndex,
      resultsFooterSection,
      openDirection,
      width,
    },
    ref,
  ) => (
    <Container size={size} isDisabled={isDisabled} isOpen={isOpen}>
      {label && (
        <Label
          {...getLabelProps()}
          isPlaceholder={value === null && includeBlank === null}
        >
          {label}
          {required && <RequiredIndicator />}
        </Label>
      )}
      <CaretContainer>
        <Caret dir="down" />
      </CaretContainer>
      <Control
        as="button"
        disabled={isDisabled}
        isActive={isOpen}
        {...getToggleButtonProps()}
        title={value}
        width={width}
      >
        {value === null ? includeBlank : value}
      </Control>
      {isOpen && (
        <Dropdown {...{ width, openDirection }}>
          <NakedFilterInput
            {...getInputProps({ value: query })}
            placeholder={filterPlaceholder}
            onClear={onInputClear}
            size="small"
            ref={ref}
          />
          <ResultsContainer>
            <Results
              errorMessage={errorMessage}
              includeBlank={value === null ? null : includeBlank}
              isLoading={isLoading}
              loadingMessage={loadingMessage}
              noResultsMessage={noResultsMessage}
              onSelectOption={onSelectOption}
              options={options}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              resultsFooterSection={resultsFooterSection}
            />
          </ResultsContainer>
        </Dropdown>
      )}
    </Container>
  ),
);

SelectFilterUI.propTypes = {
  errorMessage: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  includeBlank: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  loadingMessage: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  onInputClear: PropTypes.func,
  onSelectOption: PropTypes.func,
  options: PropTypes.arrayOf(optionShape),
  query: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string,
  getInputProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getToggleButtonProps: PropTypes.func.isRequired,
  getLabelProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  resultsFooterSection: PropTypes.node,
  openDirection: PropTypes.oneOf(['down', 'up']),
  width: PropTypes.number,
};

SelectFilterUI.defaultProps = {
  errorMessage: null,
  filterPlaceholder: '',
  includeBlank: null,
  isDisabled: false,
  isLoading: false,
  isOpen: false,
  label: null,
  onInputClear: () => {},
  onSelectOption: () => {},
  options: [],
  query: '',
  required: false,
  size: 'normal',
  value: null,
  highlightedIndex: null,
  resultsFooterSection: null,
  openDirection: 'down',
  width: null,
};

export default SelectFilterUI;
