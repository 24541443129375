import { combineReducers } from 'redux';
import { compose, map, propOr } from 'ramda';
import { mungeCurrency } from 'lib/currency';
import { PRICE_MSRP, PRICE_ACCOUNT } from 'lib/pricing';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from '../authentication';

export const CLEAR_GENERIC_ARTICLE_PRICES =
  'cart/byStyle/CLEAR_GENERIC_ARTICLE_PRICES';

export const clearGenericArticlePrices = () => ({
  type: CLEAR_GENERIC_ARTICLE_PRICES,
});

export const wrangleAccountPrice = compose(
  map(mungeCurrency),
  propOr({}, PRICE_ACCOUNT),
);
export const wrangleMSRP = compose(map(mungeCurrency), propOr({}, PRICE_MSRP));

const byGenericArticleId = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
      return {};

    case CLEAR_GENERIC_ARTICLE_PRICES:
      return {};

    default:
      return state;
  }
};

const fetching = (state = false) => state;

const error = (state = false) => state;

export default combineReducers({ error, fetching, byGenericArticleId });
