import { assoc } from 'ramda';

export const ViewModes = {
  GRID: 'grid',
  LIST: 'list',
};

const type = 'search/SET_VIEW_MODE';

const initState = ViewModes.GRID;

const creator = mode => ({
  type,
  payload: mode,
});

const reducer = (prevState, action) =>
  assoc('viewMode', action.payload)(prevState);

export default { type, creator, initState, reducer };
