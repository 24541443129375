import gql from 'graphql-tag';
import React, { createContext, FC, ComponentType } from 'react';
import { useQuery } from 'react-apollo';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { userSignedIn } from 'selectors/user-signed-in';
import {
  UserContext__ProviderQuery,
  UserContext__ProviderQueryVariables,
} from '../__generated__/types';
import { ApolloError } from 'apollo-client';

interface UserContext {
  loading: boolean;
  error?: ApolloError;
  hasPermission: (code: string) => boolean;
  isPublic: boolean;
  isLoggedIn: boolean;
}

export const DefaultUserContext = {
  loading: false,
  hasPermission: (code: string) => false,
  isPublic: false,
  isLoggedIn: false,
};
export const UserContext = createContext<UserContext>(DefaultUserContext);

const USER_CONTEXT_QUERY = gql`
  query UserContext__Provider {
    currentUser {
      id
      roles {
        roleName
        permissions {
          id
          permissionCode
        }
      }
    }
  }
`;

export const UserContextProvider: FC = props => {
  const isLoggedIn = useSelector(userSignedIn);
  const { data, error, loading } = useQuery<
    UserContext__ProviderQuery,
    UserContext__ProviderQueryVariables
  >(USER_CONTEXT_QUERY, {
    skip: !isLoggedIn,
  });
  const roles = data?.currentUser.roles || [];
  const permissionCodes = roles.flatMap(({ permissions }) =>
    permissions.map(({ permissionCode }) => permissionCode),
  );
  const hasPermission = (permissionCode: string) =>
    permissionCodes.includes(permissionCode);
  const isPublic = roles.some(({ roleName }) => roleName === 'Public');
  return (
    <UserContext.Provider
      value={{
        loading,
        error,
        hasPermission,
        isPublic,
        isLoggedIn,
      }}
      {...props}
    />
  );
};

export function useUserContext() {
  return useContext(UserContext);
}

export function withUserContext<T>(Component: ComponentType<T & UserContext>) {
  const Enhanced: FC<T> = (props: T) => (
    <Component {...props} {...useUserContext()} />
  );
  return Enhanced;
}

interface RequireUserContextProps {
  fallback: React.ReactElement;
}

export const RequireUserContext: FC<RequireUserContextProps> = ({
  fallback,
  children,
}) => {
  const { loading, isLoggedIn } = useUserContext();
  const app = loading ? null : children;
  return <>{isLoggedIn ? app : fallback}</>;
};
