const type = 'accountFilter/SELECT_ACCOUNT';

const creator = (account, key) => ({ type, account, key });

const reducer = (state, action) => ({
  ...state,
  selectedAccounts: {
    ...state.selectedAccounts,
    [action.key]: action.account,
  },
  error: null,
  isLoading: false,
});

export default { type, creator, reducer };
