import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty, isNil } from 'ramda';
import { colors, fonts, spacing } from 'lib/theme';
import Option from '../../option';
import Loading from '../../../loading';
import optionShape from '../option-shape';

const Message = styled.div`
  padding: ${spacing.xl};
  font: ${fonts.regular};
  color: ${colors.black};
  text-align: center;
`;

const ErrorMessage = styled(Message)`
  color: ${colors.red};
`;

const Options = styled.div`
  max-height: 200px;
  overflow: auto;
  padding: ${spacing.m};
`;

const Results = ({
  errorMessage,
  includeBlank,
  isLoading,
  loadingMessage,
  noResultsMessage,
  options,
  getItemProps,
  highlightedIndex,
  resultsFooterSection,
}) => {
  if (!isNil(errorMessage)) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }
  if (isLoading) {
    return (
      <Message>
        <Loading message={loadingMessage} />
      </Message>
    );
  }
  if (isEmpty(options)) {
    return <Message>{noResultsMessage}</Message>;
  }

  return (
    <Options>
      {includeBlank !== null && (
        <Option
          key="__blank__"
          option={{ title: includeBlank }}
          highlighted={highlightedIndex === 0}
          getItemProps={getItemProps}
        />
      )}
      {options.map((option, i) => (
        <Option
          key={option.id}
          option={option}
          aria-label={`select-filter-${i}`}
          highlighted={highlightedIndex === i + (includeBlank ? 1 : 0)}
          getItemProps={getItemProps}
        />
      ))}
      {resultsFooterSection}
    </Options>
  );
};

Results.propTypes = {
  errorMessage: PropTypes.string,
  includeBlank: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionShape).isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  resultsFooterSection: PropTypes.node,
};

Results.defaultProps = {
  includeBlank: null,
  errorMessage: null,
  highlightedIndex: null,
  resultsFooterSection: null,
};

export default Results;
