import { compose, path } from 'ramda';
import { isPresent } from 'lib/data-manipulation';

export const getActiveUsername = path(['authentication', 'username']);

export const getRefreshToken = path(['authentication', 'refreshToken']);

export const userSignedIn = compose(isPresent, getRefreshToken);

export default userSignedIn;
