import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors, spacing } from 'lib/theme';
import FocusAware from './focus-aware';
import CheckerLayout from './checker-layout';

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: 0;
  position: absolute;
`;

function borderColor() {
  return function getBorderColor({ focused, checked, disabled }) {
    if (focused || disabled) {
      return colors.darkGrey;
    }
    if (checked && !focused) {
      return colors.black;
    }
    return colors.darkGrey;
  };
}

export const RadioInputContainer = styled.div`
  color: ${colors.black};
  margin: ${spacing.m} 0;

  span {
    font-size: 13px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
  }
`;

const RadioCircle = styled.div`
  display: block;
  width: 20px;
  height: 20px;

  /* prettier-ignore */
  box-shadow: inset 0 0 0 ${props =>
    props.checked ? 6 : 1}px ${borderColor()};
  border-radius: 50%;
  transition: box-shadow 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
`;

function RadioInput({
  id,
  label,
  labelText,
  focused,
  checked,
  disabled,
  ...props
}) {
  return (
    <RadioInputContainer focused={focused} checked={checked}>
      <CheckerLayout
        checker={
          <div>
            <HiddenRadio
              {...props}
              disabled={disabled}
              focused={focused}
              checked={checked}
              type="radio"
              id={id}
            />
            <RadioCircle
              checked={checked}
              focused={focused}
              disabled={disabled}
            />
          </div>
        }
        label={
          <div>
            {label && <FormattedMessage id={label} />}
            {!label && labelText && <span>{labelText}</span>}
          </div>
        }
        isClickable={!disabled}
      />
    </RadioInputContainer>
  );
}

RadioInput.defaultProps = {
  label: '',
  labelText: '',
  disabled: false,
};

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelText: PropTypes.node,
  focused: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default FocusAware(RadioInput);
