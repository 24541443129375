import React, { FormEvent, FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { colors, fonts, spacing, weights } from 'lib/theme';
import { useFormatMessage } from 'lib/use-format-message';
import searchToggleIcon from 'lib/icon-search-toggle.svg';
import l from 'components/layout';
import SearchBox from './search-box';
import { Button } from '../ui/button';
import { ProductSearchProps } from './index';

const Layout = styled.div`
  align-items: center;
  background-color: ${colors.white};
  bottom: 1px;
  display: flex;
  flex: 1;
  left: 0;
  padding: ${spacing.l} 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`;

const Toggle = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font: ${fonts.extra};
  text-transform: uppercase;
  margin-left: ${spacing.m};
`;

const Icon = styled.img`
  width: 17px;
  height: 17px;
  margin-right: ${spacing.l};
`;

const CloseButton = styled(Button)`
  margin-left: ${spacing.xl};
`;

const SearchText = styled.span`
  font-weight: ${weights.regular};
`;

const SearchControl = styled(l.Row)`
  align-items: center;
`;

const TabletSearch: FunctionComponent<ProductSearchProps> = props => {
  const [isActive, flipActive] = useState(false);

  const toggleActive = (e: FormEvent): void => {
    e.preventDefault();
    flipActive(prev => !prev);
  };
  const formatMessage = useFormatMessage();
  const closeMsg = formatMessage('uiActions.close');
  if (isActive) {
    return (
      <Layout>
        <SearchBox {...props} />
        <CloseButton onClick={toggleActive}>{closeMsg}</CloseButton>
      </Layout>
    );
  }
  const searchLabel = formatMessage('search.buttonLabel');
  return (
    <Toggle onClick={toggleActive}>
      <SearchControl>
        <Icon src={searchToggleIcon} alt={searchLabel} />
        <SearchText>Search</SearchText>
      </SearchControl>
    </Toggle>
  );
};

export default TabletSearch;
