import { append, omit } from 'ramda';

const DELAY_ACTION = 'delayed-actions/DELAY_ACTION';
const MARK_ACTIONS_REPLAYED = 'delayed_actions/MARK_ACTIONS_REPLAYED';

export const delayAction = (actionToDelay, waitFor) => ({
  type: DELAY_ACTION,
  payload: { actionToDelay, waitFor },
});

export const markActionsReplayed = wasWaitingFor => ({
  type: MARK_ACTIONS_REPLAYED,
  payload: wasWaitingFor,
});

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case DELAY_ACTION:
      return {
        ...state,
        [payload.waitFor]: append(
          payload.actionToDelay,
          state[payload.waitFor],
        ),
      };

    case MARK_ACTIONS_REPLAYED:
      return omit([action.payload], state);

    default:
      return state;
  }
};
