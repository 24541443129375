import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors, spacing } from 'lib/theme';

const scaleLoadingAnimation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(0.75);
  }
`;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

// don't use ${loadingAnimation.getName()} in animation
// fields for some reason it is not working
const BlinkingLoadingSquare = styled.div`
  display: inline-block;
  width: ${spacing.l};
  height: ${spacing.l};
  background-color: ${colors.lightGrey};
  animation: ${scaleLoadingAnimation} 1.4s infinite ease-in-out;
  animation-fill-mode: both;
  margin: 0 1px;
  animation-delay: -0.32s;
  vertical-align: bottom;

  & + & {
    animation-delay: -0.16s;
  }

  & + & + & {
    animation-delay: 0s;
  }
`;

const Message = styled.p`
  font-size: 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaddedLoadingContainer = styled(LoadingContainer)`
  padding-top: ${spacing.xxxl};
  display: flex;
  justify-content: center;
`;

const Loading = ({ message = null, messageId = null }) => (
  <Container>
    <BlinkingLoadingSquare />
    <BlinkingLoadingSquare />
    <BlinkingLoadingSquare />
    {messageId && (
      <FormattedMessage id={messageId}>
        {msg => <Message>{msg}</Message>}
      </FormattedMessage>
    )}
    {message && <Message>{message}</Message>}
  </Container>
);

Loading.propTypes = {
  message: PropTypes.string,
  messageId: PropTypes.string,
};

Loading.defaultProps = {
  message: null,
  messageId: null,
};

export default Loading;
