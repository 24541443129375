import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { activateAccount, chooseShoppingMode } from 'ducks/authentication';
import AccountFilter from 'lib/components/AccountFilter';
import { accountShape } from 'lib/types';
import { borders, spacing } from 'lib/theme';
import {
  getActiveShoppingMode,
  getAvailableShoppingModes,
} from 'selectors/account';

import SegmentedLayout from '../../layout/segmented-layout';
import * as t from '../../typography';
import RadioInput, { RadioInputContainer } from '../../forms/radio-input';

const Layout = styled.div`
  border: ${borders.hairline};
  min-width: 330px;
  padding: 0 ${spacing.xl};
`;

const ShoppingModes = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  ${/* sc-sel */ RadioInputContainer} {
    margin-bottom: 0;
  }
`;

export class AccountMenuBody extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      activateAccount: PropTypes.func.isRequired,
      chooseShoppingMode: PropTypes.func.isRequired,
    }).isRequired,
    activeAccount: accountShape,
    activeShoppingMode: PropTypes.string.isRequired,
    availableShoppingModes: PropTypes.arrayOf(PropTypes.string.isRequired)
      .isRequired,
    history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  };

  static defaultProps = {
    activeAccount: null,
  };

  handleShoppingModeChange = mode => () => {
    const { actions } = this.props;
    actions.chooseShoppingMode(mode);
    this.returnHome();
  };

  handleSelectAccount = acct => {
    const { actions } = this.props;
    actions.activateAccount(acct);
    this.returnHome();
  };

  returnHome = () => {
    const { history } = this.props;
    history.replace('/');
  };

  render() {
    const {
      activeAccount,
      activeShoppingMode,
      availableShoppingModes,
    } = this.props;

    const accountChooser = (
      <AccountFilter
        selectedAccount={activeAccount}
        onSelectAccount={({ id, title, subtitle }) =>
          // Requires some data munging thanks to how
          // activateAccount works
          this.handleSelectAccount({
            id,
            name: title,
            number: subtitle,
          })
        }
      />
    );

    const shoppingMode = (
      <form key="shopping-mode">
        <FormattedMessage id="accounts.shopping-mode">
          {msg => <t.H6>{msg}</t.H6>}
        </FormattedMessage>
        <ShoppingModes>
          {availableShoppingModes.map(mode => (
            <RadioInput
              key={mode}
              id={mode}
              name="shopping-mode"
              checked={mode === activeShoppingMode}
              disabled={availableShoppingModes.length === 1}
              onChange={this.handleShoppingModeChange(mode)}
              label={`accounts.shopping-mode.${mode}`}
            />
          ))}
        </ShoppingModes>
      </form>
    );

    return (
      <Layout>
        <SegmentedLayout segments={[accountChooser, shoppingMode]} />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const auth = state.authentication || {};
  return {
    activeAccount: auth.selectedAccount,
    activeShoppingMode: getActiveShoppingMode(state),
    availableShoppingModes: getAvailableShoppingModes(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        activateAccount,
        chooseShoppingMode,
      },
      dispatch,
    ),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(AccountMenuBody);
