import { path, compose, prop } from 'ramda';
import { createSelector } from 'reselect';
import gql from 'graphql-tag';
import { isPresent } from 'lib/data-manipulation';

export const getUserProfile = path(['user', 'profile']);

export const getActivePreferences = state => state?.marketing?.preferences;

export const isUserProfileLoaded = createSelector(
  [getUserProfile],
  compose(isPresent, prop('id')),
);

export const CURRENT_USER_QUERY = gql`
  query currentUserQuery {
    __typename
    currentUser {
      id
      name
      locale
      email
      public {
        allotmentAllocations {
          current {
            balance {
              currency
              amount
            }
          }
        }
      }
      organizations {
        id
        name: organizationName
      }
      accounts(first: 1) {
        edges {
          node {
            id
            name: accountName
            shippingAddresses {
              id
            }
            organization {
              id
            }
            allotmentAllocations {
              current {
                startDate
                endDate
                balance {
                  amount
                  code: currency
                }
                spent {
                  amount
                  code: currency
                }
              }
              future {
                startDate
                endDate
                balance {
                  amount
                  code: currency
                }
                spent {
                  amount
                  code: currency
                }
              }
            }
          }
        }
      }
      roles {
        id
        name: roleName
        mappedBy
        isMixin
      }
      addresses {
        id
      }
      isCustomizationAvailable
    }
  }
`;

export default getUserProfile;
