import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { getLocale } from 'selectors/locale';
import { withoutRegion } from 'lib/locales';
import { dismiss } from 'ducks/dismissed-cookie-consent';
import { colors, fonts, breakpoint } from 'lib/theme';
import { useFormatMessage } from 'lib/use-format-message';
import { maxWidthMedia } from 'styles/mixins';
import { CloseButton } from './ui/button';
import l from './layout';
import { TextLink } from './ui/links';
import Cross from './cross';
import { BaseCaret } from './caret';

const Wrapper = styled(l.Container)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.black};
  color: ${colors.white};
  text-align: center;
  display: flex;
  z-index: 1;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    text-align: left;
  `};
`;

const LearnMoreLink = styled(TextLink)`
  margin: 0 10px;
  color: ${colors.white};
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    margin: 0;
  `};
`;

const TrustArcModalWrapper = styled.a`
  margin: 0 10px;
  color: ${colors.white};
  text-transform: uppercase;
  font: ${fonts.bold};
  text-decoration: none;
  transition: color 0.2s ease;

  ${BaseCaret} {
    transition: border-color 0.2s ease;
  }

  &:focus {
    color: ${colors.grey};
  }

  &:hover {
    color: ${colors.grey};
    cursor: pointer;
    text-decoration: underline;

    ${BaseCaret} {
      border-color: ${colors.grey};
    }
  }

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    margin: 0 5px;
  `};
`;

const LinkWrapper = styled.div`
  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    max-width: 90%;
  `};
`;

const CookieConsent = ({ actions, locale }) => {
  const privacyLink = `https://account.underarmour.com/${withoutRegion(
    locale,
  )}/data-management`;
  const formatMessage = useFormatMessage();

  const trustArcClickHandler = () => {
    window.truste.eu.clickListener();
  };

  return (
    <Wrapper>
      <LinkWrapper>
        <LearnMoreLink as="a" target="_blank" href={privacyLink} rel="noopener">
          {formatMessage('cookie-consent.new-learn-more-button')}
        </LearnMoreLink>
        <TrustArcModalWrapper
          id="trustarc"
          key="teconsent"
          onClick={trustArcClickHandler}
        >
          {formatMessage('cookie-consent.opt-out')}
        </TrustArcModalWrapper>
        <CloseButton onClick={actions.dismiss} aria-label="close">
          <Cross rotated={true} color={colors.white} />
        </CloseButton>
      </LinkWrapper>
    </Wrapper>
  );
};

CookieConsent.propTypes = {
  actions: PropTypes.shape({
    dismiss: PropTypes.func.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ locale: getLocale(state) });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ dismiss }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsent);
