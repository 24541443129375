import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { activeLayout, TABLET_LAYOUT } from 'selectors/screen-size';
import DesktopSearch from './desktop-search';
import TabletSearch from './tablet-search';

export interface ProductSearchProps {
  searchTerm: string;
  urlPath: string;
}
export const ProductSearch: FunctionComponent<ProductSearchProps> = ({
  searchTerm = '',
  urlPath = '',
}) => {
  const layout: string = useSelector(state => activeLayout(state));
  switch (layout) {
    case TABLET_LAYOUT:
      return <TabletSearch searchTerm={searchTerm} urlPath={urlPath} />;
    default:
      return <DesktopSearch searchTerm={searchTerm} urlPath={urlPath} />;
  }
};

export default ProductSearch;
