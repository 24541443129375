/*
 * Stop gap product size functions.
 * These resolve data inconsistency issues between API results and
 * algolia. To be removed with API updates and should not be used
 * haphazardly.
 */

const SIZES = ['XXS', 'XS', 'SM', 'MD', 'LG', 'XL', 'XXL', '3XL', '4XL', '5XL'];

const TIERS = {
  normal: 100,
  t: 200,
  y: 300,
  numeric: 400,
  k: 500,
  osfa: 900,
  unknown: 99999,
};
export const sizeRank = s => {
  let i = -1;

  if (s === 'OSFA') {
    return TIERS.osfa;
  }

  i = SIZES.indexOf(s);
  if (i > -1) {
    return TIERS.normal + i;
  }

  const lastChar = s.slice(-1);
  const allButLastChar = s.slice(0, -1);
  if (lastChar === 'T') {
    i = SIZES.indexOf(allButLastChar);
    if (i > -1) {
      return TIERS.t + i;
    }
  }
  if (lastChar === 'K') {
    const numericPart = parseFloat(allButLastChar);
    if (!Number.isNaN(numericPart)) {
      return TIERS.k + numericPart;
    }
  }

  if (s.slice(0, 1) === 'Y') {
    i = SIZES.indexOf(s.slice(1));
    if (i > -1) {
      return TIERS.y + i;
    }
  }

  // handle slashed sizes
  const [before, after] = s.split('/');
  if (after) {
    const namedSizeBefore = SIZES.indexOf(before);
    const namedSizeAfter = SIZES.indexOf(after);
    if (namedSizeBefore > -1 && namedSizeAfter > -1) {
      return (namedSizeBefore + namedSizeAfter) / 2;
    }

    const floatBefore = parseFloat(before, 10);
    const floatAfter = parseFloat(after, 10);
    if (!(Number.isNaN(floatBefore) || Number.isNaN(floatAfter))) {
      return floatBefore * 100 + floatAfter;
    }
  }

  const float = parseFloat(s);
  if (!Number.isNaN(float)) {
    return TIERS.numeric + float;
  }

  return TIERS.unknown;
};

export default sizeRank;
