import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { isMissing } from 'lib/data-manipulation';

export const NullCurrency = {
  amount: null,
  code: null,
};

/*
 * react-intl uses style as a prop name, which has other meaning for most
 * components. Here, it is specifying the class of number we're formatting.
 */
/* eslint-disable react/style-prop-object */
const Currency = ({ amount, code }) => {
  if (isMissing(amount) || isMissing(code)) {
    return <FormattedMessage id="currency.missingAmount" />;
  }

  const formattedNumberProps = {
    value: amount,
    currency: code,
    currencyDisplay: 'symbol',
    style: 'currency',
  };

  if (amount >= 0) {
    return <FormattedNumber {...formattedNumberProps} />;
  }

  return (
    <FormattedNumber {...{ ...formattedNumberProps, value: -amount }}>
      {formattedNumber => <span>({formattedNumber})</span>}
    </FormattedNumber>
  );
};
/* eslint-enable react/style-prop-object */

Currency.defaultProps = {
  amount: NullCurrency.amount,
  code: NullCurrency.code,
};

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
};

export default Currency;
