import { createSelector } from 'reselect';
import { fromPairs, path, props } from 'ramda';

const slugs = path(['slugs', 'results']);

export const getSlugTranslations = s =>
  fromPairs((s || []).map(props(['id', 'translation'])));

export const slugTranslations = createSelector([slugs], getSlugTranslations);

export const getSlugRanks = s =>
  fromPairs((s || []).map(props(['path', 'rank'])));

export const slugRanks = createSelector([slugs], getSlugRanks);
