import { handle } from 'redux-pack';
import gql from 'graphql-tag';

import { assoc, mergeDeepLeft, pick } from 'ramda';

export const FETCH = 'site-meta/FETCH';

const initialState = {};

const SITE_META_QUERY = gql`
  query getSiteMeta {
    siteMeta {
      contentfulSpaceId
      contentfulApiKey
      contentfulHost
      tokensUrl
      customizerScripts
    }
  }
`;

export const fetchSiteMeta = () => ({
  type: FETCH,
  promise: import('index').then(({ graphqlClient }) =>
    graphqlClient.query({
      query: SITE_META_QUERY,
    }),
  ),
});

export const reducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH:
      return handle(state, action, {
        start: mergeDeepLeft({ error: null, waiting: true }),
        finish: assoc('waiting', false),
        success: prevState =>
          mergeDeepLeft(
            pick(
              [
                'contentfulApiKey',
                'contentfulSpaceId',
                'contentfulHost',
                'tokensUrl',
                'customizerScripts',
              ],
              payload.data.siteMeta,
            ),
            { received: true },
            prevState,
          ),
        failure: prevState => ({
          ...prevState,
          error: payload.message,
        }),
      });

    default:
      return state;
  }
};

export default reducer;
