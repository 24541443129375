import { handle } from 'redux-pack';
import { loadMessages, fallbackLocale, polyfillLocale } from 'lib/locales';
import fallbackMessages from 'translations/en-US.json';

export const CHOOSE_LOCALE = 'CHOOSE_LOCALE';

export const chooseLocale = (locale, usePolyfill) => ({
  type: CHOOSE_LOCALE,
  promise: Promise.all([
    usePolyfill && polyfillLocale(locale),
    loadMessages(locale),
  ]),
  meta: { locale },
});

export const initialState = {
  locale: null,
  loading: false,
  messages: { [fallbackLocale]: fallbackMessages },
};

const locale = (state = initialState, action) => {
  switch (action.type) {
    case CHOOSE_LOCALE:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        success: prevState => ({
          ...prevState,
          locale: action.meta.locale,
          messages: {
            ...prevState.messages,
            [action.meta.locale]: action.payload[1],
          },
        }),
        // in this case we want to silently fail if the language change fails
        failure: prevState => prevState,
        finish: prevState => ({ ...prevState, loading: false }),
      });

    default:
      return state;
  }
};

export default locale;
