import styled from 'styled-components';
import { spacing } from 'lib/theme';

const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-right: ${spacing.m};
  position: relative;
  top: 4px;
`;

export default Icon;
