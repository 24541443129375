import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { media, maxWidthMedia } from 'styles/mixins';
import { breakpoint, colors, height, spacing } from 'lib/theme';
import l from 'components/layout';
import armourHouseSimple from 'lib/icons/armourhouse-simple.svg';
import LocaleContainer from './locale-container';
import Menu from './menu';

const HeaderItem = styled.div`
  margin-left: ${spacing.xxl};
  cursor: pointer;
`;

const Header = styled(l.Row)`
  background-color: ${colors.black};
  color: ${colors.white};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  display: flex;
  height: ${height.global};
  justify-content: space-between;
  align-items: center;

  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    height: ${spacing.gd.sm};
    padding: 0 ${spacing.gd.xs};
  `};
`;

const LogoImage = styled.img`
  ${maxWidthMedia(`${breakpoint.gd.phoneMax}`)`
    max-width: 60px;
  `};

  ${media(`${breakpoint.tablet}`)`
    height: 27px;
  `};
`;

export default () => (
  <Header>
    <FormattedMessage id="logo">
      {msg => <LogoImage src={armourHouseSimple} alt={msg} />}
    </FormattedMessage>
    <l.Row>
      <HeaderItem>
        <LocaleContainer inChrome={true} />
      </HeaderItem>
      <HeaderItem>
        <Menu />
      </HeaderItem>
    </l.Row>
  </Header>
);
