/* global __CONFIG */
/* eslint-disable */
// This is an exception where we export at the top of the file
// It is first defined up here and assigned a value after the app is started

export let graphqlClient;
/*
  IE11 workaround for react-loadable Object assign
  https://github.com/jamiebuilds/react-loadable/pull/144
*/
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks';
import ReactModal from 'react-modal';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { mergeDeepRight } from 'ramda';
import { polyfillLoader } from 'polyfill-io-feature-detection';
import configureStore from './store/configure-store';
import { browserLocale } from './lib/locales';
import { theme } from './lib/theme';
import Root from './components/root';
import {
  chooseLocale,
  initialState as localeInitialState,
} from './ducks/locale';
import { localeToUse } from './selectors/locale';
import { getSelectedAccountId } from './selectors/account';
import { getActiveUsername, userSignedIn } from './selectors/user-signed-in';
import { trackAuth } from './lib/newrelic';
import analytics from './lib/analytics';
import CookiePreferences from './lib/cookie-preferences';
import localStorageSupported from './lib/local-storage-supported';
import { LOCALSTORAGE_NAMESPACE } from './middleware/localStorage';
import rootReducer from './ducks/index';
import createApolloClient from 'lib/create-apollo-client';
import { TestIdentifiers } from 'lib/test-hooks';

/* eslint-enable */

function initErrorTracking() {
  const { SENTRY_DSN, COMMIT_SHA, ENV } = __CONFIG;

  if (SENTRY_DSN !== '__DSN_URL__') {
    (async function _initErrorTracking() {
      const Sentry = await import('@sentry/browser');
      Sentry.init({
        dsn: SENTRY_DSN,
        release: COMMIT_SHA,
        environment: ENV,
      });
    })();
  }
}

function initAnalytics(debug = false) {
  analytics.setDebug(debug);
  const { AMPLITUDE_API_KEY } = __CONFIG;
  if (AMPLITUDE_API_KEY !== '__AMPLITUDE_API_KEY__') {
    return analytics.install(AMPLITUDE_API_KEY);
  }
  return Promise.resolve(analytics);
}

function initCookiePreferences() {
  const cookiePreferences = new CookiePreferences();

  cookiePreferences.on('functional_approved', () => {
    analytics.setOptOut(false);
  });

  cookiePreferences.on('functional_denied', () => {
    analytics.setOptOut(true);
  });

  cookiePreferences.initialize();
}

function main() {
  initErrorTracking();
  initAnalytics();
  initCookiePreferences();

  const intlSupported = !!window.Intl;

  const localeState = {
    browserLocale: browserLocale(window.navigator),
    locale: { ...localeInitialState, intlSupported },
  };

  const storedState =
    (localStorageSupported() &&
      JSON.parse(localStorage.getItem(LOCALSTORAGE_NAMESPACE))) ||
    {};
  const reducersInitialState = rootReducer(undefined, {});

  const initialState = [reducersInitialState, storedState, localeState].reduce(
    mergeDeepRight,
  );

  const store = configureStore(initialState);

  if (userSignedIn(initialState)) {
    trackAuth(
      getActiveUsername(initialState),
      getSelectedAccountId(initialState),
    );
  }

  store.dispatch(chooseLocale(localeToUse(store.getState()), !intlSupported));

  graphqlClient = createApolloClient(store);

  let previouslySignedIn = false;
  store.subscribe(() => {
    const currentlySignedIn = userSignedIn(store.getState());
    if (previouslySignedIn && !currentlySignedIn) {
      graphqlClient.clearStore();
      analytics.setUserId(null);
    }
    if (!previouslySignedIn && currentlySignedIn) {
      analytics.setUserId(getActiveUsername(store.getState()));
    }
    previouslySignedIn = currentlySignedIn;
  });

  ReactModal.setAppElement('#root');

  const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    font-family: 'Neue Plak Text', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.overflow-hidden,
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  button {
    border-radius: 0;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
`;

  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={graphqlClient}>
        <ApolloProviderHooks client={graphqlClient}>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <TestIdentifiers.Provider
              value={{ enabled: __CONFIG.SHOULD_RENDER_TEST_IDS }}
            >
              <Root />
            </TestIdentifiers.Provider>
          </ThemeProvider>
        </ApolloProviderHooks>
      </ApolloProvider>
    </Provider>,
    document.getElementById('root'),
  );

  /* eslint-disable */
  if (module.hot) {
    module.hot.accept('./components/root', () => {
      const NextApp = require('./components/root').default;
      ReactDOM.render(
        <Provider store={store}>
          <GlobalStyle />
          <ApolloProvider client={graphqlClient}>
            <ApolloProviderHooks client={graphqlClient}>
              <ThemeProvider theme={theme}>
                <NextApp />
              </ThemeProvider>
            </ApolloProviderHooks>
          </ApolloProvider>
        </Provider>,
        document.getElementById('root'),
      );
    });
  }
  /* eslint-enable */
}

polyfillLoader({
  polyfillService: 'https://cdn.polyfill.io/v3/polyfill.min.js',
  features:
    'Promise,Promise.prototype.finally,Promise.all,URLSearchParams,fetch,Array.prototype.find,Array.prototype.flat,Array.prototype.flatMap,Array.prototype.from,Array.prototype.includes,Object.entries,Object.fromEntries,Object.keys,Object.values,Number.isNaN,String.prototype.includes,String.prototype.startsWith,Set,URL',
  onCompleted: main,
});
