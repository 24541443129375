import React, { FunctionComponent, useEffect } from 'react';
import gql from 'graphql-tag';
import { borders, spacing, colors } from 'lib/theme';
import questionIcon from 'lib/icons/question-transparent.svg';
import { useFormatNumber, useFormatMessage } from 'lib/use-format-message';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery } from 'react-apollo';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { activateAccount } from 'ducks/authentication';
import Currency from 'components/currency';
import { displayApiDate } from 'lib/dates';
import { getActiveCartId } from 'selectors/cart';
import { getSelectedAccount } from 'selectors/account';
import { MenuDropdown } from '../ui/menu';
import { PublicAccountMenu_CurrentUserQuery, Money } from '__generated__/types';
import Tooltip, { QuestionMark, overridePosition } from '../ui/tooltip';
import Arrow from '../ui/arrow';
import l from '../layout';
import {
  AccountTrigger,
  AccountIdentity,
  AccountLabel,
  AccountMode,
  Layout,
} from '../account/menu/ui';
import SegmentedLayout from '../layout/segmented-layout';
import * as t from '../typography';
import RadioInput, { RadioInputContainer } from '../forms/radio-input';

type Allocation = {
  startDate: Date;
  endDate: Date;
  balance: Money;
};

type Account = {
  id: string;
  accountName: string;
};

type AccountMenuBodyProps = {
  activeAccount: Account;
  allocation?: Allocation | null;
  availableAccounts: Account[];
  allotmentAccount: Account | null;
  billingTypeAllotment: string;
  billingTypeDealerBilling: string;
};

type AccountMenuBodyType = FunctionComponent<AccountMenuBodyProps>;

const PUBLIC_DATA = gql`
  query PublicAccountMenu_CurrentUser {
    currentUser {
      id
      name
      public {
        allotmentAccount {
          id
          accountName
        }
        billingAccount {
          id
          accountName
        }
        allotmentAllocations {
          current {
            startDate
            endDate
            balance {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

export const MenuBody = styled.div`
  background-color: ${colors.white};
  margin: 0;
  padding: 0;
  position: absolute;
  display: none;
  right: 0;
  top: 100%;
  transition: max-height 0.1s ease-in-out;

  ${/* sc-sel */ MenuDropdown}:focus-within & {
    display: unset;
  }

  ${/* sc-sel */ MenuDropdown}:hover & {
    display: inherit;
  }
`;

const TooltipText = styled.div`
  padding: ${spacing.m};
`;

const PublicLayout = styled.div`
  border: ${borders.hairline};
  min-width: 330px;
  padding: 0 ${spacing.xl};
`;

const PublicCurrency = styled(t.BoldSubText)`
  margin-right: 5px;
`;

const PublicDate = styled(t.SubText)`
  margin-left: 5px;
`;

const ShoppingModes = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  ${/* sc-sel */ RadioInputContainer} {
    margin-bottom: 0;
  }
`;

const AccountMenuBody: AccountMenuBodyType = ({
  activeAccount,
  allocation,
  availableAccounts,
  allotmentAccount,
  billingTypeAllotment,
  billingTypeDealerBilling,
}) => {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const actions = bindActionCreators({ activateAccount }, dispatch);

  const handleBillingModeChange = (account: Account | null) => {
    actions.activateAccount(account);
  };

  const billingModeDetails =
    activeAccount.id === allotmentAccount?.id ? (
      <div>
        <t.H6>{formatMessage('allotment.public.current-allotment')}</t.H6>
        <PublicCurrency>
          <Currency
            amount={allocation?.balance.amount}
            code={allocation?.balance.currency}
          />
        </PublicCurrency>
        <PublicDate>
          {`${displayApiDate(allocation?.startDate)}-${displayApiDate(
            allocation?.endDate,
          )}`}
        </PublicDate>
      </div>
    ) : (
      <div />
    );

  const billingModeSelector = (
    <form key="billing-mode">
      <l.Row>
        <t.H6>{formatMessage('order-detail.payment-method')}</t.H6>
        <QuestionMark
          data-tip={true}
          data-for={allotmentAccount?.id}
          src={questionIcon}
          alt={formatMessage('icon.question-mark')}
        />
        <Tooltip
          place="bottom"
          id={activeAccount.id}
          overridePosition={overridePosition}
        >
          <TooltipText>{formatMessage('allotment.public.tooltip')}</TooltipText>
        </Tooltip>
      </l.Row>
      <ShoppingModes>
        {availableAccounts?.map(account => (
          <RadioInput
            key={account?.id}
            id={account?.id}
            name="billing-mode"
            checked={account?.id === activeAccount.id}
            onChange={() => handleBillingModeChange(account)}
            label={
              account?.id === allotmentAccount?.id
                ? billingTypeAllotment
                : billingTypeDealerBilling
            }
          />
        ))}
      </ShoppingModes>
    </form>
  );

  return (
    <PublicLayout>
      <SegmentedLayout segments={[billingModeSelector, billingModeDetails]} />
    </PublicLayout>
  );
};

export const useAllotmentAccount = () => {
  const [getAllotmentAccount, { loading, data, error }] = useLazyQuery<
    PublicAccountMenu_CurrentUserQuery
  >(PUBLIC_DATA, {
    fetchPolicy: 'cache-and-network',
  });
  const allotmentAccount = data?.currentUser?.public?.allotmentAccount;
  const billingAccount = data?.currentUser?.public?.billingAccount;
  const allotmentAllocations = data?.currentUser?.public?.allotmentAllocations;
  return {
    allotmentAccount,
    billingAccount,
    allotmentAllocations,
    loading,
    error,
    getAllotmentAccount,
  };
};

const PublicAccountMenu = () => {
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();
  const {
    loading,
    allotmentAccount,
    billingAccount,
    allotmentAllocations,
    error,
    getAllotmentAccount,
  } = useAllotmentAccount();
  const activeAccount = useSelector(
    state => getSelectedAccount(state) as Account,
  );
  const accountHasAllocation = activeAccount?.id === allotmentAccount?.id;
  const allocation = allotmentAllocations?.current;
  const billingTypeAllotment = 'public-account-menu.allotment';
  const billingTypeDealerBilling = 'public-account-menu.dealer-billing';
  const selectedBillingType = accountHasAllocation
    ? billingTypeAllotment
    : billingTypeDealerBilling;

  const activeCartID = useSelector(getActiveCartId);
  useEffect(() => {
    getAllotmentAccount();
  }, [activeCartID, getAllotmentAccount]);

  // Public users will ALWAYS have a billing account (it's the Armourhouse Public Account)
  // They WON'T always have an allotment account, so if it's null/undefined here then we just
  // render nothing, and the user has no concept of switching "billing modes" (i.e. switching accounts)
  if (loading || error || !allotmentAccount) {
    return null;
  }

  return (
    <Layout>
      <MenuDropdown>
        <AccountTrigger>
          <AccountLabel>
            <AccountMode>
              {formatMessage('order-detail.payment-method')}
            </AccountMode>
            <AccountIdentity title={selectedBillingType}>
              {accountHasAllocation
                ? `${formatMessage(selectedBillingType)} (${formatNumber(
                    parseFloat(allocation?.balance.amount!),
                    {
                      style: 'currency',
                      currency: allocation?.balance.currency,
                    },
                  )})`
                : formatMessage(selectedBillingType)}
            </AccountIdentity>
          </AccountLabel>
          <Arrow id="accountIdentity" />
        </AccountTrigger>
        <MenuBody>
          <AccountMenuBody
            allocation={allocation}
            activeAccount={activeAccount}
            availableAccounts={[allotmentAccount, billingAccount as Account]}
            billingTypeAllotment={billingTypeAllotment}
            billingTypeDealerBilling={billingTypeDealerBilling}
            allotmentAccount={allotmentAccount}
          />
        </MenuBody>
      </MenuDropdown>
    </Layout>
  );
};

export default PublicAccountMenu;
