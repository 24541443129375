/* eslint-disable */

import gql from 'graphql-tag';
import { pathOr, groupBy } from 'ramda';
import { noticeError } from '../lib/errors';
import { addCurrencies } from '../lib/currency';
import { getFulfillerAndConfigStatus } from '../selectors/products';
import { BreakoutItemStatusFeed } from '__generated__/types';

interface Currency {
  amount: number;
  code: string;
}

interface ConfigAndFulfillerPayload {
  fulfillers: Fulfiller[];
  configStatuses: string[];
}

interface Fulfiller {
  fulfillerName: string;
  sapFulfillerName: string;
  fulfillerCustomizationUrl?: string;
  id: string;
  isUAFulfiller: Boolean;
}

interface Item {
  qty: string;
  recipeCode: string;
}

interface AddToCartArgs {
  formType: string;
  cartType: string;
  viewMode: string;
  articles: string[];
  quantity: number;
  total: number;
  shoppingMode: string;
  styleIds: string[];
  accountId: string;
  hasConfiguredProduct: boolean;
}

interface DuplicateCartArgs {
  shoppingMode: string;
  quantity: number;
  subtotal: Currency;
}

interface DirectedSearchArgs {
  filters: any;
  shoppingMode: string;
}

interface ContentClickArgs {
  contentName: string;
  shoppingMode: string;
  page?: string;
}

interface CustomizerAccessArgs {
  shoppingMode: string;
  styleId: string;
  accessLocation: string;
  accountId: string;
}

interface AccountProperties {
  name: string;
  number: string;
  organization: Organization;
  salesOrgCode: string;
  customerGroupCode: string;
  countryCode: string;
  salesGroupCode: string;
}

interface Organization {
  id: string;
  name: string;
}

// non-camel case strings do not have capital letters
const isNotCamelCase = (str: string) => !/[A-Z]/.test(str);

// convert ONLY camel case string to snake case
const toSnakeCase = (str: string) => {
  let snaked = '';
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    if (char === char.toUpperCase()) {
      snaked += `_${char.toLowerCase()}`;
    } else {
      snaked += char;
    }
  }
  return snaked;
};

// make camel case object key names snake case
export const snakeCaseKeys = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => {
    const newKey = isNotCamelCase(key) ? key : toSnakeCase(key);
    return {
      ...acc,
      [newKey]: obj[key],
    };
  }, {});

export const aggregateBreakouts = (breakouts: any) => {
  return breakouts.reduce(
    (acc: any, b: any) => {
      // destructures money and items from each breakout
      const { totalFinal, hasConfiguredProduct, totalQuantity } = b;
      let { totalFinal: totalFinalAcc, totalQty: totalQtyAcc } = acc;
      // Gives initial accumulator value
      if (!totalFinalAcc) {
        totalFinalAcc = { amount: 0, code: totalFinal.code };
      }
      return {
        ...acc,
        totalFinal: addCurrencies(totalFinal, totalFinalAcc),
        totalQty: totalQuantity + totalQtyAcc,
        hasConfiguredProduct,
      };
    },
    { totalQty: 0 },
  );
};

class Analytics {
  _a: any = null;

  _debug = false;

  install = async (apiKey: any, userID: any, debug = false) => {
    if (apiKey) {
      const amplitude = await import('amplitude-js');
      const instance = amplitude.getInstance();
      instance.init(apiKey, userID);
      this._a = instance;
    }
    this._debug = debug;
    return this;
  };

  setDebug = (d: any) => {
    this._debug = d;
  };

  ready = () => this._a !== null;

  setOptOut = (isOptingOut: boolean) =>
    this.ready() ? this._a.setOptOut(isOptingOut) : null;

  setUserId = (userID: string) =>
    this.ready() ? this._a.setUserId(userID) : null;

  setUserRole = (role: string) =>
    this.ready() ? this._a.setUserProperties({ role }) : null;

  setAccountProperties = ({
    name,
    number,
    organization,
    salesOrgCode,
    customerGroupCode,
    countryCode,
    salesGroupCode,
  }: AccountProperties) => {
    this.ready()
      ? this._a.setUserProperties({
          accountName: name,
          accountNumber: number,
          organization: pathOr(null, ['name'], organization),
          salesOrg: salesOrgCode,
          customerGroup: customerGroupCode,
          location: countryCode,
          salesGroup: salesGroupCode,
        })
      : null;
  };

  setLocale = (locale: string) => {
    this.ready() ? this._a.setUserProperties({ locale }) : null;
  };

  clearUserProperties = () =>
    this.ready() ? this._a.clearUserProperties() : null;

  logEvent = (eventType: string, ...args: any[]) => {
    if (this._debug) {
      // eslint-disable-next-line
      console.info(`[Analytics Event]: [${eventType}]`, ...args);
    }
    if (this.ready()) {
      return this._a.logEvent(eventType, ...args);
    }
    return null;
  };

  logSearch = (searchTerms: any, shoppingMode: string, filters: any) =>
    this.logEvent('search', {
      search_terms: searchTerms,
      shopping_mode: shoppingMode,
      filters: snakeCaseKeys(filters),
    });

  logFooterNavigation = (page: any) =>
    this.logEvent('footer_navigation', { page });

  logInvoiceNavigation = (page: string) =>
    this.logEvent('invoice_navigation', { page });

  logCategoryNavigation = (category: any, shoppingMode: string) =>
    this.logEvent('category_navigation', {
      category,
      shopping_mode: shoppingMode,
    });

  logFilter = (filters: any, shoppingMode: string) =>
    this.logEvent('filter', {
      filters: snakeCaseKeys(filters),
      shopping_mode: shoppingMode,
    });

  logProductDetailView = async (
    styleId: string,
    styleName: string,
    shoppingMode: string,
    accountId: string,
  ) => {
    const configAndFulfiller: ConfigAndFulfillerPayload = await getFulfillerAndConfigStatus(
      {
        styleIds: styleId,
        accountId,
        shoppingMode,
      },
    );
    this.logEvent('product_detail', {
      style: {
        id: styleId,
        name: styleName,
      },
      shopping_mode: shoppingMode,
      fulfillers: configAndFulfiller.fulfillers,
      config_statuses: configAndFulfiller.configStatuses,
    });
  };

  logQuickOrderView = (
    styleId: string,
    styleName: string,
    shoppingMode: string,
  ) =>
    this.logEvent('quick_order', {
      style: { id: styleId, name: styleName },
      shoppingMode,
    });

  logAddToCart = async ({
    formType,
    cartType,
    viewMode,
    articles,
    quantity,
    total,
    shoppingMode,
    styleIds,
    accountId,
    hasConfiguredProduct,
  }: AddToCartArgs) => {
    const configAndFulfiller = await getFulfillerAndConfigStatus({
      styleIds,
      accountId,
      shoppingMode,
    });
    this.logEvent('add_to_cart', {
      form_type: formType,
      view_type: viewMode,
      cart_type: cartType,
      shopping_mode: shoppingMode,
      articles,
      quantity,
      total,
      has_configured_product: hasConfiguredProduct,
      fulfillers: configAndFulfiller.fulfillers,
      config_statuses: configAndFulfiller.configStatuses,
    });
  };

  logCheckout = (cart: any) => async () => {
    try {
      const {
        id: cartId,
        account: { id: accountId },
        shoppingMode,
        items,
        checkoutState: { paymentMethod, fulfillmentMethod, shippingAddress },
        origin,
      } = cart;
      const styleIds = items.edges.map((item: any) => item.node.styleId);
      const uniqueStyleIds = [...new Set(styleIds)];
      const configAndFulfiller = await getFulfillerAndConfigStatus({
        styleIds: uniqueStyleIds,
        shoppingMode,
        accountId,
      });
      // Groups breakouts by fulfiller, so that each fulfiller sends a separate event
      const groupedByFulfiller = groupBy(pathOr('', ['fulfiller', 'id']));
      const handleEachOrder = (order: any) => {
        const grouped = groupedByFulfiller(order.breakouts);
        const eventsToSend = Object.entries(grouped).map(
          // Destructures array into unused ID and the breakouts associated with it
          ([_, breakouts]: any) => {
            const [breakout] = breakouts;
            return {
              fulfiller: breakout.fulfiller.fulfillerName,
              ...aggregateBreakouts(breakouts),
              orderNumber: breakout.order.number,
            };
          },
        );
        // Sends each event by fulfiller
        eventsToSend.forEach(event => {
          this.logEvent('checkout', {
            order_number: event.orderNumber,
            shopping_mode: shoppingMode,
            cart_origin: origin,
            quantity: event.totalQty,
            config_status: configAndFulfiller.configStatuses,
            fulfiller: event.fulfiller,
            total: {
              currency: event.totalFinal.code,
              amount: event.totalFinal.amount,
            },
            ship_to_type: shippingAddress.sapNumber
              ? 'From List'
              : 'One Time Drop Ship',
            payment_method: paymentMethod.paymentMethodName,
            fulfillment_method: fulfillmentMethod.name,
            has_configured_product: event.hasConfiguredProduct,
          });
        });
      };
      const { graphqlClient } = await import('../index');
      const ordersResponse = await graphqlClient.query({
        query: gql`
          query getCart($cartId: ID!) {
            cart: node(id: $cartId) {
              ... on Cart {
                id
                origin
                orders {
                  breakouts {
                    fulfiller {
                      id
                      fulfillerName
                    }
                    hasConfiguredProduct
                    totalQuantity
                    totalFinal {
                      amount
                      code: currency
                    }
                    order {
                      number
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          cartId,
        },
        // order data won't exist in the cache when this request is made
        fetchPolicy: 'no-cache',
      });
      const orders = pathOr([], ['data', 'cart', 'orders'], ordersResponse);
      orders.forEach((order: any) => handleEachOrder(order));
    } catch (e) {
      noticeError(new Error('Failed to log Amplitude checkout event'), e);
    }
  };

  logDownload = (type: string, catalogId: string, catalogName: string) =>
    this.logEvent('catalog_download', {
      type,
      catalog_id: catalogId,
      catalog_name: catalogName,
    });

  logOrderUpload = (uploadedCarts: any) =>
    this.logEvent('order_upload', uploadedCarts);

  logShareCart = (shoppingMode: string) =>
    this.logEvent('share_cart', { shopping_mode: shoppingMode });

  logReturnCart = (shoppingMode: string) =>
    this.logEvent('return_cart', { shopping_mode: shoppingMode });

  logDuplicateOrder = (shoppingMode: string) =>
    this.logEvent('duplicate_order', { shopping_mode: shoppingMode });

  logDuplicateCart = ({
    shoppingMode,
    quantity,
    subtotal,
  }: DuplicateCartArgs) =>
    this.logEvent('duplicate_cart', {
      shopping_mode: shoppingMode,
      quantity,
      total: subtotal,
    });

  logDirectedSearch = ({ filters, shoppingMode }: DirectedSearchArgs) =>
    this.logEvent('directed_search', {
      shopping_mode: shoppingMode,
      filters,
    });

  logContentClick = (
    contentType: string,
    contentName: string,
    shoppingMode: string,
    page?: string,
  ) =>
    this.logEvent('content_click', {
      content_type: contentType,
      content_name: contentName,
      shopping_mode: shoppingMode,
      page,
    });

  logContentView = (
    contentType: string,
    contentName: string,
    shoppingMode: string,
  ) =>
    this.logEvent('content_view', {
      content_type: contentType,
      content_name: contentName,
      shopping_mode: shoppingMode,
    });

  logCarouselClick = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentClick('carousel', contentName, shoppingMode);

  logCarouselView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('carousel', contentName, shoppingMode);

  logInGridImageClick = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentClick('ingrid_image', contentName, shoppingMode);

  logInGridImageView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('ingrid_image', contentName, shoppingMode);

  logPopupClick = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentClick('popup', contentName, shoppingMode);

  logPopupView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('popup', contentName, shoppingMode);

  logPageNoticeClick = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentClick('page_notice', contentName, shoppingMode);

  logPageNoticeView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('page_notice', contentName, shoppingMode);

  logVideoView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('video', contentName, shoppingMode);

  logVideoClick = ({ contentName, shoppingMode, page }: ContentClickArgs) =>
    this.logContentClick('video', contentName, shoppingMode, page);

  logBannerView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('banner', contentName, shoppingMode);

  logFitGuideView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('fitguide', contentName, shoppingMode);

  logFitGuideItemView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('fitguide_item', contentName, shoppingMode);

  logLoginImageView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('login_image', contentName, shoppingMode);

  logSetView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('set', contentName, shoppingMode);

  logSizeChartView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('size_chart', contentName, shoppingMode);

  logStaticPageView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('static_page', contentName, shoppingMode);

  logTechSheetClick = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentClick('tech_sheet', contentName, shoppingMode);

  logTechSheetView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('tech_sheet', contentName, shoppingMode);

  logTechnologyView = ({ contentName, shoppingMode }: ContentClickArgs) =>
    this.logContentView('technology', contentName, shoppingMode);

  logOrderDownload = () => this.logEvent('order_download');

  logSizeRun = (shoppingMode: string) =>
    this.logEvent('size_run', { shopping_mode: shoppingMode });

  logPrintOrder = () => this.logEvent('print_order');

  logLogin = () => this.logEvent('login');

  logLoginFail = () => this.logEvent('login failed');

  logForgotPassword = () => this.logEvent('forgot_password');

  logCustomizerAccess = async ({
    shoppingMode,
    styleId,
    accessLocation,
    accountId,
  }: CustomizerAccessArgs) => {
    const fulfillerAndConfig = await getFulfillerAndConfigStatus({
      styleIds: styleId,
      accountId,
      shoppingMode,
    });
    this.logEvent('accessed_customizer', {
      shopping_mode: shoppingMode,
      style: styleId,
      fulfiller: fulfillerAndConfig.fulfillers[0].fulfillerName,
      config_status: fulfillerAndConfig.configStatuses,
      accessLocation,
    });
  };
}

export default new Analytics();
