import { combineReducers } from 'redux';
import { handle } from 'redux-pack';
import { compose, isEmpty, isNil, map, propOr } from 'ramda';
import gql from 'graphql-tag';
import { formatDate } from 'lib/dates';
import { mungeCurrency } from 'lib/currency';
import { grafikiShoppingModes } from 'lib/account';
import { PRICE_MSRP, PRICE_ACCOUNT } from 'lib/pricing';
import { ACTIVATE_ACCOUNT, CHOOSE_SHOPPING_MODE } from '../authentication';

export const FETCH_STYLE_PRICES = 'search/FETCH_STYLE_PRICES';
export const CLEAR_STYLE_PRICES = 'cart/byStyle/CLEAR_STYLE_PRICES';

const GET_PRICES_BY_STYLE = gql`
  query getPricesByStyle(
    $accountId: ID!
    $styleIds: [String!]!
    $effectiveDate: Date!
    $shoppingMode: ShoppingMode!
  ) {
    node(id: $accountId) {
      ... on Account {
        id
        products(
          styleIds: $styleIds
          effectiveDate: $effectiveDate
          shoppingMode: $shoppingMode
        ) {
          edges {
            node {
              id
              styleId
            }
            pricing {
              accountPrice {
                max {
                  amount
                  code: currency
                }
                min {
                  amount
                  code: currency
                }
              }
              msrp {
                max {
                  amount
                  code: currency
                }
                min {
                  amount
                  code: currency
                }
              }
              promoPrice {
                max {
                  amount
                  code: currency
                }
                min {
                  amount
                  code: currency
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const emptyPrices = {
  data: {
    styles: [],
    materials: [],
    skus: [],
  },
};

const transformStylePrices = (edges = []) => {
  const styles = edges.map(edge => ({
    styleId: edge.node.styleId,
    msrp: edge.pricing.msrp,
    accountPrice: edge.pricing.accountPrice,
    promoPrice: edge.pricing.promoPrice,
  }));
  return {
    data: {
      styles,
    },
  };
};

export const fetchStylePrices = (
  accountId,
  styleIds,
  shippingDate,
  shoppingMode,
) => ({
  type: FETCH_STYLE_PRICES,
  promise: import('index').then(({ graphqlClient }) => {
    if (isNil(styleIds) || isEmpty(styleIds)) {
      return emptyPrices;
    }
    const effectiveDate = formatDate(shippingDate);
    return graphqlClient
      .query({
        query: GET_PRICES_BY_STYLE,
        variables: {
          accountId,
          styleIds,
          effectiveDate,
          shoppingMode: grafikiShoppingModes[shoppingMode],
        },
      })
      .then(payload => {
        const edges = payload?.data?.node?.products?.edges;
        if (!edges) {
          return emptyPrices;
        }
        return transformStylePrices(edges);
      });
  }),
});

export const clearStylePrices = () => ({ type: CLEAR_STYLE_PRICES });

export const wrangleAccountPrice = compose(
  map(mungeCurrency),
  propOr({}, PRICE_ACCOUNT),
);

export const wrangleMSRP = compose(map(mungeCurrency), propOr({}, PRICE_MSRP));

const mungeStylePrices = items =>
  (items || []).reduce(
    (entries, item) => ({
      ...entries,
      [item.styleId]: {
        accountPrice: item.accountPrice,
        msrp: item.msrp,
        promoPrice: item.promoPrice,
      },
    }),
    {},
  );

const byStyleId = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATE_ACCOUNT:
    case CHOOSE_SHOPPING_MODE:
      return {};

    case FETCH_STYLE_PRICES:
      return handle(state, action, {
        start: () => ({}),
        success: () => mungeStylePrices(payload.data.styles),
      });

    case CLEAR_STYLE_PRICES:
      return {};

    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  if (action.type === FETCH_STYLE_PRICES) {
    return handle(state, action, { start: () => true, finish: () => false });
  }
  return state;
};

const error = (state = false, action) => {
  const { type, payload } = action;
  if (type === FETCH_STYLE_PRICES) {
    return handle(state, action, {
      start: () => null,
      failure: () => payload.message,
    });
  }
  return state;
};

export default combineReducers({ error, fetching, byStyleId });
