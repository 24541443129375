import isIE from './ie-version';

export const pad = n => `${n < 10 ? '0' : ''}${n}`;

// takes a Date object
// returns a string in the UA API date format (YYYY-MM-DD)
export const formatDate = date => {
  // don't interrupt rendering if this is somehow called with something
  // that isn't a date.
  if (!date || !date.getFullYear) {
    return '';
  }
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}`;
};

export const parseDateToEpoch = date => {
  if (!date) {
    return NaN;
  }
  const [rawYear, rawMonth, rawDay] = date.split('-');
  const year = parseInt(rawYear, 10);
  const month = parseInt(rawMonth, 10) - 1;
  const day = parseInt(rawDay, 10);
  if (day === 0 || day > 31 || month > 12) {
    return NaN;
  }
  return new Date(year, month, day).getTime();
};

export const tryParseDate = date => {
  const epoch = parseDateToEpoch(date);
  return Number.isNaN(epoch) ? null : new Date(epoch);
};

// takes a Date object
// returns a string in a format preferred by the user's browser/OS.
export const displayDate = date => {
  if (!date) {
    return '';
  }

  // On IE, there is no native datepicker, so date input fields are displayed
  // as text fields and validated to be in YYYY-MM-DD format.  Thus, for IE,
  // to maintain consistent formatting we will render all dates to match the
  // expected format of date inputs.
  if (isIE()) {
    return formatDate(date);
  }

  return date.toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

// takes a string the UA API date format
// returns a string in the format preferred by the user's browser/OS.
export const displayApiDate = dateString =>
  displayDate(tryParseDate(dateString));

// determine ship date based on the shipping date ranges of the account
// and shopping mode, and a shipment date on a cart
export const calculateShipDate = (
  firstShipDate,
  lastShipDate,
  cartShipmentDate,
) => {
  const requestedShipDate = cartShipmentDate || firstShipDate;
  const invalidShippingDate =
    requestedShipDate === null ||
    (firstShipDate && requestedShipDate < firstShipDate) ||
    (lastShipDate && requestedShipDate > lastShipDate);

  return invalidShippingDate ? firstShipDate : requestedShipDate;
};
