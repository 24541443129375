import { assoc, path } from 'ramda';
import { createClient } from 'contentful';
import { isMissing } from 'lib/data-manipulation';
import { isReduxPackAction } from 'lib/redux-pack';
import { getLocale } from 'selectors/locale';
import { delayAction } from 'ducks/delayed-actions';
import { FETCH } from 'ducks/site-meta';

export default store => next => action => {
  const contentfulRequest = path(['meta', 'contentfulRequest'], action);
  if (!contentfulRequest || isReduxPackAction(action)) {
    return next(action);
  }

  const state = store.getState();

  const { contentfulApiKey, contentfulSpaceId, contentfulHost } =
    state.siteMeta || {};
  if (isMissing(contentfulSpaceId) || isMissing(contentfulApiKey)) {
    return store.dispatch(delayAction(action, FETCH));
  }

  const contentfulClient = createClient({
    space: contentfulSpaceId,
    accessToken: contentfulApiKey,
    host: contentfulHost,
  });

  const payload = assoc('locale', getLocale(state))(contentfulRequest);

  const actionWithContentful = assoc(
    'promise',
    contentfulClient.getEntries(payload),
  )(action);

  return store.dispatch(actionWithContentful);
};
