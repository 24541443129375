export const EDIT_CART = 'cart/EDIT_CART';

export default function cartIdEditing(state = null, action) {
  switch (action.type) {
    case EDIT_CART:
      return action.meta.id;

    default:
      return state;
  }
}
