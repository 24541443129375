import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { GetItemPropsOptions } from 'downshift';
import { Maybe, Map } from 'types';
import { isMissing } from 'lib/data-manipulation';
import { thumbnailUrl } from 'lib/product-images';
import { instantResultsProducts } from 'selectors/products';
import { slugTranslations } from 'selectors/slugs';
import { borders, colors, fonts, spacing } from 'lib/theme';
import { defaultGenericArticle } from 'lib/product';
import { SmartProductImage } from 'components/products/smart-product-image';

const Wrapper = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  background-color: ${colors.white};
  width: 100%;
  border: ${borders.thinDarkGrey};
  border-top: none;
  padding: ${spacing.m};
  position: relative;
  z-index: 2;
`;

const SearchTerm = styled.span`
  font: ${fonts.heavy};
`;

const ResultsHeader = styled.div`
  font: ${fonts.bold};
  margin-top: ${spacing.xl};
  padding-left: ${spacing.s};
  text-transform: uppercase;
`;

const Result = styled.div<{ highlighted?: boolean }>`
  margin-top: ${spacing.s};
  margin-bottom: ${spacing.s};
  padding: ${spacing.s};
  display: flex;
  cursor: pointer;
  background-color: ${p => (p.highlighted ? colors.offWhite : colors.white)};
`;
const SearchFor = styled(Result)<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  font: ${fonts.regular};
`;

const NoResults = styled(Result)`
  display: block;
  font: ${fonts.regular};
  text-align: center;
`;

const ThumbnailWrapper = styled.div`
  background-color: ${colors.offWhite};
  height: 50px;
  overflow: hidden;
  text-align: center;
  width: 50px;
`;

const Description = styled.div`
  margin-left: ${spacing.m};
  font: ${fonts.tiny};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.darkGrey};
`;

const ProductName = styled.div`
  color: ${colors.black};
  font: ${fonts.regular};
`;
export type GenericArticle = {
  id: string;
  styleId: string;
  colorHex: string;
  colorName: string;
  genders: Array<string>;
  chip: Maybe<string>;
  isCustomizable: boolean;
  requiresCustomization: boolean;
  thumbnail: string;
  sizes: Array<{
    id: string;
    display: string;
    articleId: string;
  }>;
};
export type SearchSuccess = {
  defaultGenericArticleId: string;
  details: Array<string>;
  description?: string;
  styleCode: string;
  name: string;
  category: Array<string>;
  fit: Array<string>;
  genericArticles: Array<GenericArticle>;
};
export type Item = SearchSuccess | string;
interface InstantResultsProps {
  /* Types defined @DownShift lib*/
  getItemProps(options: GetItemPropsOptions<Item>): any;
  highlightedIndex: Maybe<number>;
  inputValue: Maybe<string>;
  selectedItem: Maybe<Item>;
  isOpen: boolean;
}
interface InstantState {
  error: boolean;
  term: string;
  waiting: boolean;
}
export const InstantResults: FunctionComponent<InstantResultsProps> = ({
  getItemProps,
  highlightedIndex = -1,
  inputValue = '',
  selectedItem,
  isOpen,
}) => {
  const { error = false, term = '', waiting = false } = useSelector(
    (state: any) => state?.search?.instant ?? {},
  ) as InstantState;
  const results = useSelector(instantResultsProducts) as Array<SearchSuccess>;
  const slugs = useSelector(slugTranslations) as Map<string>;

  const visible =
    isOpen && !isMissing(term) && !(waiting && isMissing(results));
  const showNoResults = !(error || waiting) && results && results.length === 0;
  const showResults = !(error || isMissing(results));

  return (
    <Wrapper {...{ visible }}>
      <SearchFor
        visible={!showNoResults}
        highlighted={highlightedIndex === 0}
        {...getItemProps({
          item: inputValue as string,
          index: 0,
          selected: selectedItem === inputValue,
        })}
      >
        <FormattedMessage id="search.instant.for" />
        &nbsp;
        <SearchTerm>
          &ldquo;
          {term}
          &rdquo;
        </SearchTerm>
      </SearchFor>
      {error && (
        <NoResults>
          <FormattedMessage id="search.instant.error" />
        </NoResults>
      )}
      {showNoResults && (
        <NoResults>
          <FormattedMessage id="search.instant.noResults" />
          &nbsp;
          <SearchTerm>
            &ldquo;
            {term}
            &rdquo;
          </SearchTerm>
        </NoResults>
      )}
      {showResults && (
        <div>
          <ResultsHeader>
            <FormattedMessage id="search.instant.products" />
          </ResultsHeader>
          {results.map((p, i) => (
            <Result
              key={p.styleCode}
              highlighted={highlightedIndex === i + 1}
              {...getItemProps({
                key: p.styleCode,
                item: p,
                index: i + 1,
                selected: selectedItem === p,
              })}
            >
              <ThumbnailWrapper>
                <SmartProductImage
                  alt={p.name}
                  width={50}
                  height={50}
                  src={thumbnailUrl(defaultGenericArticle(p)?.id, 100)}
                />
              </ThumbnailWrapper>
              <Description>
                <ProductName>{p.name}</ProductName>
                <div>#{p.styleCode}</div>
                <div>{slugs[p.category.pop() as string]}</div>
              </Description>
            </Result>
          ))}
        </div>
      )}
    </Wrapper>
  );
};
export default InstantResults;
