import React, { useEffect } from 'react';
import styled from 'styled-components';
import analytics from 'lib/analytics';
import { colors, fonts, spacing } from 'lib/theme';
import { NoBackgroundButton } from 'components/ui/button';
import { ButtonLink } from 'components/ui/links';
import LeftPopup from './left';

const Layout = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: ${spacing.m} 60px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.m};
  margin: 60px 0;
`;

const Controls = styled.div`
  flex: 0;
`;

const Control = styled.div`
  text-align: center;
  margin: ${spacing.s} 0;
`;

const Heading = styled.div`
  color: ${colors.white};
  font: ${fonts.extraBold};
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Subheading = styled.div`
  color: ${colors.white};
  font: ${fonts.extraLargeRegular};
  text-align: center;
`;

const CancelButton = styled(NoBackgroundButton)`
  color: ${colors.white};
`;

const onClick = (shoppingMode, headline, markAsSeen) => () => {
  analytics.logPopupClick({
    shoppingMode,
    contentName: headline,
  });
  markAsSeen();
};

const FullPopup = ({
  popup: { headline, subHeadline, image, linkText, href },
  cancelText,
  markAsSeen,
  shoppingMode,
}) => {
  useEffect(() => {
    analytics.logPopupView({
      shoppingMode,
      contentName: headline,
    });
  }, [headline, shoppingMode]);
  return (
    <Layout image={image}>
      <Content>
        <Heading>{headline}</Heading>
        <Subheading>{subHeadline}</Subheading>
      </Content>
      <Controls>
        <Control>
          <ButtonLink
            to={href}
            onClick={onClick(shoppingMode, headline, markAsSeen)}
          >
            {linkText}
          </ButtonLink>
        </Control>
        <Control>
          <CancelButton onClick={markAsSeen}>{cancelText}</CancelButton>
        </Control>
      </Controls>
    </Layout>
  );
};

FullPopup.propTypes = LeftPopup.propTypes;

export default FullPopup;
