const type = 'new-cart-wizard/SET_IN_PROGRESS';

const creator = inProgress => ({ type, inProgress });

const reducer = (state, { inProgress }) => ({
  ...state,
  inProgress,
});

export default { type, creator, reducer };
