import { handle } from 'redux-pack';
import {
  persistLastAction,
  checkLastAction,
  clearLastAction,
} from 'lib/request-tracking';
import { isPresent } from 'lib/data-manipulation';

const FETCH_CONTENT = 'staticContent/FETCH_CONTENT';
const FETCH_OBJECT = 'staticContent/FETCH_OBJECT';

export const fetchContent = ({ contentKey, account }) => ({
  type: FETCH_CONTENT,
  meta: {
    contentKey,
    contentfulRequest: {
      content_type: 'staticPage',
      'fields.id': contentKey,
    },
  },
  account,
});

export const fetchObject = fieldId => ({
  type: FETCH_OBJECT,
  meta: {
    contentfulRequest: {
      content_type: 'object',
      'fields.id': fieldId,
    },
  },
});

export const start = prevState => ({
  ...prevState,
  isLoading: true,
  isError: false,
});

export const finish = prevState => ({
  ...prevState,
  isLoading: false,
});

export const failure = (prevState, action) => ({
  ...prevState,
  isError: true,
  error: action.payload,
});

const wrangleStaticContent = payload =>
  isPresent(payload?.items[0])
    ? {
        title: payload?.items[0]?.fields?.title,
        content: payload?.items[0]?.fields?.content,
      }
    : payload?.items[0];

export const success = (prevState, action) => ({
  ...prevState,
  isError: false,
  [action.meta.contentKey]: wrangleStaticContent(action.payload),
});

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTENT:
      return handle(state, action, {
        start: persistLastAction(start),
        finish: checkLastAction(clearLastAction(finish)),
        failure: checkLastAction(failure),
        success: checkLastAction(success),
      });

    case FETCH_OBJECT:
      return handle(state, action, {
        success: () => ({
          ...state,
          faqEmails: action?.payload?.items.find(item => !!item.fields).fields
            .object.regions,
        }),
        failure: prevState => ({
          ...prevState,
          error: action.payload.message,
        }),
      });

    default:
      return state;
  }
};
