import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';

export default mapRouteToProps => Component => {
  const wrapper = props => {
    const { match, location, history } = props;

    const query = QueryString.parse(location.search);

    const newProps = {
      ...props,
      ...mapRouteToProps({ match, location, history, query }, props),
    };

    return <Component {...newProps} />;
  };

  wrapper.displayName = `ConnectRoute(${Component.name})`;

  wrapper.propTypes = {
    match: PropTypes.shape({}),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    history: PropTypes.shape({}),
    children: PropTypes.node,
  };

  wrapper.defaultProps = {
    match: {},
    location: {},
    history: {},
    children: undefined,
  };

  return wrapper;
};
