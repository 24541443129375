const type = 'new-cart-wizard/SET_FORM_FIELD';

const creator = (field, value) => ({ type, field, value });

const reducer = (state, action) => ({
  ...state,
  [action.field]: action.value,
});

export default { type, creator, reducer };
