import debug from 'debug';
import { map, path } from 'ramda';
import { isPresent, isMissing } from 'lib/data-manipulation';

const getPageUrl = page => {
  const spaceId = path(['sys', 'space', 'sys', 'id'], page);
  const pageId = path(['sys', 'id'], page);

  if (isMissing(spaceId) || isMissing(pageId)) {
    return '';
  }

  return `https://app.contentful.com/spaces/${spaceId}/entries/${pageId}`;
};

const buildLogger = namespace => {
  const log = debug(`Marketing:${namespace}`);

  return {
    recordConsideredPages: pages => {
      log('Pages for consideration: %j', map(getPageUrl, pages));
      return pages;
    },

    recordFilteredPages: pages => {
      log('Pages with this placement type: %j', map(getPageUrl, pages));
      return pages;
    },

    recordRankedPages: pages => {
      log('Pages ranked by specificity: %j', map(getPageUrl, pages));
      return pages;
    },

    recordSelectedPage: page => {
      if (isPresent(page)) {
        log('Using Page: %O', page);
      } else {
        log('No Page with this placement type found.');
      }
      return page;
    },
  };
};

export default buildLogger;
