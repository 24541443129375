import { stringify } from './links';

/* Hello Traveler! You may be wondering what this is about...
   It exists as a way to fix broken 301 Redirects. In order
   to ensure that a cached broken 301 is cleared, simply change
   IMAGE_VERSION to a new value (let's just keep incrementing forever)
   and the URL will stop being a cache hit in the browser. Sicky Gnar Gnar.
   AH-650 is the inspiration for this, as 3D images are served from a different
   BaseURL. The proper URL is now returned from the API, but most folks will
   already have a broken 301 cached.
*/
export const IMAGE_VERSION = '2';

export const imageUrl = (materialId, options = {}) => {
  const { type = 'DETAIL', index = null, height = 690, width = 414 } = options;
  const params = stringify({ type, index, height, width, v: IMAGE_VERSION });
  return `/api/materials/${materialId}/image?${params}`;
};

export const thumbnailUrl = (materialId, size = 100) =>
  imageUrl(materialId, {
    type: 'PREVIEW',
    height: size,
    width: size,
  });

export const chipUrl = (materialId, size = 40) =>
  imageUrl(materialId, {
    type: 'CHIP',
    height: size,
    width: size,
  });
