import combineDucklings from 'lib/ducklings';
import selectAccount from './select-account';

const initialState = {
  accounts: [],
  error: null,
  isLoading: false,
  selectedAccounts: {},
  lastRequestId: null,
};

export default combineDucklings(initialState, [selectAccount]);
