import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { compose, pathOr, path } from 'ramda';
import { grafikiShoppingModes } from 'lib/user';
import { noticeError } from 'lib/errors';
import { tryParseDate } from 'lib/dates';
import { connect } from 'react-redux';
import { getSelectedAccountId, getActiveShoppingMode } from 'selectors/account';
import { getActiveCartId } from 'selectors/cart';

const SHIPMENT_DATE_QUERY = gql`
  query getShipmentDate($cartId: ID!) {
    node(id: $cartId) {
      ... on Cart {
        id
        shipmentDate
      }
    }
  }
`;

const FIRST_SHIP_DATE_QUERY = gql`
  query getShipmentDateAccount($accountId: ID!) {
    node(id: $accountId) {
      ... on Account {
        id
        shoppingModes {
          mode
          firstShipDate
          lastShipDate
        }
      }
    }
  }
`;

const getFirstShipDate = async (client, accountId, shoppingMode) => {
  const response = await client.query({
    query: FIRST_SHIP_DATE_QUERY,
    variables: { accountId },
    fetchPolicy: 'network-only',
  });
  const shoppingModes = pathOr([], ['data', 'node', 'shoppingModes'], response);
  const { firstShipDate } =
    shoppingModes.find(
      ({ mode }) => mode === grafikiShoppingModes[shoppingMode],
    ) || {};
  return tryParseDate(firstShipDate);
};

export const tryGetShipmentDate = async ({
  client,
  cartId,
  accountId,
  shoppingMode,
  onUpdate = () => {},
}) => {
  // if no cart ID, get first ship date from account
  if (!cartId) {
    return getFirstShipDate(client, accountId, shoppingMode);
  }
  const watchedQ = client.watchQuery({
    query: SHIPMENT_DATE_QUERY,
    variables: { cartId },
    fetchPolicy: 'network-only',
  });
  watchedQ.subscribe(({ data: { node } }) => {
    if (node && node.shipmentDate) {
      onUpdate(tryParseDate(node.shipmentDate));
    }
  });
  const response = await watchedQ.result();
  const { shipmentDate } = path(['data', 'node'], response);

  // if cart has no shipment date, get first ship date from account
  return shipmentDate
    ? tryParseDate(shipmentDate)
    : getFirstShipDate(client, accountId, shoppingMode);
};

class CurrentShipmentDate extends React.Component {
  static propTypes = {
    shoppingMode: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    activeCartId: PropTypes.string,
    cartId: PropTypes.string,
    client: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cartId: null,
    activeCartId: null,
  };

  state = {
    shipmentDate: null,
  };

  async componentDidMount() {
    this.mounted = true;
    const { shoppingMode, accountId, activeCartId, cartId } = this.props;
    const queryCartId = cartId || activeCartId;
    this.getShipmentDate({ queryCartId, accountId, shoppingMode });
  }

  async componentDidUpdate(prevProps) {
    const {
      shoppingMode: prevShoppingMode,
      accountId: prevAccountId,
      activeCartId: prevActiveCartId,
      cartId: prevCartId,
    } = prevProps;
    const { accountId, shoppingMode, activeCartId, cartId } = this.props;
    const prevQueryId = prevCartId || prevActiveCartId;
    const queryId = cartId || activeCartId;
    if (
      accountId !== prevAccountId ||
      shoppingMode !== prevShoppingMode ||
      queryId !== prevQueryId
    ) {
      this.getShipmentDate({
        queryCartId: queryId,
        accountId,
        shoppingMode,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getShipmentDate = async ({ queryCartId, accountId, shoppingMode }) => {
    const { client } = this.props;
    try {
      const shipmentDate = await tryGetShipmentDate({
        client,
        cartId: queryCartId,
        accountId,
        shoppingMode,
        onUpdate: updatedShipmentDate =>
          updatedShipmentDate && this.setShipmentDate(updatedShipmentDate),
      });
      this.setShipmentDate(shipmentDate);
    } catch (e) {
      noticeError(
        new Error('Error querying for shipment date, defaulting to today'),
        e,
      );
      this.setShipmentDate();
    }
  };

  setShipmentDate = (shipmentDate = new Date()) =>
    this.mounted && this.setState({ shipmentDate });

  render() {
    const { children } = this.props;
    const { shipmentDate } = this.state;
    // shipmentDate will return null as a loading state, getShipmentDate will always return a date object
    // from either the active cart, the selected account, or a default of Today, if none of these are
    // present then the query is still loading, and the child components can handle rendering their own
    // loading and error states individually
    return children(shipmentDate);
  }
}

const mapStateToProps = state => ({
  accountId: getSelectedAccountId(state),
  shoppingMode: getActiveShoppingMode(state),
  activeCartId: getActiveCartId(state),
});

const CurrentShipmentDateConnected = compose(
  withApollo,
  connect(mapStateToProps),
)(CurrentShipmentDate);

export const withShipmentDate = WrappedComponent => {
  const Wrapper = props => (
    <CurrentShipmentDateConnected>
      {shipmentDate => (
        <WrappedComponent {...props} shipmentDate={shipmentDate} />
      )}
    </CurrentShipmentDateConnected>
  );

  hoistNonReactStatics(Wrapper, WrappedComponent);
  return Wrapper;
};

export default CurrentShipmentDateConnected;
