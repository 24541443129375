import React from 'react';
import PropTypes from 'prop-types';
import { prop, find, propEq } from 'ramda';
import { supportedLocales } from 'lib/locales';

const currentLocaleLabel = locale =>
  prop('label', find(propEq('code', locale), supportedLocales));

const LocaleDisplay = ({ locale }) => <span>{currentLocaleLabel(locale)}</span>;

LocaleDisplay.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default LocaleDisplay;
