import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'lib/data-manipulation';
import { ErrorArea } from '../typography';

const Errors = styled(ErrorArea)`
  margin-top: 5px;
`;

export const ValidationError = ({ errors }) =>
  isPresent(errors) && <Errors>{errors}</Errors>;

ValidationError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

ValidationError.defaultProps = {
  errors: [],
};

export default ValidationError;
