import { combineTree } from 'lib/treeify';

export const LOCALSTORAGE_NAMESPACE = 'ua';

const pathsToSync = [
  ['authentication', 'access', 'created'],
  ['authentication', 'access', 'expires'],
  ['authentication', 'access', 'token'],
  ['authentication', 'access', 'permissions'],
  ['authentication', 'activeShoppingMode'],
  ['authentication', 'locale'],
  ['authentication', 'refreshToken'],
  ['authentication', 'selectedAccount'],
  ['authentication', 'username'],
  ['carts', 'activeCart', 'id'],
  ['dismissedCookieConsent'],
  ['marketing', 'seen'],
  ['preferredLocale'],
  ['search', 'viewMode'],
  ['sizeSelections'],
  ['user', 'profile', 'showCustomerPricingOnly'],
  ['user', 'profile', 'name'], // This is used for InMoment Surveys
];

export default store => next => action => {
  const result = next(action);
  const state = store.getState();
  const storedState = combineTree(pathsToSync, state);
  localStorage.setItem(LOCALSTORAGE_NAMESPACE, JSON.stringify(storedState));
  return result;
};
