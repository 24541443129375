import { compose, isNil, join, map, mergeDeepLeft, prop, reject } from 'ramda';
import {
  defaults as orderDetailDefaults,
  resolvers as orderDetailResolvers,
  typeDefs as orderDetailTypeDefs,
} from './order-detail';

export const defaults = {
  orderDetail: orderDetailDefaults,
};

export const resolvers = mergeDeepLeft(orderDetailResolvers)({});

const allTypeDefs = [orderDetailTypeDefs];

const concatIfDefined = field =>
  compose(join('\n'), reject(isNil), map(prop(field)))(allTypeDefs);

export const typeDefs = `
${concatIfDefined('types')}

type Mutation {
  ${concatIfDefined('mutations')}
}

type Query {
  ${concatIfDefined('queries')}
}
`;
